@import 'src/style/variables';
@import 'src/style/mixin';

.setting-noti-secur {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  .copy-success {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    color: $color-01;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 6px 12px;
    background: $color-03;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 21;
  }
  .logo {
    margin-top: 32px;
  }
  .text-des {
    margin-top: 40px;
    color: #1a1a1a;
    font-size: 14px;
  }
  .text-note {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    color: #1a1a1a;
    flex-direction: column;
  }
  .block-account {
    position: relative;
    width: 100%;
    border-radius: 8px;
    padding: 10px 16px;
    border: 1px solid #DC143C;
    max-width: 440px;
    background: #FFF8FA;
    margin-top: 20px;
    @include mq('sm') {
      .email-2fa{
        max-width: 215px !important;
      }
    }
  }
  .text-note1 {
    margin-top: 16px;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    margin-bottom: 16px;
  }

  .block-code {
    width: 340px;
    @include mq('sm') {
      width: 100%;
    }
  }
}