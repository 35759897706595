@import 'src/style/variables';
@import 'src/style/mixin';

.ranking-buyer-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page.uiMobile {
      margin-top: 60px;
      display: none;
    }
  }
}

.buyer-page-creator {
  .list-ranking-buyer {
    padding: 0 24px 40px 24px !important;

    @include mq('sm') {
      padding: 0 16px 40px 16px !important;
      padding-bottom: 0;
      .empty-box {
        position: fixed;
      }
    }
  }
  .fansite-form {
    padding: 16px 16px;
    @include mq('sm') {
      padding: 16px;
    }
    .fansite-form-input__controls {
      margin-bottom: 0;
    }
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
  }
  .empty-box {
    color: $color-14;
    text-align: center;
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
  }
  .select-box {
    display: flex;
    align-items: center;
    .select-label {
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      margin-right: 16px;
      margin-left: 16px;
      @include mq('sm') {
        margin-right: 8px;
      }
    }
  }

  .select-box {
    position: relative;
    &.disabled {
      & > div {
        border-radius: 6px;
        background-color: #eee;
      }
    }
    .anticon {
      position: absolute;
      right: 15.3px;
      top: 50%;
      transform: translateY(-50%);
      color: $color-14;
      z-index: 1;
    }
    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
    .anticon {
      right: 10px;
      pointer-events: none;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 20px;
        fill: none;
      }
    }
  }
}

.ranking-buyer {
  @include mq('sm') {
    .fansite-form {
      flex-wrap: initial;
      align-items: center;
      gap: 12px;

      .fansite-form-input__controls {
        &:last-child {
          width: 42%;
        }
      }

      .sel-buyer {
        width: 100%;
        > div {
          min-width: 94px;
          width: 100%;
        }
      }

      .select-order {
        &__control {
          width: 164px !important;
        }
        &__menu {
          width: 164px !important;
        }
      }

      .select-box {
        gap: 12px;
        .select-label {
          white-space: nowrap;
          width: 40%;
          margin-right: initial;
          margin-left: initial;
        }
      }
    }
  }
}
