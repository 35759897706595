.sales-sumary {
  padding: 36px 24px;
  .list-year {
    display: flex;
    gap: 12px;
    .year {
      padding: 6px 12px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      background: #b1b6bb;
      color: #fff;
      border-radius: 6px;
      cursor: pointer;
      &.active {
        background-color: #ff0000;
      }
    }
  }
}
