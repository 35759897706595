@import 'src/style/variables';
@import 'src/style/mixin';

.withdraw-setting-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.with-draw-container {
  padding: 24px;

  @include mq('sm') {
    padding: 16px;
  }

  .notes {
    display: flex;
    margin-bottom: 24px;

    @include mq('sm') {
      margin-bottom: 16px;
    }

    &__contents {
      padding-left: 16px;
      font-weight: 500;
      font-size: 14px;
      ul {
        margin: 0;
      }
    }
  }

  &__success {
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;

    .confirm {
      background: #f8f8f8;
      position: relative;

      .popup-content {
        padding: 40px 16px 50px 16px;
        max-width: 375px;

        @include mq('sm') {
          padding: 40px 24px 50px 24px;
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .success-text {
            padding: 35px 0 40px 0;
            text-align: center;
            font-weight: 400;
          }
        }
      }
    }

    .go-top {
      background-color: $color-26;
      color: $color-01;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  form {
    .fansite-form-input {
      &__controls {
        margin-bottom: 24px;
      }
    }

    textarea {
      height: 90px;
      padding: 12px;
      border-radius: 6px;
    }

    .btn_submit {
      height: 44px;
      border: none;
      background-color: $color-26;
      color: $color-01;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        background-color: $color-27;
      }
    }
  }

  label {
    font-weight: 500 !important;
    font-size: 14px;
  }
}
