@import 'src/style/variables';
@import 'src/style/mixin';

.like-list-bookmark-layout {
  &.page-view-on-timeline{
    .layout-header {
      display: none;
    }
  }
  min-height: calc((100vh - 60px) - 70px);
  .shimmer-loading {
    padding: 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
  @media (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.disable-like {
  pointer-events: none;
}

.favorite-list-post {
  min-height: calc((100vh - 60px) - 70px);
  .shimmer-loading {
    padding: 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
}

.fan-favorite-wrapper {
  .shimmer-loading {
    padding: 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }

  ul.header.header-bookmarks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    margin: 20px 25px 0px 28px;
    background: #f2f3f5;
    border-radius: 43px;
    height: 48px;

    @media (max-width: 991px) {
      margin: 16px 16px 0 16px;
      box-shadow: none;
    }

    li {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      width: 187.5px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666565;
      cursor: pointer;
      text-align: center;
      border-radius: 43px;
      border: none;
      &.active {
        color: $color-01;
        background-color: #dc143c;
        border-bottom: none;
      }
    }
  }

  .blue-img {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
    // @media (max-width: 575px) {
    //   height: 193px;
    // }
    .bg-img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      min-height: unset;
      max-height: unset;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .image-item {
      position: absolute;
      z-index: 10;
      background: #ffffff;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  .logo-container {
    width: 100%;
    height: 100%;

    .logo-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .bookmark-navigation-checkbox {
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 0 24px;

    @media (max-width: 575px) {
      padding: 20px 16px 25px 16px;
    }

    .navigate-label {
      display: flex;
      max-height: 20px;

      .active-label {
        color: #1a1a1a;
        font-weight: 700;
      }
      .first-label {
        border-right: 1px solid #000000;
        padding-right: 16px;
        margin-right: 16px;
      }
    }

    .checkbox-container {
      display: flex;
      align-items: center;

      .checkbox-label {
        margin-right: 8px;
      }
    }

    .toggle-switch {
      .toggle-switch-switch {
        display: block;
        width: 20px;
        height: 20px;
        margin: 2px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        border: 0 solid #bbb;
        border-radius: 50%;
        transition: all 0.3s ease-in 0s;
      }
    }
  }

  .no-data {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b1b6bb;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fan-list-post.list-data-null {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fan-list-post.my-page {
    min-height: 100% !important;
  }
}

.search-timeline {
  .shimmer-loading {
    padding: 16px 0 !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
}

.fan-favorite-wrapper .fan-view-on-timeline {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  //min-height: 300px;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
  @include mq('sm') {
    padding: 16px;
  }

  .item {
    flex-grow: 1;
    width: calc(50% - 6.5px);
    max-width: calc(50% - 6.5px);
    position: relative;
    margin-bottom: 76px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-collapse: separate; 
    border-radius: 10px;
    background: #fafafa;
    width: 100%;
    height: 186px;
    @include mq('sm') {
      max-width: 100%;
      width: 100%;
      margin-bottom: 76px;
    }

    &.faded {
      animation: fadeOut 2s;
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
  .bookmark-label {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    z-index: 17;
    color: #dc143c;

    &.disabled-bookmark {
      color: #bebebe;
      cursor: default;
    }
  }
  .img-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    cursor: pointer;

    .video-wrapper-bookmark {
      background-color: #1a1a1a;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .bookmark-large-image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .play-button-bookmark {
      width: 100%;
      height: 100%;
      position: absolute;
      @media screen and (min-width: 768px) and (max-width: 820px) {
        min-height: 186px;
      }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        min-height: 186px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .play-button-icon {
        z-index: 1;
      }
    }

    .lock-content-common {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(40px);
      z-index: 17;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      @include mq('sm') {
        margin: 0 16px;
        width: calc(100% - 32px);
      }
      .lock-content-inner {
        background: $color-01;
        border-radius: 19px;
        width: calc(100% - 70px);
        height: 100%;
        max-width: 343px;
        padding: 24px 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transform: scale(0.85);

        @include mq('sm') {
          border-radius: 12px;
          height: 151px !important;
          width: 170px !important;
        }

        .btn-membership {
          background: #dc143c;
          border-radius: 8px;
          color: #ffffff;
        }

        ._packageNew {
          line-height: 24px;
          max-width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0 auto;
        }

        // svg {
        //   width: 15px;
        //   height: 15px;
        // }

        @include mq('sm') {
          .hidden_des {
            display: none;
          }
        }
        ._package {
          max-height: 91px;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 3px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
          }
          &::-webkit-scrollbar-thumb {
            background: #e5e6eb;
          }
        }
        ._price {
          font-size: 14px;
          margin-bottom: 16px;
          // margin-top: 4px;
        }
        ._list-package {
          width: 100%;

          .package-item {
            margin-top: 0 !important;
          }
        }
        .btn {
          width: 100%;
          margin-top: 7px;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 5px;
          padding: 8px 6px;
        }
      }
      .mobile_3_column {
        @include mq('sm') {
          .btn-membership {
            width: 137px !important;
            height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          ._packageNew {
            font-size: 12px !important;
          }
          ._price {
            font-size: 12px !important;
          }
          svg {
            margin-bottom: 0;
          }
          height: 151px !important;
          width: 150px !important;
        }
      }
      .mobile_one_colmn {
        @include mq('sm') {
          .btn-membership {
            width: 192px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: -15px;
          }
          ._packageNew {
            font-size: 12px !important;
          }
          ._price {
            font-size: 14px !important;
            margin-bottom: 0px !important;
          }
          svg {
            margin-bottom: 0;
            width: 15px;
            height: 15px;
          }
          height: 180px !important;
          width: 216px !important;
        }
      }
    }

    .list-images-item {
      padding-bottom: 100%;
    }

    .img-default {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
      padding-top: unset !important;
    }

    .video-detail,
    video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 1/1;
      padding-top: unset !important;
    }

    .video-react-button {
      display: none;
    }
  }

  
.bookmark-lock-package {
  .lock-content-common, .in_my_page {
    margin: 0 16px !important;
    width: calc(100% - 32px) !important;
  }
}

  .thumbnail-bookmark-large {
    .lock-content-common {
      @include mq('sm') {
        margin: 0;
        width: 100%;
        .lock-content-inner {
          @include mq('sm') {
            border-radius: 12px;
            justify-content: flex-end;
            height: 155px !important;
            width: 216px !important;

            ._list-package {
              margin-top: 10px;
            }
          }
        }
      }

      .mobile_3_column {
        @include mq('sm') {
          .btn-membership {
            width: 192px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
          }
        }
      }
    }
  }

  .like-comment-wrapper {
    display: flex;
    align-items: center;
    gap: 13.5px;
    color: $color-08;
    margin: 7.5px 0 7.5px 4px;
    justify-content: space-between;
    overflow: auto;
    &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
      height: 1px;
      background: $color-08;
      border-radius: 10px;
      border-top: 1px white;
      border-bottom: 1px white;
    }
    &::-webkit-scrollbar {
      height: 6px;
    }
  }
  .content-post {
    padding: 0 14px;
    margin-top: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #282727;
  }

  .action-post {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    cursor: default;
  }

  .button-wrapper {
    display: flex;
    gap: 18px;
  }

  .time-post {
    padding-left: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #666565;
  }
}

.fan-favorite-wrapper .fan-list-post {
  &.my-page {
    border-radius: 0px 0px 20px 20px;
  }
  .my-page-item {
    background: #fafafa;
    border-radius: 8px;
    margin: 24px;
    border: none;
    overflow: hidden;

    // .box-popup {
    //   padding: 16px 0;
    // }

    .bookmark-box-popup {
      z-index: 38;
    }

    .search-timeline-popup {
      z-index: 18;
    }

    @media (max-width: 575px) {
      margin: 0 0 16px 0;

      .mobile-timeline {
        padding: 0;
      }

      .box-popup {
        padding: 0;
      }
    }

    .user-infor {
      padding: 16px 16px 0 16px;
    }

    .avatar-infor {
      //padding: unset;
      padding: 0px 0px 16px 0px;
    }

    .content {
      margin: 16px 0;
      padding: 0 16px;
    }

    .interactive {
      background: #fafafa;
      padding: 14px 16px;
    }

    .comment.block {
      padding: 0 16px;
      .form-input-comment-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff !important;
        &.disable {
          background-color: #efefef;
        }
        &.haveMeta {
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          padding: 6px 36px 12px 6px;
          textarea {
            border: none !important;
          }
        }
      }
    }

    .comment-wrap {
      padding: 0 16px;

      .comment-item {
        .content {
          padding: 0;
          .form-edit-comment {
            form {
              .form-item {
                textarea {
                  background: #ffffff;
                  border: 1px solid #e5e6eb;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-list-post {
  box-shadow: none;
  .content-container {
    padding-bottom: 0 !important;
    .fan-favorite-wrapper {
      .bookmark-navigation-checkbox,
      .header.header-bookmarks {
        display: none;
      }

      .fan-list-post {
        .infinite-scroll-component__outerdiv {
          .infinite-scroll-component {
            .my-page-item {
              margin: 0;

              @include mq('md') {
                margin: 16px 0 0 0;
              }

              @include mq('sm') {
                margin: 16px 0 0 0;
              }
            }

            .my-page-item {
              .user-infor-search-timeline {
                .box-popup {
                  padding: 16px 0;
                }
              }

              @media screen and (max-width: 575px) {
                border-radius: 0;

                .user-infor-search-timeline {
                  .box-popup {
                    padding: 0;
                  }
                }

                .mobile-timeline {
                  padding: 0;

                  .content {
                    margin: 8px 0 12px 0;
                  }

                  .lock-content-common {
                    margin: 0 16px;
                    width: calc(100% - 32px);

                    .lock-content-inner {
                      padding: 12px;
                      width: calc(100% - 120px);

                      ._list-package {
                        .package-item {
                          .package-description {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .hashtag-posts {
        padding: 20px 24px;

        @include mq('sm') {
          padding: 0;
        }

        .my-page-item {
          .user-infor {
            .box-popup {
              padding: 16px 0;

              @media screen and (max-width: 575px) {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
