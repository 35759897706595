@import 'src/style/variables';
@import 'src/style/mixin';

.comment-hide-item-parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  &-left {
    .avatar .img-avatar {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    width: calc(100% - 22px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    .info-user {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: calc(100% - 68px);
      &-name-edit {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        width: 100%;
        .info-user-name {
          @media screen and (max-width: 575px) {
            max-width: 150px;
          }
          color: #1a1a1a;
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .info-user-time-edit {
          color: #666565;
          font-size: 12px;
          font-weight: 400;
        }
      }
      &-content {
        color: #1a1a1a;
        font-size: 12px;
        font-weight: 400;
      }
      .interactive {
        display: flex;
        align-items: center;
        gap: 24px;
        @include mq('sm') {
          gap: 20px;
        }
        &-item {
          display: flex;
          gap: 4px;
          align-items: center;
          .like-icon,
          .chat-icon {
            cursor: pointer;
            color: #666565;
            svg {
              width: 18px;
              height: 18px;
            }
            &.active {
              color: $color-26;
            }
          }
          .count {
            font-size: 14px;
            font-weight: 400;
            color: #666565;
          }
        }
      }
    }
  }
  &-right {
    width: 22px;
    .icon-three {
      padding: 4px;
      cursor: pointer;
    }
  }
}
.wrap-item-child-hide-comment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 12px;
  gap: 12px;
  .view-more-hide-comment {
    width: calc(100% - 48px);
    cursor: pointer;
    color: #787880;
    font-size: 12px;
    font-weight: 500;
  }
}
.comment-hide-item-child {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  &-left {
    .avatar .img-avatar {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      cursor: pointer;
    }
    width: calc(100% - 22px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    .info-user {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      width: calc(100% - 48px);
      &-name-edit {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        width: 100%;
        .info-user-name {
          color: #1a1a1a;
          font-size: 14px;
          font-weight: 500;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .info-user-time-edit {
          color: #666565;
          font-size: 12px;
          font-weight: 400;
        }
      }
      &-content {
        color: #1a1a1a;
        font-size: 12px;
        font-weight: 400;
      }
      .interactive {
        display: flex;
        align-items: center;
        gap: 24px;
        @include mq('sm') {
          gap: 20px;
        }
        &-item {
          display: flex;
          gap: 4px;
          align-items: center;
          .like-icon,
          .chat-icon {
            cursor: pointer;
            color: #666565;
            svg {
              width: 18px;
              height: 18px;
            }
            &.active {
              color: $color-26;
            }
          }
          .count {
            font-size: 14px;
            font-weight: 400;
            color: #666565;
          }
        }
      }
      .view-more {
        color: #787880;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
  &-right {
    width: 22px;
    .icon-three {
      padding: 4px;
      cursor: pointer;
    }
  }
}
