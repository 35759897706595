@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-banner {
  background-color: $color-01;
  .list-content {
    display: flex;
    justify-content: center;
    padding: 60px 0;
    gap: 30px;
    &.no-padding {
      padding: 30px 0 0 0;
    }
    img {
      max-width: 344px;
      width: 100%;
      height: 122px;
      object-fit: cover;
    }
  }
}

@include mq('l') {
  .list-idol-banner {
    .list-content {
      padding: 30px 20px 30px 20px;
      &.no-padding {
        padding: 30px 20px 0 20px;
      }
      img {
        max-width: 300px;
      }
    }
  }
}

@include mq('md') {
  .list-idol-banner {
    .list-content {
      padding: 32px;
      flex-direction: column;
      align-items: center;
      img {
        max-width: 100%;
        height: 168px;
      }
    }
  }
}

@include mq('sm') {
  .list-idol-banner {
    .list-content {
      padding: 16px;
      &.no-padding {
        padding: 16px 16px 0 16px;
      }
      img {
        height: 122px;
      }
    }
  }
}
