@import 'src/style/variables';
@import 'src/style/mixin';

.profile-info {
  .fansite-form-input {
    &__label.form-label {
      display: flex !important;
    }
  }
  .avatar-container {
    margin-top: -60px;
    margin-bottom: 40px;
    @include mq('sm') {
      margin-top: -52px;
      margin-bottom: 16px;
    }
  }
  .back-to-page {
    display: flex;
    box-shadow: none;
    border-bottom: 1px solid #e5e6eb;
  }
  @include mq('sm') {
    .wrapper__btn-comfirm {
      display: flex;
      // width: 100%;
      justify-content: space-between;
      .btn_keep {
        width: 50%;
      }
      .btn_return {
        width: 50%;
      }
    }
  }
}

.ps-bottom {
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: #fff;
}

.ps-bottom-moblie {
  position: fixed;
  bottom: 40px;
  z-index: 100;
  width: 100%;
  padding: 0 16px;
  left: 0;
  right: 4px;
  background: #fff;
  .fansite-form-input__controls {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1024px) {
    position: sticky;
    bottom: 0px;
    padding-bottom: 2px;

    .fansite-form-input__controls {
      padding-top: 8px;
    }
  }
}

.mr-top {
  margin-top: 15px;
}

.shimmer-loading {
  padding: 24px;
}

.float-right {
  float: right;
}

.rejection-btn {
  color: #f6851f;
  background: rgba(255, 143, 80, 0.2);
  border-radius: 4px;
  pointer-events: none;
}

.select-grey > .css-1s2u09g-control {
  background: #eeeeee;
}

.select-country {
  z-index: 4;
}

.bg-while {
  &:hover {
    background-color: #f1f1f1 !important;
  }
}

.detail-btn {
  padding: 6px 12px !important;
  border-radius: 4px !important;
  text-align: center;
  font-weight: 500;
  cursor: unset;
}

.infoPersonal {
  .fansite-form-input__label.form-label {
    font-weight: 500;
  }
}

.green-status {
  color: #57c22d !important;
  background: #f6ffed !important;
  border: 1px solid #b7eb8f;
}

.btn-email {
  background: #dc143c;
  border-radius: 8px !important;
  color: #fdfefe;
  padding: 10px 24px;
  &:hover {
    background-color: $color-27;
  }
}

.btn-review {
  background: #dc143c;
  border-radius: 8px !important;
  color: #fdfefe;
  padding: 10px 24px;
  &:hover {
    background-color: $color-27;
  }
}

.yellow-status {
  color: #faad14;
  background: #fffbe6;
  border: 1px solid #ffe58f;
}

.grey-status {
  background: #efefee;
  color: #666565;
  border: 1px solid #d1d0cf;
}

.creator-profile-info .custom-input input {
  border: 1px solid #e5e6eb !important;
}

.orange-status {
  color: #faad14;
  background: #fffbe6;
  border: 1px solid #ffe58f;
}

.red-status {
  background: #fff1f0;
  color: #dc143c;
  border: 1px solid #ffccc7;
}

.dark-status {
  background-color: #2b2b2b;
  color: white;
}

.gender,
.birthday {
  position: relative;
  .anticon {
    top: 0;
    bottom: 0;
    position: absolute;
    right: 15px;
    transform: none;
    color: $color-14;
    z-index: 4;
    height: 44px;
    display: flex;
    align-items: center;
  }
  input {
    padding: 9px 30px 9px 12px;
  }
}
.birthday {
  .anticon {
    user-select: none;
    pointer-events: none;
    cursor: pointer;
  }
}

.gender {
  .anticon {
    pointer-events: none;
  }
  .anticon.focus {
    transform: rotateX(180deg);
  }
}

.creator-request-leave {
  ul {
    list-style: none;
  }
  .text-confirm {
    margin-bottom: 0;
    padding: 0 0 24px 0;
  }
}

.creator-profile-info {
  @include mq('md') {
    padding-bottom: 70px;
  }
  .birthday {
    width: 100%;
  }

  .block-field {
    display: flex;
    @include mq('sm') {
      flex-direction: column;
    }
    .box-field {
      flex: 1;
    }

    .box-field:first-child {
      margin-right: 24px;
      @include mq('sm') {
        margin-right: 0px;
      }
    }
  }

  .gender {
    .anticon {
      right: 10px;
      &.focus1 {
        svg {
          transform: rotate(180deg) !important;
        }
      }
      &.no-focus1 {
        svg {
          transform: rotate(0deg) !important;
        }
      }
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  input,
  select {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    border: 1px solid $color-14;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 9px 12px;
    z-index: 2;
    height: 44px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
