@import 'src/style/variables';
.main-video,
.main_video,
.trailer-video,
.trailer_video {
  .video-react {
    //padding-top: 360px !important;
    padding-top: 56.25% !important;
    @media screen and (max-width: 991px) {
      padding-top: 56.25% !important;
    }
    @include mq('sm') {
      padding-top: 56.25% !important;
    }
  }
}

.post-content-sale-view {
  .video-detail.main_video {
    .video-detail {
      background-color: #1a1a1a;
    }
  }
}

.video-react .video-react-poster {
  background-size: contain !important;
  aspect-ratio: 4/3;
  margin: 0;
  position: absolute;
  width: 100%;
}

.fansite-creator-post-detail {
  .video-react {
    .video-react-video {
      background: #1a1a1a;
    }
    .video-react-button.video-react-big-play-button {
      border: none;
      background: url(../../images/PlayIcon.svg);
      width: 56px;
      height: 68px;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 0;
      transform: translate(-30%, -50%);
    }
  }
}

.box-img-video {
  .video-react-video {
    background: #1a1a1a;
  }
}

.post-content-sale-view {
  // .video-react-video {
  //   background: #1a1a1a;
  // }
  .video-react {
    .video-react-button.video-react-big-play-button {
      border: none;
      background: url(../../images/PlayIcon.svg);
      width: 56px;
      height: 68px;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 0;
      transform: translate(-30%, -50%);
    }
  }
}

.fansite-creator-post-normal {
  .video-react-video {
    background: #1a1a1a;
  }
  .video-react {
    .video-react-button.video-react-big-play-button {
      border: none;
      background: url(../../images/PlayIcon.svg);
      width: 56px;
      height: 68px;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 0;
      transform: translate(-30%, -50%);
    }
  }
}

.fansite-creator-post-content {
  .video-react-video {
    background: #1a1a1a;
  }
  .video-react {
    .video-react-button.video-react-big-play-button {
      border: none;
      background: url(../../images/PlayIcon.svg);
      width: 56px;
      height: 56px;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 0;
      transform: translate(-30%, -50%);

      @include mq("sm") {
        width: 45px;
        height: 55px;
      }
    }
  }
}
