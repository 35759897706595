@import 'src/style/variables';
@import 'src/style/mixin';

.scren-slide-sigin {
  .home-quick-views-container-singin {
    aspect-ratio: 4/3;
    transition: 0.2s;
    width: 100%;
    // background-color: $color-02;
    img {
      object-fit: contain !important;
      aspect-ratio: 4/3;
    }
  }
  
  .mt-100 {
    margin-top: 100px;
  }
  
  .mt-50 {
    margin-top: 50px;
  }
  
  .mt-24 {
    margin-top: 24px;
    .home-quick-views-container-singin.d-flex {
      margin-top: 13px !important;
    }
  }
  
  .home-quick-views-container-singin.d-flex {
    width: 534px;
    height: 334px;
    margin: auto;
    padding-left: 1px;
  }
  
  .bg-icon-smaller {
    width: 53px;
    height: 53px;
  }
  
  .slick-slider { 
    width: 670px;
    height: 425px;
  }
  
  .block-div-signin .slick-dots {
    position: absolute;
    bottom: -18px !important;
  }
  
  .slick {
    &-dots {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      li {
        button:before {
          font-family: 'slick';
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 3px;
          border-radius: 5px;
          content: '';
          background-color: $color-14;
          order: 2px solid $color-14;
          text-align: center;
          opacity: 1;
        }
        &.slick-active {
          button {
            &:before {
              background-color: #ff0000;
            }
          }
        }
        position: relative;
        display: inline-block;
        padding: 0;
        cursor: pointer;
        border-color: #ff0000;
        &:before {
          width: 10px;
          border-radius: 5px;
          background: #ff0000;
          height: 5px;
          color: #ff0000;
          &:hover {
            text-align: center;
            opacity: 0.25;
            display: none;
          }
        }
      }
    }
  }
}
