@import 'src/style/variables';
@import 'src/style/mixin';

.group-upload-input {
  display: flex;
  align-items: center;
  .group-upload-desc {
    margin-left: 16px;
    color: #666565;
    font-weight: 400;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 16px;
  }
  @include mq('md') {
    flex-direction: column;
    align-items: flex-start;
    .group-upload-desc {
      margin-left: 0px;
      margin-top: 6px;
    }
  }
}
.file-multiple-selected {
  .file-selected-row {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    .file-selected-item {
      display: inline-block;
      width: 40%;
      max-width: 235px;
      vertical-align: top;
      white-space: normal;
      margin-right: 8px;
      @include mq('md') {
        margin-right: 5px;
      }
      @include mq('sm') {
        margin-right: 20px;
      }
      .file-selected-item-inner {
        position: relative;
        padding-top: 115%;
        overflow: hidden;
        border-radius: 4px;
        .selected-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #f1f1f1;
          border-radius: 4px;
          video {
            object-fit: cover;
          }
        }
        .delete-file-selected {
          cursor: pointer;
          position: absolute;
          z-index: 1;
          top: 16px;
          right: 16px;
          color: $color-01;
          @include mq('md') {
            top: 6px;
            right: 6px;
          }
          svg {
            width: 32px;
            height: 32px;
            @include mq('md') {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

@include mq('md') {
  .footer-list-idol {
    padding: 0 0 16px 0;
    flex-direction: column;
    align-items: center;
    .content-left .info {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      gap: 16px;
    }
    .content-right {
      margin-top: 18px;
      gap: 16px;
      margin-bottom: 28px;
    }
  }
}
