@import 'src/style/variables';
@import 'src/style/mixin';

.popup-wrap-timline-mess {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.8);
  .close-icon {
    position: relative;
    left: -40px;
    top: 30px;
    z-index: 10;
    background-color: #000;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    svg {
      display: flex;
      width: 25px;
      height: 25px;
      color: white;
    }
  }

  .comment-wrap-popup {
    position: relative;
    margin-top: 25px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sroll-list {
      padding: 0 16px;
      height: calc(100vh - 140px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
      }
    }
    .div-list-cmt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      border-top: 1px solid #ccc;
      padding: 16px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  .card-item--detail {
    &-line {
      width: 94%;
      border-bottom: 1px solid #e5e6eb;
      margin: auto;
    }

    .comment {
      form {
        .form-item {
          textarea {
            background: #fff;
          }
        }
      }
    }
  }
  .card-item--detail--header {
    display: flex;
    padding: 24px;
    gap: 4px;
    &-title {
      font-weight: 700;
      font-size: 16px;
      color: #1a1a1a;
    }
    &-comment {
      font-weight: 700;
      font-size: 16px;
      color: rgba(102, 101, 101, 1);
    }
  }
}

.left-content-comment-mess {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 20px;
  width: 720px;
  height: 900px;

  // background: #fff;
  // margin-top: 30px;
  // border-radius: 20px;
  // width: 720px;
  // height: 900px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 50px;

  .d-middle-popup-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .cls-title {
    width: 50%;
  }
  .line {
    width: 94%;
    border-bottom: 1px solid #e5e6eb;
    margin: auto;
  }

  .box-popup {
    position: relative;
    margin-left: auto;
    .three-point-icon {
      z-index: 4;
      cursor: pointer;
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 189px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      top: 2;
      //top: calc(100% + 2px);
      right: 0;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07;
        }
        &:first-child {
          margin-top: 13px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
