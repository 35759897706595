.height-fit-content {
  height: min-content !important;
  .button-upload {
    border-radius: 6px;
    cursor: pointer;
    background-color: #dc143c;
    color: #fff;
    padding: 8px 0px;
    width: 270px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
  }
  .button-preview {
    color: #dc143c;
    border-radius: 6px;
    border: 1px solid #e5e6eb;
    padding: 8px 0px;
    width: 270px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
  }
  .button-cancel {
    color: #1a1a1a;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #e5e6eb;
    padding: 8px 0px;
    width: 270px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
  }
}
