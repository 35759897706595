@import 'src/style/variables';
@import 'src/style/mixin';

.list-buyer {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}
.buyer-page-creator {
  ::-webkit-input-placeholder {
    color: #B1b6bb  !important;
  }
  height: 100%;
  padding: 0px 0 40px 0;
  .shimmer-loading {
    padding: 16px 24px !important;
    min-height: 100%;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
  @include mq('sm') {
    padding-bottom: 0;
    .form-buyer {
      padding: 16px !important;
      .fansite-form-input__controls {
        width: 100%;
      }

      .select-buyer > div {
        width: 100%;
        .anticon {
          pointer-events: none;
        }
      }
    }
  }
  .fansite-form {
    .fansite-form-input__controls {
      margin-bottom: 0;
    }
  }
  .empty-box {
    color: $color-14;
    text-align: center;
    padding: 100px 20px;
  }
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;

    img {
      max-width: 95px;
      max-height: 92px;
      background: transparent;
    }
  }

  .list-buyer-creator {
    padding-top: calc(24px - 16px);

    @media screen and (max-width: 991px) {
      min-height: 300px;
      .no-data {
        position: fixed;
      }
    }
  }
  
  .button-search {
    width: 73px;
    height: 44.6px;
    background: #28c76f;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #11B259 !important;
    }
  }

  .form-search {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    @include mq('sm') {
      width: 100%;
    }
    // @media screen and (max-width: 575px) {
    //   padding: 16px 16px 0 16px;
    // }

    .form-item {
      width: 193px;
      height: 100%;
      margin-bottom: 16px;
      position: relative;

      @media screen and (max-width: 991px) {
        width: 90%;
      }

      .input-field {
        width: 100%;
        padding: 11px 12% 11px 36px;
        outline: none;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 11px 2% 11px 36px;

        &.is_input {
          padding: 11px 16% 11px 36px;
          @media screen and (max-width: 575px) {
            padding: 11px 16% 11px 36px;
          }
        }
      }

      .icon-container {
        min-width: 40px;

        .search-icon {
          padding: 14px 10px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #787880;
        }
        .clear-icon {
          padding: 14px 10px;
          position: absolute;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          // @media screen and (max-width: 1240px) {
          //   right: 18%;
          // }
          // @media screen and (max-width: 1050px) {
          //   right: 20%;
          // }
          // @media screen and (max-width: 991px) {
          //   right: 12%;
          // }
          // @media screen and (max-width: 900px) {
          //   right: 13%;
          // }
          // @media screen and (max-width: 820px) {
          //   right: 14%;
          // }
          // @media screen and (max-width: 750px) {
          //   right: 15%;
          // }
          // @media screen and (max-width: 700px) {
          //   right: 16%;
          // }
          // @media screen and (max-width: 650px) {
          //   right: 17%;
          // }
          // @media screen and (max-width: 650px) {
          //   right: 18%;
          // }
          // @media screen and (max-width: 575px) {
          //   right: 17%;
          // }
          // @media screen and (max-width: 575px) {
          //   right: 18%;
          // }
          // @media screen and (max-width: 520px) {
          //   right: 19%;
          // }
          // @media screen and (max-width: 490px) {
          //   right: 21%;
          // }
          // @media screen and (max-width: 450px) {
          //   right: 22%;
          // }
          // @media screen and (max-width: 420px) {
          //   right: 24%;
          // }
          // @media screen and (max-width: 390px) {
          //   right: 27%;
          // }
        }
      }
    }

    .button-search {
      width: 73px;
      height: 44.6px;
      background: #28c76f;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background: #11B259 !important;
      }
    }
  }

  .account-buyer {
    padding: 10px 24px;
    //display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      min-height: 56px;
      max-height: 56px;
      min-width: 56px;
      max-width: 56px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .lable-text {
      color: #666565;
      font-size: 14px;
    }
    .value-text {
      color: #1a1a1a;
      font-weight: 500;
    }
    .max-title {
      max-width: 520px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mq('sm') {
        max-width: 250px;
      }
    }
    .border-card {
      margin-top: 15px;
      border: 1px solid #E5E6EB;
    }
    .content-left {
      display: flex;
      align-items: center;
      & > div {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .list-sub {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #787880;
          @include mq('sm') {
            max-width: 290px;
            word-wrap: break-word;
          }
          .item-list-sub {
            color: #666565;
          }
        }
      }
    }
  }
}
