.about-company-page {
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  div {
    margin-bottom: 8px;
    text-align: justify;
  }
  h2 {
    font-weight: 700;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}
