@import 'src/style/variables';
@import 'src/style/mixin';

.popup-wrap {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .loading-timeline {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 1000;
    height: 100%;
  }
}

.popup-view-full-post-timeline5 {
  width: 75%;
  background: rgba(26, 26, 26, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px;
  @media screen and (max-width: 640px) {
    padding: 40px 30px;
  }

  .lock-screen {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .text-follow {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    button {
      background-color: #dc143c;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 11px 24px;
      border-radius: 8px;
      font-size: 12px;
      &:hover {
        background-color: #CE1338 !important;
      }
    }
  }

  .cls-title-2nd {
    color: #787880;
    margin-top: 0;
  }

  .close-icon {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 18;
    background-color: #000;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    svg {
      display: flex;
      width: 32px;
      height: 32px;
      color: white;
    }
  }

  .pagination-slide {
    position: absolute;
    bottom: 10px;
    z-index: 10;
    width: 45px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, 0.3);
    border-radius: 2px;
    span {
      font-size: 12px;
      color: white;
    }
    &.hidden {
      display: none;
    }
  }

  .slide-image-full-view {
    width: 100%;
    height: 100%;

    .slick-arrow {
      background: $color-26;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      z-index: 1;
      &:before {
        display: none;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          > div {
            height: 100%;
          }
        }
      }
      .list-images-item {
        height: 100%;
        padding: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        .change-bg {
          background-color: transparent;
          // width: 775px;
          // height: 720px;
        }
        .button-play-icon {
          display: flex;
          cursor: pointer;
          position: absolute;
          z-index: 3;
          width: 68px;
          height: 48px;
          padding: 2px;
          border: none;
          border-radius: 14px;
          background: transparent;
          inset: 0;
          margin: auto;
          color: white;
          .anticon {
            display: flex;
            margin: 0 auto;
            align-items: center;
          }
        }
      }
    }
    .slick-dots {
      li {
        width: 50px;
        height: 50px;
        button {
          width: 50px;
          height: 50px;
          &:before {
            font-size: 20px;
            top: 15px;
            left: 15px;
            color: white;
          }
        }
      }
    }
  }

  img,
  video {
    cursor: pointer;
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain !important;
    margin: auto;
    aspect-ratio: 16/9;
    &:focus {
      outline: none;
    }
  }
  .video-safari {
    video {
      min-height: 100%; 
      min-width: 100%; 
      height: auto !important;
      width: auto !important; 
    }
  }

  video {
    min-width: 854px;
    min-height: 480px;
    width: 100%;
    height: 100%;
  }
  @include mq('sm') {
    video {
      min-height: 100%; 
      min-width: 100%; 
    }
  }
}

.left-content-comment {
  width: 25%;
  height: auto;
  background-color: #fff;
  //padding: 16px;
  .comment-wrap-popup {
    .div-list-cmt {
      .comment.block {
        form {
          .form-item {
            textarea {
              background-color: #ffffff;
              &:disabled {
                background-color: #efefef;
              }
            }
          }
          .anticon {
            position: absolute;
            // bottom: 10.67px;
            right: 11px;
            z-index: 3;
            //transform: rotate(45deg) !important;
            cursor: pointer;
            color: $color-14;
            &.active {
              color: $color-06;
            }
            svg {
              width: 19px;
              height: 21px;
            }
          }
        }
      }
      
    }
  }

  .d-middle-popup-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .div-ghim2 {
    position: relative;
    .popup-ghim2 {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 229px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      left: 10px;
      top: 30px;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        background: $color-01 !important;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07 !important;
          border-radius: 0px;
        }
        &:first-child {
          margin-top: 6px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .cls-title {
    width: 50%;
  }

  .line {
    width: 94% !important;
    border-bottom: 1px solid #e5e6eb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto !important;
  }

  .box-popup {
    position: relative;
    margin-left: auto;
    .three-point-icon {
      z-index: 4;
      cursor: pointer;
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 229px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      right: 0;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        background: $color-01 !important;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07 !important;
          border-radius: 0px;
        }
        &:first-child {
          margin-top: 6px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.comment-wrap-popup {
  position: relative;
  // margin-top: 25px;
  //height: calc(100% - 242px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sroll-list {
    padding: 0 16px;
    max-height: calc(100% - 100px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    .list-comment .comment-item .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      overflow: visible;
    }
  }
  .div-list-cmt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 16px;
    z-index: 2000;
  }
}
