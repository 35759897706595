@import 'src/style/variables';
@import 'src/style/mixin';
.calendar-page {
  background-color: #fff;
  padding-bottom: 12px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  .event-container {
    // border-top: 1px solid #eeeeee;
    padding-bottom: 50px;
    .event-time {
      display: flex;
      align-items: flex-end;
      position: relative;
      &.admin {
        // background-color: $color-07;
        cursor: auto;
        pointer-events: none;
      }
      .hour {
        display: flex;
        align-items: flex-end;
        color: #787880;
        font-size: 14px;
        margin-left: 15px;
        height: 46px;
      }
      .desc {
        border-bottom: 1px solid #eeeeee;
        width: 100%;
        min-height: 40px;
        position: relative;
        cursor: pointer;

        .item {
          padding-left: 5px;
          display: flex;
          align-items: center;
          margin: 4px 0;
          cursor: pointer;
          img {
            height: 32px;
            width: 32px;
            min-width: 32px;
            min-height: 32px;
            object-fit: unset;
            border-radius: 50%;
            margin-right: 8px;
          }
          .name {
            color: #282727;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .text {
              max-width: 200px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .live {
            margin-left: 8px;
            padding: 2px 9px;
            color: #fff;
            background: #ff0000;
            border-radius: 4px;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          }
          .content {
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-height: 20px;
            max-height: 20px;
            min-width: 100px;
            max-width: 100px;
            -webkit-line-clamp: 1;
          }
          .content,
          .time {
            color: #787880;
            font-weight: 500;
            font-size: 12px;
          }
        }
        .icon-show-more {
          position: absolute;
          top: 15px;
          right: 10px;
          color: $color-08;
          cursor: pointer;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }
    .event-time:last-child {
      .hour {
        opacity: 0;
      }
      .desc {
        border-bottom: none;
      }
    }
  }
}

.calendar-page.fan {
  background-color: $color-09;
  padding-bottom: 0;
  .event-container {
    padding-bottom: 0;
  }
  .content {
    max-width: 150px !important;
    @include mq('sm') {
      min-width: unset !important;
      max-width: 77px !important;
    }
  }
  .name label:first-child {
    max-width: calc(var(--widthItemItemCalendarFan) - 160px) !important;
  }
}

@include mq('xl') {
  .calendar-page {
    min-width: 330px;
  }
}
@include mq('l') {
  .calendar-page {
    min-width: 268px;
  }
  .calendar-page.fan {
    .event-container {
      .event-time {
        .hour {
          margin-left: 5px;
        }
      }
    }
  }
}
@include mq('md') {
  .calendar-page.fan {
    .event-container {
      .event-time {
        .hour {
          margin-left: 15px;
        }
      }
    }
  }
}
