@import 'src/style/variables';
@import 'src/style/mixin';

//short
.fansite-creator-post-short {
  background-color: $color-01;
  padding: 16px 24px;

  .avatar-infor.avatar-infor-creator {
    padding: 0;
    .content-left {
      span {
        color: $color-03 !important;
      }
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
      }
    }
  }
  .follow-flag {
    display: flex;
    align-items: center;
    gap: 12px;
    & > div {
      position: relative;
    }
    .anticon {
      position: absolute;
      right: 15.3px;
      top: 50%;
      transform: translateY(-50%);
      color: #b1b6bb;
      z-index: 1;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 11px;
      }
    }
  }

  .inputFile {
    position: relative;
    cursor: pointer;
    &.disableImgInput,
    &.disableVideoInput {
      cursor: not-allowed !important;
      pointer-events: none;
      background: #fff;
      opacity: 0.5;
      input,
      span {
        cursor: not-allowed !important;
      }
    }
    .btn-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 6px 0;
      border-radius: 6px;
      width: 151px;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      cursor: pointer;
      z-index: 0;
      border: 1px solid $color-03;
      color: $color-03;
    }
    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  form {
    padding: 16px 0;
    .box-img-video {
      .box-btn-upload {
        margin-bottom: 25px;
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        color: $color-03;
      }

      .list-item {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: auto;
        gap: 8px;
        height: auto;
        padding-bottom: 8px;
        &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
          height: 1px;
          background: #d9d9d9;
          border-radius: 10px;
          border-top: 1px white;
          border-bottom: 1px white;
        }
        &::-webkit-scrollbar {
          height: 6px;
        }
        .box-item {
          position: relative;
          overflow: hidden;
          // background-color: $color-02;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            object-fit: contain;
            width: 100%;
          }
          .anticon {
            position: absolute;
            top: 16px;
            right: 16px;
            background-color: $color-06;
            color: $color-01;
            padding: 3px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            svg {
              width: 18px;
              height: 18px;
            }
            @include mq('sm') {
              padding: 2px;
              top: 12px;
              right: 12px;
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
        .box-img1 {
          &.normal {
            min-width: 500px;
            min-height: 375px;
            max-width: 500px;
            max-height: 375px;
            img {
              aspect-ratio: 4/3;
            }
          }
          &.short {
            min-width: 500px;
            min-height: 750px;
            max-width: 500px;
            max-height: 750px;
            img {
              aspect-ratio: 2/3;
            }
          }

          @include mq('sm') {
            &.normal {
              min-width: 343px;
              min-height: 257px;
              max-width: 343px;
              max-height: 257px;
            }
            &.short {
              min-width: 340px;
              min-height: 510px;
              max-width: 340px;
              max-height: 510px;
              img {
                aspect-ratio: 2/3;
              }
            }
          }
        }
        .box-img2 {
          &.normal {
            min-width: 344px;
            min-height: 258px;
            max-width: 344px;
            max-height: 258px;
            img {
              aspect-ratio: 4/3;
            }
          }
          &.short {
            min-width: 341px;
            min-height: 512px;
            max-width: 341px;
            max-height: 512px;
            img {
              aspect-ratio: 2/3;
              max-height: inherit;
            }
          }
          @include mq('sm') {
            &.normal {
              min-width: 168px;
              min-height: 126px;
              max-width: 168px;
              max-height: 126px;
            }
            &.short {
              min-width: 160px;
              min-height: 240px;
              max-width: 160px;
              max-height: 240px;
            }
          }
        }
        &.has-video {
          margin-bottom: 16px;
        }
      }
      .list-item.list-videos {
        .box-item {
          min-width: 500px;
          max-width: 500px;
          max-height: 750px;
        }
        .video-react {
          padding-top: 0 !important;
          height: 100%;
        }
        video {
          width: 100%;
          position: relative;
          aspect-ratio: 2/3;
          max-height: inherit;
        }
      }
    }
    input {
      border: 1px solid $color-14 !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    input::placeholder,
    textarea::placeholder,
    .textarea.title[contenteditable]:empty::before,
    .textarea.content[contenteditable]:empty::before {
      color: $color-14;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    input:read-only {
      cursor: pointer;
    }
    .form-control[readonly] {
      background-color: transparent;
    }
    .form-label {
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 22px !important;
    }
    .fansite-form-input__controls {
      margin-bottom: 16px;
    }
    .form-label.fansite-form-input__label {
      font-weight: normal;
      margin-bottom: 4px;
      .fansite-form-input__required {
        display: none;
      }
    }
    .form-item-switch {
      display: flex;
      align-items: center;
      .form-label {
        margin-bottom: 0 !important;
        margin-right: 8px;
      }
    }
    .box-category {
      .form-label {
        margin-bottom: 8px !important;
      }
      .box-checkbox,
      .box-radio-checkbox {
        display: flex;
        flex-direction: column;
      }
    }

    input,
    textarea,
    .textarea {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 9px 12px;
      z-index: 2;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $color-14;
        font-size: 14px;
      }
    }

    .text-post {
      margin-top: 16px;
      margin-bottom: 0;
      &.has-img {
        margin-bottom: 16px;
      }
      textarea {
        height: 25px;
        min-height: 25px;
        border: none;
        padding: 0;
      }

      &-title {
        border-bottom: 1px solid $color-07;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }
    .button-submit {
      &.disabled {
        opacity: 0.5;
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }

      button {
        height: 100%;
        width: 100%;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }

  @include mq('sm') {
    padding: 16px;
    .fansite-form {
      padding: 16px 0;
    }
    .date-time-wrapper {
      gap: 12px;
    }
    .list-item.list-videos .box-item {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
  .box-time-sell {
    flex-direction: column;
    .fansite-form-input__controls {
      width: 100%;
    }
    .start-time,
    .end-time {
      width: 100%;
    }
  }
}

.fansite-creator-post-short.edit {
  .text-post textarea {
    height: fit-content;
  }
  .list-button-submit {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
    & > div {
      flex-basis: 100%;
    }
    .button-submit.delete {
      button {
        border: 1px solid #787880;
        color: $color-03;
        background-color: #fff;
      }
    }
    &.short {
      gap: 17px;
      button {
        border: 1px solid #787880;
        color: $color-03;
        background-color: #fff;
      }
    }
  }
}
.creator-post-short-detail {
  .video-custom {
    min-width: 500px;
    max-width: 500px;
    max-height: 750px;
    // background-color: $color-02;
    @include mq('sm') {
      min-width: 100%;
      max-width: 100%;
      max-height: 510px;
    }
  }
  .list-img-timeline {
    max-height: 750px;
    max-width: 500px;
    @include mq('sm') {
      max-height: 510px;
    }
  }
  .text {
    margin-bottom: 16px;
  }
  .slick-arrow {
    &.slick-next {
      right: 12px;
    }
    &.slick-prev {
      left: 12px;
    }
  }
}
