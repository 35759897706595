@import 'src/style/variables';
@import 'src/style/mixin';

.block-div-vender {
  //background: url('../../images/bg-footer.png');
  background-repeat: repeat;
  background-size: cover;
  //padding-top: 38px;
  padding: 20px;
  background: #fff;
  .footer-list-idolv2 {
    max-width: 1000px;
    height: 104px;
    background: #E5E6EB;
    border-radius: 12px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 35px;
    justify-content: space-between;
    padding: 28px 36px;

    .content-left {
      max-width: 1065px;
    }
    .content-left .contact-us {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      text-transform: uppercase;
      color: #DC143C;
    }

    .content-left .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #4B5768;
      margin-top: 4px;
    }

    .content-right {
      width: 457px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
    }

    .content-right .text-right{
      display: flex;
      justify-content: space-between;
      padding: 13px 0px 13px 29px;
    }

    .text-right .input-mail {
    border: none;
    text-align: left;
    margin-left: 5px;
    }

    .text-right .icon-mail {
      margin-right: 10px;
      margin-top: 5px;
    }

    .content-right .button{
      padding: 6px;
    }

    .content-right .button-submit{
      width: 94px;
      height: 36px; 
      background: #DC143C;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      line-height: 23px;
    }

  }

  .block-center {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;

    .content-left {
      padding-left: 0;
    }

    .content-left .text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #4B5768;
      margin-top: 10px;
    }

    .content-left .text-big {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #4B5768;
    }

    .content-right .text{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #DC143C;
      text-transform: uppercase;
      font-weight: bold;
    }

    .content-right .block-follow{
      padding-left: 0;
      padding-right: 0;
      display: grid;
      justify-content: flex-end;
    }

    .content-right .block-card{
      gap: 20px;
      display: flex;
      align-items: center;
    }
  }

  input:focus{
    outline: none;
  }

  .contact-about {
    display: flex;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    li {
      float: left;
    }
    .content-left{
      display: flex;
    }

    .item-link {
      display: block;
      padding: 15px 40px 15px 0px;
      color: #4B5768;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.002em;

    }

    a:hover {
      color: #DC143C !important;
      text-decoration: underline;
    }

    .content-right .text{
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      color: #4B5768;
    }
  }
}