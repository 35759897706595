@import 'src/style/variables';
@import 'src/style/mixin';

//normal
.cover-img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
.upload-file-btn-block {
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  height: 100%;
  max-width: 100%;
  min-height: 185px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  .upload-file-btn-new {
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f8fa;
    white-space: pre-line;
    justify-content: center;
    text-align: center;
    background: #f7f8fa;
    border: 1px dashed #e5e6eb;
    border-radius: 8px;
  }
}


.bg-img4 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background-size: cover;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.fansite-creator-post-normal {
  background-color: $color-01;
  padding: 16px 24px 24px 24px;
  &.next {
    padding: 16px 24px 32px 24px;
  }
  border-radius: 20px;
  .text-err {
    color: #ff0000 !important;
    font-size: 14px;
    margin: 12px 0px;
  }
  .list-item.scroll-bar-custom-horizontal.list-imgs {
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e6eb;
      border: 7px solid #e5e6eb;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background: #f2f3f5;
    border-radius: 43px;
    font-weight: 500;
    font-size: 14px;
    .item {
      display: flex;
      flex: 1;
      color: #666565;
      text-align: center;
      cursor: pointer;
      margin: 4px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
    }
    .active {
      color: $color-01;
      background-color: $color-26;
    }
  }
  &__thumbnail {
    border: 1px dashed #e5e6eb;
    background-color: #f7f8fa;
    border-radius: 8px;
    display: flex;
    width: 210px;
    height: 210px;
    position: relative;
    cursor: pointer;
    margin-bottom: 24px;

    @include mq('sm') {
      margin-bottom: 16px;
    }
    .layer {
      position: relative;
      width: 100%;
      height: 100%;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 5;
      top: 0;
      left: 0;
    }
    .img_thumbnail {
      object-fit: contain;
      height: 100%;
    }
    span svg {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 40;
    }
    .add-icon {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      color: #666565;
      font-weight: 500;
      font-size: 15px;
      .text {
        font-weight: 400;
        font-size: 12px;
        color: #666565;
        text-align: center;
        padding: 0px 31px;
        margin-top: 12px;
      }
    }
    .btn {
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #1a1a1a;
      padding: 8px 24px;
      position: absolute;
      bottom: 7px;
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      z-index: 25;
    }
    input {
      opacity: 0;
    }
  }
  .see-next {
    font-weight: 400;
    font-size: 14px;
    color: #1480ff;
    display: inline-block;
    cursor: pointer;
    position: relative;
    &.next {
      margin-bottom: 8px;
    }
    &.edit {
      top: 12px;
    }
    span {
      position: absolute;
      top: 4px;
      right: -20px;
    }
  }
  .performer-kyc {
    .list-performer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .item-performer {
        font-size: 14px;
        font-weight: 700;
        color: #1a1a1a;
      }
      margin-bottom: 16px;
    }
    .btn-performer {
      color: $color-28;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      width: fit-content;
      &.post-approved {
        cursor: default;
        color: $color-14;
      }
    }
  }
  &__footer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @include mq('sm') {
      flex-direction: column;
      gap: 12px;
    }
    .btn {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      height: 44px;
      &.btn-submit {
        color: #fdfefe;
        background-color: $color-26;
        border: none;
        &:disabled {
          background-color: $color-30;
        }
        &:hover {
          background-color: $color-27;
        }
      }
      &.btn-cancel {
        color: #1a1a1a;
        background-color: #ffffff;
        border: 1px solid #e5e6eb;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
  .avatar-infor.avatar-infor-creator {
    padding: 0;
    .content-left {
      span {
        color: $color-03 !important;
      }
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
      }
    }
  }
  .date-time-wrapper {
    gap: 16px;
    input:disabled {
      color: $color-14;
      background: #efefef;
    }
  }
  .inputFile {
    position: relative;
    cursor: pointer;

    @include mq('sm') {
      min-width: calc(50% - 8px);
    }

    &.disableImgInput,
    &.disableVideoInput {
      cursor: not-allowed !important;
      pointer-events: none;
      background: #fff;
      opacity: 0.5;
      input,
      span {
        cursor: not-allowed !important;
      }
    }
    .btn-upload {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      gap: 10px;
      padding: 14px 24px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      height: 44px;
      min-width: 156px;
      cursor: pointer;

      @include mq('sm') {
        padding: 14px;
      }

      &:hover {
        background: #f1f1f1;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .custom-time-post {
    .box-btn {
      align-items: end;
    }
  }

  form {
    padding: 24px 0 0 0;

    &.form-edit {
      padding: 0;
    }
    .box-img-video {
      margin-top: 1px;

      .box-btn-upload {
        margin-bottom: 16px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        @include mq('sm') {
        }
      }

      .list-item {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: auto;
        gap: 8px;
        height: auto;
        margin-bottom: 5px;
        &.has-video-short {
          margin-bottom: 21px;
          margin-top: -6px;
        }
        .box-item {
          position: relative;
          overflow: hidden;
          // background-color: $color-02;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            aspect-ratio: 4/3;
            object-fit: contain;
            width: 100%;
            @include mq('sm') {
              width: 235px;
              height: 276px;
              min-width: 235px;
            }
          }
          .anticon {
            position: absolute;
            top: 16px;
            right: 16px;
            color: $color-01;
            padding: 7px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            width: 40px;
            height: 40px;
            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
        .box-img1 {
          max-width: 503px;
          max-height: 670px;
          margin: auto;
          @include mq('sm') {
            max-height: 300px;
            max-width: 100%;
            width: 235px;
            height: 276px;
            min-width: 235px;
          }
        }
        .box-img2 {
          width: 235px;
          height: 276px;
          min-width: 235px;
          img {
            width: 235px;
            height: 276px;
            min-width: 235px;
          }
        }
        .video-item {
          width: 100%;
          height: 100%;
        }
        .box-video1 {
          width: 235px;
          height: 276px;
          min-width: 235px;
          margin: auto;
          &.self-video {
            width: 100%;
            aspect-ratio: 16/9;
            height: unset;
          }
          .video-react {
            padding-top: 0 !important;
            height: 100%;
          }
          video {
            width: 100%;
            position: relative;
          }
        }
        &.has-video {
          margin-bottom: 16px;
        }
      }
      .list-item.list-videos {
        .box-item {
          min-width: 500px;
          //max-width: 503px;
          max-width: 641px;
          max-height: 503px;
        }
        .video-react {
          padding-top: 0 !important;
          height: 100%;
        }
        video {
          width: 100%;
          position: relative;
        }
      }
    }
    input {
      border: 1px solid #e5e6eb !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:disabled {
        border: none !important;
      }
    }
    input::placeholder,
    textarea::placeholder,
    .textarea.title[contenteditable]:empty::before,
    .textarea.content[contenteditable]:empty::before {
      color: $color-14;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    input:read-only {
      cursor: pointer;
    }
    .form-control[readonly] {
      background-color: transparent;
    }
    .form-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important;
    }
    .fansite-form-input__controls {
      margin-bottom: 16px;
    }
    .form-label.fansite-form-input__label {
      font-weight: normal;
      margin-bottom: 4px;
    }
    .form-item-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form-label {
        margin-bottom: 0 !important;
        margin-right: 8px;
      }
      label.switch.disable-slice {
        span.slider.round {
          cursor: default;
        }
      }
    }
    .item-wrap {
      width: calc(50% - 8px) !important;
      @include mq('sm') {
        width: 100% !important;
        margin-bottom: 10px;
      }
      .zindex20 {
        z-index: 20 !important;
      }
      .btn-remove {
        cursor: pointer !important;
        position: absolute;
        width: 40px !important;
        height: 40px !important;
        top: 2px;
        right: 2px;
      }
      .main-thumbnail {
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-height: 185px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        aspect-ratio: 16/9;
        text-align: center;
      }
      input[type='file'] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .upload-file-btn {
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f7f8fa;
        white-space: pre-line;
        justify-content: center;
        text-align: center;
        background: #f7f8fa;
        border: 1px dashed #e5e6eb;
        border-radius: 8px;

        .upload-icon {
          width: 23.04px;
          height: 23.04px;
          margin-bottom: 16px;
          background: transparent;
        }
        .icon {
          color: #787880;
          position: relative;
          margin-bottom: 12px;
        }
        .text {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          padding: 0 20px;
          color: #666565;
        }
      }
    }

    .box-category,
    .box-type-sell {
      .form-label {
        margin-bottom: 16px !important;
      }
      .box-checkbox,
      .box-radio-checkbox {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }

    label#fsite-category {
      margin-bottom: 16px;
    }
    .box-category .box-checkbox {
      max-height: 500px;
      overflow-y: auto;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      padding: 17px;
    }

    .box-date {
      width: calc(100% - 62px);
    }
    .box-time {
      max-width: 135px;
      min-width: 135px;
      position: relative;
      .time {
        width: 100%;
      }
    }
    .date,
    .time {
      position: relative;
      .form-control {
        height: 44px;
        border-radius: 6px;
      }
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 3;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    input,
    textarea,
    .textarea {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 9px 12px;
      z-index: 30;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #bebebe;
        font-size: 14px;
      }
    }

    .text-post {
      margin-bottom: 0;
      textarea {
        height: 148px;
        min-height: 148px;
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 6px 12px;
      }
      &.have-meta {
        textarea {
          height: 40px;
          min-height: 40px;
        }
      }

      &-title {
        border-bottom: 1px solid $color-07;
        padding-bottom: 16px;
        margin-bottom: 16px;
      }
    }

    .btn-submit-wrapper {
      display: flex;
      align-items: center;
      gap: 24px;
      @include mq('sm') {
        flex-direction: column;
        gap: 16px;
        .btn {
          width: 100% !important;
          margin-bottom: 0;
        }
      }
      .btn {
        width: 50%;
        padding: 0;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        height: 44px;
        button {
          border-radius: 6px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
          &:focus {
            outline: none;
          }
          height: 100%;
          width: 100%;
        }
      }
      .btn-cancel button {
        background: $color-01;
        border: 1px solid $color-14;
      }
      .button-submit {
        &.disabled {
          button {
            background-color: $color-14;
            cursor: auto;
          }
        }
        button {
          background-color: $color-06;
          color: $color-01;
        }
      }
    }
  }

  @include mq('sm') {
    padding: 16px;
    .record-normal {
      width: 100% !important;
      height: 100% !important;
    }
    .fansite-form {
      padding: 16px 0;
    }
    .date-time-wrapper {
      gap: 12px;
    }
    .list-item.list-videos .box-item {
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
  .box-time-sell {
    flex-direction: column;
    .fansite-form-input__controls {
      width: 100%;
    }
    .start-time,
    .end-time {
      width: 100%;
    }
  }
  @include mq('sm') {
    .select > div {
      width: 100%;
    }
  }
  .select {
    .anticon {
      pointer-events: none;
    }
  }
}

.fansite-creator-post-normal.edit {
  .text-post textarea {
    height: fit-content;
  }
}

.layout-post-normal {
  .content-container {
    min-height: auto;
  }
  .back-to-page {
    margin-top: 60px;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .time-icon {
    position: absolute;
    top: 24px;
    right: 0px;
  }
  .wrapper-content-ogp {
    border-radius: 6px;
    border: 1px solid #e5e6eb;
    &.have-meta {
      padding: 16px;

      .content-ogp {
        padding: 0;
        min-height: 40px;
        height: 40px;
      }
    }
  }
}

.edit-post-normal-header {
  .content-container {
    margin-bottom: 67px;
  }
  .time-icon {
    position: absolute;
    top: 24px;
    right: 0px;
  }
  .wrapper-content-ogp {
    &.have-meta {
      border-radius: 6px;
      border: 1px solid #e5e6eb;
      padding: 16px;
      .content-ogp {
        border: none;
        border-radius: 0;
        padding: 0;
        min-height: 40px;
        height: 40px;
      }
    }
  }
}
