@import 'src/style/variables';
@import 'src/style/mixin';
.tooltip {
  position: relative;
  display: inline-flex;
  opacity: 1;
  font-family: inherit !important;
  text-align: center;
  z-index: unset;
  & .tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: $color-03;
    z-index: 10;
    min-width: 100px;
    width: max-content;
    max-width: 216px;
    padding: 4px 11px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    border-radius: 6px;
    bottom: 0;
    right: 0;
    transform: translate(46%, 120%);
    opacity: 0;
    transition: all 0.3s;
    text-align: center;
    &::after {
      content: '';
      -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      width: 7px;
      height: 6px;
      background: #282727;
      position: absolute;
      top: 1px;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }

  &.custom-text {
    &.fan {
      max-width: 100%;
    }
    &.creator {
      max-width: 600px;
      @include mq('l') {
        max-width: 500px;
      }
      @include mq('mw1024') {
        max-width: 400px;
      }
      @include mq('md') {
        max-width: 600px;
      }
      @include mq('mw768') {
        max-width: 450px;
      }
      @include mq('sm') {
        max-width: 245px;
      }
    }
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & .tooltiptext {
      right: unset;
      left: 0;
      transform: translate(0%, 120%);
      z-index: 20;
      max-width: 100%;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  &.ranking-name-tooltip-wrapper {
    .text {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .tooltiptext {
      left: 50%;
      max-width: 200px;
      right: unset;
      transform: translate(-50%, 120%);
    }
  }
}
