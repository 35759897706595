@import 'src/style/variables';
@import 'src/style/mixin';

.block-div {
  padding-top: 32px;
  background: #fff;
  z-index: 10;
  bottom: 0;
  width: 100%;
  &.footer-verify-sign-up {
    padding-bottom: 60px;
  }
  @include mq('md') {
    padding-top: 0;
  }
  .footer-list-idolv2 {
    max-width: 1380px;
    height: 104px;
    background: #e5e6eb;
    border-radius: 12px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 32px;
    justify-content: space-between;
    padding: 28px 36px;
    @include mq('md') {
      height: auto;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      border-radius: 0;
      padding: 16px;
      margin-bottom: 8px;
    }
    .content-left {
      max-width: 1065px;
    }
    .content-left .contact-us {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-transform: uppercase;
      color: $color-26;
    }

    .content-left .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #4b5768;
      margin-top: 4px;
    }

    .content-right {
      width: 457px;
      height: 48px;
      background: #ffffff;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      @include mq('md') {
        width: 100%;
      }
    }

    .content-right .text-right {
      display: flex;
      justify-content: space-between;
      padding: 13px 0px 13px 29px;
      min-width: 300px;
      width: auto;
      @include mq('md') {
        min-width: 200px;
        padding-left: 16px;
      }
    }

    .text-right .input-mail {
      border: none;
      text-align: left;
      margin-left: 5px;
      width: 100%;
    }

    .text-right .icon-mail {
      margin-right: 10px;
      margin-top: 5px;
    }

    .content-right .button {
      padding: 6px;
    }

    .content-right .button-submit {
      width: 94px !important;
      height: 36px;
      background: #dc143c;
      border-radius: 8px;
      color: #fff;
      font-size: 16px;
      line-height: 23px;
    }
  }

  .block-center {
    display: flex;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;
    padding: 0 16px;
    @include mq('md') {
      display: block;
      padding-top: 20px;
    }
    .content-left {
      padding-left: 0;
    }

    .content-left .text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #4b5768;
      margin-top: 8px;
      @include mq('md') {
        margin-top: 12px;
      }
    }

    .content-left .text-big {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #4b5768;
    }

    .content-right .text {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      text-transform: uppercase;
      @include mq('md') {
        padding: 0;
        margin-top: 12px;
      }
    }

    .content-right .block-follow {
      padding-left: 0;
      padding-right: 0;
      display: grid;
      justify-content: flex-end;
      @include mq('md') {
        display: block;
      }
    }

    .content-right .block-card {
      gap: 20px;
      display: flex;
      align-items: center;
      @include mq('md') {
        margin-top: 10px;
      }
    }
  }

  input:focus {
    outline: none;
  }

  .contact-about {
    display: flex;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;
    padding: 0 16px;

    .mobile-logo {
      justify-content: end;
      width: 560px;
      align-items: center;
      @include mq('sm') {
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 560px;
        align-items: center;
      }
    }

    @include mq('md') {
      display: block;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    li {
      float: left;
    }
    .content-left {
      display: flex;
      flex-wrap: wrap;
      max-width: 800px;

      @include mq('md') {
        display: block;
        //padding: 0 16px;
      }
      @include mq('sm') {
        .mobile-2257 {
          line-height: 20px;
          margin-top: -12px;
        }
        .mobile-removal{
          line-height: 20px;
          margin-top: -24px;
        }
      }
    }

    .item-link {
      display: block;
      padding: 15px 20px 15px 0px;
      color: #666565;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 0px;
      letter-spacing: 0.002em;

      @include mq('md') {
        padding: 12px 0;
      }
    }

    a:hover {
      color: #dc143c !important;
      text-decoration: underline;
    }

    .content-right .text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      color: #666565;
      @include mq('md') {
        text-align: start;
        padding: 0 16px;
        margin-bottom: 8px;
      }
    }
  }
}
