@import 'src/style/variables';
@import 'src/style/mixin';

.card-item--detail2 {
  min-height: 160px;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 0;

  .text-label {
    font-weight: 500;
    font-size: 16px;
    margin-right: 5px;
  }

  .style-mobile {
    @include mq("sm") {
      margin-bottom: 160px !important;
    }
    @include mq("md") {
      margin-bottom: 185px !important;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 200px !important;
    }
  }

  .content-sale {
    font-size: 12px;
    position: absolute;
    top: 8px;
    left: 17px;
    z-index: 21;
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    justify-content: center;
    margin-right: 12px;
    margin-top: 0px;
    p {
      margin-bottom: 0px;
      font-weight: 700;
    }
    p:first-child {
      font-weight: 400;
      margin-top: 2px;
    }
  }
  
  .shimmer-loading {
    padding: 0 !important;

    @include mq("sm") {
      padding: 0 16px !important;
    }
  }

  .item {
    border-radius: 8px;
    padding: 16px 16px 20px;
    background: rgb(250, 250, 250);
    @include mq('sm') {
      padding: 0;
      padding-left: 0px;
      padding-right: 0px;
    }
    &:last-child {
      border-bottom: none !important;
    }
  }

  .cls-card {
    position: relative;
    .post_no_content {
      cursor: pointer;
      // background-color: $color-02;
    }
    img,
    .cls-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      //aspect-ratio: 4/3;
      object-fit: contain;
      z-index: 1;
      &:error {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .img-default {
      object-fit: cover;
    }
    .blue-div {
      position: absolute;
      display: flex;
      height: 60px;
      bottom: 0;
      width: 100%;
      padding: 0 0px 8px 8px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      border-start-end-radius: 12px;
      border-start-start-radius: 12px;
      .text-mobile {
        @include mq('sm') {
          font-size: 12px;
          max-width: 140px !important;
        }
      }
    }
  }
  .wrap--text1 {
    position: absolute;
    display: flex;
    gap: 10px;
    bottom: 0;
    width: 100%;
    padding: 44px 0px 10px 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #fff;
  }

  .wrap--text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .wrap--text2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0 10px;
    background: #fafafa;
    border-radius: 10px;
    overflow: hidden;
    .point {
      color: #dc143c;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0;
    }
    .point-price {
      color: #666565;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-decoration-line: line-through;
      margin-left: 10px;
      margin-bottom: 0;
    }
    .text-point {
      color: #1a1a1a;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .cls-icon {
    cursor: pointer;
    outline: none;
    margin: 0 6px 0 0;
    &.share-icon {
      margin-right: 0;
      z-index: 10;
    }
  }
  .mg-left-auto {
    margin-left: auto;
  }

  .cls-text {
    margin-right: 10px;
    line-height: 20px;
    white-space: nowrap;
  }

  .fan-view-on-timeline {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    min-height: 300px;
    width: 100%;
    justify-content: space-between;
    .item {
      cursor: pointer;
      flex-grow: 1;
      width: calc(50% - 6.5px);
      max-width: calc(50% - 6.5px);
      position: relative;
      margin-bottom: 13px;
      overflow-x: hidden;
      border-radius: 10px;
      background: #fafafa;
      padding: 0 !important;
      @include mq('sm') {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
      }
      // .icon-video,
      // .icon-image {
      //   position: absolute;
      //   top: 8px;
      //   left: 8px;
      //   opacity: 0.8;
      //   color: $color-01;
      //   z-index: 1;
      // }
    }
    .bookmark-label {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      z-index: 10;
    }
    .bookmark-label1 {
      position: absolute;
      top: 0;
      right: 0;
      margin: 10px;
      z-index: 10;
    }
    .play-label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
    .img-wrapper {
      overflow: hidden;
      position: relative;
      height: 186px;
      @include mq('sm') {
        height: 183px;
        width: 343px;
        border-radius: 10px 10px 0px 0px;
      }
      @include mq('md') {
        width: 370px;
      }
      .list-images-item {
        padding-bottom: 100%;
      }
      img,
      .video-detail,
      video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
        padding-top: unset !important;
      }
      .img-default {
        object-fit: cover;
      }
      .video-react-button {
        display: none;
      }
    }
    .like-comment-wrapper {
      display: flex;
      align-items: center;
      gap: 13.5px;
      color: $color-08;
      margin: 7.5px 0 7.5px 4px;
      justify-content: space-between;
      overflow: auto;
      &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
        height: 1px;
        background: $color-08;
        border-radius: 10px;
        border-top: 1px white;
        border-bottom: 1px white;
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
    }

    .content-recore {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      color: #1a1a1a;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      @include mq('sm') {
        padding: 0 16px !important;
      }
    }

    .action-post {
      display: flex;
      justify-content: space-between;
      padding: 0px 10px 10px 10px;
      cursor: default;
      @include mq('sm') {
        padding: 0 16px !important;
      }
    }

    .button-wrapper {
      color: #1a1a1a;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      text-align: right;
    }

    .time-post {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #666565;
    }
  }

  .cls-title {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }

  .cls-title-2nd {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    word-wrap: break-word;
  }

  .cls-title-2nd2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 4px;
    color: #666565;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .cls-date {
    color: #bebebe;
    margin-top: 5px;
  }

  p {
    white-space: pre-wrap;
  }
  .infinite-scroll-component__outerdiv {
    padding-left: 0;
    padding-right: 0;
    @include mq('sm') {
      padding-top: 5px;
    }
  }

  .infinite-scroll-component.row {
    @include mq('sm') {
      display: flex;
      justify-content: center;
    }
    margin-left: -10px;
    margin-right: -10px;
    @include mq('sm') {
      margin-left: -5px;
      margin-right: -5px;
    }
    .cls-card {
      padding-left: 10px;
      padding-right: 10px;
      @include mq('sm') {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  & .GroupIcon {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    //overflow-x: auto;
    //overflow-y: hidden;
    &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
      height: 1px;
      background: $color-08;
      border-radius: 10px;
      border-top: 1px white;
      border-bottom: 1px white;
    }
    &::-webkit-scrollbar {
      height: 6px;
    }
  }

  .two-column-video {
    @include mq('sm') {
      display: flex;
      justify-content: center;
    }
  }

  .one-column {
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow-x: hidden !important;
    .div-ghim {
      position: relative;
      .popup-ghim {
        position: absolute;
        background: $color-01;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        width: 229px;
        height: unset !important;
        display: flex;
        flex-direction: column;
        left: 10px;
        top: 30px;
        border-radius: 6px;
        overflow: hidden;
        z-index: 15;
        .item {
          display: flex;
          align-items: center;
          padding-left: 18px;
          cursor: pointer;
          height: 38px;
          z-index: 10;
          background: $color-01 !important;
          color: $color-03;
          .anticon {
            margin-right: 8px;
          }
          &:hover {
            background-color: $color-07 !important;
            border-radius: 0px;
          }
          &:first-child {
            margin-top: 6px;
            position: relative;
          }
          &:nth-child(2) {
            margin-bottom: 6px;
          }
          .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 229px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      right: 0;
      top: 15px;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        background: $color-01 !important;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07 !important;
          border-radius: 0px;
        }
        &:first-child {
          margin-top: 6px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }

    .cls-title {
      margin-top: 16px;
      width: auto;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .mb-0.cls-title {
      margin-top: 0;
    }
    .list-images-item {
      padding-bottom: 0;
      z-index: 1;
      position: relative;
      height: unset;
      max-height: unset;
      // background-color: $color-02;
      aspect-ratio: 4/3;
      margin-top: 16px;
      img {
        position: relative;
        top: unset;
        right: unset;
        bottom: unset;
        left: unset;
        aspect-ratio: 4/3;
        object-fit: contain;
      }
    }
    .img-default.avatar {
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  &.fan {
    .one-column {
      .cls-title {
        white-space: normal;
      }
      .mb-0.cls-title {
        width: calc(100% - 34px);
      }
      .cls-title-2nd {
        margin-bottom: 0;
      }
    }
    .three-column {
      margin-left: -5px !important;
      margin-right: -5px !important;
      .post_no_content {
        //aspect-ratio: 4/3;
        width: 100%;
        height: unset !important;
      }
      .cls-card {
        height: unset !important;
        margin-bottom: 0;
        padding: 0 !important;
        width: calc(50% - 3.5px);
      }
    }

    .two-column {
      display: flex;
      justify-content: center;
      margin-left: 0 !important;
      margin-right: 0 !important;


      .post_no_content {
        aspect-ratio: 4/3;
        width: 100%;
        height: unset !important;
      }
      .cls-card {
        height: unset !important;
        margin-bottom: 0;
        padding: 0 !important;
        width: calc(50% - 3.5px);
      }
    }
  }

  &.creator {
    padding: 0 !important;
    .infinite-scroll-component.row {
      margin-left: 0;
      margin-right: 0;
    }
    .mobile-3 {
      padding: 0 !important;
      margin-right: 2px;
      &:nth-child(2n) {
        margin-right: 0px !important;
      }
    }
    .post_no_content {
      width: 223px;
      //aspect-ratio: 4/3;
    }
    .post_no_content-two {
      width: 326px;
      aspect-ratio: 4/3;
    }
    .cls-card {
      width: 223px;
      height: 223px;
      margin-bottom: 2px;
      padding-left: 0 !important;
      padding-right: 2px !important;
      justify-content: center;
      display: flex;
      &:nth-child(3n) {
        padding-right: 0px !important;
      }
    }
    .mobile-tab3 {
      @include mq('sm') {
        width: 343px !important;
      }
      // @include mq('md') {
      //   width: 370px !important;
      // }
    }
    .cs-col {
      max-width: calc(50% - 15px / 2);
      height: 220px;
      position: relative;
      margin-bottom: 170px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 15px;
      @include mq('sm') {
        margin-right: 0px;
        max-width: 100%;
      }
      &:nth-child(2n) {
        margin-right: 0px !important;
      }
    }

    // .two-column-video {
    //   justify-content: center !important;
    // }

    .cs-video {
      height: 604px;
      position: relative;
      margin-bottom: 2px;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 2px;
      width: calc(50% - 1px);

      @include mq('md') {
        width: calc(50% - 1px);
      }
 
      @include mq('sm') {
        width: 100% !important;
        max-width: 400px !important;
        height: 610px !important;
        margin-bottom: 8px;
      }
 
      &:nth-child(2n) {
        margin-right: 0px !important;
      }
    }
    .button-play {
      cursor: pointer;
      z-index: 16;
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .block-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      padding: 0 0px 20px 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      .avatar {
        display: flex;
        align-items: center;
        margin-bottom: 43px;
        cursor: pointer;
        img {
          border: none;
          border-radius: 50%;
          max-height: 44px;
          max-width: 44px;
          min-height: 44px;
          min-width: 44px;
          object-fit: cover;
        }
        .name {
          margin-left: 15px;
          font-size: 12px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 20px;
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
        box-shadow: inset 0px 0px 0px 1px #c8c8cd;
      }
      .content {
        position: absolute;
        z-index: 5;
        color: rgb(255, 255, 255);
        margin: 0px;
        width: calc(100% - 26px);
        height: 42px;
        bottom: 15px;
        overflow-y: auto;
        .text {
          margin-top: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
      }
    }

    .cls-card-two {
      width: 326px;
      height: 220px;
      margin-bottom: 2px;
      padding-left: 0 !important;
      padding-right: 2px !important;
      justify-content: center;
      display: flex;
    }
    .cls-date {
      margin-bottom: 0 !important;
    }
    @include mq('l') {
      .cls-card {
        width: calc(50% - 12px);
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .post_no_content {
        width: 100%;
        height: 171px;
      }
    }
    @include mq('sm') {
      .post_no_content {
        width: 100%;
        height: 171px;
      }
      .cls-card {
        width: calc(50% - 1.5px);
        margin-bottom: 2px;
      }
    }
    .one-column {
      .cls-title {
        margin-top: 16px;
        width: auto;
        white-space: normal;
        @include mq('sm') {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .mb-0.cls-title {
        margin-top: 0;
      }
      .img_no_water_mark {
        height: 418px;
        object-fit: cover;
        width: 100%;
        @include mq('sm') {
          height: 500px;
        }
      }
    }
  }

  .comment-wrap1 {
    margin-top: 25px;
    // height: calc(100% - 200px);
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    .btn-view-all {
      background-color: transparent;
      border: unset;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #666565;
      margin-bottom: 10px;
      &:hover {
        color: #1480ff;
      }
    }
  }
  .comment {
    display: flex;
    align-items: flex-start;
    //margin-bottom: 16px;

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      min-width: 44px;
      border: none;
      margin-right: 12px;
    }
    form {
      width: 100%;
      .form-item {
        margin-bottom: 0;
        background: $color-07;
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        .anticon {
          position: absolute;
          // bottom: 10.67px;
          right: 11px;
          z-index: 3;
          transform: rotate(0deg);
          bottom: 12.67px;
          cursor: pointer;
          color: $color-14;
          &.active {
            color: $color-06;
          }
          svg {
            width: 19px;
            height: 21px;
          }
        }
      }
      textarea {
        background: #f7f8fa;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 9px 32px 12px 9px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #bebebe;
        }
      }
    }
  }

  .like-icon {
    color: #666565;
    margin-right: 5px;
    &.active {
      color: #dc143c;
    }
  }

  .cls-text {
    color: #666565;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

.slick-arrow {
  background: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: $color-26 !important;
}

.list-images .slick-dots {
  bottom: 40px;
}

.infinite-scroll-component.row {
  display: flex;
  //justify-content: space-between;
}

.block-card-sale {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  .account-avatar {
    max-width: 32px;
    max-height: 32px;
    border-radius: 50%;
  }
  .account-name {
    color: #ffffff;
    display: block;
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 170px;
    text-overflow: ellipsis;
  }
}

.search-short-video-page {
  overflow: hidden;
  margin-top: 16px;
  padding-bottom: 20px;
  min-height: 450px;
  .loading-dots-custom {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @include mq('sm') {
    margin-top: 0;
  }

  .shimmer-loading {
    padding: 0;
  }

  @include mq('sm') {
    padding: 0 16px 20px 16px;
  }

  .list-short-video {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden !important;
    gap: 2px;

    .button-follow-search-short {
      @media screen and (max-width: 575px) {
        padding: 5px 24px;
      }
    }

    .short-video-item {
      width: calc(100% / 3 - 2px);
      height: 223px;
      position: relative;

      @media screen and (max-width: 575px) {
        height: 171px;
        width: calc(50% - 2.5px);
      }
    }

    .short-label-image {
      position: absolute;
      z-index: 12;
      top: 0px;
      left: 0px;
      padding-left: 10px;
      padding-top: 9px;
    }

    .infor {
      position: absolute;
      bottom: 0px;
      left: 0px;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      padding: 44px 0px 10px 10px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background-blend-mode: multiply;
      z-index: 11;

      &.follow-flag {
        z-index: unset;
      }

      @media screen and (max-width: 575px) {
        padding: 44px 0px 4px 10px;
      }

      .account-info {
        width: max-content;
        max-width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        z-index: 18;

        .account-avatar {
          max-width: 28px;
          max-height: 28px;
          border-radius: 50%;
        }

        .account-name {
          color: #ffffff;
          display: block;
          margin-right: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .content {
        line-height: 17px;
        margin-right: 8px;
        font-size: 12px;
        color: #ffffff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 18;

        @media screen and (max-width: 575px) {
          margin-right: 3px;
        }
      }

      .actions-item {
        display: flex;
        gap: 17.5px;
        color: #e5e6eb;
        margin-top: 7.5px;
        justify-content: space-between;

        .content {
          width: 50%;

          @media screen and (max-width: 575px) {
            width: calc(50% - 3px);
          }

          @media screen and (max-width: 370px) {
            width: calc(40% - 3px);
          }
        }

        .actions-container {
          width: calc(50% - 4px);
          margin-right: 4px;
          display: flex;
          z-index: 11;
          justify-content: space-evenly;

          @include mq('sm') {
            justify-content: space-between;
          }

          @media screen and (max-width: 370px) {
            width: calc(60% - 3px);
          }
        }

        .action-item {
          display: flex;
          width: max-content;
          gap: 3px;

          @include mq('sm') {
            gap: 2.5px;
            font-size: 10px;
          }
        }
      }
    }

    .short-video-search {
      object-fit: contain;
      background-color: #1a1a1a;
    }
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #b1b6bb;
    font-size: 14px;
    flex-direction: column;
    color: #b1b6bb;
    min-height: calc(100vh - 249px - 80px);
    gap: 12px;
  }
}
