@import 'src/style/variables';
@import 'src/style/mixin';

.box-fansite-headerv2.header-list-idolv2 {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-26;
  .fansite-header {
    display: flex;
    //justify-content: flex-end;
    max-width: 1440px;
    box-shadow: none;
    top: 0;
    left: 0;
    width: 100%;
    .content-left {
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
        margin-left: 10px;
      }
      @media screen and (min-width: 1440px) and (max-width: 1440px) {
        margin-left: 22px;
      }
    }

    // .nav__btn {
    //   @media screen and (max-width: 1024px) and (orientation: landscape) {
    //     display: none;
    //   }
    // }
  }
  .content-left {
    @include mq('md') {
      padding-left: 10px;
    }
    .logo-bird {
      margin-right: 11px;
    }
  }

  .content-center {
    display: flex;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;

    .content-left {
      display: flex;
    }

    .item-link {
      display: block;
      padding: 15px 40px 15px 0px;
      color: $color-01;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.002em;
    }

    a:hover {
      color: #fff;
      text-decoration: underline;
    }

    .custom-span {
      color: #fff;
      display: block;
      padding: 15px 40px 15px 0px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.002em;
      cursor: pointer;
    }

    span:hover {
      color: #fff !important;
      text-decoration: underline;
    }
  }

  .content-right {
    margin-right: 0px;
    display: flex;
    align-items: center;
    @include mq('sm') {
      flex-direction: column;
    }
  }
  .search-lang-wrapper {
    display: flex;
    align-items: center;
    @include mq('sm') {
      width: 100%;
      //margin-bottom: 15px;
      .popup-change-lang {
        width: 120px;
      }
    }
  }
  .search-wrapper {
    margin-right: 18px;
    display: flex;
    align-items: flex-start;
    .anticon.icon-search {
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: $color-03;
      }
    }
  }
  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 0;
    border-radius: 6px;
    & + .btn {
      margin-left: 18px;
    }
    &.btn-fan-login {
      width: 132px;
      background-color: $color-06;
      color: $color-01;
    }
    &.btn-creator-login {
      width: 172px;
      border: 1px solid $color-06;
      color: $color-06;
    }
  }
  .language-wrapper-init {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $color-03;
    position: relative;
    padding-right: 16px;
    @media screen and (min-width: 1440px) and (max-width: 1440px) {
      padding-right: 36px;
    }
    //margin-right: 24px;
    cursor: pointer;
    position: relative;
    .popup-change-lang {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      padding: 8px 0;
      width: 161px;
      box-shadow: 0px 5px 25px rgba(43, 35, 43, 0.1);
      border-radius: 6px;
      z-index: 13;
      background-color: $color-01;
      .lang-option {
        display: flex;
        align-items: center;
        height: 42px;
        padding-left: 16px;
        position: relative;
        &:hover {
          background-color: #ffdbdb;
        }
        .icon-checked {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          color: $color-06;
        }
      }
    }
    .current-lang {
      display: flex;
      align-items: center;
      .lang {
        margin: 0 7px;
        color: $color-01;
      }
    }
    .anticon {
      color: $color-01;
    }
  }
}

@include mq('sm') {
  .header-fixed {
    height: max-content !important;
  }
}
@include mq('md') {
  .header-fixed {
    height: max-content !important;
    background-color: $color-01;
    z-index: 20;
  }
  .box-fansite-header.header-list-idol {
    box-shadow: none;
    .fansite-header {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .content-left {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      width: 100%;
    }
    .content-right {
      align-items: center;
      margin-right: 0;
      padding: 16px 0;
      .search-wrapper {
        padding-right: 0;
        margin-right: 13px;
        &::after {
          display: none;
        }
      }
      .btn + .btn {
        margin-left: 15px;
      }
    }
  }
}

.box-fansite-headerv2.header-list-idolv2-singin {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-26;
  .fansite-header {
    display: flex;
    //justify-content: flex-end;
    max-width: 1440px;
    box-shadow: none;
  }
  .content-left {
    .logo-bird {
      margin-right: 11px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      margin-left: 10px;
    }
  }

  .content-center {
    display: flex;
    justify-content: space-between;
    max-width: 1380px;
    margin: 0 auto;

    .content-left {
      display: flex;
    }

    .item-link {
      display: block;
      padding: 15px 40px 15px 0px;
      color: $color-01;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.002em;
    }

    a:hover {
      color: #fff;
      text-decoration: underline;
    }

    .custom-span {
      color: #fff;
      display: block;
      padding: 15px 40px 15px 0px;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.002em;
      cursor: pointer;
    }

    span:hover {
      color: #fff !important;
      text-decoration: underline;
    }
  }

  .content-right {
    margin-right: 0px;
    display: flex;
    align-items: center;
    @include mq('sm') {
      flex-direction: column;
    }
  }
  .search-lang-wrapper {
    display: flex;
    align-items: center;
    @include mq('sm') {
      width: 100%;
      .popup-change-lang {
        width: 120px;
      }
    }
  }
  .search-wrapper {
    margin-right: 18px;
    display: flex;
    align-items: flex-start;
    .anticon.icon-search {
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: $color-03;
      }
    }
  }
  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 0;
    border-radius: 6px;
    & + .btn {
      margin-left: 18px;
    }
    &.btn-fan-login {
      width: 132px;
      background-color: $color-06;
      color: $color-01;
    }
    &.btn-creator-login {
      width: 172px;
      border: 1px solid $color-06;
      color: $color-06;
    }
  }
  .language-wrapper-init {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $color-03;
    position: relative;
    padding-right: 24px;
    //margin-right: 24px;
    cursor: pointer;
    position: relative;
    .popup-change-lang {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      padding: 8px 0;
      width: 161px;
      box-shadow: 0px 5px 25px rgba(43, 35, 43, 0.1);
      border-radius: 6px;
      z-index: 13;
      background-color: $color-01;
      .lang-option {
        display: flex;
        align-items: center;
        height: 42px;
        padding-left: 16px;
        position: relative;
        &:hover {
          background-color: #ffdbdb;
        }
        .lang {
          margin-left: 10px;
        }
        .icon-checked {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          color: $color-06;
        }
      }
    }
    .current-lang {
      display: flex;
      align-items: center;
      .lang {
        margin: 0 7px;
        color: $color-01;
      }
    }
    .anticon {
      color: $color-01;
    }
  }
}

@include mq('md') {
  .header-fixed {
    height: max-content !important;
    background-color: $color-01;
    z-index: 20;
  }
  .box-fansite-header.header-list-idol {
    box-shadow: none;
    .fansite-header {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .content-left {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      width: 100%;
    }
    .content-right {
      align-items: center;
      margin-right: 0;
      padding: 16px 0;
      .search-wrapper {
        padding-right: 0;
        margin-right: 13px;
        &::after {
          display: none;
        }
      }
      .btn + .btn {
        margin-left: 15px;
      }
    }
  }
}
