@import 'src/style/variables';
@import 'src/style/mixin';
.user-des {
  color: #787880;
  font-size: 12px;
}

.search-sub-active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #ff0000 !important;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 2px solid #ff0000 !important;
  height: 100% !important;
}

.center-gr {
  display: flex;
  align-items: center;
}

.genre_group {
  overflow-x: auto;
  justify-content: space-evenly;
  .item_genre {
    justify-content: center;
    min-width: 144px;
    height: 40px;
    margin: 16px;
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid #b1b6bb;

    &.active_genre {
      border: 1px solid #ff0000;
    }
  }
}

.search-page.creator {
  position: relative;
  width: 100%;
  .body {
    min-height: calc(100vh - 183px);
    margin: 0;
    .list {
      overflow-y: auto;
      min-height: unset;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .no-result {
      padding: unset;
      min-height: unset;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include mq('smHeight') {
        margin-top: 30px;
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
      }
    }

    .name {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item {
      width: 100%;
      img {
        object-fit: unset;
      }
      .title {
        color: #787880;
      }
    }
    .list {
      @include mq('sm') {
        padding-bottom: 0;
      }
    }
  }
}
