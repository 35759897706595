@import 'src/style/variables';
@import 'src/style/mixin';

.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $color-01;
  z-index: 9999;
  top: 0;
  left: 0;
  position: fixed;
  .page-not-found-wrapper {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-14;
    height: 300px;
    border-radius: 10px;
    padding: 0 30px;
  }
  .title {
    color: $color-06;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  .content {
    font-size: 16px;
    margin: 32px 0;
    text-align: center;
  }
  .btn {
    background: $color-06;
    color: $color-01;
    font-size: 16px;
  }
}
