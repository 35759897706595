ul.pagination-table {
  font-size: 14px;
  font-weight: normal;
  color: #787880;
  background: #ffffff;
  font-size: 14px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 0;
  li {
    text-align: right;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    color: #787880;
    cursor: pointer;
    &.selected {
      color: #ff0000;
      border: 1px solid #ff0000;
    }
    &.previous,
    &.next {
      padding: 0;
      margin: 0;
      display: inline-block;
      text-align: center;
      overflow: hidden;
      background: #fff;
      border: 1px solid #eeeeee;
      border-radius: 2px;
      width: 32px;
      height: 32px;
      color: #282727;
      svg {
        padding: 0;
        width: 18px;
        height: 18px;
        path {
          fill: #282727;
        }
      }
    }
    a {
      width: 100%;
      height: 100%;
    }
  }
}
