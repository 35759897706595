.sc-creator-rv-screen {
  width: 100%;
  height: 100%;
}

.loading-video-live {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sc-creator-views-mobile div video {
  // width: calc(100% - 0px) !important;
  // object-fit: unset !important;
}
.creator_no-obs div video {
  // object-fit: contain !important;

  // @media screen and (max-height: 450px) {
  //   object-fit: contain !important;
  // }
  // @media screen and (min-width: 576px) and (min-height: 450px) {
  //   object-fit: contain !important;
  // }
  // @media screen and (max-width: 575px) {
  //   object-fit: cover !important;
  //   object-position: center;
  // }
  // Updated at 18-10-22 by @dunghh
  object-fit: cover !important;
  aspect-ratio: 4/3;
}

.wrap-copy-success {
  position: relative;
  .copy-success {
    position: absolute;
    top: -48px;
    left: 50%;
    white-space: nowrap;
    background: #1a1a1a;
    border-radius: 8px;
    color: #eeeeee;
    padding: 8px 9px;
    transform: translateX(-50%);
    &::after {
      content: '';
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      width: 7px;
      height: 6px;
      background: #282727;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
}

.snapshot_take {
  .hover_snapshot_take {
    display: none;
    position: absolute;
    width: max-content;
    z-index: 10;
    background: #1a1a1a;
    border-radius: 8px;
    color: #eeeeee;
    padding: 8px 9px;
    top: -74px;
    max-width: 213px;
    text-align: center;
    margin: auto;
    font-weight: 400;
    font-size: 12px;
    @media screen and (max-width: 575px) {
      top: -84px;
      left: 0;
    }
    &::after {
      content: '';
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      width: 7px;
      height: 6px;
      background: #282727;
      position: absolute;
      bottom: 1px;
      left: 50%;
      transform: translate(-50%, 100%);
      @media screen and (max-width: 575px) {
        left: 33px;
        transform: translate(0%, 100%);
      }
    }
  }
  &:hover {
    .hover_snapshot_take {
      display: block;
    }
  }
}
