@import 'src/style/variables';
@import 'src/style/mixin';

@mixin TypeImgSend {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: flex-start;
  border-radius: 4px;
  img {
    width: 240px;
    overflow: hidden;
    object-fit: contain;
    object-position: bottom;
  }
}

.list-image {
  max-height: 90vh;
  overflow-y: auto;
  min-height: 100%;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  &.no-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textOptionNull {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $color-14;
  }
}
.create-message-page {
  padding: 20px;
  .icon-search {
    margin-right: 5px;
  }
  @include mq('md') {
    padding: 16px;
  }
}

.fan-message-detail {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  padding-top: 15px;
  .content {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;

    &.scroll-bar-custom {
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
    }

    .item {
      width: 100%;
      position: relative;
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 60px;
      }
      .time {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        color: $color-16;
      }
      .from-user {
        width: 70%;
        display: flex;
        align-items: flex-start;
        margin-left: 16px;
        .avatar-user {
          border-radius: 50%;
          max-width: 32px;
          max-height: 32px;
          min-height: 32px;
          min-width: 32px;
          margin-right: 12px;
          cursor: pointer;
        }
        .type-video {
          video {
            width: 100%;
          }
        }
        .type-text {
          .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            padding: 12px;
            background: $color-07;
            border-radius: 4px;
            white-space: pre-wrap;
            word-break: break-all;
          }
        }
        .type-img {
          @include TypeImgSend;
        }
        .type-coin {
          .coin {
            display: flex;
            align-items: center;
            background: $color-07;
            padding: 11px 16px;
            border-radius: 4px;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            .number {
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .type-file {
          .file {
            display: flex;
            align-content: center;
            padding: 11px 16px;
            background-color: $color-07;
            border-radius: 4px;
            cursor: pointer;
            & + .file {
              margin-top: 4px;
            }
            .arrow-icon {
              height: 10px;
              align-self: center;
            }
          }
          .name {
            margin: 0 15px 0 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
          }
          .file-icon {
            width: 24px;
            height: 24px;
          }
          .arrow-icon {
            transform: rotate(-90deg);
            color: $color-08;
            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .from-me {
        width: 65%;
        margin-right: 16px;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        .type-video {
          video {
            width: 100%;
          }
        }
        .type-text {
          .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            padding: 12px;
            color: $color-01;
            background: $color-06;
            border-radius: 4px;
            white-space: pre-wrap;
            word-break: break-all;
          }
        }
        .type-img {
          @include TypeImgSend;
        }
        .type-coin {
          .coin {
            display: flex;
            align-items: center;
            background: $color-06;
            padding: 11px 16px;
            border-radius: 4px;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            .number {
              font-weight: bold;
              font-size: 14px;
              line-height: 22px;
              color: $color-01;
            }
          }
        }
        .type-file {
          .file {
            color: white;
            background-color: $color-06;
            display: flex;
            align-content: center;
            padding: 11px 16px;
            border-radius: 4px;
            cursor: pointer;
            & + .file {
              margin-top: 4px;
            }
            .arrow-icon {
              height: 10px;
              align-self: center;
            }
          }
          .name {
            margin: 0 15px 0 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
          }
          .file-icon {
            width: 24px;
            height: 24px;
          }
          .arrow-icon {
            transform: rotate(-90deg);
            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  .post-content {
    border-top: 1px solid $color-07;
    .fansite-form {
      display: flex;
      justify-content: space-between;
      background-color: white;
      @include mq('sm') {
        padding: 0 !important;
      }
      .left {
        flex: 1;
        padding-right: 30px;
        .fansite-form-input__controls {
          margin-bottom: 0;
        }
        textarea {
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          border: none;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 9px 12px;
          z-index: 2;
          min-height: 82px;
          width: 100%;
          &.admin {
            user-select: none;
            cursor: auto;
          }
          &::-webkit-scrollbar {
            display: none;
          }
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: $color-14;
            font-size: 14px;
          }
        }
      }
      .content-right {
        display: flex;
        margin-top: 16px;
        margin-right: 18px;

        .box-icon-plus {
          position: relative;
          .pop-up {
            position: absolute;
            background: $color-01;
            box-shadow: 0px 40px 64px rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            width: 189px;
            height: 105px;
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            bottom: calc(100% + 6px);
            right: 20px;
            border-radius: 6px;
            overflow: hidden;
            z-index: 10;
            input {
              background-color: red;
              border: none;
              visibility: hidden;
              height: 0;
            }
            .item {
              display: flex;
              align-items: center;
              padding-left: 8px;
              cursor: pointer;
              height: 38px;
              &:hover {
                background-color: $color-07;
              }
              .anticon {
                color: #282727;
                width: 20px;
                height: 20px;
              }
              .text {
                font-size: 14px;
                line-height: 22px;
                margin-left: 8px;
              }
            }
          }
          .icon-plus {
            cursor: pointer;
            svg {
              height: 22px;
              width: 22px;
              margin-right: 15px;
              color: $color-17;
            }
          }
        }
        .button-submit {
          &.disabled {
            background-color: transparent;
            button {
              color: $color-14;
              cursor: auto;
            }
          }
          button {
            font-weight: bold;
            color: $color-06;
            background: transparent;
            box-sizing: border-box;
            border: none;
            padding: 0;
            cursor: pointer;

            position: relative;
            .anticon {
              svg {
                height: 20px;
                width: 20px;
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

.layout-left.message-detail > .wrapper-children {
  height: calc(100% - 71px);
}
