@import 'src/style/variables';
@import 'src/style/mixin';

.create-live-header {
  margin-top: 0px;
  .content-container  {
    padding: 0 !important;
    min-height: unset;
  }
  &.openIOS {
    .box-fansite-headerv2.header-list-idolv2 {
      height: 0;
      display: none;
    }
  }
}
.live-stream-wrapper-page {
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .mb-46 {
    margin-bottom: 46px !important;
  }
  .mb-4 {
    margin-bottom: 4px !important;
  }
  height: 100%;
  form.fansite-form.change-delivery {
    padding: 12px 24px !important;
    .fansite-form-input__controls.category {
      margin-bottom: 20px;
    }
    .hr-hyphen {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 64px;
      border-top: 1px solid #f0f0f0;
    }
    .box-checkbox.box-checkbox-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 21px;
      margin-bottom: 14px;
      .item {
        padding: 0;
      }
    }
    .subscribers-area {
      background: #efefef;
      max-height: 130px;
      height: 130px;
      min-height: 130px;
      padding: 16px 20px;
      &.white {
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 10px;
      }
      .item {
        margin-bottom: 15px;
      }
    }
    .fansite-form-input__controls.any {
      margin-bottom: 10px;
      .text {
        font-weight: 400;
        font-size: 14px;
        color: #282727;
      }
    }
    .fansite-form-input__controls.button-submit-delivery-setting {
      margin-bottom: 4px;
    }
    .list-user-selected-wrapper {
      margin-bottom: 20px;
      &.active {
        border: 1px solid #1480ff !important;
      }
      .btn-popup-select-user-live {
        position: absolute;
        top: 13.5px;
        right: 13.5px;
        &.notAllow {
          cursor: not-allowed;
          pointer-events: none;
        }
      }
      .item {
        color: #1a1a1a;
        border-radius: 2px;
        background: #e5e6eb;
      }
    }
  }
  form.fansite-form.extention-form {
    padding: 12px 24px !important;
    .fansite-form-input__controls.input-time {
      label {
        margin-bottom: 8px !important;
      }
    }
    .hr-hyphen {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 64px;
      border-top: 1px solid #f0f0f0;
    }
    .fansite-form-input__controls.input-time.mb-46 {
      margin-bottom: 46px !important;
    }
  }
  .button-cancel-word {
    padding: 4px 24px;
    height: 32px;
    width: auto;
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #e5e6eb;
    border-radius: 6px;
    margin: 16px 0px;
    margin-left: 8px;
  }
  label.form-label.fansite-form-input__label {
    font-weight: 500 !important;
    font-size: 14px;
    color: #000000;
  }

  .fansite-form-input__controls.button-submit-delivery-setting {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    button {
      border: none;
      border-radius: 6px;
      padding: 4px 24px;
      height: 32px;
      width: auto;
      font-weight: 500;
      font-size: 14px;
      background-color: $color-26 !important;
      color: #ffffff !important;
    }
    .button-cancel-delivery-setting {
      color: #1a1a1a !important;
      border: 1px solid #e5e6eb;
      background: #ffffff !important;
    }
  }
  .live-stream-page {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 30px;
    &__left {
      width: calc(100% - 360px);
      height: 100%;
      max-width: 1020px;
    }
    &__right {
      width: 100%;
      max-width: 330px;
      height: 100%;
    }
    .container-fluid {
      height: calc(100% - 64px) !important;
      background: white;
    }
    .bg-live {
      .count-user-watch {
        height: 28px;
        background: #ffffff;
        border-radius: 0px 14px 14px 0px;
        position: absolute;
        top: 30px;
        left: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 12px;
        .count {
          font-weight: 500;
          font-size: 12px;
          color: $color-26;
        }
      }
      .logo-coco {
        position: absolute;
        right: 12px;
        bottom: 12px;
      }
    }
  }
}
.popup.popup-general.popup-ng-setting.popup-select-user.custom {
  .btn-submit {
    background: #ff0000;
  }
}

.danger {
  background-color: #ff0000 !important;
}

.mr-top {
  margin-top: 24px;
}

.list-search-distributions {
  position: absolute;
  background: #ffffff;
  border-radius: 6px;
  top: 100%;
  left: 0;
  width: 300px;
  padding: 10px 0;
  max-height: 137px;
  min-height: 55px;
  overflow-y: auto;
  z-index: 11;
  margin-top: 10px;
  margin-left: -2px;

  .item-search {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: #ffdbdb;
    }
  }

  .active-dvc {
    background: #ffdbdb;
  }

  .icon-checked {
    color: #ff0000;
    display: none;
    &.active {
      display: block;
    }
  }
}
