@import 'src/style/variables';
@import 'src/style/mixin';

.comment-hide-from-post-layout {
  min-height: calc((100vh - 60px) - 70px);
  .shimmer-loading {
    padding: 0px 0px;
  }
  .comment-hide-container {
    padding: 24px;
    @include mq('md') {
      padding: 16px;
    }
    .list-comment-hide {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .no-data-comment-hide {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #f1afbb;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-align: center;
      .text {
        margin-right: -16px;
        color: #b1b6bb;
        font-weight: 400;
        font-size: 14px;
      }
      @include mq('sm') {
        position: fixed;
      }
    }
  }
}
