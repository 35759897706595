@import 'src/style/variables';
@import 'src/style/mixin';

.plan_list_page-header {
  .popup-success-payment-tiger {
    .text-content {
      margin: -10px auto 16px !important;
      text-align: center;
      width: 100%;
      padding: 0 24px;
      .title-success {
        font-weight: 700;
        font-size: 14px;
        color: #52c41a;
        margin-bottom: 18px;
      }
      .title-fail {
        font-weight: 700;
        font-size: 14px;
        color: #dc143c;
        margin-bottom: 18px;
      }
      .content {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        display: block;
      }
    }
    .button-wrapper {
      margin: 16px 0px;
      display: flex;
      gap: 8px;
      .btn {
        height: 32px;
        margin: 0 !important;
        padding: 5px 14px !important;
        &.btn-cancel {
          background: #ffffff;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #1a1a1a;
        }
        &.btn-ok {
          background: #dc143c;
          color: #fff;
        }
      }
    }
  }
}
.plan_list_page {
  width: 100%;
  position: relative;
  min-height: 100%;
  border-top: 1px solid $color-07;
  .shimmer-loading {
    padding: 16px;
  }

  .success_plan_list {
    height: 490px;
    padding: 32px;
  }
  .infinite-scroll-component__outerdiv {
    width: 100%;
    padding: 16px;
  }
  .no-data {
    width: 100%;
    margin-top: 280px;
    &__text {
      font-weight: 400;
      font-size: 14px;
      color: $color-14;
      margin-top: 10px;
    }
  }
  &__add {
    margin-top: 20px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 233px;
      height: 32px;
      font-weight: 700;
      font-size: 12px;
    }
  }
  @include mq('sm') {
    .scroll {
      overflow: hidden !important;
    }
    .popup-content {
      min-width: initial !important;
    }
  }
}
