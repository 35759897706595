@import 'src/style/variables';

.compliance-notice-page {
  padding: 16px 24px;
  min-height: calc(100vh - 128px);
  overflow: auto;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  max-width: 1056px;
  color: #282727;
  &.creator {
    padding: 16px 50px;
    @include mq('sm') {
      padding: 16px 24px;
    }
  }
  @include mq('sm') {
    padding: 16px;
    margin-bottom: 0;
  }
  .title {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .content {
    color: #282727;
    font-weight: 400;
    font-size: 14px;
  }
  .end-text {
    margin-top: 16px;
    & + .end-text {
      margin-top: unset;
    }
  }
}
