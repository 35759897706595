@import 'src/style/variables';
@import 'src/style/mixin';

.wrap-payment-card-sub {
  .popup-success-payment-tiger {
    .text-content {
      margin: -10px auto 16px !important;
      text-align: center;
      width: 100%;
      padding: 0 24px;
      .title-success {
        font-weight: 700;
        font-size: 14px;
        color: #52c41a;
        margin-bottom: 18px;
      }
      .title-fail {
        font-weight: 700;
        font-size: 14px;
        color: #dc143c;
        margin-bottom: 18px;
      }
      .content {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        display: block;
      }
    }
    .button-wrapper {
      margin: 16px 0px;
      display: flex;
      gap: 8px;
      .btn {
        height: 32px;
        margin: 0 !important;
        padding: 5px 14px !important;
        &.btn-cancel {
          background: #ffffff;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #1a1a1a;
        }
        &.btn-ok {
          background: #dc143c;
          color: #fff;
        }
      }
    }
  }
}
