@import 'src/style/variables';
@import 'src/style/mixin';
.calendar-page.creator {
  .calendar-root.custom-calendar {
    --grid-height: 282px;
    --text-color-light: #bebebe;
    --text-color-primary: #1a1a1a;
    --grid-background-color: rgb(211, 205, 198);
    --grid-foreground-color: white;
    --red-color: #ff0000;
    --space-sm: 2px;
    --space-md: 0px;
    --space-lg: 10px;
    width: 100%;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
    overflow: hidden;
  }
}

/* | Sun | Mon | Tue | Wed | Thu | Fri | Sat  */
.day-of-week-header-cell {
  // color: #787880;
  color: #1a1a1a;
  font-size: 14px;
  background-color: var(--grid-foreground-color);
  padding: var(--space-md) 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.days-of-week {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1a1a1a;
  font-weight: 500;
  font-size: 14px;
}

.days-of-week,
.days-grid {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
}

.days-grid {
  // height: var(--grid-height);
  position: relative;
  border: var(--grid-gap) solid var(--grid-background-color);
  background-color: var(--grid-foreground-color);
  // padding-bottom: 20px;
}

.day-grid-item-container {
  position: relative;
  background-color: var(--grid-foreground-color);
  display: flex;
  flex-direction: column;
}

.day-grid-item-container:not(.current-month) .day-grid-item-header {
  color: var(--text-color-light);
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item-header {
  color: var(--text-color-primary);
  padding: var(--space-md);
  flex-shrink: 0;
  text-align: center;
  border: 1px solid transparent;
}

.day-grid-item-container > .day-content-wrapper {
  cursor: pointer;
  min-height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px !important;
}

.navigation-header {
  display: flex;
  height: 38px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eeeeee;
  padding-bottom: 5px;
}

.navigation-header > * {
  margin: 0 16px;
}

.day-grid-item-header {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.--event {
    background-color: var(--grid-foreground-color);
    position: relative;
    &::after {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      background: #dc143c;
      border-radius: 50%;
      left: 50%;
      top: 112%;
      transform: translateX(-50%);
    }
  }
  &.--now {
    background-color: #e5e6eb;
    border: 1px solid #b1b6bb;
    // border-radius: 50%;
    color: #666565;
    border-radius: 2px;
    border: none;
  }
  &.--selected {
    background-color: #dc143c;
    border: 1px solid #dc143c;
    // border-radius: 50%;
    color: white !important;
  }
}

.day-grid-item-header:hover {
  background-color: #dc143c;
  // border-radius: 50px;
  color: var(--grid-foreground-color) !important;
}

.calendar-page {
  .event-container {
    background-color: #fff;
  }
}

// header
.month-year {
  display: flex;
  color: #1a1a1a;
  font-weight: 700;
  font-size: 14px;
  .month-select,
  .year-select {
    font-size: 14px !important;
    color: #282727 !important;
    font-weight: 500 !important;
    font-family: 'Noto Sans JP' !important;
    border: 0;
    background-color: transparent !important;
    padding: 0 5px;
    text-align: center;
    select::-ms-expand {
      display: none !important;
    }
  }

  .month-select:focus,
  .year-select:focus {
    border: 0;
    outline: 0;
    background-color: transparent !important;
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
.month-nav-arrow-buttons {
  img {
    outline: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
}

.calendar-root.admin {
  .month-year,
  .month-nav-arrow-buttons {
    background-color: $color-07 !important;
    color: var(--text-color-light) !important;
    pointer-events: none;
    cursor: auto;
  }

  .days-grid {
    .day-grid-item-container > .day-content-wrapper {
      pointer-events: none;
      cursor: auto;
    }
    .day-grid-item-header {
      color: var(--text-color-light) !important;
      pointer-events: none;
      cursor: auto;
    }
  }
}
