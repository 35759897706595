@import 'src/style/variables';
@import 'src/style/mixin';

.float-right {
  float: right;
}

.rejection-btn {
  color: #f6851f;
  background: rgba(255, 143, 80, 0.2);
  border-radius: 4px;
  pointer-events: none;
}

.select-grey > .css-1s2u09g-control {
  background: #eeeeee;
}

.select-country {
  z-index: 4;
}

.detail-btn {
  padding: 6px 12px !important;
  border-radius: 4px !important;
  text-align: center;
  font-weight: 500;
  cursor: unset;
}

.green-status {
  color: #28c76f;
  background: rgba(40, 199, 111, 0.2);
}

.grey-status {
  background: #eeeeee;
  color: #b1b6bb;
}

.orange-status {
  color: #f6851f;
  background: rgba(255, 143, 80, 0.2);
}

.red-status {
  background-color: #ff0000;
  color: white;
}

.dark-status {
  background-color: #2b2b2b;
  color: white;
}

.gender,
.birthday {
  position: relative;
  .anticon {
    top: 0;
    bottom: 0;
    position: absolute;
    right: 15px;
    transform: none;
    color: $color-14;
    z-index: 4;
    height: 44px;
    display: flex;
    align-items: center;
  }
  input {
    padding: 9px 30px 9px 12px;
  }
}
.birthday {
  .anticon {
    user-select: none;
    pointer-events: none;
    cursor: pointer;
  }
}

.gender {
  .anticon.focus {
    transform: rotateX(180deg);
  }
}

.creator-request-leave {
  ul {
    list-style: none;
  }
  .text-confirm {
    margin-bottom: 0;
    padding: 0 0 24px 0;
  }
}

.creator-profile-info {
  .birthday {
    width: 100%;
  }
  .gender {
    .anticon {
      right: 10px;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 11px;
      }
    }
  }
  input,
  select {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    border: 1px solid $color-14;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 9px 12px;
    z-index: 2;
    height: 44px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.creator-profile-info-status-6 {
  @include mq('sm') {
    .fansite-form {
      height: auto;
      background-color: $color-01;
    }
  }
}
