@import 'src/style/variables';
@import 'src/style/mixin';
.plan-item-subscription {
  position: relative;
  background: #f7f8f9;
  border: 2px solid $color-32;
  border-radius: 5px;
  padding: 32px 24px;
  margin: 16px auto;
  width: 500px !important;

  .premium-label {
    display: none;
  }

  .plan-item-subscription__title {
    margin-top: 20px;
    width: 100%;
    word-wrap: break-word;
  }

  &.premium {
    .premium-label {
      display: block;
      position: absolute;
      right: 0;
      top: 14px;

      img {
        max-width: 185px;
        height: 28px;
      }
    }
    // &::before {
    //   content: '';
    //   background-image: url('../../images/premium.png');
    //   position: absolute;
    //   top: 3px;
    //   right: -1px;
    //   object-fit: contain;
    //   width: 100px;
    //   height: 42px;
    //   overflow: hidden;
    // }
    &__title {
      color: #57c22d;
    }
  }

  .limited-label {
    display: none;
  }

  &.limited {
    .premium-label {
      display: none;
    }

    .limited-label {
      display: block;
      position: absolute;
      right: 0;
      top: 14px;

      img {
        max-width: 111px;
        height: 28px;
      }
    }

    // &::before {
    //   content: '';
    //   background-image: url('../../images/Limited.png');
    //   position: absolute;
    //   top: 3px;
    //   right: -1px;
    //   object-fit: contain;
    //   width: 100px;
    //   height: 42px;
    //   overflow: hidden;
    // }
  }

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 101%;
    height: 14px;
    // background-color: $color-28;
    background-color: #1480ff;
    border-radius: 5px 5px 0px 0px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  &__plan-price {
    text-align: center;
    font-size: 20px;
    color: #1a1a1a;
    font-weight: 500;
    margin-bottom: 24px;
  }
  &__title {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 10px;
    color: #1480ff;
    // &.premium {
    //   color: #57c22d;
    // }
  }
  // &.selled {
  //   border-color: #ff9f43;
  // }
  &-title {
    color: #282727;
    font-weight: 500;
    font-size: 13px;
  }
  &-price {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #ff0000;
  }
  &-subscription {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #282727;
    overflow: hidden;
  }
  &-term {
    font-weight: 500;
    line-height: 16px;
    &-note {
      color: #604de7;
    }
    label {
      font-size: 11px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 213px;
    height: 34px;
    color: white;
    background-color: $color-28;
    & + .btn {
      margin-left: auto !important;
    }
  }
  .btn-edit {
    background-color: $color-26;
    // margin-top: 24px;
    // margin-bottom: 8px;
    &:hover {
      background-color: $color-27;
    }
  }

  .btn-edit1 {
    width: 100% !important;
    background-color: $color-10;
    &:hover {
      background-color: #11B259 ;
    }
  }

  .btn-submit {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    background-color: $color-26;
    border-radius: 6px;
    padding: 8px 12px;
    border: none;
    width: 100%;
    margin: 0 14px;

    &.not-checked {
      background-color: rgba($color: $color-26, $alpha: 0.3);
    }
    &.subscribed {
      background-color: #ffbf00;
      &.not-checked {
        background: rgba($color: #ffbf00, $alpha: 0.3);
      }
    }
    &.disabled {
      background-color: $color-07;
      border: none;
      cursor: auto;
    }
  }

  .agree-policy {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    input {
      &:disabled {
        background: #efefef;
        border: none;
        opacity: 1;
      }
    }

    &.jp {
      .link {
        order: 2;
      }
      .policy-checkbox {
        order: 1;
      }
      .policy-label {
        order: 3;
      }
    }

    .link {
      color: #1480ff;
    }
  }

  .form-check-input {
    margin-top: 0;
  }
  .plan-term-note {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

@include mq('sm') {
  .plan-item-subscription {
    width: 100% !important;
  }
}
