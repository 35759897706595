@import 'src/style/variables';
@import 'src/style/mixin';
ul.header {
  justify-content: space-between;
  padding: 0;
  margin: 0;
  li {
    flex-basis: 100% !important;
    text-align: center;
    border-bottom: 1px solid $color-07;
    &.active {
      color: $color-06;
      border-bottom: 2px solid $color-06;
    }
  }
}
