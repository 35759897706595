@import 'src/style/variables';
@import 'src/style/mixin';
.slider-custom {
  .slick-prev {
    left: 16px;
  }
  .slick-next {
    right: 16px;
    transform: rotate(180deg) !important;
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
    background-color: $color-01;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;
    transform: none;
    top: 25%;
    cursor: pointer;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    svg {
      color: $color-08;
    }
    &::before {
      display: none;
    }
  }
}
