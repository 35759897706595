$grey-color: #b1b6bb;
@import 'src/style/variables';
@import 'src/style/mixin';

@mixin tablet {
  @media only screen and (max-width: 772px) {
    @content;
  }
}

@mixin size {
  width: 100%;
  height: 241px;
}

.cursor-pointer {
  cursor: pointer;
}

.link {
  cursor: pointer;
  color: #604de7;
}

.link-doc {
  cursor: pointer;
  color: #604de7;
  max-width: 550px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include mq('sm') {
    max-width: 250px;
  }
}
.agree-policy {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.note-policy {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #787880;
  padding-left: 23px;
  padding-right: 19px;
}

.form-check-input {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.identify-card {
  @include size;
  border-radius: 8px;
  overflow: hidden;
  .upload-label {
    @include size;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px dashed #E5E6EB;
    background: #F7F8FA;
    text-align: center;
  }
  input {
    display: none;
  }
  &-des {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2%;
    color: #666565;
    text-align: center;
    margin: 5px 0 0 0 !important;
  }
  .error {
    color: red;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .card-img {
    @include size;
    border-radius: 8px;
    object-fit: contain;
  }
  .upload-des {
    white-space: nowrap;
    word-break: keep-all;
  }
  .pdf-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}

.camera {
  border: 1px solid #E5E6EB;
  border-radius: 8px;
  width: auto;
  padding: 7px 24px;
  z-index: 20;
}

.camera.right {
  right: 50% !important;
  transform: translateX(50%);
}

.camera.bottom {
  bottom: 30px !important;
}

.pdf-viewer-container {
  position: relative;
  background: #F7F8FA;
  height: 120px;
  border-radius: 8px;
}