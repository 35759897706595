@import 'src/style/variables';
@import 'src/style/mixin';

.block-list-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.block-page-creator {
  .list-block-creator {
    padding: 8px 8px 40px 8px;

    @include mq('sm') {
      padding: 0 0 40px 0;
    }
    .shimmer-loading {
      padding: 16px !important;

      @include mq('sm') {
        padding: 8px !important;
      }
    }

    height: 100%;
    @include mq('sm') {
      padding-bottom: 0;
    }

    .no-data {
      @include mq('sm') {
        position: fixed;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #b1b6bb;
    }
  }
}

.block-page-header {
  border-radius: 20px 20px 0 0;
}
