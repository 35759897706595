@import 'src/style/variables';
@import 'src/style/mixin';

.message-questionnaire-creator {
  display: flex;
  flex-direction: column;
  padding: 20px 16px 16px 16px;
  min-height: calc(100vh - 120px);
  
  @include mq('sm') {
    padding: 16px 16px 0 16px;
  }
  .creator-wrapper {
    flex: 1;
  }

  .custom-tooltip {
    max-width: 400px;
    @include mq('sm') {
      max-width: calc(100vw - 140px) !important;
    }
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
    color: #666565;
    max-width: 100%;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    @include mq('sm') {
      margin-bottom: 19px;
    }
  }
  .sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .list-answer {
    margin-left: 12px;
    .item {
      td {
        padding-bottom: 8px;
        &:last-child {
          display: flex;
          white-space: nowrap;
          padding-left: 15px;
          min-width: 100px;
        }
      }
    }
  }

  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line {
    display: none;
  }

  .recharts-text {
    fill: $color-03;
  }
  tspan {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .chart-wrapper + .chart-wrapper {
    margin-top: 24px;
  }

  .content-left {
    min-width: 150px;
    margin-right: 10px;
  }
  .content-right {
    margin-top: 12px;
    padding-right: 20px;

    @include mq('sm') {
      padding-right: 0;
    }
  }
  .container-btn {
    display: flex;
    @include mq('sm') {
      display: unset;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Noto Sans JP';
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      margin-top: 24px;
      border-radius: 8px;
    }
    .btn-back {
      background: #fff;
      border: 1px solid #d9d9d9;
      color: #1a1a1a;
      @include mq('sm') {
        margin-top: 12px;
      }
    }

    .add {
      border: 1px solid #787880;
      color: #282727;
      margin-left: 12px;
      @include mq('sm') {
        margin-left: 0;
      }
    }

    .active {
      color: #fff;
      background-color: $color-26;
      border: 1px solid $color-26;
      margin-right: 12px;
    }

    .disabled {
      color: #fff;
      background-color: $color-30;
      border: 0;
      margin-right: 12px;
    }
  }
}
