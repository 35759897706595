@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-ranking {
  background-color: $color-23;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto 60px auto;
    text-align: center;
  }
  .list-idol {
    margin: 0 auto;
    display: flex;
    padding-bottom: 60px;
    max-width: 900px;
    gap: 16px;
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 14px;
        z-index: 0;
        margin-top: 7px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
    }
  }
  .list-idol1 {
    justify-content: center;
    .item {
      min-width: 140px;
      @include mq('sm') {
        min-width: 100px;
      }
    }
    .icon1 {
      color: #ffc501;
    }
    .icon2 {
      color: $color-14;
    }
    .icon3 {
      color: #b7835b;
    }
  }
  .list-idol.list-idol2 {
    padding: 0 20px 32px 20px;
    width: unset;
    .item {
      display: flex !important;
      .name {
        max-width: 100px;
      }
    }
    .slick-arrow {
      top: 25%;
    }
  }
  .slick-list {
    width: 100%;
  }
}

@include mq('md') {
  .list-idol-ranking {
    background-size: 100% 100%;
    .title {
      margin: 32px auto;
    }
    .list-idol.list-idol1 {
      padding-bottom: 43px;
    }
  }
}

@include mq('sm') {
  .list-idol-ranking {
    .list-idol.list-idol1 {
      padding-bottom: 43px;
    }
    .list-idol.list-idol2,
    .list-idol.list-idol1 {
      .item {
        width: 74px;
        img {
          height: 74px;
          width: 74px;
          margin-bottom: 12px;
        }
        .name {
          width: 74px;
          max-width: 74px;
          font-size: 12px;
        }
      }
      .slick-arrow {
        top: 22%;
      }
    }
  }
}
