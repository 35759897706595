@import 'src/style/variables';
@import 'src/style/mixin';
/*react calendar*/
.react-calendar.react-calendar-custom.bottom-right {
  right: 0;
  left: 0;
}
.react-calendar.react-calendar-custom.top-left {
  right: unset;
  left: 0;
  bottom: 100%;
  top: unset;
}
.react-calendar.react-calendar-custom.top-right {
  right: 0;
  left: unset;
  bottom: 100%;
  top: unset;
}
.react-calendar-custom.react-calendar,
.react-calendar.bottom-right,
.react-calendar.top-left {
  position: absolute;
  left: 0;
  top: 100%;
  width: 266px;
  height: auto;
  color: $color-03;
  z-index: 20;
  background: $color-01;
  margin-top: 5px;
  margin-bottom: 5px;

  @include mq('sm') {
    max-width: 266px;
  }
  .react-calendar__viewContainer {
    padding-bottom: 16px;
  }
  .react-calendar__tile {
    padding: 0;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: $color-26 !important;
    color: $color-01 !important;
    &:hover {
      color: $color-01 !important;
    }
  }

  .react-calendar__tile:disabled {
    color: $color-14 !important;
    cursor: auto;
    background-color: $color-05;
    &:hover {
      border: none;
      background: $color-05;
      color: $color-14 !important;
    }
  }

  .react-calendar__tile--now {
    background: none;
  }
  .react-calendar__tile:hover {
    border: 1px solid $color-26;
    background: transparent;
    color: $color-03;
  }
  // day--weekend
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }

  // date of next & prev month
  .react-calendar__month-view__days__day--neighboringMonth {
    color: $color-14;
  }
  // month
  .react-calendar__year-view__months {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    .react-calendar__year-view__months__month {
      margin-left: 12px;
    }
  }
  // year
  .react-calendar__decade-view__years {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    .react-calendar__decade-view__years__year {
      width: 55px;
      height: 55px;
      margin-left: 15px;
    }
  }
  // decade
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    .react-calendar__century-view__decades__decade {
      width: auto;
      max-width: 100px;
      height: 55px;
      margin-left: 15px;
    }
  }

  //top
  .react-calendar__navigation {
    // margin-bottom: 0;
    button:disabled {
      background: #f0f0f0 !important;
    }
    button:enabled:focus {
      background: transparent;
    }
    button {
      &:hover {
        background: none;
      }
    }
  }

  //arrow
  .react-calendar__navigation__arrow {
    padding: 0;
    min-width: 30px;
    svg {
      width: 12px !important;
    }
  }

  .react-calendar__navigation__prev-button {
    svg {
      transform: rotate(90deg);
    }
  }
  .react-calendar__navigation__next-button {
    svg {
      transform: rotate(-90deg);
    }
  }

  // 2 arrow
  .react-calendar__navigation__prev2-button {
    margin: 0 10px 0 5px;
    svg {
      transform: rotate(90deg);
      & + svg {
        margin-left: -5px;
      }
    }
  }
  .react-calendar__navigation__next2-button {
    margin: 0 5px 0 10px;
    svg {
      transform: rotate(-90deg);
      & + svg {
        margin-left: -5px;
      }
    }
  }
}

.react-calendar-C081_1 {
  max-width: 285px;
}

.react-calendar-AF004 {
  max-width: 285px;
}
