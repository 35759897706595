@import 'src/style/variables';
@import 'src/style/mixin';

.message-item-fan {
  display: flex;
  margin-top: 1rem;
  padding: 0 1rem;

  .content-left {
    flex: 1;
    min-width: 0;
    margin-right: 20px;
    display: flex;
    cursor: pointer;
    .avatar {
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;
      border-radius: 30px;
    }
    &_info {
      margin-left: 10px;
      flex: 1;
      min-width: 0;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
      }
      .name {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;

    .btn {
      padding: 5px 8px;
      min-width: 90px;
      height: 28px;
      font-size: 12px;
    }
    .btn-cancel {
      border: 1px solid #b1b6bb;
      border-radius: 6px;
      color: #282727;
    }
  }
}
