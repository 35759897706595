@import 'src/style/variables';
@import 'src/style/mixin';

.buyer-page-creator {
  .list-ranking-buyer {
    padding: 0 16px 40px 16px;
  }
  .fansite-form {
    padding: 24px;

    @include mq("sm") {
      padding: 16px;
    }
    
    .fansite-form-input__controls {
      margin-bottom: 0;
    }
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }
  .empty-box {
    color: $color-14;
    text-align: center;
    padding: 100px 20px;
  }
  .select-box {
    display: flex;
    align-items: center;
    &.disabled {
      & > div {
        background-color: #eee;
        border-radius: 6px;
      }
    }
    .select-label {
      font-size: 12px;
      font-weight: 500;
      margin-right: 16px;
    }
  }
  .select-box {
    position: relative;
    .anticon {
      position: absolute;
      right: 15.3px;
      top: 50%;
      transform: translateY(-50%);
      // color: $color-14;
      z-index: 1;
    }
    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
    .anticon {
      right: 10px;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
