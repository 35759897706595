@import 'src/style/variables';
@import 'src/style/mixin';

.sale-confirm-layout {
  .shimmer-loading {
    padding: 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  
  }

  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.revenue-page-creator {
  color: #1a1a1a;

  @include mq('sm') {
    padding: 0 !important;
  }

  .list-buyer-creator {
    padding: 0px 0 40px 0;
  }
  .btn-withdraw-money {
    width: 324px;
    padding: 10px 24px;
    border-radius: 8px;
    background: #dc143c;
    border: none;
    color: #fdfefe;
    font-weight: 700;
    font-size: 14px;
    &:hover {
      background-color: $color-27;
    }

    @include mq('sm') {
      width: 263px;
    }

    &.disabled {
      user-select: none;
      pointer-events: none;
      background-color: $color-08;
      border-color: $color-08;
      background: #f1f1f1;
      border: 1px solid #e5e6eb;
      color: #1a1a1a;
    }

    &:disabled {
      user-select: none;
      pointer-events: none;
      background-color: $color-08;
      border-color: $color-08;
      background: #f1f1f1;
      border: 1px solid #e5e6eb;
      color: #1a1a1a;
    }
  }
  .fansite-form {
    .fansite-form-input__controls {
      margin-bottom: 0;
    }
  }
  .empty-box {
    color: $color-14;
    text-align: center;
    // padding: 100px 20px;
    height: 100%;
    margin-bottom: -82px;
    display: block;
    position: relative;
    border-bottom: 1px solid #e5e6eb;
    padding-top: 120px;
    padding-bottom: 30px;
    @include mq('sm') {
      margin-top: initial;
    }
  }
  .group-point {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding-top: 21px;
    padding-bottom: 28px;

    @include mq('sm') {
      padding-top: 25px;
      padding-bottom: 20px;
    }

    .point-number-group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .point-number {
        font-size: 30px;
        // color: #28c76f;
        color: #1480ff;
        margin-right: 10px;
        line-height: 1.2;

        @include mq('sm') {
          font-size: 24px;
        }
      }
    }
  }

  .ranking-payment {
    .filter-date {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 32px;
      .select {
        margin: 4px;
      }
    }
    .raking-payment-table {
      // border-bottom: 1px solid #e5e6eb;
      .toggle-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        border-top: 1px solid #e5e6eb;
        border-bottom: 1px solid #e5e6eb;

        @include mq('sm') {
          padding: 12px 16px;
          color: #1a1a1a;
          font-weight: 500;
        }

        .toggle-icon {
          display: flex;
          cursor: pointer;
          color: #bebebe;
          .anticon {
            transform: rotate(0deg);
            &.active {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  @include mq('sm') {
    .text-sale-history {
      padding-left: 16px;
    }
  }

  .table-payment {
    overflow: auto;

    @include mq('sm') {
      overflow: hidden;
      width: 100%;

      &.table-top-fans {
        .tr {
          justify-content: flex-start;
          .no {
            width: 15%;
          }
          .username {
            width: 50%;
          }
          .amount {
            width: 35%;
          }
        }
      }
    }
    .tr {
      display: flex;
      justify-content: space-between;
      min-width: 320px;

      @include mq('sm') {
        justify-content: unset;
      }
      .th:last-child {
        padding-right: 32px;

        @include mq('sm') {
          padding-right: 17px;

          &.type {
            width: max-content;
            padding-right: 4px;
            padding-left: 4px;
            text-align: start;
          }
        }
      }
      .no {
        min-width: 70px;
        width: 70px;

        @include mq('sm') {
          padding: 16px 12px;
          min-width: 60px;
          width: 60px;
        }
      }
      .amount {
        width: 150px;
        min-width: 150px;

        @include mq('sm') {
          min-width: 80px;
          width: 80px;
        }
      }
      .date {
        min-width: 155px;
        width: 155px;
        white-space: nowrap;

        @include mq('sm') {
          min-width: 80px;
          width: 80px;
          white-space: unset;
        }
      }
      .type {
        width: 150px;
        min-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq('mw768') {
          min-width: 120px;
          width: max-content;
          text-align: start;
        }
        @include mq('sm') {
          min-width: 120px;
          width: max-content;
          text-align: end;
        }
        @media screen and (max-width: 400px) {
          min-width: 100px;
        }

        @media screen and (max-width: 376px) {
          min-width: 90px;
        }
      }
      .no {
        @include mq('sm') {
          min-width: 60px;
          width: 60px;
        }
      }
      .username {
        width: 200px;
        min-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include mq('sm') {
          min-width: unset;
          width: 180px;
        }

        @include mq('xs') {
          min-width: unset;
          width: 120px;
        }
      }
    }
    .thead {
      background: #e5e6eb;
      .tr {
        background: #e5e6eb;
      }
      .th {
        padding: 16px;
        background: #e5e6eb;

        @include mq('sm') {
          color: #1a1a1a;
          font-weight: 500;
        }
      }
    }
    .tbody {
      // max-height: 550px;
      min-width: 320px;
      // overflow-y: auto;

      @include mq('sm') {
        width: 100%;
        min-width: unset;
      }
      &::-webkit-scrollbar {
        width: 2px;
        background-color: #b1b6bb;
      }
      .tr {
        border-bottom: 1px solid #e5e6eb;
        width: 100%;
        min-width: fit-content;

        @include mq('sm') {
          min-width: unset;
        }
      }
      .td {
        padding: 16px;
        white-space: nowrap;
        &:last-child {
          padding-right: 30px;
        }

        @include mq('sm') {
          padding: 16px 14px;

          &.date {
            white-space: unset;
            padding: 5px 0 5px 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.type {
            padding: 16px 4px 16px 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: start;
          }
        }
      }
    }
    .table-payment-foot {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;

      &.mobile {
        .pagination-table {
          padding-right: 14px;
          li {
            &.selected {
              a {
                color: #dc143c;
              }
              border: 1px solid #dc143c;
            }
          }
        }
      }
      .pagination-table {
        li {
          border: 1px solid #d9d9d9;
          a {
            color: rgba(0, 0, 0, 0.85);
          }

          &.selected {
            a {
              color: #1890ff;
            }
            border: 1px solid #1890ff;
          }
        }

        .break {
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            align-self: center;
            color: #e5e6eb;
            line-height: 24px;
          }
        }
        svg {
          path {
            fill: none;
          }
        }
        .previous,
        .next {
          a {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .disabled {
          .disabled-prev-next {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              path {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
    .tfoot {
      height: 72px;
      font-size: 14px;
      font-weight: normal;
      color: #787880;
      background: #ffffff;
      td {
        // border-bottom: 1px solid #eeeeee;
        // border-top: 1px solid #eeeeee;
        font-size: 14px;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        .links {
          text-align: right;
          .btn__action-table {
            padding: 0;
            margin: 0;
            display: inline-block;
            text-align: center;
            overflow: hidden;
            background: #fff;
            border: 1px solid #e5e6eb;
            border-radius: 2px;
            width: 32px;
            height: 32px;
            color: #282727;
            svg {
              padding: 0;
              width: 18px;
              height: 18px;
              path {
                fill: #282727;
              }
            }
          }
          span {
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 4px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #e5e6eb;
            color: #787880;
            cursor: pointer;
            &.active {
              color: #ff0000;
              border: 1px solid #ff0000;
            }
          }
        }
      }
    }
  }

  .table-history-sale {
    .tr {
      min-width: 540px;
    }
    .tbody {
      min-width: 540px;
      min-height: 200px;
      position: relative;
    }

    &.table-history-sale {
      @include mq('sm') {
        .tr {
          min-width: unset;
        }
        .tbody {
          min-width: unset;
        }
      }
    }
  }

  .sale-details {
    .btn-export-csv {
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      min-width: 104px;
      height: 32px;
      max-width: max-content;
      padding: 0 24px;
    }
    .btn-export-csv.mobile {
      display: none;
    }
    .shimmer-button-mobile {
      display: none;
    }

    @include mq('sm') {
      .shimmer-button-mobile {
        display: block;
      }
      .shimmer-button-pc {
        display: none;
      }
      .csv-container-mobile {
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
      }
      .btn-export-csv.mobile {
        display: flex;
        margin-bottom: 16px;
      }
    }
    .sale-detail-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      flex-wrap: wrap;
      gap: 8px 26px;

      .text-total-sale {
        color: #1a1a1a;
        font-weight: 700;

        @include mq('sm') {
          font-weight: 500;
        }
      }

      .sale-number {
        color: $color-06;
        font-weight: bold;
        white-space: nowrap;
        .currency-format {
          font-size: 30px;
        }
      }

      @include mq('sm') {
        justify-content: space-between;
        padding: 0 16px;

        .title-sale-detail {
          color: #1a1a1a;
          font-weight: 500;
        }

        .sale-number {
          .currency-format {
            font-size: 20px;
          }
        }
        .btn-export-csv {
          display: none;
        }
      }
    }
  }

  .sale-select {
    @include mq('sm') {
      .col-md-6 {
        width: 50%;
        padding: 0 5px;
      }

      .row {
        max-width: 100%;
        margin: 0;
        padding: 0 11px;
      }
    }
    .sale-select-item {
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 24px;
      margin-bottom: 24px;
      border: 2px solid transparent;
      cursor: pointer;
      @media screen and (max-width: 991px) {
        margin-bottom: 16px;
      }

      @include mq('sm') {
        padding: 15px 0;
        margin-bottom: 11px;
      }

      ._title {
        font-weight: 500;
        margin-bottom: 12px;
      }
      ._amount {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      ._subject {
        font-weight: 400;
      }
      &.active {
        color: #1480ff;
        border-color: #1480ff;
      }
    }
  }
}

.popup.popup-confirm-withdraw {
  background: rgba(40, 39, 39, 0.6);
  .btn-lg {
    font-size: 14px;
    padding: 0.75rem 1rem;
    font-weight: bold;
  }
  .popup-content {
    padding: 0;
    border-radius: 12px;
    width: 100%;
    max-width: 375px;
    height: auto;
    .err {
      text-align: left;
      color: #ff0000;
      font-size: 14px;
      font-weight: 400;
    }
    @include mq('sm') {
    }
  }
  .popup-header {
    position: relative;
    display: flex;
    padding: 12px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    .anticon {
      position: absolute;
      right: 20px;
      color: #787880;
    }
    .title {
      font-size: 14px;
      margin-left: 12px;
    }
  }
  .popup-body {
    padding: 16px 24px 32px 24px;
    @include mq('sm') {
      padding: 16px 24px 24px 24px;
    }

    .popup-input-label {
      font-weight: 400;
      font-size: 14px;
      text-align: start;
    }

    .fsite-input {
      border: 1px solid #e5e6eb;
      &:focus {
        border-color: #1480ff;
      }
    }
  }
  .popup-footer {
    border-top: 1px solid #eeeeee;
    padding: 16px 25px 25px 25px;
    .group-button-footer {
      display: flex;
      justify-content: flex-end;
      .btn {
        min-width: 92px;
        &.ok {
          background: #dc143c;
          &:disabled {
            opacity: 0.5;
          }
        }

        &.cancel {
          min-width: 119px;
        }
      }
    }
  }
}

.popup.popup-withdraw-success {
  background: rgba(40, 39, 39, 0.6);
  .popup-content {
    padding: 32px 24px 24px 24px;
    height: auto;
    width: 100%;
    max-width: 379px;
    .content {
      display: flex;
      align-items: center;
    }

    .text-end {
      .btn.ok {
        min-width: 90px;
        background-color: #dc143c;
      }
    }
  }
}

.popup.popup-withdraw-success.popup-error-buy-package {
  position: absolute;
}

.popup.popup-withdraw-success.popup-error-buy-package.plan-list-error-buy-package {
  position: fixed;
}
