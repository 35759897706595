@import 'src/style/variables';
@import 'src/style/mixin';
@mixin flex-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-div-signin {
  &__active:focus {
    border: 1px solid #1480ff !important;
  }
  background-color: transparent;
  background: url('../../images/bg-singin.png');
  height: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.sign-in-wrapper {
    height: unset;
  }

  .container-div {
    justify-content: center;
    padding: 90px 0;
    display: flex;
    align-items: flex-start;
    @media screen and (min-width: 992px) and (max-width: 1024px) {
      justify-content: space-around;
    }
    @include mq('md') {
      display: block;
      padding: 0;
    }
    // @media screen and (max-width: 375px) and (max-height: 812px) {
    //   padding-top: 50px;
    // }
    @media screen and (max-width: 768px) and (min-width: 768px) {
      padding-top: 50px;
    }
    .container-left {
      margin-top: 50px;
      background: url('../../images/img-slice-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 100px;
      @media screen and (min-width: 1024px) and (max-width: 1365px) {
        margin-right: 20px;
      }
      @media screen and (min-width: 992px) and (max-width: 1024px) {
        margin-right: 0;
      }
      @include mq('md') {
        display: none;
      }
    }
  }

  .home-quick-views-container {
    //aspect-ratio: 4/3;
    transition: 0.2s;
    width: 100%;
    // background-color: $color-02;
    img {
      object-fit: contain !important;
      aspect-ratio: 4/3;
    }
  }
  .bg-icon-smaller {
    width: 53px;
    height: 53px;
  }

  .slick {
    &-dots {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: -30px;
      li {
        margin-right: 20px;
        button:before {
          font-family: 'slick';
          font-size: 6px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 38px;
          height: 2px;
          border-radius: 5px;
          content: '';
          background-color: $color-14;
          order: 2px solid $color-14;
          text-align: center;
          opacity: 1;
        }
        &.slick-active {
          button {
            &:before {
              background-color: #ff0000;
            }
          }
        }
        position: relative;
        display: inline-block;
        padding: 0;
        cursor: pointer;
        border-color: #ff0000;
        &:before {
          width: 10px;
          border-radius: 5px;
          background: #ff0000;
          height: 5px;
          color: #ff0000;
          &:hover {
            text-align: center;
            opacity: 0.25;
            display: none;
          }
        }
      }
    }
  }
}

.container-login {
  min-height: calc(100%);
  height: auto;
  @include flex-middle;
  flex-direction: column;
  background: transparent;
  border-radius: 20px;
  // @include mq('mw1024') {
  //   padding-top: 60px
  // }
  .login-form {
    @include flex-middle;
    width: 401px;
    @media screen and (min-width: 992px) and (max-width: 1023px) {
      width: 320px !important;
    }
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    @include mq('md') {
      width: 100%;
      border-radius: 0;
      margin-top: 60px;
    }
    .title {
      color: #282727;
      font-size: 24px;
      font-weight: 700;
      //margin: 24px 0;
      margin-bottom: 40px;
    }
    .form {
      margin: 40px 0;
      width: 100%;
      padding: 0 29px;
      @include mq('md') {
        margin: 0;
        margin-bottom: 16px;
      }
      @include mq('md') {
        padding: 16px;
      }
      .password {
        position: relative;
        svg {
          position: absolute;
          right: 16px;
          bottom: 12px;
        }
        .eye-icon {
          z-index: 1;
          right: 10px;
          svg {
            height: 20px;
          }
        }
        input::placeholder {
          letter-spacing: 0;
        }
      }
      input {
        letter-spacing: 0;
        font-size: 14px;
        padding: 0 12px;
        font-weight: 400;
        width: 100%;
        max-width: unset !important;
        height: 42px;
        color: #282727;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        background-color: #fff;

        &::placeholder {
          color: #b1b6bb;
          font-weight: 500;
        }
        &:focus {
          box-shadow: unset;
          outline: none;
        }
      }
      .flat-btn {
        text-align: right;
        margin-bottom: 24px;
        font-weight: 400;
        .reset-password {
          color: #1a1a1a;
        }
        .here {
          color: $color-26;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .fansite-form-input__error {
        @include mq('md') {
          margin: 4px 0;
        }
      }
    }
    @media screen and (max-height: 1023px) {
      margin-top: 40px;
    }
  }
  .forgot-password,
  .change-password {
    width: 375px;
    margin-top: 100px;

    @include mq('md') {
      margin-top: 40px;
      padding: 16px 0;
      width: 100%;
    }

    .form {
      padding: 0 16px;

      .fansite-form-input__controls {
        margin-bottom: 24px;
      }
    }
  }
  .change-password {
    .form {
      padding: 0 16px;

      .fansite-form-input__controls {
        margin-bottom: 32px;

        @include mq('md') {
          margin-bottom: 24px;
        }
      }
    }
  }
  .or {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    text-align: center;
    margin: 24px auto 16px auto;
    width: max-content;
    color: #666565;
    &::before {
      position: absolute;
      content: '';
      width: 90px;
      height: 1px;
      background: #f1f1f1;
      top: 50%;
      left: calc(100% + 14px);
    }
    &::after {
      position: absolute;
      content: '';
      width: 90px;
      height: 1px;
      background: #f1f1f1;
      top: 50%;
      right: calc(100% + 14px);
    }
  }
  .btn {
    @include flex-middle;
    margin-top: 8px;
    height: 44px;
    width: 100%;
    font-weight: bold;
    padding: 0;
    outline: 0;
    border: 0;
    border-radius: 6px;
    &.twitter {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      background: #08bef8;
      border-radius: 8px;
      font-weight: 500;
      color: #fff;
      .anticon {
        margin-right: 6px;
        color: #fff;
      }
    }
  }

  .active {
    color: #fff;
    background-color: $color-26;
    border-radius: 8px;
    font-weight: 500;
    &:hover {
      background: $color-27;
    }
  }

  .disabled {
    color: #fff;
    background: $color-26 !important;
    font-weight: 500;
    opacity: 0.3;
  }

  .register {
    color: #1a1a1a;
    background-color: #fff;
    border: 1px solid #e5e6eb;
    margin-top: 16px !important;
    border-radius: 8px;
    font-weight: 500;
  }

  .btn-forgot-pass {
    margin-top: 24px;
    font-weight: 400;
    &.active {
      background-color: #dc143c;
    }
  }
}

.twitter-login {
  padding: 90px 0;
  @include mq('md') {
    padding: 0;
  }
}

.try-email-method {
  width: 100%;
  text-align: center;
  margin-top: 16px;
  font-size: 14px;

  span {
    color: #DC143C;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
}

.resend {
  width: 100%;
  text-align: right;
  font-size: 14px;

  span:last-child {
    color: #DC143C;
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }
}