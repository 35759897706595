@import 'src/style/variables';
@import 'src/style/mixin';
.preview-live-stream-header {
  .back-to-page {
    margin-top: 0px !important;
  }
}
.review-livestream {
  padding: 24px;
  // general
  .bg {
    // padding-bottom: 70%;
    position: relative;
    background-color: #000000;
    .loading-video-live {
      position: absolute;
    }
    .logo-coco {
      position: absolute;
      right: 12px;
      bottom: 12px;
      z-index: 50;
      img {
        background-color: transparent;
      }
    }
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    color: #1a1a1a;
    &.text-description {
      color: #666565;
      word-break: break-all;
    }
  }
  .screen-live {
    position: relative;
    width: 100%;
    aspect-ratio: 1.77777;

    .box-button {
      gap: 12px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 16px;
      span {
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
    }
  }

  .box-submit {
    margin: 16px 0 32px 0;
    .button-check-delivery {
      border-color: $color-26;
      color: $color-26;
    }
    .button {
      width: 50%;
    }
  }
  .screen-info {
    .img-background {
      border-radius: 8px;
      width: 100%;
      position: relative;
      overflow: hidden;
      aspect-ratio: 1.333333;
    }
    .img_thumbnail {
      height: 100%;
      position: absolute;
      top: 0;
    }
    .row {
      margin-top: 16px;
    }
    .text {
      margin-bottom: 0;
      font-weight: bold;
    }
    .value {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #666565;
    }

    .link-copy {
      display: flex;
      align-items: center;
      position: relative;

      .tooltip-no-data {
        .anticon {
          width: 190px;
          height: 20px;
          position: absolute;
          top: -10px;
          left: 0;
          opacity: 0;
        }
        .tooltiptext {
          transform: translate(115%, 43%);
          max-width: 250px;
          bottom: calc(100% + 260px);
          &::after {
            bottom: -11px;
            top: unset;
            transform: translate(-50%, -100%) rotate(180deg);
          }
        }
      }
      &.deliveryURl {
        .tooltip-no-data {
          .anticon {
            width: 190px;
            height: 20px;
            position: absolute;
            top: -10px;
            left: 0;
            opacity: 0;
          }
          .tooltiptext {
            transform: translate(100%, 150%);
            max-width: 250px;
            bottom: 0;
            @include mq('sm') {
              transform: translate(50%, 150%);
            }
            &::after {
              bottom: unset;
              top: -6px;
              transform: translate(-50%, 0%) rotate(0deg);
            }
          }
        }
      }
      .link {
        font-size: 14px;
        line-height: 20px;
        color: $color-16;
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon-copy {
        color: $color-26;
        margin-left: 7px; // 18px;
        cursor: pointer;
        z-index: 20;
      }
    }
  }

  .box-btn {
    margin-top: 26px;
    gap: 24px;
    .btn {
      height: 44px;
    }
  }

  .button-submit {
    color: #fdfefe;
    background-color: $color-26;
    border: none;
    font-size: 16px;
  }

  .button-disable {
    color: '#999999';
    background-color: '#b1b6bb';
    border: none;
  }

  .btn-edit {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid $color-32;
    border-radius: 6px;
    color: #1a1a1a;
    background-color: transparent;
  }
  .box-table {
    width: 100%;
  }
  .table-setting {
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid $color-32;
    width: 100%;
    max-width: 286px;
  }
  .table-setting-last {
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid $color-32;
    width: 100%;
  }
  .table-head {
    border-bottom: 1px solid $color-32;
  }
  .table-head,
  .row-item {
    display: flex;
    background: $color-33;
    height: 29px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    position: relative;
    .icon-close {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
  .table-body {
    height: auto; // 155px;
    overflow: auto;
    position: relative;
    max-height: 144px;
    &::-webkit-scrollbar {
      background: transparent;
      width: 3px;
      scroll-margin-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #eeeeee;
      border-radius: 10px;
      border-right: 3px white solid;
      border-left: 3px white solid;
    }
    .row-item {
      height: fit-content;
      background: transparent;
      border-top: 1px solid $color-32;
      &:nth-child(1) {
        border-top: none;
      }
    }
    .text-setting {
      justify-content: flex-start;
      margin-left: 8px;
      span {
        width: 100%;
        overflow-wrap: break-word;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        @include mq('sm') {
          text-align: start;
        }
      }
    }
  }

  .ng-word-row {
    display: flex;
    flex-wrap: wrap;

    @include mq('ms') {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      align-items: flex-start;
    }
    @include mq('sm') {
      display: flex;
      flex-direction: column;
      gap: 17px;
    }
    .ng-word-col {
      width: 50%;
      @include mq('sm') {
        width: 100%;
      }
      // max-width: 286px;
      .table-setting {
        margin-bottom: 10px;
      }
      .text-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-14;
      }
    }

    .ng-word-col2 {
      @include mq('ms') {
        margin-left: 10px;
      }
    }
  }
  .no {
    min-width: 32px;
    max-width: 32px;
    border-right: 1px solid $color-32;
  }

  .text-setting {
    width: calc(100% - 40px);
    overflow-wrap: break-word;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .text-no-data {
    position: relative; // absolute;
    //top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-14;
  }
}

@include mq('mw1024') {
  .review-livestream {
    padding: 17px;
    .text {
      margin-bottom: 8px;
      & + .text {
        margin-bottom: 14px;
      }
    }
    .box-submit {
      margin: 16px 0 9px 0;
    }
    .screen-info {
      .row {
        margin-top: 8px;
        &.col-mobile {
          @include mq('sm') {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
    .box-btn {
      margin-top: 21px;
      margin-bottom: 18px;
    }
  }
}

@include mq('sm') {
  .box-btn {
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 12px !important;
    button {
      border: 8px;
    }
  }
}

.popup-validate-time-live.popup.confirm-fan {
  .popup-content {
    padding-right: 32px;
  }
  .content {
    width: 375px;
    .d-flex.flex-column {
      width: 100%;
      margin-left: 0 !important;
    }
  }
}

.dd-title-rv {
  width: 170px;
  font-weight: 500;
  font-size: 14px;
  color: #1a1a1a;
  line-height: 24px;
}

.screen-info .dd-title-value {
  max-width: calc(100% - 170px);
  @include mq('sm') {
    text-align: start;
  }
}

.row.col-mobile {
  @include mq('sm') {
    .dd-title-value.value,
    .dd-title-value {
      max-width: 100%;
      .table-setting {
        max-width: 100%;
      }
    }
  }
}

.copy-link-creator {
  .copy-success {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $color-01;
    left: 44%;
    top: 32px;
    transform: translateX(-50%);
    padding: 6px 12px;
    background: $color-03;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 21;
  }
}
.tt_obs-hover {
  .tooltiptext {
    bottom: calc(100% + 100px) !important;
    transform: none;
  }
}
.live_webcam-noobs {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
