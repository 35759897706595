@import 'src/style/variables';
@import 'src/style/mixin';

.layout-live-stream {
  height: 100%;
  .layout-full-screen-live {
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
    padding: 23px 30px;
    @media screen and (max-width: 1023px) {
      height: 100%;
      padding: 0;
      padding: 0;
    }
  }
}
