@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-pickup-operator {
  background: url('../../../images/SkyBackground.png');
  background-size: 1440px 554px;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto 60px auto;
    text-align: center;
  }
  .list-idol {
    width: 700px;
    margin: 0 auto;
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // justify-content: space-between;
    row-gap: 60px;
    padding-bottom: 60px;
    .item {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      position: relative;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 14px;
        z-index: 0;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
    }
  }
  .slick-slide {
    & > :nth-child(1) {
      margin-bottom: 60px;
    }
    & > div {
      display: flex;
      justify-content: center;
      gap: 60px;
    }
  }
  .slick-arrow {
    top: 37%;
  }
  .slick-list {
    width: 100%;
  }
}

@include mq('md') {
  .list-idol-pickup-operator {
    background-size: 100% 100%;
    .title {
      margin: 32px auto;
    }
    .list-idol {
      padding: 0 20px 32px 20px;
      width: unset;
      .item {
        width: max-content !important;
      }
    }
    .slick-slide {
      & > :nth-child(1) {
        margin-bottom: 0;
      }
      & > div {
        display: flex;
        justify-content: center;
      }
    }
    .slick-arrow {
      top: 22%;
    }
  }
}

@include mq('sm') {
  .list-idol-pickup-operator {
    .list-idol {
      .item {
        width: 74px;
        img {
          height: 74px;
          width: 74px;
          margin-bottom: 12px;
        }
        .name {
          width: 74px;
          max-width: 74px;
          font-size: 12px;
        }
      }
      .slick-arrow {
        top: 17%;
      }
    }
  }
}
