@import 'src/style/variables';
@import 'src/style/mixin';

.home-page-content {
  padding: 24px;
  @include mq('sm') {
    padding: 16px;
  }

  &.one-column {
    @include mq('sm') {
      padding: 0;
      .title__content_search,
      .search__wrapper,
      .row-fan--creator,
      .GroupIcon {
        padding: 0 16px;
      }
      .item {
        margin-left: 0;
        margin-right: 0;
        & > div:nth-child(1),
        & > div:nth-child(2),
        & > div:nth-child(3) {
          padding: 0 16px;
          margin: 0 auto;
        }
      }
    }

    .GroupIcon {
      & > .d-middle {
        gap: 25px;
        .cls-text {
          margin: 0;
        }
      }
    }
    .mb-2.cls-title {
      -webkit-line-clamp: 1;
    }
  }

  &.fan {
    padding: 16px;
    .title__content_search {
      span {
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
    &.one-column {
      padding: 0;
      .card-item--detail {
        padding: 0 !important;
      }
      .title__content_search,
      .search__wrapper,
      .row-fan--creator,
      .GroupIcon {
        padding: 0 16px;
      }
      .item {
        margin-left: 0;
        margin-right: 0;
        & > div:nth-child(1),
        & > div:nth-child(2),
        & > div:nth-child(3) {
          padding: 0 16px;
          margin: 0 auto;
        }
      }
    }
  }
  &.creator {
    .title__content_search {
      span {
        @include mq('sm') {
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }
  &-search {
    .title__content_search {
      color: #282727;
      font-family: 'Noto Sans JP';
      font-style: normal;
      letter-spacing: 0.002em;
      font-weight: 400;
      font-size: 14px;
      // height: 24px;
      line-height: 24px;
      margin: 0 0 4px 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
    .search__wrapper {
      column-gap: 8px;
    }
    .search-container {
      .search-icon {
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
      }
    }
    .w-80 {
      width: calc(100% - 58px);
    }
  }
  .content-tab {
    color: #787880;
    transition: 0.3s;
    // width: calc(60% - 100px);
    // &.active {
    //   color: #DC143C;
    //   transition: 0.3s;
    //   border-bottom: 2px solid #DC143C !important;
    // }
  }
  .change-view-btn {
    border: 1px solid #b1b6bb;
    padding: 6px 12px;
    background: #ffffff;
    border-radius: 6px;
    color: #282727;
    min-height: 32px;
  }
}

.over-flow-x {
  overflow-y: hidden;
}

.row-fan--creator {
  color: #b1b6bb;
  & > div {
    flex-basis: 100%;
  }
  & .change-view-btn {
    color: #282727;
    width: 112px !important;
    padding: 0px;
  }
}

@include mq('xs') {
  .row.row-fan--creator {
    width: 342px;
    & .content-tab {
      width: 120px;
      height: 50px;
    }
  }
}
.tabs-creator-c020 {
  @media screen and (min-width) {
    width: calc(67.5% - 210px);
    min-width: 285px;
  }
}
.btnf-viewmb{
  color: #fff;
  background: none;
  font-weight: 500;
  border: 1px solid #fff;
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 0;
  height: 30px;
}