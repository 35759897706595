@import 'src/style/variables';
@import 'src/style/mixin';

.react-select-custom {
  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-right {
    position: relative;
  }
}
