@import 'src/style/variables';
@import 'src/style/mixin';

.contracts-container {
  &_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: 400px;
    margin-top: 100px;
  }

  &_empty-text {
    text-align: center;
    color: #b1b6bb;
  }

  .contracts-list {
    padding-top: 30px;

    .contract-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .contract-label {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        .checkmark {
          width: 20px;
          height: 20px;
        }
        input:checked + .checkmark:after {
          content: '';
          position: absolute;
          border-left: 2.5px solid $color-01;
          border-bottom: 2.5px solid $color-01;
          border-radius: 0.5px;
          height: 7px;
          width: 12px;
          top: 43%;
          left: 51.5%;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
      .name {
        font-weight: 700;
        line-height: 24px;
        color: #1A1A1A;
      }
    }

    .btn-actions {
      .btn-cancel {
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        color: #1a1a1a;
        margin-left: 12px;
        &:hover {
          background: #f1f1f1;
        }
      }

      .btn-edit {
        background-color: #DC143C;
        color: #ffffff;
        border: 1px solid #DC143C;
        border-radius: 6px; 
        &:hover {
          background-color: $color-27;
        }
      }
    }
  }

  .note-description {
    font-size: 14px;
    font-weight: 400;
    color: #DC143C;
    text-align: center;
  }

  .contracts-add-new {
    text-align: center !important;
    display: flex;
    justify-content: center;
    &.flex-col {
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }

    .contract-description {
      font-size: 14px;
      font-weight: 400;
      color: #666565;
    }
    button {
      border: 1px solid $color-26;
      border-radius: 6px;
      color: $color-26;
      font-size: 12px;
      font-weight: 700;
      padding: 6px 12px;
      display: flex;
      align-items: center;
      &:hover {
        background-color: $color-26;
        color: $color-01;
      }
    }
  }  
}