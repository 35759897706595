@import 'src/style/variables';
@import 'src/style/mixin';

.post-content-sale-view {
  .btn-open-trailer {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1480ff;
    max-width: 500px;
    margin-top: 0;
    cursor: pointer;
  }
  .wrap-status {
    padding-left: 16px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .wrapper-approve-post {
      display: flex;
      align-items: center;
      .text {
        margin-bottom: -10px;
        font-weight: 700;
        line-height: 22px;
        font-size: 12px;
        color: #dc143c;
      }
    }
  }
  .block-notification-request {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 9px 16px;
    border-radius: 2px;
    border: 1px solid #ffccc7;
    background: #fff1f0;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.028px;
    white-space: pre-wrap;
    width: 395px;
    .information-request {
      width: 331px;
    }
    @include mq("sm") {
      width: 100%;
    }
    .text-redirect {
      color: #1480ff;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .comment.block {
    display: flex;
    align-items: flex-start;
    .comment-form {
      margin-top: 0;
    }
    .form-input-comment-meta {
      .input-comment {
        background-color: #fff;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #fff !important;
      &.disable {
        background-color: #efefef;
        .input-comment {
          background-color: #efefef;
        }
      }
      &.haveMeta {
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 6px 36px 12px 6px;
        .input-comment {
          border: none !important;
        }
      }
    }
  }

  .show-video-sample {
    margin: 29px 0;
    display: flex;
    justify-content: center;
    background-color: #f2f3f5;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  textarea.overflowHidden {
    overflow-y: hidden;
  }

  .icon-send-rep-comment {
    transform: rotate(0deg) !important;
  }
  .file-zip {
    max-height: 116px;
    width: 100%;
    height: 116px;
    overflow: hidden;
    position: relative;
    background: #f7f8fa;
    border-radius: 8px;
    .close-zip {
      position: absolute;
      top: 16px;
      right: 16px;
      background-color: $color-01;
      color: $color-08;
      border-radius: 50%;
      cursor: pointer;
      z-index: 2;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    .file-zip-anticon {
      color: $color-08;
      position: relative;
      top: unset !important;
      right: unset !important;
      background: transparent;
      height: 43px;
      width: 52px;
      svg {
        height: 43px;
        width: 52px;
      }
    }
    .file-zip-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 11.3px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.is-pointer {
        cursor: pointer;
      }
    }
    .name-file-zip {
      color: #1480ff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80%;
    }
    .text {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .comment {
    .comment-form {
      .form-item {
        textarea {
          background-color: #ffffff;
          &:disabled {
            cursor: default;
            background-color: transparent;
          }
        }
      }
    }
  }

  .comment-wrap {
    .list_comment_detail {
      .list-comment {
        .infinite-scroll-component {
          .comment-item-wrapper {
            .comment-item {
              .form-edit-comment {
                form {
                  .form-item {
                    textarea {
                      background-color: #ffffff;
                      border: 1px solid #e5e6eb;
                    }
                  }
                }
              }

              .content {
                .reply-comment {
                  .comment-item-reply {
                    .form-edit-comment {
                      form {
                        .form-item {
                          textarea {
                            background-color: #ffffff;
                            border: 1px solid #e5e6eb;
                          }
                        }
                      }
                    }
                  }
                }

                .form-reply-comment {
                  .form-item {
                    textarea {
                      background: #ffffff;
                      border: 1px solid #e5e6eb;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .content.block {
    .show-video-sample {
      color: #1480ff;
      margin: 29px 0;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;

      .btn-open-trailer {
        cursor: pointer;
      }
    }
  }
}

.post-sale-success {
  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid $color-26;
    border-radius: 6px;
    color: $color-26;
    padding: 11px 72px;
    width: max-content;
    cursor: pointer;
  }
}

.time-line {
  background-color: $color-01;
  min-height: 100%;
}

.price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .text-price {
    font-weight: 700;
    font-size: 14px;
    color: $color-27;
  }
  .button-buy {
    background-color: $color-27;
    color: white;
    font-weight: 700;
    font-size: 14px;
    padding: 6px 20px;
    border: none;
    border-radius: 6px;
  }
  .popup-content {
    width: 445px !important;
  }
  .popup-price-sale {
    padding: 27px 24px 16px 24px !important;
    .text-title-point {
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
    }
    .text-info-price {
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
    }
    .info_sell {
      margin-top: 12px;
    }
    span.checkmark.radio {
      margin-top: 5px;
    }
    .hyphen-price {
      position: absolute;
      bottom: 64px;
      left: 0;
      width: 100%;
      height: 1px;
      box-shadow: inset 0px 1px 0px #f0f0f0;
    }
    .fansite-form-input__controls.btn-cancel.btn-cancel-price {
      button {
        font-weight: 400;
        font-size: 14px;
        color: #1a1a1a;
        padding: 4px 24px;
        border: 1px solid #e5e6eb !important;
        border-radius: 6px;
        @include mq('md') {
          width: 120px;
        }
      }
    }
    .fansite-form-input__controls.button-submit.btn-submit-price {
      button {
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        border-radius: 6px;
        padding: 4px 31px !important;
      }
    }
  }
}

.list-img-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  &-item {
    cursor: pointer;
    position: relative;
    width: calc(20% - 10px);
    aspect-ratio: 4/3;
    @include mq('sm') {
      width: calc(50% - 8px);
      aspect-ratio: 16/9;
    }
  }
  @include mq('sm') {
    margin-top: 16px;
  }
}

.video-detail {
  img {
    width: 100%;
    height: 100%;
  }
}

.package {
  margin-top: 16px;
}

.package-row {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  &-item {
    width: calc(50% - 8px);
    position: relative;
    aspect-ratio: 16/9;
    @supports not (aspect-ratio: 16 / 9) {
      min-height: 160px;
    }
  }
}

.text-title {
  font-weight: 700;
  font-size: 14px;
  color: $color-03;
}

.text-content {
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
  color: $color-17;
}

.my-page {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 180px) !important;
}

.popup-edit-comment {
  position: absolute;
  background: $color-01;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 229px;
  height: unset !important;
  display: flex;
  flex-direction: column;
  top: 2;
  //top: calc(100% + 2px);
  right: 0;
  border-radius: 6px;
  overflow: hidden;
  z-index: 15;
  .item {
    display: flex;
    align-items: center;
    padding-left: 18px;
    cursor: pointer;
    height: 38px;
    z-index: 10;
    color: $color-03;
    .anticon {
      margin-right: 8px;
    }
    &:hover {
      background-color: $color-07;
    }
    &:first-child {
      margin-top: 6px;
      position: relative;
    }
    &:last-child {
      margin-bottom: 6px;
    }
    .text {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.popup-edit-comment.popup-menu-post-sale {
  width: 246px;
}

.empty-box {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.disabled {
  color: rgb(177, 182, 187);
}

.hidden-block {
  display: none;
}

.custom-content {
  left: 0px !important;
  width: calc(100% - 60px) !important;
}

.comment-wrap {
  margin-top: 10px;
  // height: calc(100% - 200px);
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .btn-view-all {
    background-color: transparent;
    border: unset;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666565;
    margin-bottom: 10px;
  }
  .like {
    gap: 4px;
    span {
      padding-top: 2px;
    }
  }

  .list_comment_detail {
    .list-comment {
      .infinite-scroll-component__outerdiv {
        .infinite-scroll-component {
          .comment-item-wrapper {
            .comment-item {
              .content {
                .form-reply-comment {
                  form {
                    .form-item {
                      textarea {
                        background: #ffffff;
                        border: 1px solid #e5e6eb;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.list-comment .comment-item .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  overflow: visible;
}

@include mq('sm') {
  .mobile-timeline {
    padding: 0px 16px;
  }
}

@include mq('md') {
  .mobile-timeline {
    padding: 0px 16px;
  }

  .popup-admin-handle-post {
    .content {
      margin-top: 0 !important;
    }
  }
}

@include mq('mw768') {
  .mobile-timeline {
    max-width: 100%;
  }
}

.disabled-action-post {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.sale-content.show-video-sample {
  height: 54px;
  background-color: $color-33;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 !important;
  position: relative;
}
