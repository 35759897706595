@import 'src/style/variables';
@import 'src/style/mixin';
.back-to-page {
  min-height: 60px;
  height: 60px;
  display: flex;
  position: fixed;
  align-items: center;
  background-color: $color-01;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 10;
  justify-content: center;

  @media (max-width: 991px) {
    min-height: 40px !important;
    height: 40px !important;
    box-shadow: none;
    border-bottom: 1px solid #e5e6eb;
    width: 100%;
    padding: 0px 16px 0 16px !important;
  }

  .title {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font: {
      size: 20px;
      weight: 500;
    }
    width: 100%;
    line-height: 28px;
    color: #282727;
    &.text-left {
      text-align: left !important;
      margin-left: 23px;
      @include mq('md') {
        margin-left: unset;
        text-align: center !important;
      }
    }
  }

  .white_space-normal {
    white-space: normal;
  }

  .anticon {
    margin-left: 23.75px;
    cursor: pointer;
    color: $color-03;
    display: flex;
    align-items: center;
  }

  @include mq('md') {
    height: 60px;
    .title {
      text-align: center;
      margin-left: 0;
    }
    .anticon {
      position: absolute;
      left: 24.5px;
      margin-left: 0;
      color: $color-08;
      svg {
        width: 10px;
        height: 15px;
      }
    }
  }

  &.uiMobile {
    height: 60px;
    justify-content: center;
    .title {
      text-align: center;
      margin-left: 0;
    }
    .anticon {
      position: absolute;
      left: 16px;
      margin-left: 0;
      color: $color-08;
      cursor: pointer;
      z-index: 2;
      svg {
        width: 25px;
        height: 24px;
      }
    }
  }
  &.has-avatar {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;
      .title {
        text-align: center;
        margin-left: 50px;
      }
    }
    img {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      border: 0;
      margin-right: 16px;
    }

    @include mq('md') {
      .left {
        display: flex;
        align-items: center;
        .title {
          margin-left: 16.5px;
        }
      }
      .anticon {
        position: relative;
        left: 0;
        margin-left: 24.5px;
      }
    }
  }
  &.no-margin {
    .title {
      margin-left: initial;
    }
  }
  &.update-profile {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    p,
    svg {
      color: #949393;
    }
  }
}

.title-page-fan {
  .box-content {
    .fixed-title-fan {
      z-index: 99;
      position: fixed;
      width: 100%;
      max-width: 375px;
    }
  }
}
.title-page-creator {
  .box-content {
    max-width: 1380px !important;
    .fixed-title-fan {
      z-index: 99;
      position: fixed;
      width: 100%;
      max-width: 1380px;
    }
  }
}

.page-legal.title-page-creator.title-page-fan {
  .box-content {
    max-width: 1056px !important;
    .fixed-title-fan {
      z-index: 99;
      position: fixed;
      width: 100%;
      max-width: 1056px;
    }
    .layout-content {
      max-width: 1056px !important;
      width: 1056px !important;
      @include mq('l') {
        width: 100% !important;
      }
    }
  }
}
