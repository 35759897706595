@import 'src/style/variables';
@import 'src/style/mixin';

.box-fansite-header.header-list-idol {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  .fansite-header {
    max-width: 1440px;
    box-shadow: none;
  }
  .content-left {
    margin-left: 70px;
    .logo-bird {
      margin-right: 11px;
    }
  }
  .content-right {
    margin-right: 65px;
    display: flex;
    align-items: center;
    @include mq('sm') {
      flex-direction: column;
    }
  }
  .search-lang-wrapper {
    display: flex;
    align-items: center;
    @include mq('sm') {
      width: 100%;
      margin-bottom: 15px;
      .popup-change-lang {
        width: 120px !important;
      }
    }
  }
  .search-wrapper {
    margin-right: 18px;
    display: flex;
    align-items: flex-start;
    .anticon.icon-search {
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        color: $color-03;
      }
    }
  }
  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 0;
    border-radius: 6px;
    & + .btn {
      margin-left: 18px;
    }
    &.btn-fan-login {
      width: 132px;
      background-color: $color-06;
      color: $color-01;
    }
    &.btn-creator-login {
      width: 172px;
      border: 1px solid $color-06;
      color: $color-06;
    }
  }
  .language-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: $color-03;
    position: relative;
    padding-right: 24px;
    margin-right: 24px;
    cursor: pointer;
    position: relative;
    .popup-change-lang {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      padding: 8px 0;
      width: 161px;
      box-shadow: 0px 5px 25px rgba(43, 35, 43, 0.1);
      border-radius: 6px;
      z-index: 13;
      background-color: $color-01;
      .lang-option {
        display: flex;
        align-items: center;
        height: 42px;
        padding-left: 16px;
        position: relative;
        &:hover {
          background-color: #ffdbdb;
        }
        .lang {
          margin-left: 10px;
        }
        .icon-checked{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          color: $color-06;
        }
      }
    }
    .current-lang {
      display: flex;
      align-items: center;
      .lang {
        margin: 0 7px;
      }
    }
    &::after {
      position: absolute;
      top: -10px;
      right: 0;
      content: '';
      width: 1px;
      height: 44px;
      background-color: $color-07;
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: 1023px) {
  .header-fixed {
    height: max-content !important;
    background-color: $color-01;
    z-index: 2000;
  }
  .box-fansite-header.header-list-idol {
    box-shadow: none;
    .fansite-header {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
    .content-left {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
      width: 100%;
    }
    .content-right {
      align-items: center;
      margin-right: 0;
      padding: 16px 0;
      .search-wrapper {
        padding-right: 0;
        margin-right: 13px;
        &::after {
          display: none;
        }
      }
      .btn + .btn {
        margin-left: 15px;
      }
    }
  }
}
