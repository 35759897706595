@import 'src/style/variables';
@import 'src/style/mixin';

.meta-tag-viewer {
  width: 100%;

  .meta-tag-container {
    background-color: #fafafa;
    display: flex;
    margin-top: 10px;
    border-radius: 3px;
    overflow: hidden;
    @include mq('sm') {
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #E5E6EB;
    }
  }

  .meta-tag-image {
    min-width: 197px;
    min-height: 111px;
    @include mq('sm') {
      height: 167px;
      width: auto;
    }
  }

  .meta-tag-image img {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
    object-fit: contain;
    border-radius: 3px !important;
  }

  .meta-tag-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% - 197px);
    gap: 4px;
    padding: 4px 4px 4px 12px;
    @include mq('sm') {
      width: 100%;
      flex-direction: column-reverse;
      padding: 8px 8px 12px 8px;
    }
  }

  .meta-tag-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #000;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mq('sm') {
      font-size: 14px;
      font-weight: 400;
      color: #1a1a1a;
    }
  }

  .meta-tag-description {
    margin: 0;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #1a1a1a;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .meta-tag-url {
    text-decoration: none !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #666565 !important;
  }
}
