@import "src/style/variables";
@import "src/style/mixin";

.home-page-content {
  padding: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  
  .mobile-loading {
    @media screen and (max-width: 991px) {
      padding: 16px 0;
    }
    @include mq('sm') {
      padding: 16px !important;
    }
  }
  
  .tab-mobile {
    @include mq("sm") {
      padding: 0 16px;
    }
  }
  @include mq("sm") {
    // padding: 16px;
    padding: 0px !important;
    .home-page-content-search{
      padding: 0 16px;
      margin-top: 15px;
    }
  }

  &.one-column {
    @include mq("sm") {
      padding: 0;
      .title__content_search,
      .search__wrapper,
      .row-fan--creator,
      .GroupIcon {
        padding: 0 16px;
      }
      .item {
        margin-left: 0;
        margin-right: 0;
        & > div:nth-child(1),
        & > div:nth-child(2),
        & > div:nth-child(3) {
          padding: 0 16px;
          margin: 0 auto;
        }
      }
    }

    .GroupIcon {
      & > .d-middle {
        gap: 25px;
        .cls-text {
          margin: 0;
        }
      }
    }
    .mb-2.cls-title {
      -webkit-line-clamp: 1;
    }
  }

  &.fan {
    padding: 16px;
    &.one-column {
      padding: 0;
      .card-item--detail {
        padding: 0 !important;
      }
      .title__content_search,
      .search__wrapper,
      .row-fan--creator,
      .GroupIcon {
        padding: 0 16px;
      }
      .item {
        margin-left: 0;
        margin-right: 0;
        & > div:nth-child(1),
        & > div:nth-child(2),
        & > div:nth-child(3) {
          padding: 0 16px;
          margin: 0 auto;
        }
      }
    }
  }
  &-search {
    .title__content_search {
      color: #282727;
      font-family: "Noto Sans JP";
      font-style: normal;
      letter-spacing: 0.002em;
      font-weight: 400;
      font-size: 14px;
      // height: 24px;
      line-height: 24px;
      margin: 0 0 4px 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .text {
        white-space: nowrap;
        font-weight: 500;
        font-size: 14px;
        min-width: max-content;
      }
    }
    .search__wrapper {
      column-gap: 16px !important;
    }
    .search-container {
      .search-icon {
        left: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        color: #787880;
      }
      .clear-icon {
        position: absolute;
        right: 10px;
        top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .w-80 {
      width: calc(100% - 58px);
    }
  }
  .content-tab {
    display: flex;
    flex: 1 1;
    color: #1a1a1a;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 4px;
    height: 40px;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    // @include mq('md') {
    //   padding: 0 25px;
    // }
    &.active {
      font-weight: 700 !important;
      color: #dc143c !important;
      font-size: 14px !important;
      position: relative;
    }
    &.active::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 18px;
      height: 2px;
      background-color: #dc143c;
    }
  }
  .btn-search {
    border: 1px solid #b1b6bb;
    padding: 6px 12px;
    background: #28c76f;
    border-radius: 6px;
    color: #fff;
    min-height: 32px;
    width: 73px;
    &:hover {
      background: #11B259 !important;
    }
  }

  .list-tab-type-post {
    color: #b1b6bb;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    & > div {
      white-space: nowrap;
      min-width: 114px;
      flex-basis: 100%;
    }
    .content-tab1 {
      color: #787880;
      transition: 0.3s;
      border-radius: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F2F3F5;
      height: 38px !important;
      width: calc(60% - 102px);
      &:first-child {
        margin-left: 4px;
      }
      &:last-child {
        margin-right: 4px;
      }
      &.active {
        background: #DC143C;
        border-radius: 43px;
        height: 38px !important;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        //transition: 0.3s;
        //border-bottom: 1px solid #DC143C !important;
      }
    }
  }
  
  // .new-list-tab-type-post {
  //   white-space: nowrap;
  //   flex-basis: 100%;
  //   display: flex;
  //   align-items: center;
  //   margin: 24px 0;
  //   //border-radius: 43px;
  //   //background: #f2f3f5;
  //   margin-top: 10px;
  //   overflow-x: auto;
  //   & > div {
  //     white-space: nowrap;
  //     // min-width: 114px;
  //     flex-basis: 100%;
  //   }
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  //   .item-post {
  //     //display: flex;
  //     //flex: 1 1;
  //     color: #1a1a1a;
  //     font-weight: 500;
  //     text-align: center;
  //     cursor: pointer;
  //     margin: 4px;
  //     font-size: 14px;
  //     height: 40px;
  //     //align-items: center;
  //     //justify-content: center;
  //     //border-radius: 43px;
  //   }
  // }
  .hash-tag-wrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    .hash-item {
      padding: 7px 10px;
      border: 1px solid #E5E6EB;
      border-radius: 6px;
      margin-top: 5px;
      color: #1A1A1A;
      font-size: 14px;
      cursor: pointer;
      &.selected {
        border: 1px solid #ff0000 !important;
        background-color: #fff;
        color: #ff0000 !important;
      }
    }
  }

  .text-hypelink-profile {
    color: #1480FF;
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;
    svg {
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  .not-active-tag {
    transform: rotate(-90deg);
  }
  
  .active-tag {
    transform: rotate(90deg);
  }

  .tags-label {
    color: #1A1A1A;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .display-media-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    @include mq("sm") {
      padding: 0 16px;
    }
    .toggle {
      display: flex;
      justify-content: space-between;
      // border-top: 1px solid #eeeeee;
      label {
        font-weight: 500;
      }

      .toggle-switch {
        .toggle-switch-switch {
          display: block;
          width: 20px;
          height: 20px;
          margin: 2px;
          background: #fff;
          position: absolute;
          top: 0;
          bottom: 0;
          border: 0 solid #bbb;
          border-radius: 50%;
          transition: all 0.3s ease-in 0s;
        }
      }
    }
    
    .media-wrap {
      display: flex;
      color: #666565;
      font-size: 14px;
      line-height: 20px;
    }

    .form-item-switch .form-label {
      display: flex;
      margin-bottom: 0 !important;
      margin-right: 8px;
    }

    .item-media {
      border-right: 1px solid #000;
      height: 25px;
      padding-right: 10px;
      &:last-child {
        border-right: none;
        padding-left: 10px;
      }

      &.active {
        font-weight: 700;
        color: #000;
      }
    }

    .display-wrap {
      display: flex;
      align-items: center;
      .title {
        color: #666565;
        margin-right: 7px;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 21px;
        height: 12px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }

      .slider {
        border: 1px solid #666565;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &::before {
          border: 1px solid #666565;
          position: absolute;
          content: "";
          height: 6px;
          width: 6px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }

      input:checked + .slider {
        background-color: #ccc;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #ccc;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(8px);
        -ms-transform: translateX(8px);
        transform: translateX(8px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
}

.over-flow-x {
  overflow-y: hidden;
}
