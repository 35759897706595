@import 'src/style/variables';
@import 'src/style/mixin';

.navbar-edit-profile {
  display: flex;
  align-items: center;
  margin: 24px 0;
  border-radius: 43px;
  background: #f2f3f5;
  @include mq('sm') {
    margin-top: -25px;
    margin: 16px 0;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tab-item {
    display: flex;
    flex: 1 1;
    color: #666565;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 4px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 43px;
    width: calc(60% - 100px);
    min-width: 150px;
    transition: 0.3s;
    @include mq('sm') {
      min-width: 112px;
    }
    &.active {
      color: #fff;
      background-color: #dc143c;
      font-size: 14px;
    }
  }
}
