@import 'src/style/variables';
@import 'src/style/mixin';
.creator-live-list-page {
  position: relative;
  .back-to-page {
    @media screen and (max-width: 1023px) {
      display: none;
      &.show-back-to-page {
        display: inline-flex;
        margin-top: 0px !important;
      }
    }
  }
  @include mq('sm') {
    .content-container {
      height: 70vh !important;
      width: 100%;
    }
  }
  // min-height: 100%;
  .box-btn-add-live {
    margin-left: 24px;
    padding-top: 24px;
    @include mq('sm') {
      padding-bottom: 0 !important;
    }
    .btn-create-live {
      border: 1px solid $color-26;
      border-radius: 6px;
      color: $color-26;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 12px 6px 17px;
      display: inline-flex;
      align-items: center;
      span {
        margin-right: 9px;
        font-size: 18px;
        line-height: 20px;
        margin-top: -3px;
      }
    }
  }
  .box-btn-add-live.no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    margin-left: 0;
    margin-top: 0;
    font-size: unset;
    .btn-create-live {
      margin-top: 7px;
    }
    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-14;
    }
  }
  .creator-list-live-stream {
    display: flex; // grid;
    flex-wrap: wrap;
    gap: 12px;
    margin: 24px auto 46px auto;
    overflow: hidden;
    padding: 0 24px;
    .live-stream-item-crt-list {
      position: relative;
      overflow: hidden;
      border-radius: 6px;
      cursor: pointer;
      width: calc(50% - 10px);
      aspect-ratio: 1.304;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      &.full {
        width: 100%;
        aspect-ratio: 1.7778;
      }
      .layer-go-lives {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 55;
        width: 100%;
        height: 100%;
        cursor: default;

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #1a1a1a;
          opacity: 0.5;
          border-radius: 10px;
          z-index: 15;
        }
        .go-live {
          z-index: 25;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .button-go-live {
            cursor: pointer;
            background-color: $color-26;
            color: $color-01;
            border: 1px solid $color-26;
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            padding: 6px 19px;
            opacity: 1;
          }
        }
        .count-user-watch {
          height: 28px;
          background: #ffffff;
          border-radius: 0px 14px 14px 0px;
          position: absolute;
          top: 12px;
          left: 0;
          z-index: 10;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 12px;
          .count {
            font-weight: 500;
            font-size: 12px;
            color: $color-26;
          }
        }
        .episodes-tags {
          position: absolute;
          top: 12px;
          right: 12px;
          z-index: 10;
          cursor: default;
          span {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            background: #dc143c;
            padding: 1px 9px;
          }
          &.crt-dlt {
            span {
              width: 30px;
              height: 30px;
              background: #ffffff;
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .watch-tags {
          position: absolute;
          bottom: 28px;
          right: 15px;
          z-index: 10;
          span {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            background: #dc143c;
            padding: 1px 9px;
          }
        }
      }
      .thumb {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 64px);
        &.full {
          height: 100%;
        }
        width: 100%;
        // background-color: #000;
        overflow: hidden;
        img {
          object-fit: contain;
          z-index: 10;
          position: absolute;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          &.img-placeholder {
            width: 100%;
            left: 0px;
            object-fit: cover;
            transform: unset;
          }
        }
        .count-user-watch {
          height: 28px;
          background: #ffffff;
          border-radius: 0px 14px 14px 0px;
          position: absolute;
          top: 12px;
          left: 0;
          z-index: 10;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px 12px;
          .count {
            font-weight: 500;
            font-size: 12px;
            color: $color-26;
          }
        }
        .episodes-tags {
          position: absolute;
          top: 12px;
          right: 12px;
          z-index: 10;
          span {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            background: #dc143c;
            padding: 1px 9px;
          }
          &.crt-dlt {
            span {
              width: 30px;
              height: 30px;
              background: #ffffff;
              border-radius: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .watch-tags {
          position: absolute;
          bottom: 28px;
          right: 15px;
          z-index: 10;
          span {
            display: block;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            background: #dc143c;
            padding: 1px 9px;
          }
        }
      }

      .content {
        position: relative;
        z-index: 50;
        padding: 10px 14px;
        border-radius: 0 0 10px 10px;
        color: #282727;
        background: #fafafa;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        cursor: default;
        &.full {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 96px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
          background-blend-mode: multiply;
          border-radius: 0px 0px 10px 10px;
          padding: 17px 15px 17px 20px;
        }
        &.first {
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .content-info {
          display: flex;
          flex-direction: row;
          gap: 10px;
          cursor: default;
          width: calc(100% - 130px);
          &.live {
            width: 100%;
          }
          img {
            border-radius: 40px;
          }
          .title {
            color: #282727;
            font-weight: 700;
            font-size: 14px;
            &.full {
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
            }
          }
          .name {
            color: #1a1a1a;
            font-weight: 400;
            font-size: 12px;
            &.full {
              font-weight: 400;
              font-size: 16px;
              color: #e5e6eb;
            }
          }
          &__right {
            width: calc(100% - 60px);
            .name,
            .title {
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          &__left {
            img {
              width: 44px;
              height: 44px;
              cursor: pointer;
            }
          }
        }
        .content-date {
          font-weight: 400;
          font-size: 12px;
          color: #1a1a1a;
          white-space: nowrap;
          padding-bottom: 6px;
          .button-go-live-right {
            cursor: pointer;
            background-color: $color-26;
            color: $color-01;
            border: 1px solid $color-26;
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            padding: 2px 12px;
            opacity: 1;
            height: 24px;
          }
        }

        span {
          display: block;
          // margin-bottom: 4px;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          white-space: nowrap;
          .creator-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        span:nth-child(1) {
          color: #221818;
          font-size: 11px;
        }
        span:nth-child(2) {
          color: #b1b6bb;
        }
        span:nth-child(3) {
          color: #b1b6bb;
        }
        .title {
          max-width: 323px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .bg-blur {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 123px;
        background: linear-gradient(180deg, rgba(40, 39, 39, 0) 0%, #2f2121 100%);
      }

      img {
        z-index: 1;
        // max-width: 100%;
        // max-height: 236px;
      }
      .title {
        font-weight: 400;
        font-size: 11px;
        color: $color-01;
      }
      .description {
        width: 147px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .episodes-tags-live {
        position: absolute;
        right: 12px;
        top: 17px;
        z-index: 10;
        span {
          display: block;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          color: #fff;
          border-radius: 4px;
          background: #dc143c;
          padding: 4px 11px;
        }
      }

      .count-user-watch {
        height: 28px;
        background: #ffffff;
        border-radius: 0px 14px 14px 0px;
        position: absolute;
        top: 12px;
        left: 0;
        z-index: 10;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 12px;
        .count {
          font-weight: 500;
          font-size: 12px;
          color: $color-26;
        }
      }

      .episodes-tags {
        left: unset;
        right: 16px;
        top: 16px;
        cursor: pointer;
        z-index: 10;
        span {
          background: unset;
          padding: unset;
        }
      }
    }
  }
}
@include mq('mw768') {
  .creator-list-live-stream {
    margin-top: 16px;
    gap: 12px;
    padding: 0 16px;
    width: 100%;
    .live-stream-item-crt-list {
      width: 100% !important;
      max-width: unset;
    }
  }
}

.crt-dlt span {
  color: #b1b6bb !important;
}

.during-delivery-livestreams-layout {
  .back-to-page {
    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }
  .shimmer-loading {
    padding: 16px 24px;
  }
}
