.bg-aa {
  background-image: url('../../../images/img.png');
  .bg-img {
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
  }
}
.number-img {
  position: absolute;
  z-index: 16;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  color: #ffffff;
}

.set-w-h-img {
  width: 100%;
  height: 319px;
  object-fit: contain;
  position: absolute;
  z-index: 10;
  background: transparent !important;
}

.div-blue-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.4;
  z-index: 16;
}

.button-play-video {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.show-play-icon {
  *::-webkit-media-controls-panel {
    display: none !important;

    -webkit-appearance: none;
  }
}
