.pick-month {
  &-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #282727;
  }
  &-des {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  &-input {
    padding: 4px 12px;
    position: relative;
    max-width: 300px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #b1b6bb;
    border-radius: 6px;
    display: flex;
    align-items: center;

    &__value {
      width: 130px;
      min-width: 130px;
      display: flex;
      align-items: center;
      position: relative;
      &.end {
        width: 100%;
      }
      span {
        color: #b1b6bb;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
  .icon {
    color: #b1b6bb;
    position: absolute;
    right: 2px;
    cursor: pointer;
    margin-right: 12px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
  }

  .react-calendar.react-calendar-custom {
    border-radius: 5px;
    border: none;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.14);
    left: 4px;
    margin-top: 7px;

    &::after {
      content: '';
      position: absolute;
      top: -14px;
      left: 20px;
      border-top: 8px solid transparent;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #ffffff;
      z-index: 99;
    }

    &.top-calendar {
      margin-bottom: 7px;

      &::after {
        display: none;
      }
      &::before {
        content: '';
        position: absolute;
        bottom: -14px;
        left: 20px;
        border-top: 8px solid #ffffff;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid transparent;
        z-index: 99;
      }
    }

    .react-calendar__navigation {
      border-bottom: 2px solid #eee;
    }

    .react-calendar__tile {
      border-radius: unset;
      width: 100%;
      height: 70px;
      color: #1a1a1a;

      &:hover {
        border: none;
      }

      &:disabled {
        background: transparent;
        color: #bebebe !important;
      }

      &:-webkit-disabled {
        background: transparent;
        color: #bebebe !important;
      }

      &:-moz-disabled {
        background: transparent;
        color: #bebebe !important;
      }
    }

    .react-calendar__tile--active.react-calendar__year-view__months {
      border-radius: 2px;
    }

    .react-calendar__year-view__months {
      grid-template-columns: repeat(3, 1fr);

      .react-calendar__year-view__months__month {
        margin: auto;
      }
    }
  }
}
