.button-readmore {
  color: #604de7;
  display: inline;
  white-space: nowrap;
  cursor: pointer;
}

.content-current {
  white-space: pre-line;
  word-break: break-word;
}

.content-normal {
  white-space: normal;
  word-break: break-word;
}
