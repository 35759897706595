@import 'src/style/variables';
@import 'src/style/mixin';

.detail-post-normal-header {
  min-height: calc((100vh - 60px) - 70px);
}
.time-line {
  background-color: $color-01;
  min-height: 100%;
}

*:focus {
  outline: none !important;
}

.detail-post-popup {
  .in_my_page {
    width: calc(100% - 32px) !important;
    margin: 0 16px !important;
    @include mq('md') {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

.my-page {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 180px) !important;
}

.detail-post-custom {
  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 17;
    width: calc(100% - 32px) !important;
    margin: 0 16px !important;
    @include mq('md') {
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

.block-repost {
  padding: 16px;
  border: 1px solid #e5e6eb;
  margin-top: 16px;
  margin-bottom: -16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  &:hover {
    background-color: #f2f3f5 !important;
  }
}

.block-repost-no-img {
  padding: 16px;
  border: 1px solid #e5e6eb;
  //margin-top: 16px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  //border-radius: 8px;
  &:hover {
    background-color: #f2f3f5 !important;
  }
}

.my-page-item {
  .interactive {
    .left {
      .item {
        &.disabled {
          .anticon.repost-icon {
            opacity: 0.5;
          }
        }
        .anticon.repost-icon {
          cursor: default;
        }
      }
    }
    .icon-hide-comment-post-normal {
      margin-right: 32px;
      cursor: pointer;
    }
  }
}

.sroll-list {
  padding: 4px 16px 0 16px;

  &.no-list {
    padding: 0 16px;
  }

  .list_comment_detail {
    .list-comment {
      .infinite-scroll-component {
        .comment-item-wrapper {
          .comment-item {
            .form-edit-comment {
              form {
                .form-item {
                  textarea {
                    background-color: #ffffff;
                    border: 1px solid #e5e6eb;
                  }
                }
              }
            }
            .content {
              .like-reply {
                .like {
                  gap: 5px;
                }
              }

              .reply-comment {
                .comment-item-reply {
                  .form-edit-comment {
                    form {
                      .form-item {
                        textarea {
                          margin-top: 0;
                          background-color: #ffffff;
                          border: 1px solid #e5e6eb;
                        }
                      }
                    }
                  }
                }
              }

              .form-reply-comment {
                .form-item {
                  textarea {
                    margin-top: 0;
                    background: #ffffff;
                    border: 1px solid #e5e6eb;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.div-list-cmt {
  padding: 16px;
  .form-input-comment-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff !important;
    &.disable {
      background-color: #efefef;
    }
    &.haveMeta {
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      padding: 6px 36px 6px 6px;
      .input-comment {
        border: none !important;
      }
    }
  }
  .comment.block {
    form {
      margin-top: 0;
      .form-item {
        textarea {
          line-height: unset;
        }
        .input-comment {
          border: 1px solid #e5e6eb;
          background-color: #ffffff;

          &:disabled {
            background: #eeeeee;
            border: none;
          }
        }
      }
    }
  }
}

.popup-edit-comment {
  position: absolute;
  background: $color-01;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 189px;
  height: unset !important;
  display: flex;
  flex-direction: column;
  top: 2;
  //top: calc(100% + 2px);
  right: 0;
  border-radius: 6px;
  overflow: hidden;
  z-index: 15;
  .item {
    display: flex;
    align-items: center;
    padding-left: 18px;
    cursor: pointer;
    height: 38px;
    z-index: 10;
    color: $color-03;
    .anticon {
      margin-right: 8px;
    }
    &:hover {
      background-color: $color-07;
    }
    &:first-child {
      margin-top: 13px;
      position: relative;
    }
    &:nth-child(2) {
      margin-bottom: 6px;
    }
    .text {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.empty-box {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.disabled {
  color: rgb(177, 182, 187);
}

.hidden-block {
  display: none;
}

.custom-content {
  left: 0px !important;
  width: calc(100% - 60px) !important;
}

.comment-wrap {
  margin-top: 10px;
  // height: calc(100% - 200px);
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .btn-view-all {
    background-color: transparent;
    border: unset;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666565;
    margin-bottom: 10px;
  }

  .comment {
    .comment-form {
      .form-item {
        textarea {
          cursor: pointer;
          background-color: #ffffff;
          &:disabled {
            cursor: default;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.list-comment .comment-item .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  overflow: visible;
}

@include mq('sm') {
  .mobile-timeline {
    padding: 0px 16px;
  }
}

@include mq('md') {
  .mobile-timeline {
    padding: 0px 16px;
  }

  .popup-admin-handle-post {
    .content {
      margin-top: 0 !important;
    }
  }
}

@include mq('mw768') {
  .mobile-timeline {
    max-width: 100%;
  }
}

.disabled-action-post {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.reject-status {
  color: #DC143C !important;
  font-weight: 700;
  font-family: Noto Sans JP;
  background: #E5E6EB;
  padding: 3px 15px;
  border-radius: 30px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 12px;
}

.reviewing-status {
  color: #1480FF !important ;
  font-weight: 700;
  font-family: Noto Sans JP;
  background: #E5E6EB;
  padding: 3px 15px;
  border-radius: 30px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.40) ;
  font-size: 12px;
}

.only-creator-status {
  color: #FE9923 !important ;
  font-weight: 700;
  font-family: Noto Sans JP;
  background: #E5E6EB;
  padding: 3px 15px;
  border-radius: 30px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.40) ;
  font-size: 12px;
}

.private-status {
  color: #1a1a1a !important;
  font-weight: 700;
  font-family: Noto Sans JP;
  background: #E5E6EB;
  padding: 3px 15px;
  border-radius: 30px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 12px;
}

.reserved-status {
  color: #52C41A !important;
  font-weight: 700;
  font-family: Noto Sans JP;
  background: #E5E6EB;
  padding: 3px 15px;
  border-radius: 30px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.40);
  font-size: 12px;
}
