@import 'src/style/variables';
@import 'src/style/mixin';

.avatar-infor {
  padding: 16px 0px 16px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  //background: $color-01;

  &.faded {
    animation: fadeOut 2s;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  img {
    border-radius: 50%;
    min-height: 44px;
    max-height: 44px;
    min-width: 44px;
    max-width: 44px;
    // background: #000;
    border: none;
    margin-right: 12px;
    object-fit: cover;
  }
  .content-left {
    display: flex;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: column;
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        word-break: break-word;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .content1 {
        margin-top: 2px;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: $color-08;
        @include mq('sm') {
          flex-direction: column;
        }
        .mb-left{
          @include mq('sm') {
            margin-left: 0px !important;
          }
        }
      }
      // .al-center{
      //   align-items: center;
      // }
      .icon-status-post {
        margin-top: 3px;
      }
    }
  }
  &.account-message {
    justify-content: space-between;
    .content-left {
      .name {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }
      .content1 {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: $color-03;
        margin-top: 4px;
      }
    }
    .link {
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 12px;
      background: $color-06;
      border-radius: 6px;
      color: $color-01;
      cursor: pointer;
    }

    .btn-wrapper {
      display: flex;
      flex-direction: column;
      .delete-message {
        background: #ffffff;
        border: 1px solid #b1b6bb;
        border-radius: 6px;
        text-align: center;
        padding: 4px 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        margin-top: 3px;
        cursor: pointer;
      }
    }
  }
  &.avatar-info-detail {
    .content-left {
      align-items: flex-start;
      > div {
        .content1 {
          color: #282727;
        }
      }
    }
  }
}

.avatar-infor.avatar-infor-creator {
  padding: 24px 16px 0 16px;
  justify-content: space-between;

  .content-left {
    align-items: flex-start;
    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
    }
    .content1,
    .content2 {
      margin-top: 4px;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-08;
    }
  }
  .content-left.block {
    align-items: center;
  }
  .content-right.block {
    .time {
      font-size: 12px;
      line-height: 22px;
      color: $color-08;
    }
    .bnt-block {
      background: $color-06;
      border-radius: 6px;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      color: $color-01;
      padding: 6px 12px;
      white-space: nowrap;
    }
  }

  .content-right.edit {
    .account--icon {
      margin-top: -4px;
      margin-right: 6px;
    }

    background: $color-06;
    border: 1px solid $color-06;
    border-radius: 6px;
    color: $color-01;
    max-width: 98px;
    min-width: 98px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    .link {
      color: $color-01;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.avatar-infor-blocklist.avatar-infor.avatar-infor-creator {
  padding: 16px 16px 0 16px;
  cursor: default;

  .bnt-block,
  .content-left {
    cursor: pointer;
  }

  img {
    min-height: 56px;
    max-height: 56px;
    min-width: 56px;
    max-width: 56px;
  }

  .content-right {
    .time {
      color: $color-03;
    }

    .bnt-block {
      background: $color-26;
      &:hover {
        background-color: $color-27;
      }
    }
  }
}
