@import 'src/style/variables';
@import 'src/style/mixin';
.success-register-text {
  color: #282727;
}
.content-header {
  font-weight: 700;
  font-size: 24px;
}
.content-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 32px auto;
}
button.btn.btn-submit.text-white.danger.rounded-6px {
  background-color: $color-26 !important;
  &:disabled {
    background-color: $color-30 !important;
    border: none;
  }
}

// .popup-loading-down {
//   top: -50px !important;
// }
.download-video {
  background: #DC143C;
  border-radius: 4px;
  padding: 6px 12px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @include mq('sm') {
   font-size: 10px;
   padding: 6px 10px;
  }
}
.text-err {
  color: #ff0000 !important;
  font-size: 14px;
  margin: 12px 0px;
}

.success-register-title-text {
  color: #ff0000;
  font-weight: 500;
}
.register-success-fan {
  .btn {
    font-weight: 500;
    padding: 11px 0px !important;
  }

  .btn-outline-danger-v2 {
    background: #dc143c;
    color: #ffffff;
    border-radius: 8px;
    width: 343px;
    font-size: 16px;
    &:hover {
      background: $color-27;
    }
    @include mq('sm') {
      width: 100%;
    }
  }
  .content {
    border-radius: 8px;
  }
}
.danger-yellow-icon svg {
  color: #ff9f43;
}
.popup-select-user.popup-ng-setting {
  .popup-select-user-view.pp-up-ng-setting-views {
    height: 210px;
    overflow: visible;
  }
  .popup-content {
    overflow: visible;
  }
  .search-wrapper {
    position: relative;
    width: 100%;
  }
  .list-search {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px 0;
    max-height: 130px;
    overflow-y: auto;
  }
  .item-search {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: #ffdbdb;
    }
    &.active {
      background: #ffdbdb;
    }
  }
  .icon-checked {
    color: #ff0000;
    display: none;
    &.active {
      display: block;
    }
  }
}

.popup.popup-unlock-post {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(40px);
  position: absolute;
  .popup-content {
    background-color: rgba(#fff, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .lock_icon {
    svg {
      width: 22.67px;
      height: 26.67px;
    }
  }
  .text {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin: 18px 0 28px 0;
  }
  .box-button {
    width: 100%;
  }
  .btn {
    width: 100%;
    background-color: #ff0000;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
}

.popup.popup-show-video.popup-general {
  .title {
    visibility: hidden;
  }
  .popup-content {
    max-width: unset;
    width: unset;
    overflow: hidden;
    @include mq('mw768') {
      min-width: unset;
      max-width: 95%;
    }
    .box-general {
      padding: 0 24px 24px 24px;
      height: auto;
      @include mq('mw768') {
        padding: 0 12px 12px 12px;
      }
      .video-detail-custom {
        margin-top: 0;
        min-width: 500px;
        min-height: 500px;
        max-width: unset;
        @include mq('mw768') {
          min-width: unset;
          min-height: unset;
          max-width: 100%;
        }
        @include mq('sm') {
          max-height: 400px;
        }
        .video-react-poster {
          display: unset !important;
        }
      }
    }
  }
  &.C021 {
    .popup-content {
      max-width: 343px;
      min-width: 343px;
      max-height: unset;
      border-radius: 12px;
      .title {
        height: 46px;
      }
      .box-general {
        padding: 0 16px 16px 16px;
        height: auto;
        .video-detail-custom {
          overflow: hidden;
          margin-top: 0;
          min-width: 100%;
          min-height: 236px;
          max-height: 236px;
          max-width: 100%;
        }
      }
    }
  }
}

.popup.popup-general.popup-quote-post {
  .popup-fan-detail-view1 {
    &.box-general {
      height: auto;
      max-height: 716px;
      @media screen and (max-height: 700px) {
        max-height: 516px;
      }
      @include mq('sm') {
        max-height: 65vh;
      }
    }
  }
  .item-wrap {
    width: calc(50% - 8px) !important;
    @include mq('sm') {
      width: 100% !important;
      margin-bottom: 10px;
    }
    .zindex20 {
      z-index: 20 !important;
    }
    .btn-remove {
      cursor: pointer !important;
      position: absolute;
      width: 40px !important;
      height: 40px !important;
      top: 2px;
      right: 2px;
    }
    .main-thumbnail {
      width: 100%;
      height: 100%;
      max-width: 100%;
      min-height: 185px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      aspect-ratio: 16/9;
      text-align: center;
    }
    input[type='file'] {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      &:disabled {
        cursor: not-allowed;
      }
    }
    .upload-file-btn {
      height: 100%;
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f7f8fa;
      white-space: pre-line;
      justify-content: center;
      text-align: center;
      background: #f7f8fa;
      border: 1px dashed #e5e6eb;
      border-radius: 8px;

      .upload-icon {
        width: 23.04px;
        height: 23.04px;
        margin-bottom: 16px;
        background: transparent;
      }
      .icon {
        color: #787880;
        position: relative;
        margin-bottom: 12px;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        padding: 0 20px;
        color: #666565;
      }
    }
  }
  
  .title {
    font-size: 20px !important;
    font-weight: 500 !important;
    min-height: 60px;
  }

  .close-icon svg {
    width: 24px !important;
    height: 24px !important;
  }

  .block-img {
    padding: 20px 24px 16px 24px;
    @include mq('sm') {
      padding: 16px 16px 0px 16px;
    }
  }

  .performer-kyc {
    .list-performer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .item-performer {
        font-size: 14px;
        font-weight: 700;
        color: #1a1a1a;
      }
      margin-bottom: 16px;
    }
    .btn-performer {
      color: $color-28;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      width: fit-content;
    }
  }

  .popup-content {
    &::-webkit-scrollbar {
      //width: 3px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
    border-radius: 20px;
    // padding: 0 16px 16px 16px;
    width: 100%;
    font-weight: 400;
    max-width: 720px !important;
    max-height: 90vh;
    @include mq('smHeight') {
      max-height: 80vh;
    }
    overflow-y: auto;
  }
  .list-item.scroll-bar-custom-horizontal.list-imgs {
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e6eb;
      border: 7px solid #e5e6eb;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  .box-img-video {
    margin-top: 1px;
    padding: 0 20px;
    // .record-normal {
    //   width: 100% !important;
    // }
    .box-btn-upload {
      margin-bottom: 10px;
      margin-top: -5px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .list-item {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow-x: auto;
      gap: 8px;
      height: auto;
      margin-bottom: 5px;
      .box-item {
        position: relative;
        overflow: hidden;
        // background-color: $color-02;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          aspect-ratio: 4/3;
          object-fit: contain;
          width: 100%;
        }
        .anticon {
          position: absolute;
          top: 16px;
          right: 16px;
          color: $color-01;
          padding: 7px;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2;
          width: 40px;
          height: 40px;
          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
      .box-img1 {
        max-width: 503px;
        max-height: 670px;
        margin: auto;
        @include mq('sm') {
          max-height: 300px;
          max-width: 100%;
          width: 235px;
          height: 276px;
          min-width: 235px;
        }
      }
      .box-img2 {
        width: 235px;
        height: 276px;
        min-width: 235px;
        img {
          width: 235px;
          height: 276px;
          min-width: 235px;
        }
      }
      .video-item {
        width: 100%;
        height: 100%;
      }
      .box-video1 {
        width: 235px;
        height: 276px;
        min-width: 235px;
        margin: auto;
        &.self-video {
          width: 100%;
          aspect-ratio: 16/9;
          height: unset;
        }
        .video-react {
          padding-top: 0 !important;
          height: 100%;
        }
        video {
          width: 100%;
          position: relative;
        }
      }
      &.has-video {
        margin-bottom: 16px;
      }
    }
    .list-item.list-videos {
      .box-item {
        min-width: 500px;
        //max-width: 503px;
        max-width: 641px;
        max-height: 503px;
      }
      .video-react {
        padding-top: 0 !important;
        height: 100%;
      }
      video {
        width: 100%;
        position: relative;
        aspect-ratio: 4/3;
      }
    }
  }
  .content-div-info {
    padding: 0 24px;
    @include mq('sm') {
      padding: 0;
    }
  }
  .block-info-text {
    padding: 16px;
    border: 1px solid #E5E6EB;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    //border-top-left-radius: 8px;
    //border-top-right-radius: 8px;
    border-radius: 8px;
  }
  .block-info-text1 {
    padding: 16px;
    border: 1px solid #E5E6EB;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    &:hover {
      background-color: #f2f3f5 !important;
    }
  }
  .remore-text {
    font-size: 14px;
    color: #1a1a1a;
  }
  .wrap--text {
    margin-top: 5px;
    margin-left: 10px;
    width: calc(100% - 60px);
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .text-are {
    .fansite-form-input__controls {
      margin-bottom: 0px;
    }
    @include mq('sm') {
      padding: 0;
    }
    padding: 0 24px;
    textarea:placeholder-shown {
      border: none;
    }
    textarea {
      width: 100%;
      border: none;
      overflow: auto;
      outline: none;

      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none; /*remove the resize handle on the bottom right*/
    }
  }
  .inputFile {
    position: relative;
    cursor: pointer;
    &.disableImgInput,
    &.disableVideoInput {
      cursor: not-allowed !important;
      pointer-events: none;
      background: #fff;
      opacity: 0.5;
      input,
      span {
        cursor: not-allowed !important;
      }
    }
    .btn-upload {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      gap: 4px;
      padding: 14px 24px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      height: 44px;
      min-width: 156px;
      cursor: pointer;
      span {
        margin-left: 10px;
      }
      &:hover {
        background: #f1f1f1;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
  .block-border {
    border: 1px solid #e5e6eb;
  }
}

.popup.popup-general.phone {
  .popup-content {
    .gender .anticon, .birthday .anticon {
      right: 5px !important;
    }
    &::-webkit-scrollbar {
      //width: 3px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
    border-radius: 20px;
    // padding: 0 16px 16px 16px;
    width: 100%;
    font-weight: 400;
    max-width: 524px !important;
    max-height: 90vh;
    @include mq('smHeight') {
      max-height: 80vh;
    }
    overflow: hidden;
  }
}
.popup.popup-general.popup-recording-detail {
  background: rgba(40, 39, 39, 0.6);
  .box-general {
    //height: 100%;
    //max-height: 80%;
    overflow-y: auto;
    padding: 0 16px 16px 16px;
    @include mq('sm') {
      height: 415px;
    }
    .toggle-switch {
      .toggle-switch-switch {
        display: block;
        width: 20px;
        height: 20px;
        margin: 2px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        border: 0 solid #bbb;
        border-radius: 50%;
        transition: all 0.3s ease-in 0s;
      }
    }
  }
  .popup-content {
    border-radius: 12px;
    // padding: 0 16px 16px 16px;
    width: 100%;
    max-width: 757px !important;
    max-height: 604px;
    //max-height: 80%;
    overflow-y: auto;
  }
  .title {
    //border-bottom: none;
    //height: 0;
    font-weight: bold;
  }

  .record-detail {
    width: 100%;
    height: 100%;
    //height: 386px;

    @include mq('smHeight') {
      height: 180px !important;
    }
    .react-player__preview {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cls-title {
    font-size: 14px;
  }

  .cls-text {
    font-size: 12px;
  }

  .view-number {
    color: #dc143c;
  }

  .profile-icon {
    color: #dc143c;
  }

  .wrap--text {
    color: #666565;
    font-size: 12px;
  }

  .account {
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      border-radius: 50%;
      max-width: 60px;
      max-height: 60px;
      min-height: 60px;
      min-width: 60px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .content-left {
      display: flex;
      padding-right: 25px;
      flex-direction: row;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
        }
      }
    }
  }
  .description {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
  }
  .read-more {
    color: $color-28;
  }
  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .button {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      display: flex;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      align-items: center;
      padding: 4px 16px;
      gap: 8px;
    }
    @media screen and (max-width: 390px) {
      .btn-sub {
        padding: 6px 16px;
      }
      .btn-sale {
        padding: 6px 16px;
      }
      .btn-add-livestream {
        padding: 6px 20px;
      }
      .btn-del {
        padding: 6px 24px;
      }
    }
    // @include mq('sm') {
    //   .btn-sub {
    //     padding: 10px 4px;
    //   }
    //   .btn-sale {
    //     padding: 10px 4px;
    //   }
    //   .btn-del {
    //     padding: 10px;
    //   }
    // }
    .btn-follow {
      min-width: 89px;
    }
    .btn-block {
      min-width: 99px;
    }
    .btn-open-mess {
      min-width: 131px;
      &.disabled {
        background-color: $color-07;
        border: none;
        cursor: auto;
      }
    }
  }
}

.popup.popup-general.popup-fan-detail {
  .box-general {
    height: auto;
    max-height: 80%;
    overflow-y: auto;
    padding-right: 16px;
  }
  .popup-content {
    border-radius: 12px;
    padding: 0 0 16px 16px;
    width: 343px;
    max-height: 80%;
    overflow-y: auto;
  }
  .title {
    border-bottom: none;
    height: 0;
  }
  .account {
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      border-radius: 50%;
      max-width: 60px;
      max-height: 60px;
      min-height: 60px;
      min-width: 60px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .content-left {
      display: flex;
      padding-right: 25px;
      flex-direction: row;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          max-width: 180px;
          margin: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          display: block;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
        }
      }
    }
  }
  .description {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
  }
  .read-more {
    color: $color-28;
  }
  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .button {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      border: 1px solid $color-08;
      border-radius: 6px;
      padding: 5px;
      text-align: center;
      cursor: pointer;

      &.active {
        color: $color-01;
        background: $color-06;
        border: $color-06;
        padding: 5px 16px;
      }
    }
    .btn-follow {
      min-width: 89px;
    }
    .btn-block {
      min-width: 99px;
    }
    .btn-open-mess {
      min-width: 131px;
      &.disabled {
        background-color: $color-07;
        border: none;
        cursor: auto;
      }
    }
  }
}
.popup.popup-user-selll-post.popup-general {
  .popup-content {
    border-radius: 12px;
    width: 500px;
    max-width: 500px;
    // min-height: 288px;
    @include mq('sm') {
      width: 90%;
      max-width: 90%;
      .button-submit {
        width: auto !important;
      }
    }
  }
  .box-general {
    height: auto;
  }
  .close-icon,
  .title {
    display: none;
    visibility: hidden;
  }
  form {
    padding: 24px;
    @include mq('sm') {
      padding: 16px;
    }
    .box-radio-checkbox {
      display: flex;
      //margin-bottom: 16px;
      .item {
        & + .item {
          margin-left: 20px;
        }
        width: max-content;
        .text {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $color-03;
        }
        input {
          opacity: 0;
          position: absolute;
          width: 16.67px;
          height: 16.67px;
          left: 0;
          margin: 0;
          top: 3px;
        }

        .checkmark.radio {
          border-radius: 50%;
        }
      }
    }
    .info_sell {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 25px;
    }
    .info_sell_wrapper {
      display: flex;
      justify-content: space-between;
    }
    .btn-sell-point {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      border-radius: 6px;
      background-color: #1480FF;
      color: #FFFFFF;
      cursor: pointer;
      font-size: 12px;
    }

    .button,
    button {
      cursor: pointer;
      height: 32px;
      padding: 0px 12px;
    }

    .btn-submit-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      // padding-bottom: 16px;
      .fansite-form-input__controls {
        margin-bottom: 0;
      }
      button {
        border-radius: 6px;
        border: none;
        outline: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        &:focus {
          outline: none;
        }
      }
      .btn-cancel button {
        background: $color-01;
        border: 1px solid $color-14;
        &:hover {
          background: #f1f1f1;
        }
      }
      .button-submit {
        &.disabled {
          button {
            cursor: auto;
            opacity: 0.5;
          }
        }
        button {
          background-color: $color-26;
          color: $color-01;
        }
      }
    }
  }
}

.popup.confirm-fan {
  &.in_my_page {
    position: absolute;
  }
  .content {
    margin: 0;
  }

  .box-button {
    .ok {
      height: 32px;
    }
  }
}

.content {
  .mg-icon {
    margin-right: 10px !important;
  }
}

.popup-block {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background: rgba(255, 255, 255, 0.5);
  //backdrop-filter: blur(5px);
  z-index: 9990;
  overflow: hidden;
  &.creator {
    background: rgba(26, 26, 26, 0.6) !important;
    //backdrop-filter: blur(40px);
    position: absolute;
  }
  &_content {
    padding: 32px 32px 22px 32px;
    z-index: 9999;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 416px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #ffffff;
    //opacity: 0.8;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #1a1a1a;
    display: flex;
    flex-direction: column;
    @include mq('sm') {
      width: 370px;
    }
    &.creator {
      position: absolute;
      .btn-logout {
        //display: none;
        @include mq('md') {
          display: inline-block;
        }
      }
    }
    button {
      margin-top: 20px;
      background: $color-26;
      padding: 6px 16px;
      color: $color-01;
      font-weight: 400;
      border-radius: 6px;
      &:hover,
      &:focus {
        color: $color-01;
        background: $color-27;
      }
    }
  }
}
.list-search-distributions {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  top: 100%;
  left: 0;
  width: 300px;
  padding: 10px 0;
  max-height: 137px;
  min-height: 55px;
  overflow-y: auto;
  z-index: 11;
  margin-top: -50px;
  margin-left: -2px;

  .item-search {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: #ffdbdb;
    }
    &.active {
      background: #ffdbdb;
    }
  }
  .icon-checked {
    color: #ff0000;
    display: none;
    &.active {
      display: block;
    }
  }
}

.popup.choice-option-twitter .popup-content {
  padding: 30px;
  .text {
    font-size: 14px;
    margin-bottom: 30px;
  }
}

.popup.popup-general.popup-list-package-c092 {
  .popup-content {
    padding: 40px 28px;
    overflow-y: hidden;
    max-width: 400px;
    min-height: 300px;
  }
  .title {
    display: none;
  }
  // .close-icon {
  //   display: none;
  // }
  .list-package-wrapper {
    overflow-y: auto;
    max-height: 500px;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .subscription-empty {
    padding-top: 50px;
  }
  .box-general {
    height: auto;
  }
  .btn-creator-package {
    border: 1px solid $color-06;
    color: $color-06;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 1023px) {
    .popup-content {
      padding: 40px 28px;
      overflow-y: hidden;
      max-width: 400px;
      min-height: 300px;
      max-height: 600px;
    }
  }
}

.popup_creator-effect {
  .crt-effect_title {
    border-bottom: 1px solid #eeeeee;
    justify-content: center;
  }
  .box-btn {
    margin: 0 !important;
    padding: 16px !important;
  }
}

.popup.popup-general.popup-choice-type-post {
  background-color: rgba($color-03, 0.6);

  .popup-content {
    border-radius: 12px;
    background: transparent;
    overflow: hidden;
  }
  .box-general {
    padding: 0;
    display: flex;
    align-items: center;
    height: auto;
    .list-choice {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0;
      height: 100%;
      .btn-navigate {
        border: none;
        width: 100%;
        height: 100%;
        margin-left: 0;
        border-radius: 0;
        font-weight: 700;
        font-size: 22px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 111px;
        background: #fff;
        color: $color-06;
        @include mq('sm') {
          height: 80px;
        }
        & + .btn-navigate {
          border-top: 1.5px solid #eeeeee !important;
        }
      }
    }
  }
}

.popup.popup-general.popup-choice-img-highlight {
  .popup-content {
    .title {
      height: 48px;
    }
    .box-general {
      padding: 13px 16px 24px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 32px;
      height: auto;
      .content {
        min-width: 240px;
        max-width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed #b1b6bb;
        border-radius: 6px;
        padding: 47px 14px 40px 14px;
        gap: 16px;
        .note {
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.002em;
          color: #b1b6bb;
        }
      }
      .img-highlight {
        min-width: 240px;
        min-height: 180px;
        max-width: 240px;
        max-height: 180px;
        position: relative;
        // background-color: $color-02;
        .close-img {
          position: absolute;
          top: 12px;
          right: 12px;
          background-color: $color-06;
          color: $color-01;
          padding: 5px;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2;
          svg {
            width: 13px;
            height: 13px;
          }
        }
        img {
          object-fit: contain;
          aspect-ratio: 4/3;
          width: 100%;
        }
      }
      .btn.button-submit {
        background-color: #ff0000;
        border-radius: 6px;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        width: 100%;
        color: $color-01;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        padding: 11px 0;
        border: none;
        &.disabled {
          background-color: $color-14;
        }
      }
    }
  }
}

.block-page {
  &.popup {
    background: rgba(40, 39, 39, 0.6);

    .popup-content {
      min-width: unset;
    }
  }

  &.popup.popup-general.popup-fan-detail {
    .box-general {
      padding-right: 0px;
    }

    .popup-content {
      margin: 0;
      padding: 0;
      width: 250px;
      min-height: 290px;
      display: flex;
      justify-content: flex-start;
    }

    .account {
      margin-bottom: 0px;
      margin-top: 0px;
      padding-bottom: 16px;
      padding-top: 25px;
      flex-direction: column;
    }

    .description.display-linebreak {
      display: none;
    }

    .btn-wrapper {
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      .button {
        border: 1px solid #bebebe;
        width: 70%;
        clip-path: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          color: #fdfefe;
          background: $color-26;
          border: $color-26;
        }
      }
    }

    img {
      max-width: unset;
      max-height: unset;
      min-height: unset;
      min-width: unset;
      width: 70px;
      height: 70px;
      margin-right: 0;
    }

    .content-left {
      display: flex;
      padding-right: 0;
      flex-direction: column;
      align-items: center;
    }
  }
}

.sell-package-page.popup-content {
  &.popup {
    background: rgba(40, 39, 39, 0.6);

    .popup-content {
      min-width: 383px;
      padding: 32px 32px 24px 34px;

      .content {
        .danger-icon {
          svg {
            min-width: 22px;
            max-width: 22px;
            min-height: 22px;
            max-height: 22px;
          }
        }
      }
    }
  }

  .ok.btn {
    width: 92px;
  }

  .btn.cancel {
    min-width: 119px;
  }
}
.show-sub-popup {
  .popup.confirm-fan {
    .popup-content {
      padding: 2px;
      min-width: 90%;
      min-height: 96%;
      height: 96%;
      width: 90%;
      margin: 2px;

      .content {
        .text {
          font-size: 12px;
        }
      }

      .content-text {
        line-height: unset;
        font-weight: 400;
        font-size: 12px;
        margin: 10px 0 10px 10px;
        width: 100%;
      }

      .box-button {
        margin-bottom: 4px;
        margin-right: 4px;

        .ok.btn {
          padding: 6px;
          font-size: 12px;
        }

        .btn.cancel {
          font-size: 12px;
          padding: 6px;
        }
      }
    }
  }
}

.popup-confirm {
  &__with-draw {
    .popup-content {
      padding: 32px 32px 24px 35px !important;
      width: 379px;
      min-height: 136px !important;

      .box-button {
        .confirm {
          height: 32px;
          display: flex;
          align-items: center;
          font-weight: 500;
          padding: 0 31.5px;
          background-color: $color-26;
          color: $color-01;
        }
        .cancel {
          height: 32px;
          font-weight: 400;
          display: flex;
          align-items: center;
          padding: 0 24px;
        }
      }
    }
  }
}
