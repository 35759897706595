@import 'src/style/variables';
@import 'src/style/mixin';
.table-container {
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 16px;
  margin-top: 16px;
  &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
    height: 1px;
    background: $color-08;
    border-radius: 10px;
    border-top: 1px white;
    border-bottom: 1px white;
  }
  &::-webkit-scrollbar {
    height: 6px;
  }
  table {
    min-width: 100%;
  }

  thead {
    background: #eeeeee;
    th {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #282727;
    }
  }
  tr {
    border-color: #b1b6bb;
  }

  td,
  th {
    padding-left: 7.5px;
    padding-right: 7.5px;
    white-space: nowrap;
    min-width: 53px;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  tbody {
    th {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #282727;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 350px;
  }
  table tr th,
  table tr td {
    border-right: 0;
    border-bottom: 0.5px solid #b1b6bb;
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 0.5px solid #b1b6bb;
  }
  table tr th:first-child,
  table tr td:first-child {
    border-left: 0.5px solid #b1b6bb;
  }

  table tr th:last-child,
  table tr td:last-child {
    border-right: 0.5px solid #b1b6bb;
  }

  table thead tr th {
    background: #eee;
    text-align: left;
    border-top: solid 0.5px #b1b6bb;
  }

  thead tr:first-child th:first-child {
    border-top-left-radius: 6px;
  }

  thead tr:first-child th:last-child {
    border-top-right-radius: 6px;
  }

  tbody tr:last-child th:first-child {
    border-bottom-left-radius: 6px;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #b1b6bb;
  }
}

.table-container.general {
  thead {
    th {
      text-align: center;
    }
  }
  tbody th {
    min-width: 100px;
  }
  td {
    text-align: center;
  }
}

.table-container.able-click {
  tbody {
    td {
      cursor: pointer;
      color: $color-04;
      &.active {
        color: $color-06;
      }
    }
    tr.disabled {
      td {
        cursor: auto;
        user-select: none;
        pointer-events: none;
        color: $color-25;
      }
    }
  }
}

.table-container.general.subscription {
  width: max-content;
  @include mq('sm') {
    width: 100%;
  }
  th,
  td {
    min-width: 58px;
  }
}

.table-container.table-af004 {
  &.custom {
    thead th {
      color: $color-03;
    }
  }
  thead th {
    color: $color-04;
    cursor: pointer;
    &.active {
      color: $color-06;
    }
  }
  tbody td {
    color: #868686;
  }
}
