@import 'src/style/variables';
@import 'src/style/mixin';

.subscriber-page-creator {
  height: 100%;
  padding: 0px 0 40px 0;
  @include mq('sm') {
    padding-bottom: 0;
    .no-data {
      position: fixed !important;
    }
  }

  .shimmer-loading {
    padding: 0 24px;
    @include mq('sm') {
      padding: 0 16px;
    }
  }
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;

    img {
      max-width: 95px;
      max-height: 92px;
      background: transparent;
    }
  }
  .account-subscriber {
    padding: 8px 0 8px 24px;
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      min-height: 60px;
      max-height: 60px;
      min-width: 60px;
      max-width: 60px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .content-left {
      cursor: pointer;
      display: flex;
      padding-right: 25px;

      .avatar {
        max-width: 56px;
        max-height: 56px;
        min-width: 56px;
        min-height: 56px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          word-break: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .list-sub {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #787880;
        }
      }
    }
  }
}

.list-subscriber {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
  .fansite-form {
    padding: 24px !important;
    .fansite-form-input__controls {
      margin: 0 !important;
    }
    @include mq('sm') {
      padding: 16px !important;

      .select-sub > div {
        width: 100%;
      }
    }
  }

  .select {
    .select-sub {
      .anticon {
        pointer-events: none;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .back-to-page {
      display: none;
    }
  }
}
