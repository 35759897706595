@import 'src/style/variables';
@import 'src/style/mixin';

//detail
.fansite-creator-post-detail {
  background-color: transparent;
  //padding: 19px 24px 50px 24px;
  padding: 0;
  .account {
    &__creator {
      padding: 0 0 16px 0;
      width: 100%;
      align-items: flex-start;
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        margin-right: 8px;
      }
      .content1 {
        margin-top: 2px !important;
        color: $color-03 !important;
      }
    }
  }
  .content {
    overflow: hidden;
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &--text {
      font-weight: 500;
      margin-bottom: 16px;
    }

    .video-custom {
      background-color: #fafafa;
      border-radius: 20px;
      overflow: hidden;
      -webkit-transform: translateZ(0);
      object-fit: cover;
      video {
        object-fit: cover;
      }
    }

    .mute-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0px;
    }

    .block-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      padding: 0 28px 24px 16px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      border-end-end-radius: 20px;
      border-end-start-radius: 20px;
      .avatar {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        cursor: pointer;
        img {
          border: none;
          border-radius: 50%;
          max-height: 44px;
          max-width: 44px;
          min-height: 44px;
          min-width: 44px;
          object-fit: cover;
        }
        .name {
          margin-left: 15px;
          font-size: 14px;
          font-weight: bold;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 20px;
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
        box-shadow: inset 0px 0px 0px 1px #c8c8cd;
      }
      .content {
        position: absolute;
        z-index: 5;
        color: rgb(255, 255, 255);
        margin: 0px;
        width: calc(100% - 100px);
        height: 50px;
        bottom: 20px;
        .text {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
      }
    }
    .group-icon {
      position: absolute;
      bottom: 15px;
      right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      gap: 15px;
      .icon-like {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .bg-icon {
        cursor: pointer;
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        .like-icon {
          &.active {
            color: $color-26;
          }
        }
      }
    }
  }
  .interactive {
    margin: 20px 0;
    display: flex;
    align-items: center;
    color: $color-08;
    .left,
    .item {
      display: flex;
      align-items: center;
    }
    .item {
      .number {
        margin-left: 6px;
      }
      & + .item {
        margin-left: 33.67px;
      }
    }
    .like-icon,
    .chat-icon,
    .share-icon {
      cursor: pointer;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .like-icon {
      &.active {
        color: $color-26;
      }
    }
  }
  .comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      min-width: 44px;
      border: none;
      margin-right: 12px;
    }
    form {
      width: 100%;
      .form-item {
        margin-bottom: 0;
        background: $color-07;
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        .anticon {
          position: absolute;
          bottom: 13.67px;
          right: 10.67px;
          z-index: 3;
          cursor: pointer;
          color: $color-14;
          &.active {
            color: $color-06;
          }
          svg {
            width: 16.67px;
            height: 16.67px;
          }
        }
      }
      textarea {
        background: $color-07;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: none;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .video-detail {
    width: 100%;
    margin-top: 16px;
    max-width: 500px;
    max-height: unset;
  }

  .list-images {
    margin-top: 16px;
    max-width: 500px;
  }

  .slick-arrow {
    background: #dc143c;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &::before {
      display: none;
    }
    svg {
      background: transparent;
    }
    &.slick-next {
      right: 10px;
    }
    &.slick-prev {
      left: 10px;
    }
  }
}

.creator-post-normal-detail.fansite-creator-post-detail {
  .target,
  .date {
    display: flex;
    align-items: flex-start;
  }
  .content--title {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .text1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-08;
    margin-right: 3px;
    white-space: nowrap;
  }
  .avatar-infor.avatar-infor-creator {
    .content-left {
      span {
        color: $color-03 !important;
      }
    }
  }
  @include mq('md') {
    .list-images.custom-creator {
      max-width: 500px;
      .list-images img,
      .list-images video {
        max-height: 500px;
      }
    }
  }
  @include mq('sm') {
    padding: 16px;
    .list-images.custom-creator {
      max-width: 100%;
      .list-images img,
      .list-images video {
        max-height: 500px;
      }
    }
    .img_no_water_mark {
      max-width: 100%;
    }
  }
}

.creator-post-content-detail.fansite-creator-post-detail {
  .content.block {
    position: relative;
  }
  .title-page {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 24px;
  }
  .link-edit-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    .link-edit,
    .btn-change-mode {
      cursor: pointer;
      .account--icon {
        margin-top: -4px;
        margin-right: 6px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      padding: 5px;
      background: $color-06;
      border: 1px solid $color-06;
      border-radius: 6px;
      color: $color-01;
      width: 98px;
      height: 32px;
    }
    .btn-change-mode {
      width: unset;
      min-width: 98px;
      margin-left: 12px;
    }
  }

  .image-thumbnail {
    max-width: 500px;
    width: 500px;
    height: 375px;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      &.img_thumbnail {
        aspect-ratio: 4 / 3;
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    &.bg-zip {
      // background: $color-02;
      @include mq('sm') {
        width: 100%;
        height: auto;
      }
    }
  }

  .video-react-poster {
    background-size: contain !important;
  }

  .video-detail,
  .image-thumbnail,
  .list-img-detail,
  .file_zip,
  .btn-open-trailer {
    margin-left: auto;
    margin-right: auto;
  }

  .btn-open-trailer {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-28;
    max-width: 100%;
    margin-top: 16px;
    cursor: pointer;
  }

  .content--title {
    margin-top: 16px;
    position: relative;
  }
  .package,
  .number-part,
  .date,
  .number_premium_ticket,
  .content-left {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .text {
      overflow: hidden;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }
  .text1 {
    font-weight: 700;
  }
  .content-left {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    .price {
      margin-top: 6px;
      color: $color-06;
    }
  }

  .interactive {
    justify-content: space-between;
  }

  .file_zip {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .anticon {
      margin-right: 10px;
      color: #787880;
    }
    .file_name {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $color-04;
    }
  }

  .list-img-detail {
    margin-top: 16px;
    width: 500px;
    .list-images-item {
      padding-bottom: 375px;
    }
  }

  .list-fan-buy-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-04;
    padding: 16px 0;
    border-top: 1px solid $color-07;
    border-bottom: 1px solid $color-07;
    margin-top: 16px;
    cursor: pointer;
  }
}

@include mq('sm') {
  .creator-post-content-detail.fansite-creator-post-detail {
    padding: 16px;
    .list-img-detail {
      max-width: 100%;
    }
    .video-detail,
    .image-thumbnail,
    .image-thumbnail,
    .list-img-detail {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
    .video-react.video-detail.main_video {
      padding-top: 100% !important;
    }
    .list-img-detail {
      .list-images-item {
        padding-bottom: 100%;
      }
    }
  }
}

.fansite-creator-post-detail.creator-post-normal-detail.creator-post-short-detail-timeline {
  .box-item,
  .video-details {
    min-width: 500px;
    max-width: 500px;
    max-height: 880px;
  }

  .video-custom {
    min-width: 500px;
    max-width: 500px;
    max-height: 880px;
  }

  .video-react {
    padding-top: 0 !important;
    height: 100%;
  }

  video {
    width: 100%;
    position: relative;
    //aspect-ratio: 2/3;
  }
}
