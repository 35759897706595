@import 'src/style/variables';
@import 'src/style/mixin';

.ranking-option-search {
  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__select {
      width: 132px;
      max-width: 132px;
      .anticon {
        pointer-events: none;
      }
    }
    &__time {
      text-align: end;
      max-width: calc(100% - 138px);

      @media screen and (min-width: 991px) {
        display: none;
      }
      @include mq('md') {
        //white-space: nowrap;
        white-space: pre-line;
      }
      @include mq('sm') {
        white-space: pre-line;
        font-size: 13px;
      }
    }
  }
  &-type {
    display: flex;
    gap: 12px;
    margin-top: 16px;

    @media screen and (max-width: 575px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .tab-item {
        min-width: fit-content;
        font-size: 16px;
      }
    }

    .tab-item {
      width: max-content;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: #f2f3f5;
      border-radius: 8px;
      font-size: 16px;
      cursor: pointer;
      &.active {
        background: $color-26;
        color: #ffffff;
        cursor: default;
        &.hover-color {
          &:hover {
            background: $color-26;
          }
        }
      }
      &.hover-color {
        &:hover {
          background: $color-32;
        }
      }
    }
  }
}
