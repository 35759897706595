@import 'src/style/variables';

.bold {
  font-weight: bold;
}

.content-sale {
  video {
    object-fit: contain;
    aspect-ratio: 4/3;
    position: relative !important;
  }
  .video-detail {
    margin-top: 16px;
    margin-bottom: 16px;
    height: auto;
    padding-top: unset !important;
  }
  .video-react-poster {
    margin: unset;
  }
  .text {
    white-space: pre-wrap;
  }
  .content-video {
    margin-bottom: 16px;
  }
  .content-thumnail {
    margin-bottom: 6px;
  }
  .content-trailer {
    margin-bottom: 16px;
  }
  .content-image {
    .row {
      --bs-gutter-x: 6px;
      .col-4 {
        margin-bottom: var(--bs-gutter-x);
      }
      .content-image-item {
        position: relative;
        padding-top: 72%;
        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: contain;
          aspect-ratio: 4/3;
        }
      }
    }
  }
  .bookmark-wrapper {
    position: relative;
    .img-content-recod {
      width: 100%;
      height: 100%;
    }
    .image-thumbnail {
      aspect-ratio: 4/3;
      // background-color: #000;
      object-fit: contain;
      width: 100%;
    }
    .file_zip {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .anticon {
        margin-right: 10px;
        color: #787880;
      }
      .file_name {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $color-04;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 70%;
        overflow: hidden;
      }
    }
  }

  .content-zip {
    margin-bottom: 16px;
    position: relative;
  }

  .list-images.custom-fan {
    margin-bottom: 16px;
  }

  .content-body {
    padding-top: 16px;
    border-top: 1px solid $color-07;
  }
  .btn-open-trailer {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #604de7;
    max-width: 503px;
    margin-top: 16px;
    cursor: pointer;
    margin-bottom: 16px;
  }
}

.buy-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  ._point {
    color: $color-06;
    font-weight: bold;
  }
  ._right {
    .btn {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.style-mb-package-homepage {
  .confirm-fan {
    z-index: 99 !important;
  }
  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 17;
  }

  .lock-content-common-new {
    width: 100% !important;
    margin: unset !important ;
  }
}

.style-mb-package-timeline {
  .confirm-fan {
    z-index: 99 !important;
  }
  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 17;
    width: calc(100% - 32px) !important;
    margin: 0 16px !important;
  }

  .lock-content-common-new {
    width: 100% !important;
    margin: unset !important ;
  }
}

.style-mb-package {
  .confirm-fan {
    position: absolute;
    z-index: 99 !important;
  }
  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 17;
    @media screen and (max-width: 575px) {
      width: calc(100% - 32px) !important;
      margin: 0 16px !important;
    }
  }
}

.bookmark-lock-package {
  .lock-content-common, .in_my_page {
    margin: 0 16px !important;
    width: calc(100% - 32px) !important;
  }
}

.my-page-item-new {
  position: relative;
  .lock-content-common {
    @include mq('sm') {
      margin: 0 16px !important;
      width: calc(100% - 32px) !important;
    }
  }
}

.my-page-item {
  position: relative;
  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 34;

    // @include mq('sm') {
    //   margin: 0 16px;
    //   width: calc(100% - 32px);
    // }

    @include mq('xs') {
      margin: 0;
      width: 100%;
    }

    .lock-content-inner {
      background: $color-01;
      border-radius: 19px;
      width: calc(100% - 30px);
      max-width: 343px;
      padding: 24px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: scale(0.85);

      .btn-membership {
        background: #dc143c;
        border-radius: 8px;
        color: #ffffff;
        &:hover {
          background-color: $color-27;
        }
      }
      ._packageNew {
        line-height: 24px;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
      }
      @include mq('mw1024') {
        transform: scale(0.7);
      }
      @include mq('sm') {
        .hidden_des {
          display: none;
        }

        padding: 12px;
        width: calc(100% - 120px);
      }
      ._package {
        max-height: 91px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
          background: #e5e6eb;
        }
      }
      ._price {
        font-size: 18px;
        margin-bottom: 16px;
        margin-top: 10px;
      }
      ._list-package {
        width: 100%;
      }
      .btn {
        width: 100%;
        margin-top: 15px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }
    }
    .mobile_3_column {
      @include mq('sm') {
        .btn-membership {
          width: 137px !important;
          height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ._packageNew {
          font-size: 12px !important;
        }
        ._price {
          font-size: 14px !important;
        }
        svg {
          margin-bottom: -7px;
        }
        height: 151px !important;
        width: 150px !important;
      }
    }
    .mobile_one_colmn {
      @include mq('sm') {
        .btn-membership {
          width: 192px !important;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: -15px;
        }
        ._packageNew {
          font-size: 12px !important;
        }
        ._price {
          font-size: 14px !important;
          margin-bottom: 0px !important;
        }
        svg {
          margin-bottom: -7px;
        }
        height: 180px !important;
        width: 216px !important;
      }
    }
  }
  &.popup_lock_post {
    // min-height: 400px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
  }

  .video-custom {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // background: #000;
    aspect-ratio: 4/3;
    &:focus {
      outline: none;
    }
  }
}

.my-page-item.sell-post-success {
  padding: 132px 32px 102px 32px;
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: none;
  .check-icon {
    color: $color-10;
    svg {
      width: 54px;
      height: 54px;
    }
  }
  .text-confirm {
    color: $color-10;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin: 22.3px 0 16px 0;
  }
  .text-contact {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
  }
  .button {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid $color-06;
    border-radius: 6px;
    color: $color-06;
    padding: 11px 72px;
    width: max-content;
    cursor: pointer;
  }
}
.my-page-detail {
  .my-page-item {
    border-bottom: none;
  }
}

.popup-view-full-post-timeline {
  .my-page-item {
    border-bottom: none !important;
  }
}
