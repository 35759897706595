@import 'src/style/variables';
@import 'src/style/mixin';
:root {
  --widthCalendar: var(--widthCalendar);
}
.layout-fan {
  .layout-left {
    .wrapper-children {
      background-color: $color-01;
      height: calc(100% - 70px);
      @include mq('md') {
        padding-bottom: 70px;
      }
    }
  }
  .layout-content {
    min-height: calc(100vh - 60px);
    gap: 30px;
    @include mq('xl') {
      gap: 20px;
    }

    @include mq('l') {
      gap: 10px;
    }

    @include mq('md') {
      gap: 0;
    }
    .layout-right {
      flex-grow: 1;
      width: 100%;
      @include mq('xl') {
        max-width: 100%;
      }
      .calendar-wrapper {
        width: calc(var(--widthCalendar));
      }
    }
  }
}
