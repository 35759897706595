@import 'src/style/variables';
@import 'src/style/mixin';

.profile {
  border-radius: 20px;
  @include mq('md') {
    .back-to-page {
      display: flex;
      box-shadow: none;
      border-bottom: 1px solid #e5e6eb;
    }
  }
  @include mq('sm') {
    padding-bottom: 70px;
    .wrapper__btn-comfirm {
      display: flex;
      // width: 100%;
      justify-content: space-between;
      .btn_keep {
        width: 49%;
      }
      .btn_return {
        width: 49%;
      }
    }
  }
  .mr-top {
    margin-top: 15px;
  }

  // .ps-bottom {
  //   position: sticky;
  //   bottom: 0;
  //   z-index: 100;
  //   background: #fff;
  //   @media screen and (max-width: 1023px) {
  //     position: fixed;
  //     bottom: 40px;
  //     z-index: 100;
  //     width: 100%;
  //     padding: 0 16px;
  //     left: 0;
  //     background: #fff;
  //     .fansite-form-input__controls {
  //       margin-top: 8px;
  //       margin-bottom: 20px;
  //     }
  //   }
  // }

  .bg-while {
    &:hover {
      background-color: #f1f1f1 !important;
    }
  }
  .detail-btn {
    padding: 10px 16px !important;
    border-radius: 4px !important;
    text-align: center;
    font-weight: 500;
    cursor: unset;
  }

  .green-status {
    color: #57c22d !important;
    background: #f6ffed !important;
    border: 1px solid #b7eb8f;
  }

  .grey-status {
    background: #efefee;
    color: #666565;
    border: 1px solid #d1d0cf;
  }

  .orange-status {
    color: #f9833e;
    background: #feefd8;
    border: 1px solid #fedcb1;
  }

  .yellow-status {
    color: #faad14;
    background: #fffbe6;
    border: 1px solid #ffe58f;
  }

  .red-status {
    background: #fff1f0;
    color: #dc143c;
    border: 1px solid #ffccc7;
  }

  .dark-status {
    background-color: #2b2b2b;
    color: white;
  }

  .icon-twitter {
    position: absolute;
    margin: 5px;
    margin-top: 0px;
  }

  .text-hypelink-profile {
    color: #1480ff;
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;
    svg {
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  .not-active-tag {
    transform: rotate(-90deg);
  }

  .active-tag {
    transform: rotate(90deg);
  }

  .btn-link-or-unlink-twitter {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    //background-color: $color-06;
    color: #1480ff;
  }
  .form-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .label {
      font-size: 14px;
      line-height: 24px;
    }
    .status {
      padding: 6px 12px;
      border-radius: 4px;
      &.status1 {
        background: rgba(40, 199, 111, 0.2);
        color: #28c76f;
      }
    }
  }

  .btn-dele {
    padding: 8px 16px 10px 16px;
    cursor: pointer;
    border: 1px solid #e5e6eb;
    border-radius: 8px;
  }

  .statustweetGreen {
    max-width: 106px;
    padding: 10px 0px;
    margin-bottom: 22px;
    font-weight: 500;
    border-radius: 4px;
    color: #57c22d !important;
    background: #f6ffed !important;
    border: 1px solid #b7eb8f;
    display: flex;
    justify-content: center;
  }

  .statustweetRed {
    max-width: 90px;
    padding: 10px 0px;
    margin-bottom: 22px;
    font-weight: 500;
    border-radius: 4px;
    color: #dc143c !important;
    background: #fff1f0 !important;
    border: 1px solid #ffccc7;
    display: flex;
    justify-content: center;
  }

  @include mq('sm') {
    .fansite-form-input__error {
      // position: absolute;
      // right: 0;
      margin: 0;
    }
    .avatar-error {
      position: relative;
    }
  }
  .sns {
    @include mq('mw1024') {
      @media (orientation: landscape) {
        margin-bottom: 60px;
      }
    }
    input {
      height: 46px;
      width: 80%;
      padding: 7px;
      outline: none;
      color: #1a1a1a;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid #e5e6eb;
    }

    input:focus {
      border: 1px solid #e5e6eb;
    }

    select {
      height: 44px;
      width: 30%;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
    }

    select:focus {
      border: 1px solid rgb(0, 208, 255);
    }

    option[value=''][disabled] {
      display: none;
    }
    option {
      color: black;
    }

    .services {
      display: flex;
      justify-content: space-between;
    }

    .sns-select {
      position: relative;
      .anticon {
        pointer-events: none;
        right: 10px;
        position: absolute;
        top: 12px;
        &.focus2 {
          svg {
            transform: rotate(180deg) !important;
          }
        }
        &.no-focus2 {
          svg {
            transform: rotate(0deg) !important;
          }
        }
        svg {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    .first-division {
      display: flex;
      flex-direction: column;
      margin: 0 20px 0.5rem 0;
      width: 100%;
      @include mq('sm') {
        margin: 0 10px 0.5rem 0;
      }
    }

    .first-division1 {
      display: flex;
      flex-direction: column;
      margin: 0 20px 0.5rem 0;
      width: 100%;
      @include mq('sm') {
        margin: 0 10px 0.5rem 0;
      }
    }

    button {
      background: none;
      outline: none;
      cursor: pointer;
      font-weight: 500;
      border-radius: 2px;
      padding: 5px 10px;
    }
    .first-division button {
      align-self: flex-start;
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      padding: 8px 12px;
      background: #ffffff;
    }

    .first-division1 button {
      align-self: flex-start;
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      padding: 8px 12px;
      background: #ffffff;
    }
  }
}
