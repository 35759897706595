.fansite-form-input {
  &__controls {
    margin-bottom: 16px;
  }
  &__required {
    margin: 0;
    padding: 3px;
  }
  &__sub-label {
    font-size: 12px;
    color: #666565;
    font-weight: 400;
    margin-left: 6px;
  }
  &__label.form-label {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    margin-bottom: 4px;
    display: flex;
  }
  &__text.form-control {
    padding-left: 8px;
    padding-right: 8px;
    font-size: s(14);
    height: 40px;
    &:focus {
      box-shadow: none;
    }

    &.--textarea {
      min-height: 130px;
      line-height: (19.6 / 14);
      padding-top: 10px;
      height: auto;
      &-small {
        min-height: 82px;
        height: 82px;
      }
    }
    &.--contenteditable {
      height: 130px;
      overflow: auto;
    }

    &.is-invalid {
      background-color: #ff0000;
      background-image: none;
      padding-right: 8px;
    }
  }

  &__error {
    margin: 8px 0;
    font-size: 14px;
    color: #ff0000;
  }
}
