@import 'src/style/variables';

.setting-noti-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.popup-sercu {
  .popup-content {
    max-width: 380px !important;
  }
}
.notification-page {
  padding: 0 8px;

  @include mq("sm") {
    padding: 0;
  }

  .shimmer-loading {
    padding: 16px !important;
    background-color: #ffffff;

    @include mq("sm") {
      padding: 8px !important;
    }
  }
  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background-color: $color-01;
    border-bottom: 1px solid #eeeeee;
    .item {
      flex: 1;
      color: #787880;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
    .active {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-06;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 2px solid $color-06;
      height: 100%;
    }
  }
  .body,
  .list {
    min-height: 300px;
    .infinite-scroll-component,
    .infinite-scroll-component__outerdiv {
      min-height: 268px;
    }
  }
  .body {
    background-color: $color-01;
    position: relative;
    .list {
      padding: 16px 16px;
      .infinite-scroll-component {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        cursor: pointer;
        .logo-coco-fan {
          object-fit: contain;
          background-color: #eee;
        }
        & > div {
          width: calc(100% - 77px);
        }
        img {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 16px;
        }
        .content,
        .name {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
        }
        .content {
          width: 90%;
        }

        .name {
          color: $color-03;
          overflow: hidden;
          width: 150px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
        }
        .date {
          font-size: 12px;
        }
        .--isRead {
          color: $color-14;
        }
        .--isUnRead {
          color: $color-03;
        }
      }
    }
    .no-result {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100% - 70px);
      width: 100%;
      color: $color-14;
      font-size: 14px;
    }
  }
  .detail-noti {
    padding: 16px;
    background-color: $color-01;
    .date {
      font-weight: 500;
      font-size: 12px;
    }
    .title {
      font-weight: 700;
      font-size: 14px;
    }
    .content {
      font-weight: 400;
      font-size: 14px;
      white-space: pre-line;
    }
  }

  .setting-noti {
    background-color: $color-01;
    font-size: 14px;
    &.creator {
      .toggle {
        padding: 10px 16px 10px 32px;
        width: 375px;
        border-top: none;
      }
    }
    .title {
      font-weight: 700;
      padding: 20px 16px 10px 16px;
    }
    .toggle {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      gap: 35px;

      label {
        font-weight: 500;
      }

      .toggle-switch {
        .toggle-switch-switch {
          display: block;
          width: 20px;
          height: 20px;
          margin: 2px;
          background: #fff;
          position: absolute;
          top: 0;
          bottom: 0;
          border: 0 solid #bbb;
          border-radius: 50%;
          transition: all 0.3s ease-in 0s;
        }
      }
    }
    .btn-setting {
      padding: 16px;
      button {
        height: 44px;
        width: 100%;
        margin-top: 10px;
        padding: 8px;
        border-radius: 6px;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        border: 0;
        cursor: pointer;
        color: #fff;
        background-color: #dc143c;
        &:hover {
          background-color: $color-27;
        }
      }
    }
  }
}
