@import 'src/style/variables';
@import 'src/style/mixin';

.my-page {
  background-color: $color-01;
  min-height: 100%;
  &-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-07;
    .content {
      margin: 0 16px 16px 16px;
      &.bg-blur {
        filter: blur(4px);
        -webkit-filter: blur(4px);
      }

      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .read-more {
        color: $color-28;
        display: inline;
        cursor: pointer;
      }
      .text {
        font-weight: 400;
      }
    }
    .disabled {
      user-select: none;
      pointer-events: none;
    }
    .interactive {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $color-08;
      outline: none !important;
      background: $color-01;
      .left,
      .item {
        display: flex;
        align-items: center;
        outline: none !important;
      }
      .item {
        .number {
          margin-left: 6px;
        }
        & + .item {
          margin-left: 26.5px;
        }
        .anticon {
          outline: none !important;
          svg {
            outline: none !important;
          }
        }
      }
      .like-icon,
      .chat-icon,
      .share-icon {
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          outline: none !important;
        }
      }
      .like-icon {
        &.active {
          color: $color-26;
        }
      }
    }
    .comment {
      display: flex;
      align-items: flex-start;
      //margin: 0 16px 9px 16px;

      img {
        border-radius: 50%;
        width: 44px;
        height: 44px;
        min-width: 44px;
        min-height: 44px;
        border: none;
        margin-right: 12px;
        object-fit: cover;
      }
      &.form-edit-comment {
        form {
          max-width: 100%;
        }
      }
      form {
        width: 100%;
        max-width: calc(100% - 56px);
        &.form-reply-comment {
          max-width: 100%;
          &.haveMeta {
            max-width: calc(100% - 36px);
          }
        }
        margin-top: 3px;
        .form-item {
          margin-bottom: 0;
          background: $color-07;
          border-radius: 6px;
          position: relative;
          display: flex;
          align-items: center;
          .anticon {
            position: absolute;
            right: 10.67px;
            z-index: 1;
            cursor: pointer;
            color: #b1b6bb;
            bottom: 12.67px;
            &.active {
              color: $color-26;
            }
            svg {
              width: 16.67px;
              height: 16.67px;
              //transform: rotate(45deg);
            }
          }
        }
        textarea {
          background: $color-07;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          width: 100%;
          border: none;
          border-radius: 6px;
          padding: 9px 12px;
          z-index: 0;
          height: 44px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .list-comment {
    //padding: 5px 16px 0px 16px;
    .comment-item {
      display: flex;
      gap: 12px;
      width: 100%;
      & + .comment-item {
        border-top: 1px solid $color-07;
        margin-top: 6px;
        padding-top: 15px;
      }
      .avatar {
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }
      .content {
        display: flex;
        //flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin: 0;

        .info-comment {
          display: flex;
          justify-content: space-between;
          .left,
          .content-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .left {
            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }
            .comment-content {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              max-width: 250px;
              word-break: break-word;
            }
          }
          .content-right {
            color: $color-08;
            align-items: flex-end;
            .padding-time {
              padding-top: 2px;
            }
            .time {
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;
              //margin-top: 8.5px;
              white-space: nowrap;
            }
            .box-popup {
              position: relative;
              .three-point-icon {
                z-index: 4;
                cursor: pointer;
              }
              .popup-edit-comment {
                position: absolute;
                background: $color-01;
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
                border-radius: 6px;
                width: 189px;
                height: 96px;
                display: flex;
                flex-direction: column;
                top: calc(100% + 8.5px);
                right: 0;
                border-radius: 6px;
                overflow: hidden;
                z-index: 15;
                .item {
                  display: flex;
                  align-items: center;
                  padding-left: 18px;
                  cursor: pointer;
                  height: 38px;
                  color: $color-03;
                  .anticon {
                    margin-right: 8px;
                  }
                  &:hover {
                    background-color: $color-07;
                  }
                  &:first-child {
                    //margin-top: 13px;
                    position: relative;
                  }
                  &:nth-child(2) {
                    margin-bottom: 6px;
                  }
                  .text {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
        .like-reply {
          margin-top: 16px;
          margin-bottom: 15.5px;
          color: $color-08;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          span {
            cursor: pointer;
          }
          .like.active {
            color: $color-06;
          }
          .reply-btn {
            margin-left: 16px;
          }
        }
      }
      .reply-comment {
        .comment-item-reply {
          gap: 10px;
          & + .comment-item-reply {
            border-top: none;
            padding: 0;
          }
          .comment-content {
            max-width: 200px !important;
            &.comment-content_v2 {
              max-width: 100% !important;
            }
          }
        }
        .avatar {
          img {
            width: 24px;
            height: 24px;
          }
        }
        .like-reply {
          margin-bottom: 9px;
        }
      }
      .form-reply-comment,
      .form-edit-comment {
        margin: 0 0 16px 0;
        img {
          width: 24px;
          height: 24px;
        }
        form {
          textarea {
            height: 38px;
          }
        }
      }
      .form-edit-comment {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .out-edit {
          font-size: 12px;
          line-height: 20px;
          color: $color-08;
          margin-top: 4px;
          margin-left: 10px;
          cursor: pointer;
          margin-bottom: 12px;
          span {
            color: $color-04;
          }
        }
      }
    }
  }
}

.list-idol-suggestion {
  background: url('../../images/SkyBackground.png');
  background-color: $color-01;
  background-size: 1440px 401px;
  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    padding: 41px 0 32px 24px;
  }
  .list-idol {
    display: flex;
    justify-content: space-between;
    padding: 0 0 14px 0;
    .item {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      img {
        width: 74px;
        height: 74px;
        border-radius: 50%;
        margin-bottom: 14px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
      @include mq('sm') {
        .name {
          max-width: 80%;
        }
      }
    }

    .slick-arrow {
      top: 21%;
    }
  }
  .slick-list {
    width: 100%;
  }
}
