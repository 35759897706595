.bg-aa {
  background-image: url('../../../../images/img.png');
  .bg-img {
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
  }
}
.number-img {
  position: absolute;
  z-index: 16;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.002em;
  color: #ffffff;
}

.div-blue-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  opacity: 0.4;
  z-index: 16;
}

.button-play-video {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.one-media-card {
  //height: 418px;
  @media screen and (max-width: 575px) {
    height: 100%;

    // div {
    //   height: 100%;
    // }
  }
}

.two-media-card {
  height: 319px;
  div {
    height: 319px;
  }

  video,
  .img-custom {
    height: 319px;
  }
  @media screen and (max-width: 575px) {
    height: 193px;

    div {
      height: 193px;
    }

    video,
    .img-custom {
      height: 193px !important;
    }
  }
}

.three-media-card {
  height: 640px;

  &.isMobile {
    height: 343px;

    .secondary-img {
      position: relative !important;
      aspect-ratio: 1/1;
      // max-width: 185px;
    }
  }
}

.four-media-card {
  height: 640px;
}
