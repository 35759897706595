@import 'src/style/variables';
@import 'src/style/mixin';

.help-header {
  overflow: hidden;
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}
.help-post {
  .back-to-page {
    @media (max-width: 1023px) {
      display: flex;
    }
  }
}

.creator-post-removal-request {
  .mobile-checkbox{
    display: flex;
    flex-direction: column;
  }
  width: 1056px !important;
  .title-page-remove {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 24px;
    color: #1a1a1a;
  }
  .title-content-remove {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 400;
    margin-bottom: 24px;
  }
  @include mq('sm') {
    padding: 0 16px !important;
    margin-bottom: 0;
  }
  input.fsite-input,
  textarea.fsite-input,
  select.fsite-input {
    font-weight: 400 !important;
  }
  .fansite-form {
    background: $color-01;
    @include mq('sm') {
      padding: initial;
    }
    .title {
      margin-top: 20px;
      label {
        color: $color-03;
        font-size: 14px;
        margin-bottom: 4px;
      }
      input {
        width: 100%;
        height: 44px;
        padding: 0 12px;
      }
      textarea {
        width: 100%;
        // height: 166px;
        height: 103px;
        padding: 12px;
      }
      input,
      textarea {
        // border: 1px solid #b1b6bb;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        outline: none;
      }
    }

    .category {
      position: relative;
      border-radius: 6px;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    .category {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        pointer-events: none;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          // height: 11px;
        }
      }
    }

    .button-submit {
      display: flex;
      gap: 20px;
      @include mq('sm') {
        display: initial;
        button {
          width: 100% !important;
          margin-bottom: 16px;
        }
      }
      button {
        height: 100%;
        width: calc(100% / 2 - 10px);
        font-weight: bold;
        background-color: $color-26;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        border-radius: 8px;

        &.cancel {
          color: #1a1a1a;
          background: #ffffff;
          border: 1px solid #e5e6eb;
        }

        &:focus {
          outline: none;
        }

        &:disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
    }
  }
}

.question-container {
  flex-grow: 1;
}
