.video-custom {
  position: relative;
  background-color: #1a1a1a;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    position: relative;
    max-height: inherit;
  }
  .button-play-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    width: 89px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    // background-color: rgba($color: #282727, $alpha: 0.5);
    background: transparent;
    display: inline-flex;
    svg {
      color: #fff;
    }
  }
}
