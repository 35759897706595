.blur-image {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: absolute;
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
  }
  .cover-img {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    z-index: 10;
  }
}
