@import 'src/style/variables';
@import 'src/style/mixin';

.sub-purchase-navbar {
  display: flex;
  align-items: center;
  margin: 20px 24px 0 24px;
  border-radius: 43px;
  background: #f2f3f5;

  .tab-item {
    display: flex;
    flex: 1 1;
    color: #666565;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin: 4px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 43px;

    &.active {
      color: #fff;
      background-color: #dc143c;
      font-size: 14px;
    }
  }
}

@include mq("sm") {
  .sub-purchase-navbar {
    margin: 16px;

    .tab-item, .active {
      font-size: 12px;

      &.active {
        font-size: 12px;
      }

    }

  }
}
