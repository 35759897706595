@import 'src/style/variables';
@import 'src/style/mixin';
.box-icon-more {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper-popup {
    position: absolute;
    width: 86px;
    height: 42px;
    right: 0;
    top: calc(50% - 22px);
    z-index: 1;
  }
  .pop-up {
    z-index: 10;
    position: absolute;
    background: $color-01;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    width: 86px;
    height: 42px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: calc(50% - 22px);
    right: 40px;
    border-radius: 6px;
    overflow: hidden;
    .item-delete {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 8px;
      padding: 2px 8px;
      height: 42px;
      &:hover {
        background-color: $color-07;
      }
      .anticon {
        color: #282727;
        width: 20px;
        height: 20px;
        padding-top: 2px;
      }
      .text {
        color: #282727 !important;
        padding: 0 !important;
        background-color: transparent !important;
        font-size: 14px;
      }
    }
  }
  .icon-more {
    cursor: pointer;
    height: 37px;
    padding-top: 8px;
    svg {
      height: 22px;
      width: 22px;
      color: $color-17;
    }
  }
  .anticon {
    z-index: 10;
  }
}
