@import 'src/style/variables';
@import 'src/style/mixin';

.tabs-footer {
  z-index: 22;
  position: sticky;
  bottom: 0vh;
  color: #282727;
  margin: 0;
  border-top: 1px solid $color-07;
  flex-wrap: nowrap;
  justify-content: space-between !important;
  width: 100%;
  .item {
    cursor: pointer;
    flex-basis: 100%;
    display: inline-flex !important;
    width: max-content;
    padding: 0 5px;
    flex-grow: 1;
    height: 70px;
    &.disabled {
      user-select: none;
      pointer-events: none;
      color: $color-08;
    }
    &.active {
      background-color: #ffdbdb;
      color: $color-06;
      width: unset;
    }
    .anticon {
      svg {
        height: 24px;
      }
    }
  }
}

@include mq('md') {
  .tabs-footer {
    position: fixed;
    z-index: 22;
    left: 0;
    > div {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
      font-size: 10px;
    }
  }
}

.tabs-footer.fan {
  height: 70px !important;
  position: sticky;
  width: 100%;
  flex-wrap: nowrap;
  @include mq('md') {
    position: fixed;
  }
}
.tabs-footer.creator {
  max-height: 70px;
  min-height: 70px;
}
