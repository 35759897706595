@import 'src/style/variables';
@import 'src/style/mixin';

.list_comment_detail {
  // display: flex;
  // flex-direction: column;
  // height: calc(100vh - 150px);
  // padding-top: 15px;
}
//detail
.list-comment {
  .mw-550 {
    max-width: 550px !important;
  }
  .infinite-scroll-component {
    height: initial !important;
    overflow: initial !important;
  }
  .comment-item-wrapper {
    position: relative;
    & + .comment-item-wrapper {
      border-top: 1px solid $color-07;
      margin-top: 6px;
      padding-top: 15px;
    }
  }
  .dp-flex {
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
    width: 100%;
  }
  .comment-item {
    display: flex;
    gap: 12px;
    width: 100%;
    .avatar {
      cursor: pointer;
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      overflow: visible;
      .info-comment {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .action-icons {
            &.disabled {
              color: #b1b6bb;
            }
          }
        }
        .content-right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
        .like-icon {
          margin-right: 5px;
          &.active {
            color: #dc143c;
          }
          &:disabled {
            color: #b1b6bb;
          }
        }
        .cls-text {
          color: #666565;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          line-height: 22px;
        }
        .left {
          width: 100%;
          cursor: default;
          .name {
            word-break: break-all;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;

            &.list-top-first-comment {
              display: flex;
              width: 100%;
              max-width: 100%;
            }
          }
          .comment-content {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            max-width: calc(100% - 60px);
            color: #1a1a1a;
          }
          .icon-edited {
            margin-left: 10px;
            svg {
              width: 14px;
              height: 14px;
              color: $color-08;
            }
          }
          .read-more {
            color: $color-28;
          }
        }
        .time {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          //margin-top: 8.5px;
          white-space: nowrap;
          color: #666565;
        }
        .box-popup {
          position: relative;
          margin-left: auto;

          // @media (max-width: 575px) {
          //   padding: 16px 0;
          // }

          .three-point-icon {
            z-index: 4;
            cursor: pointer;
          }
          .popup-edit-comment {
            position: absolute;
            background: $color-01;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            width: 189px;
            height: unset !important;
            display: flex;
            flex-direction: column;
            top: 8px;
            right: 0;
            border-radius: 6px;
            overflow: hidden;
            z-index: 20;
            .item {
              background-color: #fff !important;
              display: flex;
              align-items: center;
              padding-left: 18px;
              cursor: pointer;
              height: 38px;
              z-index: 10;
              color: $color-03;
              .anticon {
                margin-right: 8px;
                min-width: 20px;
              }
              &:hover {
                background-color: $color-07 !important;
              }
              &:first-child {
                margin-top: 6px;
                position: relative;
              }
              &:nth-child(2) {
                margin-bottom: 6px;
              }
              .text {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        .content-right {
          color: $color-08;
        }
      }
      .like-reply {
        margin-top: 16px;
        margin-bottom: 15px;
        color: $color-08;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        span {
          cursor: pointer;
        }
        .follow {
          margin-left: 16px;
        }
        .follow.active {
          color: $color-04;
          display: inline !important;
          width: max-content;
          border-bottom: none !important;
          font-size: 10px !important;
        }
        .reply-btn {
          margin-left: 16px;
        }
        .comment-icon {
          margin-left: 30px;
        }
        .like {
          font-weight: 400;
          font-size: 14px;
          color: #666565;
          line-height: 22px;
          display: flex;
          align-items: center;
        }
        .like.active {
          color: $color-06;
          display: inline !important;
          width: max-content;
          border-bottom: none !important;
          font-size: 10px !important;
        }
      }
    }
    .reply-comment {
      .comment-item-reply {
        gap: 10px;
        & + .comment-item-reply {
          border-top: none;
          padding: 0;
        }
      }
      .avatar {
        cursor: default;
        img {
          cursor: pointer;
          width: 44px;
          height: 44px;
          max-width: 44px;
          min-width: 44px;
          max-height: 44px;
          min-height: 44px;
        }
      }
      .like-reply {
        margin-bottom: 15px;
      }
      .content .info-comment {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: start;
      }
    }
    .form-reply-comment,
    .form-edit-comment {
      img {
        margin-top: 0px;
        width: 44px;
        height: 44px;
        max-width: 44px;
        min-width: 44px;
        max-height: 44px;
        min-height: 44px;
      }
      form {
        textarea {
          min-height: 38px;
          height: fit-content;
          padding-right: 40px;
        }
      }
    }
    .form-reply-comment {
      margin-bottom: 16px;
    }
    .form-edit-comment {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .out-edit {
        font-size: 12px;
        line-height: 20px;
        color: $color-08;
        margin-top: 4px;
        margin-left: 10px;
        cursor: pointer;
        margin-bottom: 12px;
        span {
          color: #007be5;
        }
      }
    }
  }
  .view-more-comment,
  .collapse-comment {
    color: #787880;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }
  .view-more-comment{
    &:hover{
      color: $color-28;
    }
  }
  .textarea-post-rep-comment {
    &::placeholder {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @include mq('sm') {
    .comment-content {
      max-width: calc(100% - 15px) !important;
    }
  }
}
