@import 'src/style/variables';
@import 'src/style/mixin';

.creator-live-name-length {
  overflow: unset !important;
  .tooltip {
    max-width: 250px;
    &.creator {
      max-width: 150px;
    }
  }
}

.popup-realtime-user-active {
  background-color: #ff0000;
  color: white;
}

.popup-realtime-user-active:hover {
  color: white;
}

.real-fan-view {
  max-height: 685px;
  overflow: hidden !important;
  overflow-y: auto !important;
  &.isMobile {
    max-height: 515px;
  }
}

.wrapper-live-view {
  padding: 18px 18px 46px 18px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  height: 100%;
  overflow: hidden;
  .item {
    padding: 0 !important;
  }
  .info-user {
    display: flex;
    align-items: center;
    gap: 8px;
    img.img-custom.avatar {
      min-height: 56px !important;
      min-width: 56px !important;
      max-height: 56px !important;
      max-width: 56px !important;
    }
    .text {
      font-weight: 700;
      font-size: 14px;
      color: #282727;
    }
    &.full {
      width: 100% !important;
      .tooltip.ranking-name-tooltip-wrapper.creator.full {
        max-width: 256px;
      }
    }
  }
  .box-follow-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    .btn {
      height: 36px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      color: #1a1a1a;
      font-weight: 700;
      font-size: 12px;
      padding: 7px 12px !important;
      &.popup-realtime-user-active {
        background: #dc143c;
        color: #FFFFFF;
        border: none !important;
        padding: 8px 12px !important;
      }
    }
  }
}
