@import 'src/style/variables';
@import 'src/style/mixin';

.list-images {
  img,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
    // background: #000;
    aspect-ratio: 4/3;
    &:focus {
      outline: none;
    }
  }
  &-item {
    width: 100%;
    padding-bottom: 75%;
    overflow: hidden;
    position: relative;
    .pagination {
      position: absolute;
      top: 16px;
    }
    .pagination {
      left: 16px;
      height: 23px;
      text-align: center;
      z-index: 2;
      cursor: auto;
      &.hidden {
        display: none;
      }
      span {
        color: white;
        font-weight: 500;
        font-size: 12px;
        z-index: 2;
        position: relative;
        line-height: 20px;
        padding: 0px 12px;
      }
      .gray-screen {
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: $color-03;
        opacity: 0.6;
        left: 0;
        border-radius: 4px;
        top: 0;
      }
    }
  }
  .slick-dots {
    bottom: 16px;
    li {
      width: unset;
      height: unset;
      margin: 0 4px;
      button {
        padding: 0 3px;
        width: auto;
        // box-shadow: rgba($color: #fff, $alpha: 0.5) 0px 7px 29px 0px;
        height: fit-content;
        &::before {
          opacity: 1;
          line-height: 1px;
          color: transparent;
          content: '';
          background: $color-14;
          width: 9px;
          height: 3px;
          border-radius: 25%;
          position: relative;
          display: block;
        }
      }
    }

    li.slick-active button:before {
      background: $color-06;
    }
  }
  .slick-prev {
    left: 10px;
    z-index: 1;
  }
  .slick-next {
    right: 10px;
    z-index: 1;
  }

  // Phú CSS
  .slick-prev::before {
    display: none;
  }
  .slick-next::before {
    display: none;
  }
  .post-video {
    .cheat-ui {
      position: absolute;
      width: 100%;
      height: calc(100% - 65px);
      z-index: 10;
    }
  }
}

.my-page-item {
  position: relative;
  .slick-arrow {
    background: $color-27;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &:before {
      display: none;
    }
  }
}

.img-demo {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-images.list-images-custom {
  // background-color: $color-02;

  .list-images-item {
    width: 100%;
    height: 377px;
    padding-bottom: 0 !important;
    // background: $color-02;
    cursor: pointer;
    display: inline-flex !important;
    justify-content: center;
    align-content: center;
    img {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      width: unset;
      height: unset;
      object-fit: contain;
      aspect-ratio: 4 / 3;
      display: inline-block;
      max-width: 503px;
      max-height: 377px;
      // background: $color-02;
    }
    @include mq('sm') {
      height: unset;
      max-width: 100%;
      img {
        max-width: 100%;
        max-height: unset;
      }
    }
  }
}
