@import 'src/style/variables';
@import 'src/style/mixin';
.back-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 64px;
  min-height: 64px;
  border-bottom: 1px solid #eeeeee;
  .pr {
    padding-right: 23px !important;
  }
  .no-button-back {
    p {
      margin-left: 0px !important;
    }
  }
  p {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #282727;
  }
  svg {
    cursor: pointer;
    color: #787880;
  }
  .avatar-right {
    margin-top: 10px;
    margin-right: 30px;
    margin-left: auto;
    min-width: 45px;
    max-height: 45px;
    max-width: 45px;
    min-height: 45px;
    border-radius: 45px;
    cursor: pointer;
    background-color: #eeeeee;
  }
  &.update-profile {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    p,
    svg {
      color: #949393;
    }
  }
}

.margin-left {
  margin-left: 35px !important;
}

@include mq('sm') {
  .back-to-page .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    &.page-C083_1,
    &.C010-profile-info {
      white-space: normal !important;
      line-height: 21px;
      letter-spacing: 1px;
    }
  }
}

.diasble-go-back {
  opacity: 0.4;
  cursor: default !important;

  svg {
    cursor: default !important;
  }
}