@import 'src/style/variables';

@mixin thumbnail {
  height: fit-content;
  width: 100%;
}

.time-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.create-live-list-page {
  &-header {
    padding: 20px 14px 0 14px;
    @include mq('sm') {
      padding: 20px 8px 0 8px;
    }
    &_btn {
      font-weight: 500;
      font-size: 16px;
      color: #fdfefe;
      background-color: $color-26;
      border-radius: 8px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
      height: 44px;
      min-width: 110px;
      img {
        background-color: transparent;
      }
    }
    &_text {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      color: #666565;
    }
  }
}

.border-thumbnail {
  border: 1px solid #b1b6bb;
}

.thumbnail {
  @include thumbnail;
  width: 100%;
  aspect-ratio: 1.33333;
  // height: var(--valueHeight);
  border: 1px dashed #e5e6eb;
  background-color: #f7f8fa;
  cursor: pointer;
  border-radius: 8px;
  @include mq('sm') {
    max-width: 100%;
  }

  &-des {
    color: #b1b6bb;
  }
  .cover-img {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
  }
  &-img {
    // @include thumbnail;
    z-index: 1;
    height: 100%;
    max-width: 100%;
  }
  &-container {
    position: relative;
    z-index: 1;
  }
  &-cross-icon {
    position: absolute;
    top: 7px;
    right: 15px;
    @include mq('sm') {
      right: 7px;
    }
  }
}
.creator-create-live {
  @include mq('sm') {
    padding-bottom: 77px;
  }
  .card-body {
    padding-top: 10px;
    @include mq('sm') {
      padding: 1rem 8px !important;
    }
    .card-block-item {
      .wrapper-content-ogp {
        &.have-meta {
          padding: 4px 16px 16px 16px;
          border-radius: 6px;
          border: 1px solid #e5e6eb;
          .card-block-item_input {
            border: none !important;
            padding: 0;
          }
        }
      }
      &_format {
        label {
          font-weight: 400 !important;
          color: #1a1a1a;
        }
      }
      label {
        color: #1a1a1a;
      }
      &_input {
        width: 100%;
        height: 44px;
        background: #ffffff !important;
        border: 1px solid #e5e6eb !important;
        border-radius: 6px !important;
        padding: 12px;
        &::placeholder {
          color: #bebebe;
        }
        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }
  .card-item-time {
    .time {
      .time-icon {
        position: absolute;
        top: 11px;
        right: 10px;
      }
    }
  }
  .subscribers-area-live .checkox-wrap {
    label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #1a1a1a;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .text-next {
    color: #666565;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .btn_submit {
    height: 44px;
    border: none;
    background-color: $color-26 !important;
    &:disabled {
      background-color: $color-30 !important;
    }
  }
}
.subscribers-area-live {
  background-color: #ffffff;
  min-height: 90px;
  border-radius: 8px;
  border: 1px solid #e5e6eb;
}

.individual:disabled {
  background-color: #eeeeee;
}

.min-width {
  min-width: 38px;
}

.danger {
  background-color: $color-26;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

.calendar {
  width: 25px;
  height: 25px;
}

.list-user-selected-wrapper {
  border: 1px solid #efefef;
  &.border-none {
    border: none;
  }
  background-color: #efefef;
  border-radius: 6px;
  width: 100%;
  position: relative;
  cursor: not-allowed;
  &.approve {
    cursor: unset;
    background-color: #fff;
  }
  .list-user {
    padding: 8px 40px 8px 12px;
    display: flex;
    flex-wrap: wrap;
    min-height: 44px;
    gap: 8px;
  }
  .item {
    padding: 4px 4px 4px 8px;
    background: #eeeeee;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    cursor: pointer;
    span {
      color: #b1b6bb;
      margin-left: 8px;
    }
  }
  .btn-popup-select-user {
    position: absolute;
    top: 13.5px;
    right: 13.5px;
    width: 16.5px;
    height: 16.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #787880;
    padding-bottom: 2px;
    border-radius: 6px;
  }
}

#fsite-folk-can-view {
  font-weight: 700;
}

.creator-lvstream-create {
  position: absolute;
  right: 21px;
  top: 50%;
  transform: translateY(-50%);
  color: #b1b6bb;
  z-index: 1;
}

.date,
.time {
  .close-icon {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: #b1b6bb;
    z-index: 10;
    cursor: pointer;
  }
}

.custom-time-post {
  position: absolute;
  right: 0;
  width: 112px;
  z-index: 3;
  bottom: calc(100% + 5px);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  .hour,
  .minute {
    height: 224px;
    overflow-y: auto;
    width: 56px;
    text-align: center;
    background-color: white;
    box-shadow: inset 1px 0px 0px #f0f0f0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .item {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .item.active {
    color: #fff;
    background: $color-26;
    padding: 0 2px;
  }
  .box-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq('sm') {
      align-items: flex-end;
    }
    height: 30px;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    .btn {
      color: #fff;
      margin-right: 12px;
      background-color: $color-26;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 2px;
      width: 35px;
      height: 24px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      justify-content: center;
    }
  }
}

//C090
.input-at-livestream-creator {
  color: #282727 !important;
  font-weight: 400 !important;
  background-color: #ffffff !important;
}

.cal-creator-live {
  // .react-calendar__tile--now {
  //   background: #ff0000 !important;
  //   color: #ffffff !important;
  // }

  position: absolute;
  left: 12px !important;
  top: 44px !important;

  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  border: none;
  border-radius: 0px 0px 12px 12px;
}

.table-ng-words {
  table {
    width: 100%;
    max-width: 320px;
    @include mq('sm') {
      max-width: 100%;
    }
    border: none;
    font-size: 12px;
    border-collapse: separate !important;
    border-spacing: 0px;
    th,
    td {
      border: 1px solid $color-33 !important;
      border-right: none !important;
      &:last-child {
        border-right: 1px solid $color-33 !important;
      }
    }
    .no {
      width: 32px;
    }
    .action {
      width: 40px;
    }
    .del-ngword {
      color: $color-26;
      cursor: pointer;
    }
    thead {
      background: $color-07;
      font-weight: normal;
      th {
        font-weight: normal;
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
      tr {
        border-radius: 10px 10px 0 0;
        // border: 1px solid $color-14;
      }
    }
    tbody {
      tr {
        td {
          border-top: none !important;
        }
        .td-text {
          overflow: hidden;
          white-space: wrap;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 10px;
            }
            &:last-child {
              border-radius: 0 0 10px 0;
            }
          }
        }
      }
      .tr-empty {
        td {
          border-radius: 0 0 10px 10px !important;
          text-align: center;
          color: #bebebe;
        }
      }
    }
  }
}

.list-search-distributions {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  top: 100%;
  left: 0;
  width: 300px;
  padding: 10px 0;
  max-height: 137px;
  min-height: 55px;
  overflow-y: auto;
  z-index: 11;
  margin-top: 10px;
  margin-left: -2px;

  .item-search {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    &:hover {
      background: #ffdbdb;
    }
  }

  .active-dvc {
    background: #ffdbdb;
  }

  .icon-checked {
    color: $color-26;
    display: none;
    &.active {
      display: block;
    }
  }
}
