@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-new-idol {
  background: url('../../../images/VectorBanner3.png');
  background-color: $color-01;
  background-size: 1440px 608px;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto 60px auto;
    text-align: center;
  }
  .list-idol {
    display: flex;
    justify-content: space-between;
    padding: 0 65px 0 65px;
    .item {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 14px;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
    }
  }
  .slick-arrow {
    top: 26%;
  }
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    img {
      width: 864px;
      max-height: 200px;
      object-fit: cover;
    }
  }
  .slick-list {
    width: 100%;
  }
}

@include mq('l') {
  .list-idol-new-idol {
    .list-idol {
      padding: 0 20px 0 20px;
    }
  }
}

@include mq('md') {
  .list-idol-new-idol {
    background-size: 100% 100%;
    .title {
      margin: 20px auto 30px auto;
    }
    .banner {
      padding: 32px 0;
      img {
        width: calc(100% - 64px);
      }
    }
  }
}

@include mq('sm') {
  .list-idol-new-idol {
    .list-idol {
      .item {
        width: 74px;
        img {
          height: 74px;
          width: 74px;
          margin-bottom: 12px;
        }
        .name {
          width: 74px;
          max-width: 74px;
          font-size: 12px;
        }
      }
    }
    .banner {
      padding: 16px;
      img {
        width: 100%;
        max-width: 100%;
        height: 122px;
        max-height: 122px;
      }
    }
  }
}
