@import 'src/style/variables';
@import 'src/style/mixin';

.list-live-another-header {
  .back-to-page {
    @media screen and (max-width: 1023px) {
      display: none !important;
    }
  }
}

.live-title-lock {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 5px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #1a1a1a;
}

.live-title-lock-description {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  max-height: 104px;
  height: 104px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-line;
  color: #282727;
  text-align: start;
  padding: 0 12px;
  &::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e6eb;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.live-deslink-lock {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700; // 700;
  font-size: 18px;
  line-height: 26px;
  margin-top: 26px;
  margin-bottom: 24px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #1a1a1a;
  // NEW 14-7
  text-align: center;
  word-break: keep-all;
  word-wrap: break-word;
  width: 100%;
  max-width: 100%;
  // END NEW 14-7
}

.live-btn-back-lock {
  background: #dc143c;
  border: 1px solid #dc143c;
  box-sizing: border-box;
  border-radius: 6px;
  width: 279px;
  height: 44px;
  align-items: center;
  padding: 6px 12px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-top: 16px;
}
.list-lives-page {
  background-color: #fff;
  position: relative;
  min-height: calc(100% - 60px - 70px);
  border-radius: 20px;
  .shimmer-bg {
    width: auto;
    padding: 0px 34px;
  }
  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background: #f2f3f5;
    margin: 0px 16px;
    margin-top: 16px;
    margin-bottom: 12px;
    border-radius: 43px;
    font-weight: 500;
    font-size: 14px;
    .item {
      display: flex;
      flex: 1;
      color: #666565;
      text-align: center;
      cursor: pointer;
      margin: 4px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
    }
    .active {
      color: $color-01;
      background-color: $color-26;
    }
  }
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.list-live-stream {
  display: flex; // grid;
  flex-wrap: wrap;
  gap: 20px;
  margin: 16px auto;
  overflow: hidden;
  padding: 0 16px;
}

.live-stream-item {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  cursor: pointer;
  width: calc(50% - 10px);
  aspect-ratio: 1.304;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  &.full {
    width: 100%;
    aspect-ratio: 1.7778;
  }
  .thumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 64px);
    &.full {
      height: 100%;
    }
    width: 100%;
    // background-color: #000;
    overflow: hidden;
    img {
      object-fit: contain;
      z-index: 10;
      position: absolute;
      height: 100%;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      &.img-placeholder {
        width: 100%;
        left: 0px;
        object-fit: cover;
        transform: unset;
      }
    }
    //end change 25-7-2022
    .count-user-watch {
      height: 28px;
      background: #ffffff;
      border-radius: 0px 14px 14px 0px;
      position: absolute;
      top: 12px;
      left: 0;
      z-index: 10;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 12px;
      .count {
        font-weight: 500;
        font-size: 12px;
        color: $color-26;
      }
    }
    .episodes-tags {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 10;
      span {
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        border-radius: 4px;
        background: #dc143c;
        padding: 1px 9px;
      }
    }
    .watch-tags {
      position: absolute;
      bottom: 28px;
      right: 15px;
      z-index: 12;
      span {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        border-radius: 4px;
        background: #dc143c;
        padding: 2px 12px;
      }
    }
  }
  .content {
    position: relative;
    z-index: 10;
    padding: 10px 14px;
    border-radius: 0 0 10px 10px;
    color: #282727;
    background: #fafafa;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    cursor: default;
    &.first {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .content-info {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: calc(100% - 130px);
      &.full-name {
        width: 100%;
      }
      img {
        border-radius: 40px;
      }
      .title {
        font-weight: 700;
        font-size: 14px;
      }
      .name {
        font-weight: 400;
        font-size: 12px;
      }
      &__right {
        width: calc(100% - 60px);
        .name,
        .title {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &__left {
        img {
          width: 44px;
          height: 44px;
          cursor: pointer;
        }
      }
    }
    .content-date {
      font-weight: 400;
      font-size: 12px;
      color: #1a1a1a;
      white-space: nowrap;
      padding-bottom: 6px;
    }

    span {
      display: block;
      // margin-bottom: 4px;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      white-space: nowrap;
      .creator-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    span:nth-child(1) {
      color: #221818;
      font-size: 11px;
    }
    span:nth-child(2) {
      color: #b1b6bb;
    }
    span:nth-child(3) {
      color: #b1b6bb;
    }
    .title {
      max-width: 323px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .content-first {
    position: absolute;
    left: 0;
    z-index: 10;
    border-radius: 0 0 10px 10px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(
      360deg,
      #1a1a1a 5.62%,
      rgba(26, 26, 26, 0.447917) 74.86%,
      rgba(26, 26, 26, 0) 100%
    );
    bottom: 0;
    height: 96px;
    padding: 17px 15px 17px 20px;
    cursor: default;
    .content-info {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: calc(100% - 130px);
      img {
        border-radius: 40px;
      }
      .title {
        font-weight: 500;
        font-size: 16px;
      }
      .name {
        font-weight: 400;
        font-size: 16px;
        color: #e5e6eb;
      }
      &__right {
        width: calc(100% - 60px);
        .name,
        .title {
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &__left {
        img {
          width: 44px;
          height: 44px;
          cursor: pointer;
        }
      }
    }
    .content-date {
      font-weight: 400;
      font-size: 12px;
      color: #1a1a1a;
      white-space: nowrap;
      padding-bottom: 6px;
    }
  }

  .bg-blur {
    position: absolute;
    bottom: 14px;
    left: 0;
    width: 100%;
    height: 123px;
    background: linear-gradient(180deg, rgba(40, 39, 39, 0) 0%, #2f2121 100%);
  }
}

@include mq('mw768') {
  .list-live-stream {
    margin-top: 16px;
    gap: 16px;
    padding: 0 16px;
    .live-stream-item {
      width: 100%;
      max-width: unset;
    }
  }
}
