@import 'src/style/variables';
@import 'src/style/mixin';

.box-modal {
  .gray-screen {
    position: fixed;
    width: 100vw;
    min-height: 100vh;
    background: $color-03;
    opacity: 0.6;
    top: 0;
    left: 0;
    z-index: 2001;
  }
}

// Menu Mobile Creator
.header__mobile_creator {
  //display: none !important;
  margin-top: -40px !important;
  &.margin-top-20 {
    margin-top: 20px;
  }
  @include mq('md') {
    //display: flex !important;
    width: 100%;
    height: 100px;
    padding: 40px 16px 0 16px;
    border-bottom: 1px solid #e5e6eb;
    .notification {
      color: $color-08;
      position: relative;
      &-number {
        position: absolute;
        top: 4px;
        right: 2px;
        transform: translate(50%, -50%);
        background: #ea5455;
        font-size: 10px;
        min-width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 8px;
        padding-left: 4px;
        padding-right: 4px;
        text-align: center;
        sup {
          left: -2px;
          top: -3px;
        }
      }
    }
  }
  @media screen and (max-width: 375px) and (max-height: 812px) {
    height: 80px;
    padding-top: 30px;
  }
  .nav__btn {
    transition: all 1s;
  }
}

.menu-mobile {
  &::-webkit-scrollbar {
    display: none !important;
  }
  width: 292px !important;
  // padding-bottom: 100px;
  z-index: 2000;
  opacity: 1;
  height: 100vh;
  overflow-y: auto;
  @include mq('md') {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2002;
    background-color: $color-01;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    .account-sidebar {
      position: relative;
      .close-drawer {
        transition: all 1s;
        position: absolute;
        top: 10px;
        right: 14px;
        margin-right: 0;
      }
    }
  }
}

.header__desktop__creator {
  position: relative;
  .notification {
    &-number {
      position: absolute;
      top: 28px;
      right: 43px;
      transform: translate(50%, -50%);
      background: #ea5455;
      font-size: 10px;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 8px;
      padding-left: 4px;
      padding-right: 4px;
      text-align: center;
      sup {
        left: -2px;
        top: -3px;
      }
    }
  }
  @include mq('md') {
    display: none !important;
  }
}
