@import 'src/style/variables';

.list-page {
  .shimmer-loading {
    padding: 24px;
    @include mq('sm') {
      padding: 0 16px;
    }
  }
  background-color: $color-15;
  border-radius: 0px 0px 20px 20px;
  .body {
    background-color: $color-01;
    min-height: 300px;
    position: relative;
    border-radius: 0px 0px 20px 20px;
    .button-container {
      display: flex;
      padding: 16px 16px 0;
      div {
        height: 32px;
        padding: 6px 12px;
        margin-right: 8px;
        color: $color-01;
        font-weight: 700;
        font-size: 12px;
        cursor: pointer;
      }
      .--default {
        background: $color-14;
        border-radius: 6px;
      }
      .--active {
        background: $color-06;
        border-radius: 6px;
      }
    }

    .list {
      display: flex;
      align-items: center;
      // padding: 16px;
      padding: 20px 24px 20px 24px;
      width: 100%;
      .infinite-scroll-component__outerdiv {
        width: 100%;
      }

      @media (max-width: 575px) {
        padding: 24px 0 20px 15.5px;
      }

      .date {
        font-size: 12px;
        margin-bottom: 16px;
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        &.is_subscribe {
          align-items: flex-start;
        }
        img {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .name {
          color: #282727;
          font-size: 14px;
          line-height: 20px;
          max-height: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .no-result {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: $color-14;
      font-size: 14px;
      flex-direction: column;
      color: #b1b6bb;
      min-height: calc(100vh - 249px - 48px);
      gap: 12px;
      img {
        background-color: transparent;
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
}

.list-page.list-liked-post {
  .fan-favorite-wrapper {
    background-color: #ffffff;
  }

  .body {
    .list {
      .post-item {
        &:hover {
          cursor: pointer;
        }
      }
      .item {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: center;
        margin-right: 0 !important;
        width: 100%;
        .item-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: calc(100% - 60px);
          .name {
            max-width: 556px;
            font-weight: 700;
            font-size: 14px;
            color: #282727;
          }
          .content {
            max-width: 556px;
            color: #666565;
          }
          .content,
          .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            position: relative;
            display: inline-block;
            width: 100%;
            max-width: 100%;
            // @include mq('l') {
            //   max-width: calc(100vw - 100px);
            // }
            // @include mq('md') {
            //   max-width: calc(100vw - 100px);
            // }
          }
        }
      }

      .date {
        font-weight: 700;
        font-size: 14px;
        color: #282727;

        line-height: 20px;
        margin-bottom: 20px !important;
      }

      .images-container {
        position: relative;

        .anticon {
          position: absolute;
          bottom: 0;
          left: 70%;
        }
      }

      img {
        min-width: 56px;
        max-width: 56px;
        min-height: 56px;
        max-height: 56px;
        border-radius: 50%;
        margin-right: 0 !important ;
      }

      .d-flex {
        gap: 12px;
        & + .d-flex {
          margin-top: 20px;
        }
        &.post-item {
          width: 100%;
        }
      }
    }
  }
}

.list-page.fan-subscriber {
  height: 100%;
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  .list {
    flex-direction: column;
  }
  .package_item {
    // border: 2px solid #28c76f;
    border: 2px solid #1480ff;
    border-radius: 6px;
    // padding: 16px;
    padding: 16px 30px;
    width: 100%;
    z-index: 0;

    &:hover {
      cursor: pointer;
    }

    & + .package_item {
      margin-top: 16px;
    }
  }
  .name_plan {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .package_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    & + .package_name {
      margin-top: 4px;
    }
  }

  .user-info {
    display: flex;
    align-items: center;

    .subscriber-content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .user-avatar {
      max-width: 56px;
      max-height: 56px;
      min-width: 56px;
      min-height: 56px;
      border-radius: 50%;
      margin-right: 14px;
      object-fit: cover;
    }
  }
  .name_creator {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 2px;
    display: flex;
    // align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 50%;
    color: #282727;

    .second-name {
      font-weight: 400;
      font-size: 12px;
      color: #666565;
    }

    img {
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      z-index: 1;
      cursor: pointer;
    }
  }
  .name_number_ticket {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: auto;
    align-self: flex-end;
    font-size: 14px;
    color: #1a1a1a;
  }
}

.list-page.browse-history {
  .body .grid {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    gap: 13px;
    min-height: calc(100% - 70px);
    padding: 16px;
    .post {
      aspect-ratio: 1/1;
      overflow: hidden;
      position: relative;
      .list-images-item {
        padding-bottom: 100%;
      }
      img,
      .video-detail {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        // background: #000;
        object-fit: contain;
        aspect-ratio: 1/1;
        padding-top: unset !important;
      }
      .img-default,
      .img-demo {
        object-fit: cover;
      }
      .type {
        position: absolute;
        top: 8px;
        left: 8px;
        opacity: 0.8;
        color: $color-01;
        z-index: 10;
      }
      .bookmark {
        position: absolute;
        top: 8px;
        right: 8px;
        min-width: 16px;
        width: max-content;
        height: 41px;
        background: $color-01;
        border-radius: 4px;
        clip-path: polygon(100% 0, 100% 100%, 50% 90%, 0 100%, 0 0);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0 5px;
        cursor: pointer;
        &.active {
          background: $color-06;
          color: $color-01;
          svg {
            color: $color-01;
            fill: $color-01;
          }
        }
        svg {
          width: 12px;
          height: 11.33px;
          margin-top: 6.33px;
          color: $color-08;
        }
        .number-book-mark {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }
}

@include mq('sm') {
  .list-page.fan-subscriber {
    .body {
      .list {
        padding: 16px;
        padding-top: 8px;

        .package_item {
          padding: 16px;
        }
      }
    }

    .name_plan {
      margin-bottom: 14px;
      padding-bottom: 14px;
    }

    .package_name {
      padding-bottom: 10px;
      & + .package_name {
        margin: 0;
      }
    }

    .user-info {
      .subscriber-content {
        display: block;

        .name_creator {
          max-width: 100%;
        }
      }
    }

    .name_number_ticket {
      margin-top: 0;
    }
  }
}
