@import 'src/style/variables';
@import 'src/style/mixin';
.sales-history {
  padding: 24px;
  .without-money-button,
  .button-search {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px 21px;
    background: #b1b6bb;
    border-radius: 6px;
    border: none;
    width: 99px;
    opacity: 1;
    &.active {
      background-color: $color-06;
    }
  }

  .text-money {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 16px;
    span:last-child {
      font-weight: 700;
    }
    &:nth-child(2) {
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  .without-money {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    &-input {
      max-width: 380px;
    }
  }
  .search {
    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 24px;
    }
    .date-wrapper,
    .account-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      label {
        width: 75px;
        white-space: nowrap;
      }
    }
    .date-wrapper {
      .date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        .calendar-icon {
          svg {
            width: 15px;
            height: 15px;
            color: #b1b6bb;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            z-index: 10;
          }
        }
        input {
          border: 1px solid #b1b6bb;
          cursor: pointer;
          background-color: #fff;
          width: 136px;
          padding-left: 12px;
          padding-right: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $color-03;
        }
      }
    }
    .account-wrapper {
      margin-top: 16px;
      margin-bottom: 26px;
      .fsite-input {
        width: 290px;
      }
    }
    .btn-export-csv {
      background: #ffffff;
      border: 1px solid #787880;
      border-radius: 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      min-width: 104px;
      height: 32px;
      margin-bottom: 26px;
    }
  }
}
