@import 'src/style/variables';
@import 'src/style/mixin';

.menu-sidebar.custom {
  @include mq('sm') {
    // padding-bottom: 100px;
  }

  .menu-item {
    .arrow {
      svg {
        transition: all 0.5s;
      }
      &.active_icon svg {
        transform: rotate(180deg);
      }
    }
    .active_icon svg {
      transform: rotate(180deg);
    }

    &.is-actived {
      color: #dc143c;
      background: #ffffff;

      .link {
        background: #ffffff;
        color: #dc143c;
      }
    }
  }

  .menu-child .box-item-2 {
    .link {
      color: #282727;
      line-height: 20px;
      font-weight: 500;
      padding: 5px 20px 5px 70px;

      @include mq('md') {
        @include mq('s') {
          padding: 5px 5px 5px 14px;
        }
      }
      &:hover {
        // color: #ff0000;
        color: #dc143c;
      }
      &.activeMenuItem {
        // color: $color-06 !important;
        // background-color: $color-20;
        color: #dc143c;
        background: #f2f3f5;
      }
    }
  }
}

.banner-mobile {
  img {
    padding: 10px;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}

.post-menu-item {
  background-color: #dc143c;
  padding: 0 16px 0 16px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 54px;
  gap: 10px;
  cursor: pointer;
  &.is-mobile {
    display: none;
  }

  @media screen and (max-width: 1023px) {
    &.is-mobile {
      display: flex;
    }
    &.not-mobile {
      display: none;
    }
  }
  
  &.not-mobile {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  &:hover {
    background-color: $color-27;
  }
  @include mq('sm') {
    border-radius: 0px;
  }
  &.admin {
    cursor: default;
  }
}

//layout
.layoutSider {
  // width: 335px;
  // min-width: 335px;
  padding-bottom: 20px;
  background-color: $color-01;
  // position: fixed;
  // height: calc(100vh - 60px);
  // overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include mq('xl') {
    min-width: 330px;
    width: 330px;
  }
}

//menu general
.menu-sidebar {
  background-color: $color-01;
  margin-top: 20px;

  overflow-x: hidden;
  overflow-y: overlay;
  max-height: calc(100% - 250px);
  &.is-d-none {
    max-height: calc(100% - 253px);
    @media screen and (min-width: 1024px) and (max-width: 1440px) {
      max-height: calc(100% - 236px);
    }
    @include mq('sm') {
      max-height: calc(100% - 180px);
    }
  }
  @include mq('sm') {
    margin-top: 0px;
    max-height: calc(100% - 190px);
  }
  &.admin {
    max-height: calc(100% - 194px);
  }

  // padding: 0 24px;

  @include mq('sm') {
    @include mq('s') {
      padding: 0 4px;
    }
  }
  &::-webkit-scrollbar {
    width: 3px;
    z-index: 3;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
  }
  .anticon {
    svg {
      width: 20px;
      height: 20px;
      color: inherit;
    }
  }
  .menu-icon {
    height: 20px;
    display: flex;
    align-items: center;
  }
  .disabled-menu {
    pointer-events: none;
    .menu-item {
      color: #787880 !important;
    }
    .link {
      color: #787880 !important;
    }

    .activeMenuItem {
      opacity: 0.6;
    }
  }

  .disabled-menu-click {
    pointer-events: none;
    cursor: default;
    .menu-item {
      cursor: default;
      color: #787880 !important;
    }
    .link {
      cursor: default;
      color: #787880 !important;
    }
    .activeMenuItem {
      cursor: default;
      opacity: 0.6;
    }
    .anticon {
      color: #787880;
    }
  }

  .logout-menu {
    .anticon svg {
      height: 23px;
    }
    .label {
      margin-top: 3px;
    }
  }

  // .menu-item:first-child:before {
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 1px;
  //   background-color: $color-07;
  //   top: 0;
  //   right: 0;
  // }

  // & > .menu-item {
  //   &::before {
  //     position: absolute;
  //     content: '';
  //     width: 100%;
  //     height: 1px;
  //     background-color: $color-07;
  //     top: 0;
  //     right: 0;
  //   }
  // }

  .menu-child {
    margin-top: 0;
    padding: 0;
    margin-bottom: 0;
    .menu-item {
      // padding: 0 2px 0 0;
      // height: 40px;
      line-height: 20px;
      margin: 0 16px 16px 0;
      margin: 0;
      @include mq('l') {
        @include mq('s') {
          height: max-content;
        }
      }

      &::before {
        display: none;
      }
    }
    .box-item {
      .menu-item {
        padding-left: 20px;
      }
    }
  }

  .box-item {
    &:hover > .menu-item .arrow svg {
      transform: rotate(90deg);
    }
    .menu-item {
      @include mq('md') {
        @include mq('s') {
          height: max-content;
          padding: 2px 0;
        }
      }
      .link {
        padding: 17px 44px;
      }
    }
  }

  .menu-item {
    cursor: pointer;
    margin-right: 20px;
    color: $color-03;
    position: relative;
    height: 60px;
    // line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    font-weight: 500;
    .sub-item {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
    .label {
      margin-left: 10px;
    }
    .link {
      color: $color-03;
      line-height: 20px;
      font-weight: 500;
      text-decoration: none;
      padding: 10px 0px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 16px;

      &.is_minimal {
        justify-content: center;
      }
    }

    .anticon {
      &.arrow-icon {
        padding-right: 16px;
      }
    }

    &:hover .link {
      // color: $color-06;
      color: #dc143c;
    }

    .activeMenuItem {
      // color: $color-06 !important;
      // background-color: $color-20;
      color: #dc143c;
      background: #f2f3f5;
    }

    .arrow {
      svg {
        width: 12.5px;
        height: 6px;
      }
      transition: all 0.3s ease;
      transform: rotate(-90deg);
      color: $color-08;
      // margin-right: 30px;
    }
  }
}
//menu mobile
.menu-mobile {
  width: 335px !important;
  // padding-bottom: 100px;
  z-index: 2000;
  opacity: 1;
  height: 100vh;
  overflow-y: auto;
  @include mq('md') {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 51;
    background-color: $color-01;
    .account-sidebar {
      position: relative;
      .close-drawer {
        position: absolute;
        top: 26px;
        right: 14px;
        margin-right: 0;
      }
      .left img {
        min-height: 60px;
        max-height: 60px;
        min-width: 60px;
        max-width: 60px;
      }
    }
  }
}

//infor in menu
.account-sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: none;
  padding: 15px 15px;
  border-radius: 4px;
  background-color: #f7f8fa;
  &.is_minimal {
    padding: 0;
    background-color: #fff;
  }
  .dp-flex {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .link-F016 {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: $color-28;
    cursor: pointer;
    @include mq('l') {
      font-size: 10px;
    }
    @include mq('md') {
      font-size: 12px;
    }
  }

  .color-gray1 {
    display: block;
    margin-top: 0px;
    color: #666565;
    max-width: 135px;
    @media screen and (max-width: 1024px) {
      max-width: 108px;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .current-point {
    display: flex;
    align-items: flex-start;
    .color-gray {
      display: inline-block;
      margin-top: 0px;
      color: #666565;
      white-space: nowrap;
    }
    .info-point {
      max-width: calc(100% - 100px);
      text-align: start;
      .text {
        width: 100%;
      }
      .tooltiptext {
        left: 50%;
        transform: translate(-50%, 120%);
      }
    }
    .point {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .left {
    position: relative;
    cursor: pointer;
    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      // background: #000;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .anticon {
      position: absolute;
      bottom: 0;
      right: 10px;
      width: 20px;
      height: 20px;
      z-index: 2;
      cursor: pointer;
    }
  }
  .infor {
    margin-top: 15px;
    width: 100%;
    @media screen and (max-width: 991px) {
      margin-top: 0px;
    }
    & > span {
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 5px;
    }

    .buy-point-link {
      color: #1480ff;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .content-right {
    width: 100%;
    position: relative;

    .name-noti {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mobile-noti {
        position: absolute;
        width: 30px;
        right: 0px;
        @include mq('sm') {
          right: 34px;
        }
        @include mq('md') {
          right: 34px;
        }
      }
      .noti {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        margin-right: 10px;

        .quantity-noti {
          background: $color-26;
          border-radius: 50%;
          padding: 2px;
          font-size: 9px;
          color: #fff;
          position: absolute;
          top: -7px;
          left: 12px;
          width: 18px;
          display: flex;
          height: 18px;
          justify-content: center;
        }

        svg {
          height: 24px;
          width: 24px;

          @media screen and (max-width: 991px) {
            margin-right: 30px;
          }
        }
      }
    }
    .name {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mq('l') {
        max-width: 100px !important;
      }
      @media screen and (max-width: 1024px) {
        max-width: 88px !important;
      }
      @include mq('sm') {
        max-width: 110px !important;
      }
    }

    svg {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
  @include mq('xl') {
    padding: 8px;
    .infor {
      font-size: 12px;
    }
  }
  @include mq('l') {
    .left {
      img {
        height: 44px;
        width: 44px;
      }
      .anticon {
        height: auto;
        width: auto;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .infor {
      & > span {
        max-width: 174px !important;
      }
    }
  }
}

.list-terms {
  &.is-mobile {
    display: none;
    @media screen and (max-width: 1023px) {
      display: block;
    }
  }
}

.list-link {
  display: flex;
  flex-wrap: wrap;
  color: #666565;
  font-size: 12px;
  @include mq('sm') {
    margin-top: 0px;
    margin-left: 0px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 0px 10px;
  }

  .link_route {
    margin-right: 15px;
  }
}
.right-copy {
  color: #666565;
  font-size: 12px;
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    padding: 0px 10px;
  }
}
