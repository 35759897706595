@import 'src/style/variables';
@import 'src/style/mixin';

.page-ranking-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  @include mq('sm') {
    margin-bottom: 24px;
  }
  &_rank {
    margin-right: 10px;
    max-width: 50px;
    min-width: 50px;
    width: 50px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $color-03;
    @include mq('sm') {
      margin-bottom: 64px;
    }
    .icon-rank-ranking-page {
      svg {
        height: 32px;
        width: 32px;
      }
    }
  }
  &_info_pc {
    display: flex;
    width: calc(100% - 60px);
    height: 171px;
    max-height: 171px;
    @include mq('sm') {
      display: none;
    }
    &-left {
      position: relative;
      width: 171px;
      height: 171px;
      min-width: 171px;
      min-width: 171px;
      margin-right: 16px;
      .video-ranking {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        z-index: 1;
        cursor: pointer;
        background-color: $color-03;
        button.button-play-custom {
          display: none;
        }
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          max-width: 100%;
          top: 0;
          left: 0;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 190px);
      .info-title {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: $color-02;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .interactive {
        display: flex;
        align-items: center;
        gap: 32px;
        @include mq("sm") {
          gap: 20px;
        }
        margin-top: 12px;
        margin-bottom: 16px;
        &-item {
          display: flex;
          gap: 4px;
          align-items: center;
          .like-icon,
          .chat-icon {
            cursor: pointer;
            color: #666565;
            svg {
              width: 20px;
              height: 20px;
            }
            &.active {
              color: $color-26;
            }
          }
          .count {
            font-size: 14px;
            font-weight: 400;
            color: #666565;
          }
        }
      }
    }
  }
  &_info_mobile {
    @media screen and (min-width: 576px) {
      display: none;
    }
    display: flex;
    flex-direction: column;
    width: calc(100% - 68px);
    &-top {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;

      &-right {
        width: calc(100% - 180px);
      }
      &-left {
        position: relative;
        width: 171px;
        height: 171px;
        min-width: 171px;
        min-width: 171px;
        margin-right: 16px;
        .video-ranking {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          z-index: 1;
          cursor: pointer;
          background-color: $color-03;
          button.button-play-custom {
            display: none;
          }
          video {
            position: absolute;
            width: 100%;
            height: 100%;
            max-width: 100%;
            top: 0;
            left: 0;
          }
        }
      }
      &-right {
        .info-title {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: $color-02;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
        }
        .interactive {
          display: flex;
          align-items: center;
          gap: 32px;
          @include mq("sm") {
            gap: 20px;
          }
          margin-top: 12px;
          margin-bottom: 16px;
          &-item {
            display: flex;
            gap: 4px;
            align-items: center;
            .like-icon,
            .chat-icon {
              cursor: pointer;
              color: #666565;
              svg {
                width: 20px;
                height: 20px;
              }
              &.active {
                color: $color-26;
              }
            }
            .count {
              font-size: 14px;
              font-weight: 400;
              color: #666565;
            }
          }
        }
      }
    }
    &-bot {
      width: 100%;
      margin-top: 16px;
    }
  }
  .info-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .info-user-avatar {
      display: flex;
      align-items: center;
      gap: 10px;
      width: calc(100% - 110px);
      .avatar {
        .img-avatar {
          width: 56px;
          height: 56px;
          border-radius: 56px;
          cursor: pointer;
        }
      }
      .name {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: calc(100% - 60px);
        .account-name {
          font-size: 16px;
          font-weight: 700;
          color: $color-03;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .account_id {
          font-size: 14px;
          font-weight: 400;
          color: $color-03;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .btn-followOrUnFollow {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 12px;
      border-radius: 6px;
      background: $color-26;
      color: $color-01;
      white-space: nowrap;
      border-radius: 6px;
      width: 100px;
      height: 36px;
      justify-content: center;
      display: flex;
      border: 1px solid $color-02;
      cursor: pointer;

      &.following {
        color: $color-02;
        background: $color-01;
        border: 1px solid #e5e6eb !important;
        border: none;
        &.hover-color {
          &:hover {
            background: #f1f1f1;
          }
        }
      }

      &.no-follow {
        color: $color-01;
        border: 1px solid #e5e6eb;
        &.hover-color {
          &:hover {
            background: rgba(255, 0, 0, 0.1);
            border: 1px solid rgba(255, 0, 0, 0.1);
            color: $color-26;
          }
        }
      }

      &.hover {
        background: #f1f1f1;
      }
    }
  }

  .lock-content-common {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    z-index: 17;

    // @include mq('sm') {
    //   margin: 0 16px;
    //   width: calc(100% - 32px);
    // }

    @include mq('xs') {
      margin: 0;
      width: 100%;
    }

    .lock-content-inner {
      background: $color-01;
      border-radius: 19px;
      width: 100%;
      max-width: 343px;
      padding: 24px 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: scale(0.9);

      .btn-membership {
        background: #dc143c;
        border-radius: 8px;
        color: #ffffff;
        &:hover {
          background-color: $color-27;
        }
      }
      svg {
        height: 32px;
        width: 32px;
      }
      ._packageNew {
        line-height: 24px;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
        margin-top: 8px;
      }

      @include mq('sm') {
        transform: scale(0.75);
        .hidden_des {
          display: none;
        }

        padding: 12px;
        width: calc(100% - 120px);
      }
      ._package {
        max-height: 91px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
          background: #e5e6eb;
        }
      }
      ._price {
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 8px;
        font-weight: 700;
        color: $color-03;
      }
      ._list-package {
        width: 100%;
      }
      .btn {
        width: 100%;
        margin-top: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        height: 32px;
        padding: 0;
      }
    }
    .mobile_3_column {
      @include mq('sm') {
        .btn-membership {
          width: 137px !important;
          height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ._packageNew {
          font-size: 12px !important;
        }
        ._price {
          font-size: 14px !important;
        }
        svg {
          margin-bottom: -7px;
        }
        height: 151px !important;
        width: 150px !important;
      }
    }
    .mobile_one_colmn {
      @include mq('sm') {
        .btn-membership {
          width: 192px !important;
          height: 40px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: -15px;
        }
        ._packageNew {
          font-size: 12px !important;
        }
        ._price {
          font-size: 14px !important;
          margin-bottom: 0px !important;
        }
        svg {
          margin-bottom: -7px;
        }
        height: 180px !important;
        width: 216px !important;
      }
    }
  }
  &.popup_lock_post {
    // min-height: 400px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
  }

  .video-custom {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // background: #000;
    aspect-ratio: 4/3;
    &:focus {
      outline: none;
    }
  }
  .popup.popup-withdraw-success.popup-error-buy-package {
    position: fixed;
  }
}
