@import 'src/style/variables';
@import 'src/style/mixin';

.view-full-image-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.8);
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  @media screen and (max-width: 640px) {
    padding: 40px 30px;
    top: 120px;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    svg {
      width: 25px;
      height: 25px;
      color: white;
    }
  }

  .pagination-slide {
    position: absolute;
    top: 10px;
    left: 30px;
    z-index: 10;
    span {
      font-size: 20px;
      color: white;
    }
    &.hidden {
      display: none;
    }
  }

  .slide-image-full-view {
    width: 100%;
    height: 100%;

    .slick-arrow {
      background: $color-26;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      @include mq('sm') {
        width: 28px;
        height: 28px;
      }
      border-radius: 50%;
      z-index: 1;
      &:before {
        display: none;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          > div {
            height: 100%;
          }
        }
      }
      .list-images-item {
        height: 100%;
        padding: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }
    .slick-dots {
      li {
        width: 50px;
        height: 50px;
        button {
          width: 50px;
          height: 50px;
          &:before {
            font-size: 20px;
            top: 15px;
            left: 15px;
            color: white;
          }
        }
      }
    }
  }

  img {
    margin: auto;
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain !important;
    background: transparent;
    @include mq('sm') {
      padding: 0 20px;
    }
    &:focus {
      outline: none;
    }
  }

  video {
    cursor: pointer;
    max-height: 90vh;
    max-width: 90vw;
    object-fit: contain !important;
    margin: auto;
    aspect-ratio: 4/3;
    &:focus {
      outline: none;
    }
  }
}
