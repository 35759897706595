@import 'src/style/variables';
@import 'src/style/mixin';

//* Screen Live Fan
#dscreen-live-fan {
  background-color: #282727;
  overflow: hidden;
}
//general
//info-idol
.info-idol {
  display: flex;
  & > div {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
  }
  .description {
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
//content-chat
.content-chat {
  overflow: hidden;
  position: relative;
  width: 100%;
  .bg-box-shadow {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(
      0deg,
      rgba($color-03, 0) 0%,
      rgba($color-03, 0.3) 25%,
      rgba($color-03, 0.6) 50%,
      rgba($color-03, 0.9) 100%
    );
    z-index: 10;
  }
  .box {
    overflow: auto;
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    // width: 100%;
    max-height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    @include mq('md') {
      padding-left: 16px;
      padding-right: 16px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .comment {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    // display: flex;
    // align-items: center;
    gap: 4px;
    display: inline-block;
    .name {
      color: #b1b6bb;
    }
    .text-live {
      color: #1a1a1a !important;
      font-weight: 400;
      .d-ib {
        display: inline-block;
      }
    }
    &.lv2,
    &.lv1,
    &.lv3 {
      line-break: anywhere;
    }
    &.lv2 {
      font-weight: 700 !important;
      font-size: 12px;
      .text-live {
        font-weight: 700 !important;
        font-size: 12px;
      }
    }
    &.lv2 .name {
      color: $color-19;
      line-break: anywhere;
    }
    &.lv1 .name {
      color: #b1b6bb;
    }
    &.lv3 {
      // background: linear-gradient(
      //   89.16deg,
      //   rgba(#43e97b, 0.8) 16.61%,
      //   rgba(#38f9d7, 0.8) 100%
      // );
      // width: max-content;
      // padding: 5px 12px;
      // border-radius: 30px;
      // color: $color-01;
      .name {
        // font-weight: bold;
        color: #28c76f;
      }
    }
  }

  .sendCoin {
    display: flex;
    align-content: center;
    padding: 2px 12px 2px 1px;
    background: linear-gradient(
      90deg,
      rgba(253, 55, 142, 0.7) 41.29%,
      rgba(253, 55, 142, 0) 100%
    );
    border-radius: 22px;
    width: max-content;
    color: $color-01;
    min-width: 200px;
    &.sendCoin_top1 {
      background: linear-gradient(
        90deg,
        rgba(220, 20, 60, 0.7) 41.29%,
        rgba(220, 20, 60, 0) 100%
      );
      .text {
        color: #fff !important;
      }
      span {
        margin-top: 3px;
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 116px;
    }
    .box-coin {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    .name,
    .text {
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    .coin {
      width: 34px;
      max-height: 34px;
    }
  }
}

//fans
.fans-live-stream-page {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $color-03;
  overflow: hidden;
  .screen-live {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100%;
    }
  }
  .screen-content {
    width: 30%;
    color: $color-01;
    .group-info {
      padding: 24px 33px 16px 16px;
      border-bottom: 1px solid $color-08;
      .box-info {
        display: flex;
        justify-content: space-between;
      }
      .number-fan-and-close {
        display: flex;
        align-items: center;
        height: max-content;
        .close-icon {
          cursor: pointer;
          svg {
            color: $color-01;
            width: 20px;
            height: 20px;
          }
        }
        .number-fan {
          background-color: $color-08;
          border-radius: 4px;
          padding: 3px 4px 3px 5px;
          height: max-content;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 22px;
          .number {
            font-size: 12px;
            line-height: 17px;
          }
          .profile-icon {
            svg {
              width: 12px;
              height: 15px;
              margin-right: 5px;
            }
          }
        }
      }
      .box-btn {
        display: flex;
        .btn {
          padding: 3px 9px;
          border-radius: 4px;
          text-align: center;
          margin-top: 11px;
          cursor: pointer;
        }
        .btn-live {
          background-color: $color-06;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
          color: $color-01;
        }
        .btn-follow {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          border: 1px solid $color-01;
          margin-left: 8px;
          color: $color-01;
        }
      }
    }
    // group chat fan
    .group-chat-box {
      margin-top: 0;
      // background-color: $color-18;
      height: calc(100% - 143px);
    }

    .group-chat-box-mobile {
      margin-top: 0;
      height: 250px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .content-chat {
        height: calc(100% - 50px);
      }
    }

    .content-chat {
      padding: 16px;
      height: calc(100% - 77px);
    }
    .group-service {
      display: flex;
      align-content: center;
      padding: 14px 24px 24px 16px;
      border-top: 1px solid $color-08;
      .form-chat {
        width: 100%;
        .form-item {
          margin-bottom: 0;
          border-radius: 6px;
          position: relative;
          display: flex;
          align-items: center;
          .anticon {
            position: absolute;
            bottom: 12.67px;
            right: 14.67px;
            z-index: 3;
            cursor: pointer;
            color: $color-14;
            &.active {
              color: $color-06;
            }
            svg {
              width: 16.67px;
              height: 16.67px;
            }
          }
        }
        input {
          background: $color-18;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          border: none;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 9px 12px;
          padding-right: 35px;
          z-index: 2;
          height: 43px;
          color: $color-01;
          border: 1px solid $color-01;
          border-radius: 22.5px;
          width: 100%;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            color: #b1b6bb;
            opacity: 0.8;
            text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.15);
          }
        }
      }
      .img-gift {
        width: 44px;
        height: 44px;
        margin: 0 16px 0 24px;
        cursor: pointer;
        user-select: none;
      }
      .box-live {
        display: flex;
        flex-direction: column;
        .number-like {
          font-size: 14px;
          line-height: 20px;
          text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
          user-select: none;
        }
        .like-icon {
          cursor: pointer;
          svg {
            // width: 20px;
            // height: 23px;
          }
          &.active {
            color: $color-06;
          }
        }
      }
    }
  }
}

@include mq('l') {
  .fans-live-stream-page {
    .description {
      width: 120px !important;
    }
    .screen-live {
      width: 70%; // 60%;
    }
    .screen-content {
      width: 30%; // 40%;
      .group-info {
        border-bottom: none;
        padding: 24px 16px 16px 16px;
      }
    }
  }
}

@include mq('md') {
  .fans-live-stream-page {
    background-color: transparent;
    .description {
      width: 190px !important;
    }
    .screen-live {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .screen-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      .group-info {
        border-bottom: none;
        padding: 24px 16px 16px 16px;
      }
      .group-chat-box {
        background-color: transparent;
        // height: 350px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 16px;
      }
      .content-chat {
        padding: 0 16px;
        // height: 50%;
        max-height: 35%;
        width: auto;
        &.landscape {
          max-height: 45%;
        }
        &.portrait {
          max-height: 100%;
        }
      }

      .group-chat-box-mobile {
        .content-chat {
          max-height: 50%;
          &.landscape {
            max-height: 45%;
          }
          &.portrait {
            max-height: 100%;
          }
        }
      }

      .group-service {
        border-top: none;
        padding-top: 0;
        padding-bottom: 0;
        .form-chat {
          input {
            background: transparent;
          }
        }
      }
    }
  }
}

@include mq('sm') {
  .fans-live-stream-page {
    .screen-content {
      .number-fan {
        background-color: rgba($color-03, 0.6) !important;
      }
    }
  }
}

@include mq('smHeight') {
  .fans-live-stream-page {
    .screen-content {
      .group-service {
        align-items: flex-end;
      }
    }
  }
}

//------------------CREATOR------------------------------
.creator-livestream {
  // padding: 24px;
  @media screen and (min-width: 576px) and (min-height: 450px) {
    padding: 0 !important;
  }
  height: 100%;
  // general
  .button {
    height: 44px;
    border: 1px solid $color-14;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
  }
  .bg-live {
    background-color: $color-03;
    position: relative;

    // & > div:first-child {
    //   // height: 520px !important;
    //   height: 480px;
    // }
  }
  .bg-box-shadow {
    display: none;
  }
  .screen-live {
    position: relative;
    .bg-content {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;
      height: 100%;
    }
    .group-info {
      padding: 24px 16px 0 16px;
      color: $color-01;
      .box-btn {
        margin-top: 8px;
        gap: 11px;
        .btn {
          padding: 3px 5px;
          border-radius: 4px;
          cursor: pointer;
          background: rgba($color-03, 0.6);
          svg {
            color: $color-14;
          }
          .number {
            color: $color-01;
            margin-left: 7px;
          }
        }
        .btn-live {
          background-color: $color-06;
          font-weight: bold;
          font-size: 14px;
          line-height: 21px;
        }
        .btn-follow {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          border: 1px solid $color-01;
          margin-left: 8px;
          color: $color-01;
        }
      }
      .group-button-right {
        gap: 8px;
        .group {
          gap: 8px;
          margin-left: auto;
          @media screen and (max-width: 575px) {
            margin-left: 0;
            margin-right: auto;
          }
        }
        .btn {
          cursor: pointer;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          border-radius: 6px;
        }
        .group1 {
          gap: 8px;
          .btn {
            padding: 6px 0;
            border: 1px solid $color-14;
            width: 89px;
            background: $color-01;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .group2 {
          .btn {
            padding: 6px 12px;
            // background-color: rgba($color: $color-03, $alpha: 0.6);
            color: $color-01;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
    // group chat
    .group-chat-box {
      margin-top: 34px;
      height: 50%;
      display: flex;
      align-items: flex-end;
    }
    .content-chat {
      padding: 0 24px;
      height: 100%;
      max-height: 80%;
      @include mq('md') {
        padding-left: 16px;
        padding-right: 16px;
        max-height: 50%;
      }
    }

    // .box-button {
    //   gap: 12px;
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   bottom: 16px;
    //   span {
    //     width: 44px;
    //     height: 44px;
    //     cursor: pointer;
    //   }
    // }
    .box-button-lstream {
      gap: 16px;
      position: absolute;

      span {
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
    }

    .box-button-pc {
      // bottom: 15px;
      bottom: -64px;
      right: 0px;
    }

    .box-button-horizontal {
      margin-left: 15px;
    }

    .box-button-vertical {
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      &.en {
        bottom: 84px;
      }
    }

    .group-service {
      display: flex;
      align-content: center;
      padding: 12px 24px 0 24px;

      .form-chat {
        position: absolute;
        bottom: 76px;
        // width: 100%;
        left: 24px;
        right: 24px;
        @include mq('md') {
          left: 16px;
          right: 16px;
        }
        // padding-right: 36px;
        .form-item {
          margin-bottom: 0;
          border-radius: 6px;
          position: relative;
          display: flex;
          align-items: center;
          .anticon {
            position: absolute;
            bottom: 12.67px;
            right: 12.67px;
            @media screen and (max-width: 575px), (max-height: 450px) {
              right: 64.67px;
            }
            z-index: 3;
            cursor: pointer;
            color: $color-14;
            &.active {
              color: $color-06;
            }
            svg {
              width: 16.67px;
              height: 16.67px;
            }
          }
          @media screen and (max-width: 575px), (max-height: 450px) {
            .send_effect_creator {
              width: 44px;
              height: 44px;
              background-color: #fff;
              right: -4px;
              z-index: 5;
              img {
                width: 28px;
                height: 28px;
              }
            }
          }
        }
        input {
          background: rgba($color: $color-02, $alpha: 0.1);
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          border: none;
          box-sizing: border-box;
          padding: 9px 12px;
          padding-right: 35px;
          z-index: 2;
          height: 43px;
          color: $color-01;
          border: 1px solid $color-01;
          border-radius: 22.5px;
          width: 100%;
          @media screen and (max-width: 575px), (max-height: 450px) {
            width: calc(100% - 50px);
          }
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-size: 14px;
            line-height: 20px;
            color: $color-14;
            opacity: 0.8;
            text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
    .group-service.group-service-horizontal {
      display: flex;
      width: 100%;
      position: absolute;
      bottom: 14px;
      .form-chat {
        position: relative;
        padding-right: 20px;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        .form-item {
          width: 100%;
        }
      }
      .box-button-horizontal {
        position: relative;
        width: auto;
        padding-right: 0;
        min-width: 320px;
      }
    }
  }
  .content-chat .box {
    overflow: auto;
    position: absolute;
    padding-top: 10px;
    padding-bottom: 5px;
    // bottom: 25px;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@include mq('l') {
  .creator-livestream {
    .group-info {
      padding-top: 16px;
    }
    .screen-live {
      .group-chat-box {
        .content-chat {
          // padding-left: 16px;
        }
      }
      .group-service {
        padding-top: 0;
        padding-left: 16px;
      }
    }
  }
}

@include mq('md') {
  .creator-livestream {
    padding: 0;
    height: calc(100% - 64px);
    .screen-live {
      height: 100%;
      .bg-live {
        padding-bottom: unset;
        height: calc(100%);
        & > .fullscreen {
          height: 100% !important;
        }
        // & > div:first-child {
        //   height: 100% !important;
        // }
      }
      .group-chat-box {
        margin-top: 20px;
        height: calc(100% - 245px);
      }
    }

    .group.group1 {
      gap: 15px;
      width: calc(100% - 32px);
      @include mq('sm') {
        gap: 10px;
        width: calc(100% - 16px);
      }
      .btn {
        cursor: pointer;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        border-radius: 6px;
        padding: 6px 0;
        border: 1px solid $color-14;
        background-color: $color-01;
        width: calc(33% - 14px);
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq('sm') {
          font-size: 11px;
          width: calc(33% - 10px);
        }
      }
    }
  }
}

@include mq('sm') {
  .creator-livestream {
    height: calc(100%);
    .box-popup-setting-mobile {
      position: absolute;
      bottom: 24px;
      left: 8px;
      // left: 50%;
      // transform: translateX(-50%);
      padding: 0 !important;
    }
    .box-btn .btn,
    .group-button-right .btn {
      background-color: $color-08 !important;
    }
    .screen-live {
      .group-chat-box {
        height: calc(100% - 340px);
        margin-top: 20px;
      }
      .group1 .btn {
        color: $color-01;
      }
      .group-info {
        padding-top: 10px !important;
      }
      .group2 {
        margin-top: 8px;
        // flex-direction: column;
        width: max-content;
      }
      .comment {
        line-height: 20px !important;
        margin-top: 6px !important;
      }
      .box-button.box-button-vertical {
        bottom: 70px !important;
        gap: 12px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      .form-chat {
        bottom: 127px !important;
        &.en {
          bottom: 136px !important;
        }
      }
    }
  }
}

@include mq('smHeight') {
  .creator-livestream {
    height: calc(100%);
    .box-popup-setting-mobile {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 !important;
    }
    .box-btn .btn,
    .group-button-right .btn {
      background-color: $color-08; // !important;
    }
    .screen-live {
      .group-button-right {
        gap: 8px;
        .group {
          gap: 14px;
        }
      }
      .bg-live {
        height: 100%;
      }

      .group-chat-box {
        height: calc(100% - 170px);
        margin-top: 20px;
        @include mq('sm') {
          height: calc(100%);
          margin-top: 20px;
          align-items: flex-start;
          .content-chat {
            max-height: 135px;
            .box {
              bottom: 50px;
              top: 0;
              @include mq('xsHeight') {
                bottom: 88px;
              }
              @include mq('minHeight') {
                display: none;
              }
            }
          }
        }
      }
      .group1 .btn {
        // color: $color-01;
      }
      .group-info {
        padding-top: 10px !important;
      }
      .group2 {
        margin-top: 8px;
        width: max-content;
      }
      .comment {
        line-height: 20px !important;
        margin-top: 6px !important;
      }

      .box-button.box-button-horizontal {
        padding-right: 0 !important;
        left: unset;
        transform: unset;
        bottom: 0;
        padding-left: 24px;
      }
    }
  }
}

.creator-livestream.screenHorizontal {
  height: 100%;
  .screen-live {
    height: 100%;
  }
}

.creator-livestream {
  height: 100%;
  .screen-live {
    height: 100%;
    &.screen-live-pc {
      // width: 100%;
      // padding-top: 75%;
      // height: auto;
      // margin: 0 auto;

      position: relative;
      width: 640px;
      height: 480px;

      .bg-live {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .fullscreen {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          #local-player {
            video {
              // object-fit: unset !important;
            }
          }
        }
      }
    }
  }
}

#local-player {
  video {
    // object-fit: unset !important;
  }
}

.stamps-display {
  // width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.lvvs-copy-link-creator {
  .copy-success {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $color-01;
    left: 20%;
    top: -20px;
    transform: translate(-50%, -50%);
    padding: 6px 12px;
    background: $color-03;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 21;
  }
}

.btn-twitter-follow {
  color: #282828;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border-radius: 6px !important;
  width: 98px;
  height: 32px;
  text-align: center;
  padding: 0px 7px;
  white-space: nowrap;
  border: 1px solid #b1b6bb;
  background-color: $color-01;
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  margin: 5px;
  .anticon {
    color: #55adef;
    margin-right: 6px;
    svg {
      width: 18px;
      height: 14px;
    }
  }
}
.screen-live-guest {
  img {
    height: auto !important;
  }
}
