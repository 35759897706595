@import 'src/style/variables';
@import 'src/style/mixin';

.fansite-form-input {
  &__controls {
    margin-bottom: 24px;
    input,
    textarea,
    select {
      color: $color-03;
    }
    .text-count-length {
      white-space: nowrap;
      display: flex;
      margin-left: auto;
    }
  }
  &__label.form-label {
    font-size: s(14);
    line-height: 1.4;
    // color: $color-gray-dark;
    font-weight: 500;
    margin-bottom: 12px;
    display: flex;
    color: #1A1A1A;

    @media (max-width: 576px) {
        display: block !important;
    }
  }

  &__required {
    font-size: s(14);
    line-height: 1;
    color: $color-13;
    margin-left: 0px;
  }
  &__text.form-control {
    padding-left: 8px;
    padding-right: 8px;
    font-size: s(14);
    height: 40px;
    &:focus {
      box-shadow: none;
    }

    &.--textarea {
      min-height: 130px;
      line-height: (19.6 / 14);
      padding-top: 10px;
      height: auto;
      &-small {
        min-height: 82px;
        height: 82px;
      }
    }
    &.--contenteditable {
      height: 130px;
      overflow: auto;
    }

    &.is-invalid {
      background-color: $color-06;
      background-image: none;
      padding-right: 8px;
    }
  }

  &__error {
    margin-top: 12px;
    font-size: s(14);
  }
}

.uploaded {
  padding: 6px 16px;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #B7EB8F;
  background: #F6FFED;
  color: #52C41A;
  font-weight: 500;
}

.asterisk {
  color: red;
  margin-left: 2px;
}

@include mq('sm') {
  .fansite-form-input {
    &__error {
      margin-top: 12px;
      font-size: 10px !important;
    }
  }
}
