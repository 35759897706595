@import 'src/style/variables';
@import 'src/style/mixin';

//detail
.detail-post-sale-mode2-header {
  .shimmer-mode-2 {
    padding: 24px;

    @include mq('sm') {
      padding: 16px;
    }
  }
}

.block-require {
  display: flex;
  .mg-left{
    margin-left: 30px;
    @include mq('sm') {
      margin-left: 0px;
    }
  }
  @include mq('sm') {
    flex-direction: column;
  }
}
.fansite-creator-post-detail {
  background-color: $color-01;
  padding: 19px 24px 50px 24px;
  .account {
    &__creator {
      padding: 0 0 16px 0;
      width: 100%;
      align-items: flex-start;
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        margin-right: 8px;
      }
      .content1 {
        margin-top: 2px !important;
        color: $color-03 !important;
      }
    }
  }
  .content-item {
    padding: 0px 16px 16px 16px;
  }
  .content {
    overflow: hidden;
    &--title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &--text {
      font-weight: 500;
      margin-bottom: 16px;
    }
  }
  .interactive {
    margin: 20px 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    color: $color-08;
    .left,
    .item {
      display: flex;
      align-items: center;
    }
    .item {
      .number {
        margin-left: 6px;
      }
      & + .item {
        margin-left: 33.67px;
      }
    }
    .like-icon,
    .chat-icon,
    .share-icon {
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .like-icon {
      &.active {
        color: $color-06;
      }
    }
  }

  .comment-wrap {
    .list_comment_detail {
      .list-comment {
        .infinite-scroll-component {
          .comment-item-wrapper {
            .comment-item {
              .form-edit-comment {
                form {
                  .form-item {
                    textarea {
                      background-color: #ffffff;
                      border: 1px solid #e5e6eb;
                    }
                  }
                }
              }

              .content {
                .reply-comment {
                  .comment-item-reply {
                    .form-edit-comment {
                      form {
                        .form-item {
                          textarea {
                            background-color: #ffffff;
                            border: 1px solid #e5e6eb;
                          }
                        }
                      }
                    }
                  }
                }

                .form-reply-comment {
                  .form-item {
                    textarea {
                      background: #ffffff;
                      border: 1px solid #e5e6eb;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    img {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      min-width: 44px;
      border: none;
      margin-right: 12px;
    }
    form {
      width: 100%;
      .form-item {
        margin-bottom: 0;
        background: #ffffff;
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: flex-end;
        textarea {
          background: #ffffff;
          border: 1px solid #e5e6eb;
        }
        .anticon {
          position: absolute;
          bottom: 13.67px;
          right: 10.67px;
          z-index: 3;
          cursor: pointer;
          color: $color-14;
          &.active {
            color: $color-06;
          }
          svg {
            width: 16.67px;
            height: 16.67px;
            //transform: rotate(45deg);
          }
        }
      }
      textarea {
        background: $color-07;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: none;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 38px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }

  .video-detail {
    width: 100%;
    margin-top: 16px;
    max-width: 500px;
    max-height: unset;
  }

  .list-images {
    margin-top: 16px;
    max-width: 500px;
  }

  .slick-arrow {
    background: #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &::before {
      display: none;
    }
    svg {
      background: transparent;
    }
    &.slick-next {
      right: 10px;
    }
    &.slick-prev {
      left: 10px;
    }
  }
}

.creator-post-normal-detail.fansite-creator-post-detail {
  .target,
  .date {
    display: flex;
    align-items: flex-start;
  }
  .content--title {
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .text1 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-08;
    margin-right: 3px;
    white-space: nowrap;
  }
  .avatar-infor.avatar-infor-creator {
    .content-left {
      span {
        color: $color-03 !important;
      }
    }
  }
  @include mq('md') {
    .list-images.custom-creator {
      max-width: 500px;
      .list-images img,
      .list-images video {
        max-height: 500px;
      }
    }
  }
  @include mq('sm') {
    padding: 16px;
    .list-images.custom-creator {
      max-width: 100%;
      .list-images img,
      .list-images video {
        max-height: 500px;
      }
    }
    .img_no_water_mark {
      max-width: 100%;
    }
  }
}

.creator-post-content-detail.fansite-creator-post-detail {
  .content-image {
    padding: 0px 16px;
    .video-detail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      padding: 0 !important;
      margin: 0 !important;
    }
    .list-img-detail {
      width: 100%;
      aspect-ratio: 16/9;
      position: relative;
      .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide {
            div {
              position: relative;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              .list-images-item {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
              }
            }
          }
        }
      }
      .slick-slider.list-images.custom-creator.false.slick-initialized {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
      }
    }
    .file-zip {
      max-height: 116px;
      width: 100%;
      height: 116px;
      overflow: hidden;
      position: relative;
      background: #f7f8fa;
      border-radius: 8px;
      &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 11.3px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 16px;
        &.is-pointer {
          cursor: pointer;
        }
        .file_name {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #1480ff;
        }
      }
    }
  }
  textarea#textarea-post-comment {
    height: 44px;
  }
  .content-interactive {
    padding: 0px 16px;
  }
  .avatar {
    .content-left {
      display: flex;
      flex-direction: row;
      margin-top: 0px;
    }
  }
  .content.block {
    position: relative;
    margin: 24px 24px 20px;
    @include mq('sm') {
      margin: 0px;
    }
  }
  .title-page {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 24px;
  }
  .link-edit-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
    .link-edit,
    .btn-change-mode {
      cursor: pointer;
      .account--icon {
        margin-top: -4px;
        margin-right: 6px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
      padding: 5px;
      background: $color-06;
      border: 1px solid $color-06;
      border-radius: 6px;
      color: $color-01;
      width: 98px;
      height: 32px;
    }
    .btn-change-mode {
      width: unset;
      min-width: 98px;
      margin-left: 12px;
    }
  }

  .image-thumbnail {
    max-width: 500px;
    width: 500px;
    height: 375px;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      &.img_thumbnail {
        aspect-ratio: 4 / 3;
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
    &.bg-zip {
      // background: $color-02;
      @include mq('sm') {
        width: 100%;
        height: auto;
      }
    }
  }

  .video-react-poster {
    background-size: contain !important;
  }

  .video-detail,
  .image-thumbnail,
  .list-img-detail,
  .file_zip,
  .btn-open-trailer {
    margin-left: auto;
    margin-right: auto;
  }

  &.post-sale-mode-2 {
    .post-mode-2-video {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;
      margin: 16px 0;
      .video-react.video-detail.main_video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        padding-top: 0px !important;
        left: 0;
        .video-react-video {
          border-radius: 8px;
        }
        .video-react-poster {
          border-radius: 8px;
        }
      }
    }
    @include mq('sm') {
      padding: 0;
    }
    .btn-open-trailer {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #1480ff;
      max-width: 500px;
      margin-top: 0;
      cursor: pointer;
    }

    .show-video-sample {
      height: 54px;
      background-color: $color-33;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .content--title {
    margin-top: 16px;
    position: relative;
  }
  .package,
  .number-part,
  .date,
  .number_premium_ticket,
  .content-left {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .text {
      overflow: hidden;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }
  .text1 {
    font-weight: 700;
  }
  .content-left {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    .price {
      margin-top: 6px;
      color: $color-06;
    }
  }

  .interactive {
    justify-content: space-between;
  }

  .file_zip {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .anticon {
      margin-right: 10px;
      color: #787880;
    }
    .file_name {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $color-04;
    }
  }

  .list-img-detail {
    margin-top: 16px;
    &.mode2 {
      width: 100%;
      padding: 0 16px;
    }
    .list-images-item {
      padding-bottom: 0px;
    }
  }

  .list-fan-buy-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-04;
    padding: 16px 0;
    border-top: 1px solid $color-07;
    border-bottom: 1px solid $color-07;
    margin-top: 16px;
    cursor: pointer;
  }
}

@include mq('sm') {
  .creator-post-content-detail.fansite-creator-post-detail {
    padding: 16px;
    .list-img-detail {
      max-width: 100%;
    }
    .video-detail,
    .image-thumbnail,
    .image-thumbnail,
    .list-img-detail {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
    // .video-react.video-detail.main_video {
    //   padding-top: 100% !important;
    // }
    .list-img-detail {
      .list-images-item {
        padding-bottom: 100%;
      }
    }
  }
}

.fansite-creator-post-detail.creator-post-normal-detail.creator-post-short-detail {
  .box-item,
  .video-details {
    min-width: 500px;
    max-width: 500px;
    max-height: 750px;
  }
  .video-react {
    padding-top: 0 !important;
    height: 100%;
  }

  video {
    width: 100%;
    position: relative;
    aspect-ratio: 2/3;
  }
}

.post-content-sale-view {
  .btn-open-trailer {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1480ff;
    max-width: 500px;
    margin-top: 0;
    cursor: pointer;
  }

  .show-video-sample {
    margin: 29px 0;
    display: flex;
    justify-content: center;
  }
}

.disable-comment-post-mode-2 {
  textarea {
    background-color: #efefef !important;
  }
}