@import 'src/style/variables';
@import 'src/style/mixin';

.survey-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.survey-container {
  padding: 24px;
  @include mq('sm') {
    padding: 16px;
  }
  &-header {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    margin-bottom: 16px;

    @include mq('sm') {
      margin-bottom: 8px;
    }
  }
  .notification-title {
    margin-bottom: 16px;
  }
  &_list {
    .survey-item {
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      padding-bottom: 8px;
      &_info {
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;
        flex: 1;
        max-width: 60%;

        p {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &_right {
          margin-top: 6px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &-title {
            color: #1a1a1a;
          }
          &-description {
            color: #666565;
          }

          // @include mq("sm") {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 220px;
          // }
        }
      }
      &_action {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 160px;

        @include mq('sm') {
          width: auto;
        }
        &_up-time {
          font-weight: 400;
          font-size: 12px;
          color: #282727;
          position: relative;
          top: 10px;
        }
        &_down {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
        .btn {
          width: 75px;
          height: 32px;
          font-weight: 700;
          font-size: 12px;

          @include mq('sm') {
            width: 60px;
          }
        }
        .btn-edit {
          background-color: $color-26;
          color: #ffffff;
          border: 1px solid $color-26;
          border-radius: 6px;
          &:hover {
            background-color: $color-27;
          }
        }
        .btn-cancel {
          background: #ffffff;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          color: #1a1a1a;
          &:hover {
            background: #f1f1f1;
          }
        }
      }
    }
  }
  &_empty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    gap: 10px;
    &-text {
      text-align: center;
      color: #b1b6bb;
    }
  }
  .survey-add-new {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px auto;
    button {
      border: 1px solid $color-26;
      border-radius: 6px;
      color: $color-26;
      font-size: 12px;
      font-weight: 700;
      padding: 6px 12px;
      &:hover {
        background-color: $color-26;
        color: $color-01;
      }
    }
  }
}
