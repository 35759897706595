.search-page {
  background-color: #f8f8f8;
  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    .item {
      flex: 1;
      color: #787880;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
    .active {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff0000;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 2px solid #ff0000;
      height: 100%;
    }
  }
  .body {
    background-color: #fff;
    min-height: 300px;
    position: relative;
    .form-search {
      padding: 12px 16px;
      .form-item {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 6px;
        height: 44px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8.3px 0 8.7px 14px;
      }
      .anticon svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      input {
        border: none;
        background-color: transparent;
        margin-left: 6.7px;
        width: 100%;
        padding-right: 10px;
        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
    .list {
      // display: flex;
      // align-items: center;
      min-height: calc(100% - 70px);
      padding: 16px;
      // overflow: scroll;
      .item {
        height: 60px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        img {
          max-height: 60px;
          max-width: 60px;
          min-height: 60px;
          min-width: 60px;
          object-fit: unset;
          border-radius: 50%;
          margin-right: 16px;
        }
        .name {
          display: flex;
          align-items: center;
          color: #282727;
          font-size: 14px;
          font-weight: 700;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          line-height: 20px;
          max-height: 20px;
          -webkit-line-clamp: 1;
          max-width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .title {
          display: flex;
          align-items: center;
          color: #282727;
          font-size: 14px;
          font-weight: 400;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;

          max-height: 44px;
          -webkit-line-clamp: 2;
        }
      }
    }
    .no-result {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100% - 70px);
      width: 100%;
      color: #b1b6bb;
      font-size: 12px;
      padding: 50px 0;
    }
    .list-btn {
      display: flex;
      float: none;
      overflow-x: scroll;
      white-space: nowrap;
      font-size: 12px;
      font-weight: bold;
      padding: 12px 16px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #282727;
        text-align: center;
        border: 1px solid #eeeeee;
        border-radius: 6px;
        margin-right: 8px;
        height: 40px;
        min-width: 140px;
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      .selected {
        border: 1px solid #ff0000 !important;
        background-color: #fff;
        color: #ff0000 !important;
      }
      :last-child {
        margin-right: 0px;
      }
      &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
        border-top: 7px #b1b6bb solid;
        border-bottom: 7px #b1b6bb solid;
      }
    }
  }
}
