@import 'src/style/variables';
@import 'src/style/mixin';

@mixin border-grey {
  border: 1px solid #b1b6bb;
}

@mixin mobile {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin flex($horizontal: center, $vertial: center) {
  display: flex;
  align-items: $vertial;
  justify-content: $horizontal;
}

@mixin live-btn {
  border-radius: 20px;
  background: linear-gradient(90deg, #dc143c 0%, #e9367c 100%);
  padding: 2px 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 2;
  text-transform: uppercase;
  transform: translate(-50%, 50%);
  line-height: 21px;
  width: 60px;
  height: 32px;
  @include mq('sm') {
    width: 44px;
    height: 20px;
  }
  @include flex();
}

.view-creator--home-page {
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .cls-title-2nd5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 4px;
    color: #666565;
    overflow: hidden;
    overflow: hidden;
    cursor: pointer;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .shimmer-loading {
    padding: 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
  .home-info-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: 23px;
    @include mq('sm') {
      margin-top: 0px;
    }
    @include mq('md') {
      margin-top: 0px;
    }
    .header-short {
      margin-bottom: 0;
      height: 20px;
      display: none !important;
      position: relative;
      height: 120px;
      width: 100%;
      @include mq('sm') {
        display: block !important;
        .layout-header {
          height: 60px;
        }
      }
      @media screen and (max-width: 991px) {
        display: none !important;
      }
    }

    .home-background-image {
      background-color: $color-07;
      // height: 200px;
      aspect-ratio: 2/1;
      overflow: hidden;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      position: relative;
      margin-top: -24px;
      cursor: pointer;
      @include mq('sm') {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-top: -40px;
      }
      @include mq('md') {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        margin-top: -40px;
      }
      @media screen and (max-width: 375px) and (max-height: 812px) {
        margin-top: -40px !important;
      }
      @include flex();
      @include mq('sm') {
        height: 100%;
      }
      img {
        object-fit: contain;
      }
      .bg-icon {
        width: 53px;
        height: 53px;
      }
      .bg-image {
        height: 100%;
        object-fit: cover;
      }
    }

    .img-and-button-wrapper {
      position: relative;
      display: flex;
      // justify-content: flex-end;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 13px;
      @include mq('sm') {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 5px;
      }
    }

    .img-and-button-wrapper.creator {
      padding-bottom: 10px;

      @include mq('sm') {
        padding-bottom: 0px;
      }
    }

    .home-avatar-image {
      border-radius: 50%;
      transform: translateY(-50%);
      border: #ffff 4px solid;
      position: absolute;
      top: 0;
      left: 20px;
      z-index: 19;
      @include mq('sm') {
        left: 12px;
      }
      &.border-red {
        border-color: #e9367c;
      }
      .live-btn {
        @include live-btn;
      }

      .avatar {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        margin: auto;
        background: $color-07;
        @include flex();
        @include mobile {
          width: 120px;
          height: 120px;
        }
      }

      .bg-icon-avatar {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
      }
    }

    .control-group {
      &_spacign {
        gap: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
        flex-wrap: wrap;
        margin-top: -5px;
        @include mq('sm') {
          margin-top: 0px;
        }
      }

      button {
        font-size: 12px;
        font-weight: 600;
        border: unset;
      }

      .edit-button {
        border-radius: 6px;
        background-color: #dc143c;
        width: 96px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        @include mq('sm') {
          width: 83px;
          height: 36px;
        }
        &:hover {
          background-color: #CE1338;
        }
      }

      .share-button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        @include mq('sm') {
          width: 36px;
          height: 36px;
        }
        // background: $color-01;
        @include flex();
      }

      .report-button {
        width: 80px;
        color: #282727;
        background-color: transparent;
        border: 1px solid #b1b6bb;
      }

      .report-button.follow.active {
        color: $color-01;
        background-color: $color-06 !important;
        border-color: $color-06;
      }

      .btn-followOrUnFollow {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        padding: 6px 12px;
        border-radius: 6px;
        //background: $color-01;
        background: $color-26;
        color: $color-01;
        height: max-content;
        white-space: nowrap;
        border-radius: 6px;
        width: 100px;
        height: 40px;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid $color-02;
        cursor: pointer;

        &.following {
          color: $color-02;
          //background: $color-26;
          background: $color-01;
          border: 1px solid #e5e6eb !important;
          border-radius: 4px;
          font-weight: 700;
          border: none;
        }

        &.no-follow {
          width: auto;
          height: 40px;
          //color: $color-03;
          color: $color-01;
          border: 1px solid #e5e6eb;
          @include mq('sm') {
            height: 36px;
          }
          @media (min-width: 992px) {
            &:hover {
              //background: #f1f1f1 !important;
              background: rgba(255, 0, 0, 0.1);
              border: 1px solid rgba(255, 0, 0, 0.1);
              color: $color-26;
            }
          }
        }

        &.hover {
          background: #f1f1f1;
          //border: 1px solid rgba(255, 0, 0, 0.1);
          //color: $color-26;
          //font-weight: 700;
        }
      }

      .follow-button {
        width: 77px;
        height: 36px;
        background-color: #fff;
        border: 1px solid #e5e6eb;
        color: #000;
        &.hover {
          background: rgba(255, 0, 0, 0.1);
          border: 1px solid rgba(255, 0, 0, 0.1);
          color: $color-26;
          font-weight: 700;
        }
      }
      .follow-button.active {
        width: 100px;
        height: 36px;
        color: $color-01;
        background-color: #dc143c !important;
        border-color: #dc143c;
      }
    }

    .rank-button {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      @include mq('sm') {
        width: 36px;
        height: 36px;
      }
      border: unset;
      @include flex(center, center);
      .anticon {
        svg {
          width: 25px;
          height: 25px;
        }
      }
      &.disabled {
        background-color: #eee;
        color: $color-08;
      }
      &.twitter-follow {
        width: unset;
        margin-left: 24px;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        padding-left: 10px;
        padding-right: 15.5px;
        .anticon {
          color: #55adef;
          margin-right: 11.5px;
          svg {
            width: 18px;
            height: 14px;
          }
        }
      }
    }

    .message-button {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: unset;
      @include mq('sm') {
        width: 36px;
        height: 36px;
      }
      @include flex(center, center);

      .anticon {
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .name-wrap {
      margin-left: 18%;
      width: 40%;
      margin-top: -10px;
      @include mq('sm') {
        margin-top: 0px;
        //margin-top: 35px;
        margin-left: 16px;
        width: 80%;
      }
      // @include mq('md') {
      //   margin-top: 35px;
      // }
      @media screen and (min-width: 1024px) and (max-width: 1366px) {
        margin-left: 106px;
        margin-top: 0px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        margin-left: 20%;
        margin-top: 0px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1280px) {
        margin-left: 20%;
        margin-top: 0px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1024px) {
        margin-left: 4%;
        margin-top: 0px;
      }
      .full-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        max-width: 266px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .japan-name {
        font-size: 12px;
        margin-bottom: 8px;
        color: #666565;
        font-weight: normal;
        max-width: 266px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .name-title-account {
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: -55px;
      }
    }
    .react-wrap {
      margin-left: auto;
    }
    &__body-part {
      .dp-flex-home-page {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }

      .dp-colurm-home-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .home-infor-header_infomation {
        display: flex;
        justify-content: center;
        column-gap: 60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 20px;
        @include mq('sm') {
          column-gap: 20px;
          margin-top: 10px;
        }
        @include mq('xs') {
          column-gap: 10px;
          margin-top: 10px;
        }
        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .column.follow {
          cursor: pointer;
        }
        .column.followers {
          cursor: pointer;
        }
        .column.total_posts {
          pointer-events: none;
        }
        .column.limiteded {
          pointer-events: none;
        }
      }

      .highlight-wrap {
        margin-top: 30px;

        .title-highlight {
          font-weight: 500;
          font-size: 16px;
          line-height: 29px;
          margin: -5px 0px 20px 5px;
        }

        .block-tags {
          margin-top: 0px;
          margin-bottom: 30px;
          flex-wrap: nowrap;
          width: 100%;
          overflow-x: auto;
          display: flex;
          flex-wrap: nowrap;
          gap: 24px;
          padding-bottom: 8px;
          color: #1a1a1a;
          &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
            height: 1px;
            background: $color-08;
            border-radius: 10px;
            border-top: 7px #b1b6bb solid;
            border-bottom: 7px #b1b6bb solid;
          }
          &::-webkit-scrollbar {
            height: 6px;
          }
        }

        .dp-flex {
          display: flex;
        }
        .left {
          width: 15%;
          position: relative;
          border-right: 1px solid #efefef;
          margin-right: 20px;
          padding-right: 10px;
          img {
            border-radius: 50%;
            width: 78px;
            height: 78px;
            border: none;
            object-fit: cover;
          }
          .anticon {
            position: absolute;
            bottom: 0;
            right: 15px;
            width: 20px;
            height: 20px;
            z-index: 2;
            cursor: pointer;
          }
        }
      }

      .introduce {
        color: #787880;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .info-label {
        color: #1a1a1a;
        font-size: 16px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include mq('sm') {
          font-size: 14px;
        }
      }

      .info-value {
        font-size: 24px;
        margin-top: 8px;
        color: $color-26;
        font-weight: 700;
        @include mq('sm') {
          font-size: 20px;
        }
      }

      .subscribe-btn {
        background: #28c76f;
        padding: 9px 12px;
        font-size: 14px;
        font-weight: 500;
        height: 44px;
        &:hover {
          background: #11B259 !important;
        }
      }
    }
    @include mq('sm') {
      .avatar,
      .avatar-home {
        max-width: 77px;
        max-height: 77px;
        min-width: 77px;
        min-height: 77px;
      }
    }
  }

  .tabs-footer {
    margin-left: 0 !important;
  }
  .infinite-scroll-component {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}
.view-creator--fan.view-creator--home-page {
  .back-to-page .title {
    max-width: calc(375px - 100px);
  }
  @include mq('sm') {
    width: 100%;
    .box-content {
      width: 100%;
    }
  }

  .home-avatar-image {
    > .avatar {
      width: 77.28px;
      height: 77.28px;
    }
  }

  .home-info-header {
    .img-and-button-wrapper {
      padding-bottom: 12px;
    }
    .home-background-image {
      height: 200px;
      img {
        object-fit: contain;
      }
      @include mq('md') {
        height: 360px;
        img {
          object-fit: unset;
        }
      }
      @include mq('sm') {
        height: 100%;
        img {
          object-fit: unset;
        }
      }
    }
  }

  .home-page-content .content-tab {
    color: #b1b6bb;
  }

  .card-item--detail {
    .bg-icon {
      width: 20px;
    }
  }

  .list-images {
    img,
    video {
      background-color: transparent !important;
    }
  }

  .list-images.my-page-view img,
  .list-images.my-page-view video {
    object-fit: cover;
    height: 500px;
    padding: 0 10px;
  }

  .home-quick-views-container img {
    object-fit: cover;
  }

  .home-info-header__body-part {
    width: 100% !important;
    .quick-views > div {
      background-color: #eeeeee;
    }
  }

  .control-group {
    button {
      height: 32.1px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .row-fan--creator {
    div {
      &:nth-child(1),
      &:nth-child(2) {
        width: 120px !important;
      }

      &:nth-child(3) {
        width: 100px !important;
        button {
          padding: 0;
        }
      }
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    justify-content: center;
  }

  .list-images-item {
    max-height: 500px;
    height: 100%;
  }
}

@include mq('sm') {
  .view-creator--home-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .home-info-header {
      position: relative;
      .popup-creator-block {
        display: block !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.65);
        backdrop-filter: blur(5px);
        z-index: 7;
        height: 100%;
      }
      .home-background-image img {
        // object-fit: cover;
        // width: 100%;
        // height: 200px;
        overflow: hidden;
      }
      .control-group_spacign {
        gap: 8px;
      }
      button {
        height: 32px;
        font-weight: 700;
        font-size: 12px;
        margin-top: 0 !important;
        line-height: 14px;
      }
      .anticon {
        cursor: pointer;
      }
      .navigator-btn {
        width: 15px;
        height: 3px;
        border: none;
        border-radius: 20px;
        outline: none;
        &:after {
          height: 3px;
          content: '';
        }
      }
      .control-group {
        .edit-button {
          border-radius: 6px;
          border: none;
        }
      }
    }
  }
  .home-page-content {
    position: relative;
    .popup-creator-block {
      display: block !important;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(5px);
      z-index: 7;
      height: 100%;
    }
    .row-fan--creator {
      .content-tab {
        width: calc(65% - 100px);
      }
    }
  }
  .view-creator--fan {
    .control-group {
      transform: translateY(10%) !important;
    }
  }
  .layout-content > div {
    width: 100% !important;
  }
  .home-info-header {
    width: 100% !important;
    .home-background-image {
      height: 100%;
    }
    .home-background-image img {
      object-fit: contain;
    }
  }
}

// Phu C020 - List livesteam
.list-live--creator.list-idol-delivered-idol {
  background-color: #fff !important;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto 60px auto;
    text-align: center;
  }
  .list-idol {
    display: flex;
    justify-content: space-between;
    padding: 0;
    // @include mq('sm') {
    //   padding: 16px 0;
    // }
    .item {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      position: relative;
      img {
        width: 120px !important;
        height: 120px !important;
        border-radius: 50%;
        margin-bottom: 0;
        z-index: 0;
        border: none;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .live {
        position: absolute;
        z-index: 2;
        bottom: 5px;
        border-radius: 4px;
        color: $color-01;
        text-transform: uppercase;
        font-weight: 700;
        width: 47px;
        height: 24px;
        @include flex();
      }
    }
    .slick-arrow {
      background: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      @include flex();
    }
    .slick-arrow:hover {
      background: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: pointer;
      @include flex();
    }
  }
  .slick-arrow {
    top: 40% !important;
  }
  .slider-custom .slick-next {
    right: 14px;
  }
  .slider-custom .slick-prev {
    left: 14px;
  }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  & > svg {
    color: #fff;
  }
}

.list-social-link {
  display: flex;
  white-space: nowrap;
  column-gap: 16px;
  overflow-x: auto;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  @include mq('sm') {
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
  @include mq('md') {
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }
  &.scroll-bar-custom-horizontal {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .social-link-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    background: $color-07;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      @include mq('sm') {
        width: 38px !important;
        height: 38px !important;
      }
    }
    @include mq('sm') {
      width: 38px;
      height: 38px;
    }
    &:hover {
      color: $color-03;
    }

    .bg-unset {
      background-color: transparent;
    }
  }
}

.pding-5 {
  padding-top: 32px;
}
