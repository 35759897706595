@import 'src/style/variables';
@import 'src/style/mixin';

.fansite-creator-premium-ticket-post {
  form {
    padding: 16px 24px;
    @include mq('sm'){
      padding-bottom: 0;
    }
    input:focus {
      outline: none;
      border: 1px solid $color-14;
      box-shadow: none;
    }
    input::placeholder {
      color: $color-14;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .input:disabled {
      background-color: $color-15;
      border: 1px solid #b1b6bb;
    }

    .condition,
    .timeRegister {
      position: relative;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }

    .condition,
    .timeRegister {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          height: 11px;
        }
      }
    }

    .fansite-form-input__required {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      padding: 2px 12px;
      border: 1px solid $color-13;
      box-sizing: border-box;
      border-radius: 6px;
      color: $color-13;
    }

    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.fsite-price {
        margin-bottom: 8px;
      }
    }
    .form-label.fansite-form-input__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
    }
    .form-item-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form-label {
        margin-bottom: 0 !important;
        margin-right: 8px;
      }
    }

    input {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      border: 1px solid $color-14;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 9px 12px;
      z-index: 2;
      height: 44px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $color-14;
        font-size: 14px;
      }
      &:disabled {
        background-color: #eeeeee;
        color: #b1b6bb;
      }
    }
    .box-button {
      gap: 24px;
    }

    .button-submit,
    .button-cancel {
      width: 50%;
      button {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }

    .btn-create {
      width: 100%;
    }

    .button-submit {
      button {
        background-color: $color-06;
        color: $color-01;
      }
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }
    }
    .button-cancel {
      button {
        border: 1px solid $color-14;
        background-color: $color-01;
        color: $color-03;
      }
    }
  }
}
@include mq('sm') {
  .button-submit,
  .button-cancel {
    width: 100% !important;
  }
}
