.popup-play-icon-post-details {
  position: absolute;
  inset: unset;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.list-images-item.popup-post-details {
  .change-bg {
    background-color: transparent;
  }

  .show-btn-play {
    *::-webkit-media-controls-panel {
      display: none !important;
      -webkit-appearance: none;
    }
  }
}
