@import 'src/style/variables';
@import 'src/style/mixin';

.purchase-page-header {
  min-height: calc((100vh - 60px) - 70px);
  .shimmer-loading {
    padding: 16px 24px;
  }
}
.popup-success-payment-fail-purchase {
  .title,
  span.anticon.close-icon {
    display: none !important;
  }
  .box-general {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .success-icon {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .text-content {
    margin: -10px auto 16px !important;
    text-align: center;
    width: 100%;
    padding: 0 24px;
    .title-success {
      font-weight: 700;
      font-size: 14px;
      color: #52c41a;
      margin-bottom: 18px;
    }
    .title-fail {
      font-weight: 700;
      font-size: 14px;
      color: #dc143c;
      margin-bottom: 18px;
    }
    .content {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      display: block;
    }
  }
  .button-wrapper {
    margin: 16px 0px;
    display: flex;
    gap: 8px;
    .btn {
      height: 32px;
      margin: 0 !important;
      padding: 5px 14px !important;
      &.btn-cancel {
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        color: #1a1a1a;
      }
      &.btn-ok {
        background: #dc143c;
        color: #fff;
      }
    }
  }
}
.payment-page,
.purchase-page {
  .shimmer-loading {
    padding: 16px 24px;
  }
  background-color: #fff;
  height: 100%;
  .--body {
    background-color: #fff;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1a1a1a;
      text-align: center;
      // margin-top: 16px;
      margin: 25px 0 20px 0;
    }
    .--list {
      padding: 25px 30px;
      @include mq('sm') {
        padding: 25px 15px;
      }

      .top-content {
        display: flex;
        gap: 5%;
        @include mq('sm') {
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;
        }

        .current-point {
          color: #ffffff;
          width: 290px;
          height: 200px;
          background-image: url('../../images/CoinBackground.svg');
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 10px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .coin-badge {
            max-width: 65px;
            max-height: 65px;
            background-color: transparent;
          }

          .current-coin-title {
            font-weight: 500;
            font-size: 16px;
          }

          .current-coin-number {
            font-weight: 700;
            font-size: 22px;
          }
        }

        .input-point-container {
          flex-basis: 335px;
          @include mq('sm') {
            flex-basis: 201px;
            width: 100%;
          }
          border-radius: 10px;
          border: 1px solid #e5e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          .input-point-title {
            color: #1a1a1a;
            font-size: 16px;
            font-weight: 700;
            margin-top: 15px;
          }

          .input-point {
            margin: 10px 10px 0 10px;
            text-align: center;
            width: 312px;
            // position: relative;

            .text-danger {
              display: block;
              background: #fff;
              font-size: 10px;
              @include mq('sm') {
                font-size: 12px;
                margin-top: 4px;
              }
              height: 28px;
              margin-bottom: 10px;
            }

            .currency-format {
              padding: 0 12px;
              width: 100%;
              height: 44px;
              color: #1a1a1a;
              border-radius: 6px;
              font-size: 14px;
              border: 1px solid #e5e6eb;
              text-align: center;

              &::placeholder {
                color: #bebebe;
                text-align: center;
              }

              &:focus {
                outline: none;
              }
            }
          }

          .--btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 32px;
            background: #dc143c;
            color: #ffffff;
            border-radius: 6px;
            cursor: pointer;

            &.--disabled {
              opacity: 0.3;
              cursor: not-allowed;
            }
          }

          .--price {
            margin-top: 20px;
            font-weight: 700;
            font-size: 22px;
            color: #1480ff;
          }
        }
      }

      .list-buyer-creator {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 30px;
        @include mq('sm') {
          gap: 10px;
        }

        .fixed-point-item {
          flex-basis: calc((100% / 3) - 20px);
          @include mq('sm') {
            flex-basis: calc((100% / 2) - 5px);
          }
          border-radius: 10px;
          border: 2px solid #e5e6eb;
          display: flex;
          flex-direction: column;
          align-items: center;

          .--point {
            color: #1a1a1a;
            display: flex;
            flex-direction: column;
            background: #f2f3f5;
            border-radius: 5px;
            align-items: center;
            margin: 10px;
            width: calc(100% - 20px);

            .coin-package-img {
              background: #f2f3f5;
              width: 60px;
              height: 60px;
              margin-top: 26px;
            }

            .point-number {
              font-size: 22px;
              font-weight: 500;
            }

            .point-text {
              font-size: 16px;
              font-weight: 400;
            }
          }

          .--btn {
            background: #1480ff;
            border-radius: 6px;
            width: 140px;
            @include mq('sm') {
              width: calc(100% - 20px);
            }
            margin-bottom: 12px;

            &.long-price {
              width: 80%;
              display: flex;
              flex-wrap: wrap;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
      .--item {
        border: 2px solid #28c76f;
        border-radius: 6px 6px 0 0;
        position: relative;
        // margin-bottom: 27px;
        // border-bottom: 0 !important;
        // height: 150px;
        &:last-child {
          // margin-bottom: 0px;
        }
        // p.font-sm {
        //   font-size: 12px;
        //   margin-bottom: 0;
        //   position: absolute;
        //   text-align: center;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   white-space: nowrap;
        // }
        .input-point {
          margin: 16px 16px 0;
          text-align: center;
          position: relative;
          .text-danger {
            background: #fff;
          }
          input {
            padding: 0 12px;
            width: 100%;
            height: 44px;
            color: #282727;
            font-weight: 700;
            background: #eeeeee;
            border-radius: 6px;
            border: 0;
            text-align: center;
            &::placeholder {
              font-weight: 500;
            }
            &:focus {
              border: none;
              outline: none;
            }
          }
        }
        .--point {
          margin: 30px auto 12px;
          text-align: center;
          color: #787880;
          font-weight: 700;
          font-size: 14px;
        }
        .--price {
          text-align: center;
          color: #ff0000;
          font-weight: 700;
          font-size: 24px;
          padding: 0 10px;
          word-break: break-word;
          margin-top: 19px;
        }
        .--btn {
          // position: absolute;
          // height: 38px;
          width: 100%;
          padding: 8px;
          color: #fff;
          background-color: #28c76f;
          font-size: 14px;
          text-align: center;
          font-weight: 500;
          cursor: pointer;
          width: calc(100% + 4px);
          left: -2px;
          bottom: -10px;
          border-radius: 0 0 6px 6px;
        }
        &.custom {
          border: 2px solid #ff9f43;
          .--btn {
            background-color: #ff9f43;
          }
        }
        .--disabled {
          color: #fff;
          // background-color: #b1b6bb !important;
          user-select: none;
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
    .no-result {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100% - 70px);
      width: 100%;
      color: #b1b6bb;
      font-size: 12px;
    }
  }
  .infinite-scroll-component {
    overflow: unset !important;
  }
}

.payment-page {
  background: #ffffff;

  .--body {
    height: 100%;
    padding: 16px;
    display: flex;
    @include mq('sm') {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
    // flex-wrap: wrap;
    .card {
      // height: 185px;
      width: 270px;
      min-height: 255px;
      height: fit-content;
      margin-bottom: 12px;
      // border: 2px solid #28c76f;
      border: 2px solid #e5e6eb;
      border-radius: 6px;
      // position: relative;
      margin-right: 46px;
      @include mq('sm') {
        margin: 0;
        width: 100%;
        min-height: 260px;
        height: auto;
      }
      .point-card {
        margin-bottom: 12px;
        text-align: center;
        color: #1a1a1a;
        font-weight: 700;
        font-size: 16px;
        background: #f2f3f5;
        border-radius: 5px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 175px;

        .coin-package-img {
          background: #f2f3f5;
          width: 74px;
          height: 80px;
        }

        .coin-package-number {
          font-size: 24px;
          font-weight: 500;
        }

        .coin-package-text {
          font-weight: 400;
        }
      }
      .price-card {
        text-align: center;
        // color: #ff0000;
        color: #1480ff;
        font-weight: 700;
        // font-size: 30px;
        font-size: 26px;
        padding-top: 6px;
      }
    }
    .payment-option {
      margin-top: 6px;
      margin-left: -4px;
      flex: 1 1 auto;
      @include mq('sm') {
        width: 100%;
      }
      h6 {
        margin-bottom: 20px;
        font-weight: 700;
      }
      .vendo-payment {
        margin-top: 24px;
        .label-credit {
          white-space: nowrap;
          &.disable {
            color: #b1b6bb;
          }
        }
        .checkbox-payment-vendo {
          width: 25px;
          height: 25px;
          margin-left: 0px;
          margin-top: -2px;
          &.disable {
            color: #b1b6bb;
            cursor: not-allowed;
          }
          span.checkmark {
            border-radius: 50%;
            width: 18px;
            height: 18px;
          }
        }
      }
      .custom-radio-select {
        position: relative;
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;
        .checked-icon {
          position: absolute;
          z-index: 1;
          top: 0px;
        }

        input[type='checkbox'] {
          visibility: hidden;
        }

        input ~ .checkmarked {
          position: absolute;
          left: 0;
          top: 1px;
          height: 18px;
          width: 18px;
          border-radius: 28.5px;
          border: solid 1px #aaaaaa;
          background-color: #ffffff;
          cursor: pointer;
          margin-top: 0;

          &:after {
            content: '';
            position: absolute;
            // top: 2px;
            // left: 2px;
            // width: 10px;
            // height: 10px;
            background-color: red;
            border: 1px solid red;
            border-radius: 50%;
            display: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        input:checked ~ .checkmarked {
          border-color: red;
          &:after {
            display: block;
          }
        }
        label {
          margin-left: 4px;
          font-weight: 500;
          font-size: 14px;
        }
      }
      .icon-payment {
        margin-top: -2px;
        margin-left: 31px;
        @include mq('sm') {
          margin-left: 20px;
        }
        img {
          margin-right: 16px;
          margin-bottom: 12px;
        }
        &_card {
          width: auto;
          height: 48px;
        }
      }
      .note-tiger-pay {
        margin-left: 14px;
        font-weight: 500;
        font-size: 10px;
      }
      .form-payment {
        .card-number,
        .expired-date,
        .cvv {
          label {
            margin-bottom: 4px;
          }
          input {
            padding: 12px;
            height: 44px;
            width: 100%;
            border-radius: 6px;
            border: 1px solid #b1b6bb;
          }
        }
        .err-msg {
          color: #ff0000;
          font-size: 12px;
        }
      }
    }
    .btn-payment {
      height: 44px;
      width: 100%;
      margin-top: 24px;
      padding: 8px;
      border-radius: 6px;
      font-size: 14px;
      text-align: center;
      font-weight: 700;
      border: 0;
      cursor: pointer;
      background-color: #dc143c;
      color: #fff;
      @include mq('sm') {
        margin-top: 12px;
      }
      &:hover {
        background-color: $color-27;
      }
    }
    .--active {
    }
    .--disabled {
      background-color: #b1b6bb;
      background: rgba($color: #dc143c, $alpha: 0.3);
      &:hover {
        background-color: #b1b6bb;
        background: rgba($color: #dc143c, $alpha: 0.3);
        cursor: not-allowed;
      }
    }
  }
}
.fansite-payment-success {
  height: calc(100vh - 60px);
}

.payment-page.step-2,
.payment-page.step-3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 16px;
  .img-tiger-pay {
    width: 100%;
    margin-top: 16px;
    height: 147px;
  }
  .btn-payment {
    height: 44px;
    width: 100%;
    margin-top: 16px;
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    border: 0;
    cursor: pointer;
  }
  .--active {
    color: #fff;
    background-color: #ff0000;
  }
  .--cancel {
    // color: #ff0000;
    background-color: #fff;
    // border: 1px solid #ff0000;
  }
  .div3 {
    &.custom {
      display: grid;
      grid-template-columns: 270px auto;

      &.jp {
        grid-template-columns: 200px auto;
      }

      .text-right {
        min-width: 107px;
        &.jp {
          min-width: 130px;
        }
      }
    }
  }
}

.payment-page.step-2 {
  .img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .img-tiger-pay {
      flex-basis: 70%;
      height: 240px;
    }
  }
  .btn-payment {
    width: calc(100% / 2 - 10px);
    margin-top: 35px;
    margin-bottom: 20px;
    &.btn-col {
      @include mq('sm') {
        width: 100%;
        margin: 0px !important;
      }
      &.top {
        @include mq('sm') {
          margin-top: 24px !important;
          margin-bottom: 12px !important;
        }
      }
    }

    &.--active {
      margin-right: 20px;
      background-color: #dc143c;
    }

    &.--cancel {
      border: 1px solid #e5e6eb;
      color: #282727;
    }
  }
}
