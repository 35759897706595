@import 'src/style/variables';
@import 'src/style/mixin';

.shimmer-bg {
  width: 500px;
  margin: auto;
}
.empty-box {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.shimmer-short-video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  @include mq('mw768') {
    border-radius: 0;
  }
}

.popup-wrap-short-search {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2002;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.8);
  @include mq('mw768') {
    z-index: 4000 !important;
  }
  .close-icon {
    position: absolute;
    left: -50px;
    top: 0px;
    color: #fff;
    cursor: pointer;
    z-index: 10;
    background-color: #000;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    @include mq('mw1024') {
      left: -40px;
    }
    @include mq('md') {
      left: 20px;
      top: 20px;
    }
    @include mq('sm') {
      left: 20px;
      top: 20px;
      z-index: 9000;
    }
    svg {
      display: flex;
      width: 25px;
      height: 25px;
      color: white;
    }
  }
}

.page-list-short-video-search {
  width: 720px;
  @media screen and (max-width: 1023px) {
    width: 100vw;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &.popup-short {
    margin-top: 60px;
    @include mq('sm') {
      margin-top: 0;
    }
    @media (orientation: landscape) {
      margin-top: 20px;
    }
  }
  overflow: scroll;
  height: calc(100vh - 86px);
  scroll-snap-type: y mandatory;

  display: grid;
  gap: 16px;
  padding: 0px 120px 20px 120px;
  margin-top: 20px;
  @include mq('md') {
    gap: 0;
    padding: 16px 0px;
    height: 100dvh !important;
    min-height: calc(100vh - 60px);
  }
  &-item {
    scroll-snap-align: start;
    margin: auto;
    height: calc(100vh - 110px);

    width: 100%;
    position: relative;

    @include mq('md') {
      height: 100dvh !important;
      max-height: calc(100vh - 120px);
    }
    @include mq('sm') {
      height: 100dvh !important;
      max-height: unset;
    }
    &__video {
      display: inline-block;
      position: absolute;
      height: 100%;
      width: 100%;
    }
    .video-custom {
      background-color: #fafafa;
      border-radius: 20px;
      overflow: hidden;
      -webkit-transform: translateZ(0);
      object-fit: contain;
      @include mq('mw768') {
        border-radius: 0px;
      }
      video {
        object-fit: contain;
        background-color: #1a1a1a;
      }
    }

    .mute-icon {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0px;
      @include mq('mw768') {
        right: 6px;
      }
    }
    .index10 {
      z-index: 10;
    }
    .index20 {
      z-index: 20;
    }
    .index25 {
      z-index: 25;
    }

    .play-icon {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .block-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      padding: 0 28px 18px 16px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      border-end-end-radius: 20px;
      border-end-start-radius: 20px;
      z-index: 18;
      @include mq('mw768') {
        border-end-end-radius: 0px;
        border-end-start-radius: 0px;
        padding: 0 28px 40px 16px;
      }
      gap: 8px;
      .avatar {
        display: flex;
        align-items: center;
        margin-bottom: 0px !important;
        &.full-content {
          margin-bottom: 18px;
        }
        cursor: pointer;
        img {
          border: none;
          border-radius: 50%;
          max-height: 44px;
          max-width: 44px;
          min-height: 44px;
          min-width: 44px;
          object-fit: cover;
        }
        .name {
          margin-left: 15px;
          font-size: 14px;
          font-weight: bold;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
      }
      ::-webkit-scrollbar-thumb {
        background: #ffffff;
        border-radius: 20px;
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 20px;
        box-shadow: inset 0px 0px 0px 1px #c8c8cd;
      }
      .content-des {
        position: relative;
        z-index: 5;
        color: rgb(255, 255, 255);
        margin: 0px;
        width: calc(100% - 40px);
        bottom: 0px;
        &.full-content {
          margin-top: 5px;
          bottom: 16px;
        }
        .text {
          margin-top: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
        }
        .btn-read-less {
          color: #1480ff;
          cursor: pointer;
          position: absolute;
          right: 8px;
        }
      }
    }
    .group-icon {
      position: absolute;
      bottom: 15px;
      right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      gap: 11px;
      @include mq('mw768') {
        padding-bottom: 30px;
      }
      .icon-like,
      .icon-chat {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .cls-text {
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      }
      .bg-icon {
        cursor: pointer;
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        .like-icon {
          &.active {
            color: $color-26;
          }
        }
      }
      .popup-edit-comment {
        position: absolute;
        background: $color-01;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        width: 229px;
        height: unset !important;
        display: flex;
        flex-direction: column;
        bottom: 42px;
        right: 0;
        border-radius: 6px;
        overflow: hidden;
        z-index: 15;
        .item {
          display: flex;
          align-items: center;
          padding-left: 18px;
          cursor: pointer;
          height: 38px;
          z-index: 10;
          color: $color-03;
          .anticon {
            margin-right: 8px;
          }
          &:hover {
            background-color: $color-07;
          }
          &:first-child {
            margin-top: 6px;
            position: relative;
          }
          &:nth-child(2) {
            margin-bottom: 6px;
          }
          .text {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .item {
    .content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 95%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
      height: 22px;
    }
    img {
      object-fit: contain;
      aspect-ratio: 2/3;
      max-width: -webkit-fill-available;
      height: inherit;
    }

    video {
      // background-color: #000;
      max-width: 100%;
      height: 525px;
      aspect-ratio: 2/3;
      background-size: contain;
    }
    .img-timeline-wrapper {
      // background-color: #000;
      height: 525px;
      overflow: hidden;
      width: 100%;
      text-align: center;
    }
    @include mq('mw768') {
      .img-timeline-wrapper,
      video {
        height: 252px;
        width: 100%;
      }
      .button-play-custom {
        width: 42px;
        height: 28px;
        border-radius: 8px;
        svg {
          width: 12.5px;
          height: 14.5px;
        }
      }
    }

    & .GroupIcon {
      display: flex;
      align-items: center;
      margin-top: 16px;
      justify-content: space-between;
      overflow-x: auto;
      overflow-y: hidden;
      &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
        height: 1px;
        background: $color-08;
        border-radius: 10px;
        border-top: 1px white;
        border-bottom: 1px white;
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
    }
  }
  form {
    &.form-comment-popup-short-search {
      .form-input-comment-meta {
        max-height: 80px;
        overflow-y: auto;
        textarea {
          min-height: 44px;
        }
      }
    }
  }
}
