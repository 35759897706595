@import 'src/style/variables';
@import 'src/style/mixin';

.create-message-layout,
.message-layout {
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    margin-top: 60px;
    @media screen and (max-width: 1023px) {
      margin-top: 0px !important;
    }
  }
}

.message-page {
  .shimmer-loading {
    padding: 24px !important;

    @include mq('sm') {
      padding: 16px !important;
    }
  }

  .fansite-form {
    padding: 20px 24px !important;
    @include mq('sm') {
      padding: 16px !important;
    }
  }
}
.buyer-page-creator {
  .select {
    min-width: 144px;
    .anticon {
      pointer-events: none;
    }
  }

  .messages {
    padding: 0 8px;

    @include mq('sm') {
      padding: 0;
    }
  }
  .message-add-new {
    button {
      font-weight: 700;
      font-size: 12px;
      padding: 6px 22px;
    }
  }
  .block-empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30%;
    &_text {
      font-weight: 400;
      font-size: 14px;
      color: #b1b6bb;
      margin: 10px auto;
    }
  }
}
.create-message-page {
  padding: 24px !important;
  .wrapper-content-ogp {
    &.have-meta {
      border-radius: 6px;
      border: 1px solid #e5e6eb;
      padding: 16px;
      .content-ogp {
        border: none;
        border-radius: 0;
        padding: 0;
        min-height: 40px;
        height: 40px;
        max-height: 40px;
      }
    }
  }
  .error-package {
    color: #ff0000;
    font-size: 14px;
  }

  .fansite-form-input__controls {
    label {
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
    }
    @include mq('sm') {
      .select > div {
        width: 100%;
        .anticon {
          pointer-events: none;
        }
      }
    }
  }

  .group-options-survey {
    border: 1px solid $color-32;
    border-radius: 6px;
    padding: 16px;

    .option-survey-checkbox + .option-sub-checkbox {
      max-height: 180px;
      overflow-y: auto;
      margin-top: 14px;
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
  }

  .icon-search {
    margin-right: 5px;
  }
  @include mq('md') {
    padding: 16px !important;
  }
  @include mq('sm') {
    .fansite-form {
      padding-bottom: 0;
    }
  }

  .link-navigate-survey {
    color: #1480ff;
    cursor: pointer;
    margin: 16px 0;
  }

  button {
    border: 6px;
  }
  textarea {
    background: #ffffff;
    border: 1px solid #e5e6eb;
    border-radius: 6px;
    &::placeholder {
      color: #b1b6bb;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .no-survey {
    font-weight: 400;
    font-size: 12px;
    color: #dc143c;
  }
  .select {
    .anticon {
      pointer-events: none;
    }
  }
}

.option-survey {
  padding: 16px;
  background: #eeeeee;
  border-radius: 6px;
}

.avatar-60 {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.checkbox-package:disabled {
  background-color: #eeeeee;
  border-color: #b1b6bb;
}

.all-plan.disabled {
  color: #b1b6bb;
}
