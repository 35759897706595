@import 'src/style/variables';
@import 'src/style/mixin';

.footer-mobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 52px;
  padding: 1px 16px;
  background-color: #fff;
  border-top: 1px solid #e5e6eb;
  z-index: 200;

  .list-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 170%;
    letter-spacing: 0.2px;
    color: #bebebe;

    .menu-item {
      flex: 1;
      svg {
        width: 24px;
        height: 24px;
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      a:hover {
        color: $color-26;
      }
      &.default-click {
        pointer-events: none;
      }
    }
    .link-not-active,
    .link-active {
      line-height: 1;
    }
    .link-active {
      color: $color-26;
    }
  }
}
