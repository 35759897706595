@import 'src/style/variables';
@import 'src/style/mixin';

.follow-header {
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    margin-top: 0px !important;
  }
  .follow-follower-page {
    background: #ffffff;
    border-top: 1px solid $color-07;
    padding: 20px 0 0 0;
  
    .menu {
      margin: 0 28px;
      display: flex;
      background: #f2f3f5;
      border-radius: 43px;
      overflow: hidden;
      @include mq('sm') {
        margin: 0 16px !important;
      }
      .menu-item {
        border-radius: 43px;
        color: #666565;
        background: #f2f3f5;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin: 4px;
  
        &.active {
          color: $color-01;
          background: $color-26;
        }
  
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.follow-page-creator {
  height: 100%;
  .div_block {
    overflow-x: hidden !important;
  }
}


.list-follow-creator {
  padding: 0px 0 40px 0;

  .shimmer-loading {
    padding: 24px;

    @include mq('sm') {
      padding: 16px;
    }
  }
  @include mq('sm') {
    padding-bottom: 0;
  }
  .account-follow,
  .account {
    padding: 16px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq('sm') {
      &:first-child {
        margin-top: 15px;
      }
      padding: 12px 16px !important;
    }
    &.hidden {
      cursor: default;
      animation: fadeOut 2s;

      .btn-followOrUnFollow {
        cursor: default;
      }

      .content-left {
        cursor: default;
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    img {
      border-radius: 50%;
      min-height: 56px;
      max-height: 56px;
      min-width: 56px;
      max-width: 56px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .content-left {
      cursor: pointer;
      display: flex;
      padding-right: 25px;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          word-break: break-word;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 280px;
          @include mq('sm') {
            max-width: 120px !important;
          }
        }

        .second-name {
          color: #666565;
          font-size: 12px;
        }

        .list-sub {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #787880;
          @include mq('sm') {
            max-width: 170px;
          }
        }
      }
    }
    .btn-followOrUnFollow {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 6px;
      background: $color-26;
      color: $color-01;
      height: max-content;
      white-space: nowrap;
      border: 1px solid $color-02;

      &.following {
        color: $color-02;
        background: $color-01;
        border: 1px solid #e5e6eb !important;
        border-radius: 4px;
        font-weight: 700;
        border: none;
      }

      &.no-follow {
        color: $color-01;
        border-radius: 4px;
        font-weight: 500;
        border: 1px solid #e5e6eb;
        &:hover {
          background: rgba(255, 0, 0, 0.1);
          border: 1px solid rgba(255, 0, 0, 0.1);
          color: $color-26;
        }
      }
      &:hover {
        background: #f1f1f1;
      }

      &.loading-spin {
        .spinner-border {
          margin-right: 4px;
        }
      }
    }
  }
}
.follow-page-creator {
  // height: 100%;
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #b1b6bb;

    @include mq('sm') {
      position: fixed;
    }
  }
}
