@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-delivered-idol {
  background-color: $color-22;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto 60px auto;
    text-align: center;
  }
  .list-idol {
    display: flex;
    justify-content: space-between;
    padding: 0 65px 60px 65px;
    .item {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      position: relative;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 14px;
        border: 2px solid $color-06;
        z-index: 0;
      }
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
      .live {
        position: absolute;
        z-index: 2;
        bottom: 31px;
        border-radius: 4px;
        background: $color-06;
        color: $color-01;
        text-transform: uppercase;
        font-weight: 700;
        width: 47px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .slick-arrow {
    top: 21%;
  }
  .slick-list {
    width: 100%;
  }
}

@include mq('l') {
  .list-idol-delivered-idol {
    .list-idol {
      padding: 0 20px 60px 20px;
    }
  }
}

@include mq('md') {
  .list-idol-delivered-idol {
    background-size: 100% 100%;
    .title {
      margin: 32px auto;
    }
    .list-idol {
      padding-bottom: 32px;
      .live {
        left: 50%;
        transform: translateX(calc(-50%));
      }
    }
  }
}

@include mq('sm') {
  .list-idol-delivered-idol {
    .list-idol {
      .item {
        width: 74px;
        img {
          height: 74px;
          width: 74px;
          margin-bottom: 12px;
        }
        .name {
          width: 74px;
          max-width: 74px;
          font-size: 12px;
        }
      }
      .slick-arrow {
        top: 17%;
      }
      .live {
        bottom: 24px;
      }
    }
  }
}
