@import 'src/style/variables';
@import 'src/style/mixin';

.home-quick-views-container {
  transition: 0.2s;
  width: 100%;
  position: relative;
  img {
    object-fit: contain !important;
    aspect-ratio: 4/3;
  }
  .cover-img {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    background-color: transparent;
  }
}

.bg-icon-smaller {
  width: 53px;
  height: 53px;
}

.slick {
  &-dots {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    //bottom: 0;
    bottom: -30px;
    li {
      button:before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 3px;
        border-radius: 5px;
        content: '';
        background-color: $color-14;
        order: 2px solid $color-14;
        text-align: center;
        opacity: 1;
      }
      &.slick-active {
        button {
          &:before {
            background-color: #ff0000;
          }
        }
      }
      position: relative;
      display: inline-block;
      padding: 0;
      cursor: pointer;
      border-color: #ff0000;
      &:before {
        width: 10px;
        border-radius: 5px;
        background: #ff0000;
        height: 5px;
        color: #ff0000;
        &:hover {
          text-align: center;
          opacity: 0.25;
          display: none;
        }
      }
    }
  }
}

.list-image-home-page{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
  @include mq('sm') {
    gap: 2px;
  }
  .item{
    position: relative;
    width: 32%;
    border-radius: 8px;
    overflow: hidden;
    @include mq('sm') {
      max-height: 130px;
      width: 100%;
      border-radius: revert;
    }
    .item-images{
      border-radius: 8px;
      width: 100%;
      height: 241px;
      object-fit: contain;
      @include mq('sm') {
        height: 100%;
        width: 100%;
        border-radius: revert !important;
      }
    }
  }
}