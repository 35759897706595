@import 'src/style/variables';
@import 'src/style/mixin';

.list-follower-creator {
  padding: 0px 0 40px 0;
  .shimmer-loading {
    padding: 24px;
    @include mq('sm') {
      padding: 16px;
    }
  }
  
  @include mq('sm') {
    padding-bottom: 0;
  }
  .account-follower {
    padding: 16px 25px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    @include mq('sm') {
      &:first-child {
        margin-top: 15px;
      }
      padding: 12px 16px !important;
    }
    img {
      border-radius: 50%;
      min-height: 56px;
      max-height: 56px;
      min-width: 56px;
      max-width: 56px;
      border: none;
      margin-right: 12px;
      object-fit: cover;
    }
    .content-left {
      display: flex;
      padding-right: 25px;
      width: calc(100% - 83px);
      &-item-info {
        width: calc(100% - 60px);
      }
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          word-break: break-word;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 280px;
          @include mq('sm') {
            max-width: 120px !important;
          }
        }

        .second-name {
          color: #666565;
          font-size: 12px;
        }

        .list-sub {
          display: flex;
          flex-direction: column;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #787880;
          @include mq('sm') {
            max-width: 170px;
          }
        }
      }
    }
    .btn-followOrUnFollow {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 12px;
      border-radius: 6px;
      background: $color-26;
      color: $color-01;
      height: max-content;
      white-space: nowrap;
      border-radius: 6px;
      border: 1px solid $color-02;

      &.following {
        color: $color-02;
        background: $color-01;
        border: 1px solid #e5e6eb !important;
        border-radius: 4px;
        font-weight: 700;
        border: none;
      }

      &.no-follow {
        color: $color-01;
        border: 1px solid #e5e6eb;
        @media screen and (min-width: 1025px) {
          &:hover {
            background: rgba(255, 0, 0, 0.1);
            border: 1px solid rgba(255, 0, 0, 0.1);
            color: $color-26;
          }
        }
      }

      &.hover {
        background: #f1f1f1;
      }
    }
  }
}
.follower-page-creator {
  height: 100%;
  .div-block {
    @include mq('sm') {
      overflow-x: hidden !important;
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: calc(50% - 40px);
    left: 50%;
    transform: translate(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #b1b6bb;

    @include mq('sm') {
      position: fixed !important;
    }
  }
}
