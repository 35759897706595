@import 'src/style/variables';
@import 'src/style/mixin';

.search-page {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    margin-top: 40px;
    .layout-header {
      height: 0px !important;
      margin-top: 0px !important;
    }
  }
  padding: 24px;
  background-color: #ffffff;

  .shimmer-loading {
    padding-top: 16px;
  }

  .back-header {
    display: none !important;
  }

  .back-to-page {
    display: none;
  }

  @include mq('sm') {
    padding: 0px;
  }

  .tabs-search {
    display: flex;
    gap: 12px;
    // @include mq('md') {
    //   padding-top: 60px;
    // }
    @media screen and (max-width: 575px) {
      padding: 12px 16px 10px 16px;
      overflow-x: scroll;
      gap: 6px;
      &::-webkit-scrollbar {
        display: none;
      }

      .tab-item {
        min-width: fit-content;
        font-size: 16px;
      }
    }

    @media screen and (max-width: 375px) and (max-height: 812px) {
      padding: 5px 16px 10px 16px;
    }

    .tab-item {
      width: max-content;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: #f2f3f5;
      border-radius: 8px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        background: #e5e6eb !important;
      }
      &.active {
        background: #dc143c;
        color: #ffffff;
        cursor: default;
        &:hover {
          background: #dc143c !important;
        }
      }
    }
  }

  .popup-wrap-short-video1 {
    .card-item--detail.left-content-comment-mess {
      height: 94vh;
      margin-bottom: 30px;

      .item {
        background-color: #ffffff;
      }

      .div-list-cmt {
        .comment.block {
          form {
            .form-item {
              .anticon {
                bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .from-search-page {
    @media screen and (min-width: 990px) {
      display: flex;
      gap: 16px;
      justify-content: space-between;

      @media screen and (max-width: 575px) {
        padding: 16px 16px 0 16px;
      }

      .form-item {
        width: 583px;
        height: 100%;
        margin-bottom: 16px;

        @media screen and (max-width: 991px) {
          width: 90%;
        }

        .input-field {
          width: 100%;
          padding: 11px 12% 11px 36px;
          outline: none;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          padding: 11px 2% 11px 36px;

          &.is_input {
            @media screen and (max-width: 575px) {
              padding: 11px 16% 11px 36px;
            }
          }
        }

        .icon-container {
          min-width: 40px;

          .search-icon {
            padding: 14px 10px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #787880;
          }
          .clear-icon {
            padding: 14px 10px;
            position: absolute;
            right: 16%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media screen and (max-width: 1240px) {
              right: 18%;
            }
            @media screen and (max-width: 1050px) {
              right: 20%;
            }
            @media screen and (max-width: 991px) {
              right: 12%;
            }
            @media screen and (max-width: 900px) {
              right: 13%;
            }
            @media screen and (max-width: 820px) {
              right: 14%;
            }
            @media screen and (max-width: 750px) {
              right: 15%;
            }
            @media screen and (max-width: 700px) {
              right: 16%;
            }
            @media screen and (max-width: 650px) {
              right: 17%;
            }
            @media screen and (max-width: 650px) {
              right: 18%;
            }
            @media screen and (max-width: 575px) {
              right: 17%;
            }
            @media screen and (max-width: 575px) {
              right: 18%;
            }
            @media screen and (max-width: 520px) {
              right: 19%;
            }
            @media screen and (max-width: 490px) {
              right: 21%;
            }
            @media screen and (max-width: 450px) {
              right: 22%;
            }
            @media screen and (max-width: 420px) {
              right: 24%;
            }
            @media screen and (max-width: 390px) {
              right: 27%;
            }
          }
        }
      }
    }
    @media screen and (max-width: 375px) and (max-height: 812px) {
      top: -6px !important;
    }
    @media screen and (max-width: 991px) {
      position: fixed;
      display: flex;
      z-index: 2000;
      top: -10px;
      justify-content: center;
      height: 60px;
      align-items: center;
      left: 50%;
      transform: translateX(-53%);

      .form-item {
        position: relative;

        .input-field {
          padding: 6px 34px;
          border-radius: 6px;
          border: 1px solid var(--neutral-line, #e5e6eb);
          background: var(--neutral-white, #fff);
          max-width: 238px;
          height: 35px;

          @media screen and (min-width: 600px) {
            max-width: 420px;
            width: 420px;
          }

          @media screen and (max-width: 575px) {
            max-width: 238px;
            width: 238px;
          }

          @media screen and (max-width: 375px) and (max-height: 812px) {
            height: 35px;
          }
        }

        .search-icon,
        .clear-icon {
          position: absolute;
          display: flex;
          align-items: center;
          height: 100%;
        }

        .search-icon {
          left: 7px;

          svg {
            color: #666565;
          }
        }

        .clear-icon {
          right: 10px;
        }
      }

      @media screen and (max-width: 375px) and (max-height: 812px) {
        height: 50px;
      }
    }
    .button-search {
      @media screen and (max-width: 991px) {
        display: none;
      }
      width: 73px;
      height: 44.6px;
      background: #28c76f;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      font-weight: 700;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background: #11b259 !important;
      }
    }
  }
}
