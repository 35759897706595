@import 'src/style/variables';
@import 'src/style/mixin';
//content

.mtop-20 {
  margin-top: 20px;
}
.post-content-page-article{
  @include mq('sm') {
    padding: 0 0 190px 0 !important;
  }
}

.post-content-header {
  .content-container.post-content-page-article {
    @media screen and (min-width: 767px) and (max-width: 991px) {
      padding-bottom: 20px !important;
    }
  }
  .back-to-page {
    margin-top: 60px;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .wrapper-content-ogp {
    &.have-meta {
      padding: 16px;
      border-radius: 6px;
      border: 1px solid #e5e6eb;
      .area-l-height {
        border: none;
        padding: 0;
        min-height: 40px;
        height: 40px;
      }
    }
  }
}

.edit-post-content-header {
  .content-container.post-content-page-article {
    @media screen and (min-width: 767px) and (max-width: 991px) {
      padding-bottom: 20px !important;
    }
  }
  .wrapper-content-ogp {
    &.have-meta {
      padding: 16px;
      border-radius: 6px;
      border: 1px solid #e5e6eb;
      .area-l-height {
        border: none;
        padding: 0;
        min-height: 40px;
        height: 40px;
      }
    }
  }
}

.text-creator-info {
  color: #DC143C !important
}

.fansite-creator-post-content {
  border-radius: 20px;
  .list-item.scroll-bar-custom-horizontal.list-imgs {
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e5e6eb;
      border: 7px solid #e5e6eb;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
  }
  .inputFile {
    position: relative;
    cursor: pointer;

    @include mq('sm') {
      min-width: calc(50% - 8px);
    }

    &.disableImgInput,
    &.disableVideoInput {
      cursor: not-allowed !important;
      pointer-events: none;
      background: #fff;
      opacity: 0.5;
      input,
      span {
        cursor: not-allowed !important;
      }
    }
    .btn-upload {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      gap: 10px;
      padding: 14px 24px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      height: 44px;
      min-width: 156px;
      cursor: pointer;

      @include mq('sm') {
        padding: 14px;
      }

      &:hover {
        background: #f1f1f1;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  form {
    padding: 24px 0 0 0;
    padding-top: 0;

    &.form-edit {
      padding: 24px;
    }

    .box-img-video {
      margin-top: 16px;

      .box-btn-upload {
        margin-bottom: 16px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
      }

      .list-item {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow-x: auto;
        gap: 8px;
        height: auto;
        margin-bottom: 5px;
        &.has-video-short {
          margin-bottom: 21px;
          margin-top: -6px;
        }
        .box-item {
          position: relative;
          overflow: hidden;
          // background-color: $color-02;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            aspect-ratio: 4/3;
            object-fit: contain;
            width: 100%;
            @include mq('sm') {
              width: 235px;
              height: 276px;
              min-width: 235px;
            }
          }
          .anticon {
            position: absolute;
            top: 16px;
            right: 16px;
            color: $color-01;
            padding: 7px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 2;
            width: 40px;
            height: 40px;
            svg {
              width: 40px;
              height: 40px;
            }
          }
        }
        .box-img1 {
          max-width: 503px;
          max-height: 670px;
          margin: auto;
          @include mq('sm') {
            max-height: 300px;
            max-width: 100%;
            width: 235px;
            height: 276px;
            min-width: 235px;
          }
        }
        .box-img2 {
          width: 235px;
          height: 276px;
          min-width: 235px;
          img {
            width: 235px;
            height: 276px;
            min-width: 235px;
          }
        }
        .video-item {
          width: 100%;
          height: 100%;
        }
        .box-video1 {
          width: 235px;
          height: 276px;
          min-width: 235px;
          margin: auto;
          &.self-video {
            width: 100%;
            aspect-ratio: 16/9;
            height: unset;
          }
          .video-react {
            padding-top: 0 !important;
            height: 100%;
          }
          video {
            width: 100%;
            position: relative;
          }
        }
        // &.has-video {
        //   margin-bottom: 16px;
        // }
      }
      .list-item.list-videos {
        .box-item {
          min-width: 500px;
          //max-width: 503px;
          //max-width: 641px;
          max-height: 503px;
        }
        .video-react {
          padding-top: 0 !important;
          height: 100%;
        }
        video {
          width: 100%;
          position: relative;
        }
      }
    }

    .box-img-video1 {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 16px;
      margin-top: 16px !important;
    }
  }
  .zindex20 {
    z-index: 20 !important;
  }
  .mb-4 {
    margin-bottom: 4px !important;
  }
  .mt-10 {
    margin-top: 10px !important;
  }
  .mt-8 {
    margin-top: 8px !important;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mb-12 {
    margin-bottom: 12px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .mt-24 {
    margin-top: 24px !important;
  }
  background-color: $color-01;
  // padding-top: 16px;
  .btn-remove {
    cursor: pointer !important;
    position: absolute;
    width: 40px !important;
    height: 40px !important;
    top: 2px;
    right: 2px;
  }
  &.edit {
    padding-top: 0px;
  }
  .time-icon {
    z-index: 2;
    position: absolute;
    right: 4px;
    top: 15px;
  }
  .custom-time-post {
    position: absolute;
    left: 0;
    bottom: calc(100% + 5px);
  }
  .see-next {
    font-weight: 400;
    font-size: 14px;
    color: #1480ff;
    display: inline-block;
    cursor: pointer;
    position: relative;
    span {
      position: absolute;
      top: 4px;
      right: -20px;
    }
    &.next {
      margin-bottom: 16px;
    }
  }
  &__footer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @include mq('sm') {
      flex-direction: column;
      gap: 12px;
    }
    .btn {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      height: 44px;
      &.btn-submit {
        color: #fdfefe;
        background-color: $color-26;
        border: none;
        &:disabled {
          background-color: $color-30;
        }
        &:hover {
          background-color: $color-27;
        }
      }
      &.btn-cancel {
        color: #1a1a1a;
        background-color: #ffffff;
        border: 1px solid #e5e6eb;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background: #f2f3f5;
    margin: 0px 24px;
    border-radius: 43px;
    font-weight: 500;
    font-size: 14px;

    @include mq('sm') {
      margin-bottom: 0;
      margin: 0px 16px;
      margin-top: 16px;
    }

    .item {
      display: flex;
      flex: 1;
      color: #666565;
      text-align: center;
      cursor: pointer;
      margin: 4px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
    }
    .active {
      color: $color-01;
      background-color: $color-26;
    }
  }
  form {
    padding: 0 24px 16px;
    width: 100%;
    .text-post {
      margin-bottom: 0 !important;
      &.has-img {
        margin-bottom: 16px !important;
      }
    }
    .color-red {
      color: #DC143C !important;
    }
    .box-img-video {
      .item-wrap {
        width: calc(50% - 8px) !important;
        @include mq('sm') {
          width: 100% !important;
        }
      }
      .setting-time {
        width: 100%;
        border-radius: 8px;
        border: 1px dashed $color-32;
        background-color: $color-01;
        padding: 16px;
        text-align: center;
        .setting-time-title {
          font-size: 16px;
          font-weight: 700;
          color: $color-03;
          margin-right: 12px;
        }
        .setting-time-input-time {
          .time-split-video {
            width: 76px;
            height: 36px;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 700;
            color: $color-03;
            text-align: center;
            &:disabled {
              color: #bebebe;
            }
            &.error {
              border: 1px solid $color-26 !important;
            }
          }
        }
        &_info {
          margin: auto;
          text-align: start;
          width: fit-content;
          margin-top: 12px;
          &_item {
            font-size: 12px;
            font-weight: 400;
            color: #666565;
            &.focus-red {
              color: $color-26;
            }
          }
        }
      }
      .item-label {
        font-size: 14px;
        font-weight: 500;
        color: $color-03;
        margin-bottom: 8px;
      }
      .record-content {
        width: 100% !important;
        aspect-ratio: 16/9;
        overflow: hidden;
        position: relative;
      }
      .video {
        z-index: 2;
        height: 100%;
        padding-top: 0 !important;
        min-height: 185px;
      }
      .box-item {
        .anticon {
          position: absolute;
          top: 2px;
          right: 0;
          color: $color-01;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        &.img-des {
          .anticon {
            top: 2px;
            right: 0px;
          }
        }
      }
      input[type='file'] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
        &:disabled {
          cursor: not-allowed;
        }
      }
      img {
        max-width: 100%;
        height: 100%;
        cursor: pointer;
        &.img_thumbnail {
          aspect-ratio: 4 / 3;
          object-fit: contain;
        }
      }
      .trailer-video,
      .main-thumbnail {
        width: 100%;
        height: 100%;
        max-width: 100%;
        min-height: 185px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        aspect-ratio: 16/9;
        text-align: center;
      }
      .main-video {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 16/9;
        @supports not (aspect-ratio: 16 / 9) {
          min-height: 378px;
          .video-react {
            min-height: 378px;
          }
        }
        min-height: 378px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        margin: 0;
        padding: 0;
        &.center {
          text-align: center;
        }
      }
      .list-video {
        width: 100%;
      }
      .list-img-sample {
        width: 100%;
        .list-img {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 16px;
          .img-des {
            overflow: hidden;
            position: relative;
            width: calc(20% - 13px);
            aspect-ratio: 4/3;
            max-height: 117px;
            border-radius: 8px;
            overflow: hidden;
          }
          .add-input.img-des {
            width: calc(20% - 13px);
            max-height: 94px;
            min-height: 61px;
            aspect-ratio: 4/3;
            @supports not (aspect-ratio: 16 / 9) {
              min-height: 84px;
            }
            background: #f7f8fa;
            border: 1px dashed #e5e6eb;
            border-radius: 8px;
            .add-icon {
              position: absolute;
              top: 48%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              border-radius: 6px;
              color: $color-01;
              font-weight: 500;
              font-size: 15px;
              &.add-icon-cursor {
                cursor: pointer;
                &.disable {
                  cursor: not-allowed;
                }
              }
              @include mq('sm') {
                svg {
                  height: 22px;
                  width: 22px;
                }
              }
            }
            .text {
              font-weight: 400;
              font-size: 12px;
              color: #666565;
              margin-top: 6px;
              text-align: center;
              @media screen and (min-width: 1024px) and (max-width: 1148px) {
                font-size: 9px;
              }
            }
          }
        }
      }

      .list-img-box {
        width: 100%;
        img {
          cursor: auto;
          object-fit: cover;
        }
      }
      .list-img-out {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 12px 16px;
        .box-item {
          min-height: 126px;
          position: relative;
          width: calc(25% - 12px);
          aspect-ratio: 4/3;
          border-radius: 8px;
          overflow: hidden;
        }
        .img-des.box-item.add-input {
          width: calc(25% - 12px);
          aspect-ratio: 4/3;
          background: #f7f8fa;
          border: 1px dashed #e5e6eb;
          border-radius: 8px;
          overflow: hidden;
          .add-icon {
            width: 100%;
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .text {
              font-weight: 400;
              font-size: 12px;
              line-height: 22px;
              color: #666565;
              text-align: center;
              margin-top: 12px;
            }
          }
        }
      }
      .upload-file-btn {
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f7f8fa;
        white-space: pre-line;
        justify-content: center;
        text-align: center;
        background: #f7f8fa;
        border: 1px dashed #e5e6eb;
        border-radius: 8px;

        .upload-icon {
          width: 23.04px;
          height: 23.04px;
          margin-bottom: 16px;
          background: transparent;
        }
        .icon {
          color: #787880;
          position: relative;
          margin-bottom: 12px;
        }
        .text {
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          padding: 0 20px;
          color: #666565;
        }
      }

      .edit-post-content {
        width: 100%;
        height: auto;
      }

      .file-zip {
        width: 100%;
        height: 100%;
        padding: 0 12px;
        overflow: hidden;
        position: relative;
        background: #f7f8fa;
        border-radius: 8px;
        .close-zip {
          position: absolute;
          top: 16px;
          right: 16px;
          background-color: $color-01;
          color: $color-08;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 15px;
            height: 15px;
          }
        }
        .file-zip-anticon {
          color: $color-08;
          position: relative;
          top: unset !important;
          right: unset !important;
          background: transparent;
          height: 43px;
          width: 52px;
          svg {
            height: 43px;
            width: 52px;
          }
        }
        .file-zip-content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 11.3px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        .name-file-zip {
          color: #1480ff;
        }
        .text {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .box-img-video-zip {
      margin-top: 10px !important;
    }
      
    #title {
      min-height: 44px;
    }
    textarea,
    .textarea {
      height: 44px;
      min-height: 44px;
    }
    .area-l-height {
      min-height: 122px;
    }
    input {
      border: 1px solid $color-32 !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    input::placeholder {
      color: #bebebe;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
    .form-control[readonly] {
      background-color: transparent;
    }
    input:disabled {
      cursor: auto;
      background-color: #efefef;
    }
    .form-label {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 22px !important;
      margin-bottom: 16px !important;
      color: #1a1a1a;
    }
    .fansite-form-input__controls {
      margin-bottom: 16px;

      #fsite-price,
      #fsite-title,
      #fsite-content,
      #fsite-setting,
      #fsite-condition {
        margin-bottom: 4px !important;
      }
    }
    .fansite-form-input__controls.box-type-sell {
      padding: 0 16px;
    }
    .bg-gray {
      padding: 12px 0px;
      background-color: $color-07;
      border-radius: 6px;
    }
    .box-category {
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      padding: 17px 0px 17px 13px;
      .box-checkbox {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        width: max-content;
        gap: 8px 17px;
        max-height: 500px;
        overflow-y: auto;
        width: 100%;

        @include mq('sm') {
          grid-template-columns: 1fr;
        }
        span.checkmark.checkbox {
          border: 1.5px solid #e5e6eb;
          border-radius: 3px;
        }
        &.list-package {
          .label-checkbox {
            margin-top: 0;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .scroll-bar-custom {
        &::-webkit-scrollbar-thumb {
          background: $color-32;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
      }
    }

    .box-category.bg-gray {
      width: 100%;
      .box-checkbox,
      .item {
        width: 100%;
      }
    }

    .box-time-sell {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 12px;
      margin-bottom: 16px;

      @include mq('l') {
        flex-direction: column;
      }
      .fansite-form-input__controls {
        margin-bottom: 0;
        display: flex;
        position: relative;
        align-items: center;
        gap: 16px;
        @include mq('mw768') {
          width: 100%;
        }
      }
      .sign {
        height: max-content;
      }
      .date,
      .time {
        position: relative;
        width: 85px;
        input {
          background-color: $color-01;
          cursor: pointer;
          padding-left: 9px;
        }
        .form-control {
          height: 44px;
          border-radius: 6px;
        }
        .time-icon {
          position: absolute;
          right: -2px;
          top: 50%;
          transform: translateY(-50%);
          color: $color-14;
          z-index: 3;
          @media screen and (max-width: 767px) {
            top: 44%;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      @include mq('mw768') {
        flex-direction: column;
        .start-time,
        .end-time {
          flex-grow: 1;
          max-width: 559px;
        }
        .time {
          width: 85px;
          height: 34px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          position: relative;
          input {
            width: 85px;
          }
        }
        input {
          height: 34px;
        }
        .anticon {
          width: 15px !important;
          height: 15px !important;
        }
      }
    }
    .list-performer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .item-performer {
        font-size: 14px;
        font-weight: 700;
        color: #1a1a1a;
      }
      margin-bottom: 16px;
    }
    .btn-performer {
      color: $color-28;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      width: fit-content;
      &.post-approved {
        cursor: default;
        color: $color-14;
      }
    }

    .change-color {
      input::-webkit-input-placeholder {
        color: #1a1a1a !important;
      }
    }

    .start-time,
    .end-time,
    .time {
      position: relative;
      width: 150px;
      cursor: pointer;
      display: flex;
      gap: 6px;
      @include mq('sm') {
        flex-grow: 0;
      }

      input {
        cursor: pointer;
        &:disabled {
          color: $color-08 !important;
          background-color: #eee;
        }
      }
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 3;
      }
    }

    .start-time input:disabled {
      background-color: $color-07;
      cursor: auto;
    }

    .box-checkbox,
    .box-radio-checkbox {
      display: flex;
    }

    input,
    textarea,
    .textarea {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      border: 1px solid $color-32;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 9px 12px;
      z-index: 2;
      height: 44px;
      &:focus {
        outline: none;
      }
      &::placeholder,
      .textarea.title[contenteditable]:empty::before,
      .textarea.content[contenteditable]:empty::before {
        color: #bebebe;
        font-size: 14px;
      }
    }
    .input-price,
    .input-condition {
      input {
        padding-right: 75px;
      }
      position: relative;
      &::after {
        width: 90px;
        height: 100%;
        position: absolute;
        right: 0;
        padding-top: 10px;
        color: $color-26;
        font-size: 14px;
        text-align: center;
        line-height: 22px;
        background-color: #fcecef;
        border-radius: 0px 6px 6px 0px;
      }
    }
    .input-price::after {
      content: attr(after-content);
    }
    .input-condition::after {
      content: attr(after-content);
    }
  }
  .add-error-message {
    margin-bottom: 32px !important;

    &.add-error-message-custom {
      margin-bottom: 48px !important;
      .error-message-custom {
        top: calc(100% + 6px);
      }
    }
    &.add-error-message-date {
      margin-bottom: 40px !important;
      .error-message-custom {
        top: calc(100% + 12px);
      }
    }
  }
  .start-time,
  .end-time,
  .box-category,
  .bg-gray {
    position: relative;
  }
  .text-error {
    color: rgb(255, 0, 0);
    width: 100%;
    white-space: pre-wrap;
    margin-top: 4px;
  }
  .error-message-date,
  .error-message-custom {
    position: absolute;
    top: calc(100% + 16px);
    white-space: nowrap;
  }
  .error-message-custom {
    left: 0;
    top: calc(100% + 8px);
  }
  .group-method {
    &.fansite-form-input__controls {
      margin-bottom: 8px;
    }
    .label-checkbox {
      margin-top: 8px;
      margin-bottom: 10px;
    }
    .group-haft {
      display: flex;
      flex-direction: column;
      span.checkmark.checkbox {
        border-radius: 50%;
      }
      .item-haft {
        width: calc(100% - 28px);
        align-self: flex-end;
        align-items: flex-start;
        &.error-message {
          margin-bottom: 24px;
        }
      }
    }
    .group-both {
      display: flex;
      align-items: center;
      gap: 18px;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
    }
  }
  .discount-group {
    &.error-start-discount {
      margin-bottom: 40px;
    }
    &.group-haft {
      display: flex;
      flex-direction: column;
      .item-haft {
        width: calc(100% - 28px);
        align-self: flex-end;
      }
    }
    .group-time {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 18px;
      @media screen and (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
      }
      &.error-start-discount {
        margin-bottom: 32px;
      }
      .left,
      .right {
        width: 50%;
      }
      .item-time {
        &.fansite-form-input__controls {
          margin-bottom: 0px;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .start-time {
          width: 181px;
          min-width: 150px;
          margin-right: 20px;
          @include mq('xl') {
            width: 161px;
          }
        }
        .time {
          width: 110px;
          min-width: 80px;
          @include mq('xl') {
            width: 100px;
          }
          .time-icon {
            z-index: 2;
            position: absolute;
            right: 4px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@include mq('ml') {
  .fansite-creator-post-content {
    form {
      .start-time,
      .end-time {
        width: 181px;
      }
    }
  }
}
@include mq('sm') {
  .fansite-creator-post-content {
    form {
      .box-time-sell {
        width: 100%;
        flex-direction: column;

        .start-time,
        .end-time {
          width: 100%;
          min-width: 115px;

          input {
            height: 34px;
            font-size: 12px;
            &::placeholder {
              font-size: 10px;
            }
            padding-left: 8px;
          }
          .anticon {
            right: 11px;
            height: 7px;
          }
          .anticon,
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}
@include mq('l') {
  .fansite-form-input__controls.have-error {
    margin-bottom: 38px !important;
    .error-message-date.error-message-custom {
      left: 0;
      top: 52px;
    }
  }
}

@include mq('sm') {
  .fansite-form-input__controls.have-error {
    margin-bottom: 32px !important;
    .error-message-date.error-message-custom {
      left: 0;
      top: 44px;
    }
  }
  .fansite-creator-post-content {
    padding-top: 0;

    form {
      padding: 0 16px;
      .file-zip {
        width: 100% !important;
        max-width: unset !important;
      }
      .box-img-video {
        margin-bottom: 16px;
        margin-top: 16px;

        .record-content {
          height: 200px;
        }

        .box-item {
          .anticon {
            top: 12px;
            right: 12px;
            width: 24px;
            height: 24px;
          }
          &.img-des {
            .anticon {
              top: 8px;
              right: 8px;
              width: 18px;
              height: 18px;
            }
          }
        }
        .main-video,
        .trailer-video {
          min-height: 193px;
          width: 100% !important;
        }

        .list-video {
          width: 100%;
        }

        .list-img-sample {
          width: 100%;
          .list-img {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 16px;
            .img-des {
              overflow: hidden;
              position: relative;
              width: calc(50% - 8px);
              aspect-ratio: 4/3;
              max-height: 117px;
              border-radius: 8px;
              overflow: hidden;
            }
            .add-input.img-des {
              width: calc(50% - 8px);
              max-height: 123px;
              aspect-ratio: 4/3;
              background: #f7f8fa;
              border: 1px dashed #e5e6eb;
              border-radius: 8px;
              .add-icon {
                position: absolute;
                top: 48%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                color: $color-01;
                font-weight: 500;
                font-size: 15px;
                @include mq('sm') {
                  svg {
                    height: 22px;
                    width: 22px;
                  }
                }
              }
              .text {
                font-weight: 400;
                font-size: 12px;
                color: #666565;
                margin-top: 6px;
                text-align: center;
              }
            }
          }
        }
        .list-img-box {
          width: 100%;
          flex-direction: column;
        }
        .list-img-out {
          display: grid !important;
          gap: 15px;
          width: 100% !important;
          grid-template-columns: repeat(2, 1fr);
          .add-input {
            width: 100% !important;
          }
          .img-des.box-item {
            width: 100% !important;
          }
        }
      }

      .box-time-sell {
        .start-time,
        .end-time {
          width: 55%;
          flex-grow: 0;
        }
      }
    }
    .error-message-date {
      font-size: 10px;
    }
  }
}

.fansite-creator-post-content.edit {
  #content,
  .textarea {
    height: fit-content;
  }
}
