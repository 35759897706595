@import 'src/style/variables';
@import 'src/style/mixin';
.fansite-verify-age {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 60px);

  @include mq("sm") {
    height: 100vh;
  }

  .box-verify-age {
    max-width: 375px;
    width: 375px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    background: #FFFFFE;
    border: 1px solid #FFFFFE;
    border-radius: 8px !important;
    overflow: hidden;
    padding: 16px;
  }

  .header-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 28px;
    }
    .logo-setting {
      width: 150px;
      height: 140px;
    }
    padding-bottom: 40px;
    @media screen and (max-height: 640px) {
      padding-bottom: 20px;
    }
    background: $color-01;
    .logo-cocofans {
      background-color: transparent;
    }
  }
  .title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 24px;
    margin-bottom: 40px;
    @media screen and (max-height: 640px) {
      margin-bottom: 20px;
    }
    color: $color-03;
    &.sub-title {
      font-size: 18px;
      line-height: 26px;
      margin-top: 0;
      margin-bottom: 16px;
    }
  }

  .text {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-08;
    margin-bottom: 34px;
    p {
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .text-stop {
    margin-bottom: 80px;
  }

  .note {
    color: $color-06;
    text-align: center;
  }

  .content-notfound {
    margin-bottom: 60px;
  }

  .content-maintenance {
    margin-bottom: 0;
    padding: 0px 8px;
  }
  .content-maintenance + .content-maintenance {
    padding-bottom: 24px;
  }

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (max-height: 640px) {
      gap: 16px;
    }
    padding: 0 0 24px 0;

    @include mq("sm") {
      gap: 12px;
    }
    .btn {
      width: 100%;
      border-radius: 8px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.002em;
      padding: 10px 0;
    }
    .btn-yes {
      color: $color-01;
      background-color: $color-26;
      &:hover  {
        background-color: $color-27;
      }
    }
    .btn-no {
      color: #1A1A1A;
      border: 1px solid #E5E6EB;
    }
  }

  .footer {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    svg {
      width: 80px;
      height: 22px;
    }
    margin-top: 8px;
    height: 60px;
  }
}
