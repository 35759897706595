@import 'src/style/variables';
@import 'src/style/mixin';
ul.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $color-01;
  height: 66px;
  li {
    flex-basis: unset;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-14;
    cursor: pointer;
    width: 120px;
    text-align: center;
    &.active {
      color: $color-06;
      border-bottom: 2px solid $color-06;
      font-weight: 700;
    }
  }
}
