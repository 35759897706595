.thumbnail-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e5e6eb !important;
  border-radius: 8px;
  color: #1A1A1A;
  font-size: 14px;
  line-height: 24px;
  font-weight: 200;
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
}
