$grey: #b1b6bb;
@import 'src/style/variables';
@import 'src/style/mixin';

@mixin img-size {
  height: 342px;
}

.border-grey {
  border: 2px dashed $grey;
}

.quick-view-img {
  transition: 0.5s;
  object-fit: cover;
  flex-direction: column;
  @include img-size;
  border-radius: 6px;
  .des {
    color: $grey;
  }
  .error-image {
    z-index: 1;
    text-shadow: 0px 1px #ffffff;
  }
}

.quick-view-dots {
  position: absolute;
  bottom: 25px;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
  span {
    display: block;
    background-color: $color-14;
    width: 6px;
    height: 2px;
    border-radius: 5px;
    margin: 4px;
    cursor: pointer;
    &.active {
      background-color: #ff0000;
    }
  }
}

.img {
  // object-fit: cover;
  @include img-size;
  border-radius: 6px;
}

.thumbnail-cross-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  transition: 0.5s;
  color: $color-01;
  cursor: pointer;
}

.slide-prev-icon,
.slide-next-icon {
  color: $color-01;
}
.slide-prev-icon {
  z-index: 2;
  left: 5px;
}

.slide-next-icon {
  z-index: 2;
  right: 5px;
}

@for $i from 0 through 3 {
  .transform-#{$i} {
    transform: translateX(#{$i * -100%}) !important;
  }
}

.quick-view-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.camera {
  width: 106px;
  bottom: 48px;
  z-index: 2;
  cursor: pointer;
  .icon svg {
    width: 20px;
    height: 20px;
  }
  .upload-des {
    color: #1A1A1A;
    font-weight: 400;
    font-size: 16px;
  }
  &.camera-absolute {
    position: absolute;
    bottom: 50px;
  }
}

.quick-view-container {
  max-width: calc(100vw - 1rem);
  margin: 0 auto;
  .quick-view-img {
    height: 241px;
    object-fit: contain;
    @include mq('md') {
      height: 400px;
    }
    .img {
      width: unset !important;
      //aspect-ratio: 4/3;
      height: 100%;
      object-fit: contain;
    }
    @include mq('sm') {
      height: 257px;
      .img {
        width: 100% !important;
      }
    }
  }
}
