.stream-player {
  display: flex;
  flex: 1 1;
}
.current-view {
  // position: relative;
  // background-repeat: no-repeat;
  // background-position: 50%;
  // background-color: #000000;
  // background-size: 280px;
  background: #000000;
  border-radius: 10px;
}

.current-view,
.meeting {
  display: flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  .wrap-payment-card-sub {
    .popup {
      z-index: 4000;
    }
    .layer-spinner {
      z-index: 4100;
    }
  }
}
.creator-v5-cls-view div {
  video {
    object-fit: none !important;
  }
}
// video {
//   display: block;
//   left: 0;
//   top: 0;

// }

.str-sc-user {
  video {
    object-fit: cover !important;
  }
}

// video {
//     object-fit: contain !important;
// }

// .str-sc-user  {
//   div {
//     video {
//       object-fit: none !important;
//     }
//   }
// }
