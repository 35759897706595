@import '../../../style/variables.scss';
.page-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: $color-03;
}
label {
  font-weight: 400;
  font-size: 14px;
}
input.fsite-input,
textarea.fsite-input,
select.fsite-input {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  border: 1px solid $color-32;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 12px;
  z-index: 2;
  height: 44px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $color-14;
    font-size: 14px;
  }
  &:read-only,
  &:disabled {
    color: #BEBEBE;
    border-color: #E5E6EB;
    background-color: #EFEFEF;
  }
}

textarea.fsite-input {
  min-height: 121px;
}

.bg-red {
  background-color: #DC143C;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    background-color: $color-27;
  }
}

.btn-bg-red {
  background-color: #DC143C;
  width: 92px;
  &:hover {
    background-color: $color-27;
  }
  &:focus {
    box-shadow: none;
  }
}

.app-btn {
  height: 44px;
}

.bg-grey {
  background-color: #EEEEEE;
}

.button-form-disabled-bg {
    background-color: $color-14;
}