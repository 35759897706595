@import 'src/style/variables';
@import 'src/style/mixin';

.page-succes-wrapper {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .page-success-box {
    width: 100%;
    max-width: 375px;
    background-color: #ffffff;
    box-shadow: 0 4px 24px 0 rgba(0,0,0,0.1);
    .message-success {
      font-size: 18px;
    }
    .page-header {
      padding: 32px 24px;
      text-align: center;
    }
    .page-body {
      padding: 16px 20px;
    }
    .page-footer {
      padding: 14px 24px;
      text-align: center;
      border-top: 1px solid #EEEEEE;
      justify-content: center;
    }
  }
}