@import 'src/style/variables';
@import 'src/style/mixin';
.fansite-sign-up {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  .box-sign-up.box-sign-up-fan {
    width: 375px;
    min-width: 375px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 28px;
    }
    padding-top: 33px;
    padding-bottom: 25px;
    background: $color-01;
  }

  .fansite-form {
    padding: 0 16px 32px 16px;
    background: $color-01;
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      color: $color-03;
    }
    .text {
      text-align: center;
      color: $color-08;
      span {
        color: $color-04;
      }
    }
    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.button-submit {
        margin-bottom: 12px;
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 4px;
        .fansite-form-input__required {
          display: none;
        }
      }
      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      input,
      select {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-14;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &.is_affiliator {
        margin-bottom: 8px;
      }
    }
    .confirm-password,
    .password,
    .gender,
    .birthday {
      position: relative;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    .gender {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          height: 11px;
        }
      }
    }
    .birthday {
      .form-control[readonly] {
        background-color: transparent !important;
      }
      svg {
        width: 15px;
        height: 15px;
      }
      .anticon {
        z-index: 0 !important;
        cursor: pointer;
      }
      .input-react-calendar {
        z-index: 2;
      }
    }
    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }
      button {
        height: 100%;
        width: 100%;
        font-weight: bold;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        &:focus {
          outline: none;
        }
      }
    }
    .box-radio-checkbox {
      display: flex;
      .item {
        & + .item {
          margin-left: 20px;
        }
        width: max-content;
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: $color-03;
        }
        input {
          opacity: 0;
          position: absolute;
          width: 16.67px;
          height: 16.67px;
          left: 0;
          margin: 0;
          top: 3px;
        }

        .checkmark.radio {
          border-radius: 50%;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    svg {
      width: 80px;
      height: 22px;
    }
    margin-top: 8px;
    height: 60px;
  }
}
.fansite-sign-up-success {
  height: calc(100vh - 60px);
}
