@import 'src/style/variables';
@import 'src/style/mixin';

.live-archived-header {
  .back-to-page {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
}

.live-archived-header {
  .custom_box {
    &.empty-box {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 100%;

      @include mq('sm') {
        position: fixed;
      }
    }
  }
}

.shimmer-loading {
  padding: 16px 24px;
}

.time-title {
  color: #282727;
  font-weight: 500;
  font-size: 18px;
  margin: 20px 0 0 24px;
}

.page-list-recording {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  background-color: $color-01;
  @include mq('mw768') {
    gap: 7px;
    padding: 16px;
    display: block;
  }

  .cls-title {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    //width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-03;
    display: inline-block;
    max-width: 100%;
  }

  .cls-title-2nd {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-03;
  }

  .item {
    background-color: #fafafa;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    @include mq('sm') {
      margin-bottom: 16px;
    }

    .delete-recording {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 15;
    }

    .thumbnail-video {
      width: 100%;
      height: 183px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      .no-background-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .blue-img {
        height: 183px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        .bg-img {
          border-radius: 10px 10px 0px 0px;
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 9;
          background-size: cover;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(20px);
          min-height: unset;
          max-height: unset;
        }

        .image-item {
          position: absolute;
          z-index: 10;
          background: #ffffff;
        }
      }
      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        z-index: 11;
        position: relative;
      }
    }

    .vjs-poster {
      background-color: #fff;
    }
    .content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 95%;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
      height: 22px;
    }

    video {
      // background-color: #000;
      max-width: 100%;
      //height: 440px;
      background-size: contain;
    }
    .img-timeline-wrapper {
      // background-color: #000;
      height: 440px;
      overflow: hidden;
      width: 100%;
      text-align: center;
    }
    @include mq('mw768') {
      .img-timeline-wrapper,
      video {
        height: 220px;
        //width: 343px;
      }
      .button-play-custom {
        width: 42px;
        height: 28px;
        border-radius: 8px;
        svg {
          width: 12.5px;
          height: 14.5px;
        }
      }
    }

    & .GroupIcon {
      display: flex;
      align-items: center;
      margin-top: 16px;
      justify-content: space-between;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 16px;

      .wrap--text {
        width: 100%;
      }

      .bottom-content {
        display: flex;
        justify-content: space-between;

        .live-date {
          color: #666565;
          font-size: 12px;
        }

        .live-time {
          color: #1a1a1a;
          font-weight: 700;
          font-size: 12px;
        }
      }

      &.scroll-bar-custom-horizontal::-webkit-scrollbar-thumb {
        height: 1px;
        background: $color-08;
        border-radius: 10px;
        border-top: 1px white;
        border-bottom: 1px white;
      }
      &::-webkit-scrollbar {
        height: 6px;
      }
    }
  }
}
