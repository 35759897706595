@import 'src/style/variables';
@import 'src/style/mixin';

.contracts {
  .back-to-page {
    display: flex;
    box-shadow: none;
    border-bottom: 1px solid #e5e6eb;
  }
}
.create-contracts {
  .contracts {
    width: 720px !important;
    padding: 16px 0 0 0 !important;
    .content {
      width: 100%;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
      padding-left: 24px;
      height: 56px;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #eeeeee;

      @include mq('sm') {
        padding-bottom: 12px;
        padding-left: 12px;
      }
    }
    .close-icon {
      position: absolute;
      right: 24px;
      top: 20px;
    }
    .box-button {
      padding: 0 24px;
      position: absolute;
      right: 0;
      bottom: 16px;
      z-index: 10;
    }
    .button-actions-relative {
      position: relative;
      height: 64px;
      bottom: 0;
      padding: 0 24px;
    }
  }
  .bg-while {
    &:hover {
      background-color: #f1f1f1 !important;
    }
  }
  .content-wrapper {
    width: 100%;
    max-height: 80vh;

    @include mq('md') {
      min-height: 400px;
    }
    @include mq('smHeight') {
      min-height: unset;
    }

    overflow: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
  }
  form {
    width: 100%;
    padding: 16px 24px;
    .group-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 16px;
      color: #1a1a1a;
    }
    .box-field {
      .fansite-form-input {
        &__label.form-label {
          font-weight: 400;
        }
      }
    }

    .fansite-form-input {
      &__label.form-label {
        display: flex !important;
        height: 32px;
        font-weight: 500;
        .uploaded {
          padding: 6px 16px;
          margin-left: 8px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          border: 1px solid #b7eb8f;
          background: #f6ffed;
          color: #52c41a;
          font-weight: 500;
        }
      }
      &__error {
        font-size: 13px;
      }
    }

    .button-actions {
      margin-top: 16px;
    }

    .group-name {
      display: flex;
      flex-wrap: wrap;
      gap: 0 24px;

      .box-field {
        width: calc(50% - 12px);

        @include mq('sm') {
          width: 100%;
        }
      }
    }

    .upload-file-btn {
      height: 226px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f7f8fa;
      white-space: pre-line;
      justify-content: center;
      text-align: center;
      background: #f7f8fa;
      border: 1px dashed #e5e6eb;
      border-radius: 8px;
      .icon {
        margin-bottom: 12px;
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.002em;
        margin-top: 4px;
      }
      .button-upload {
        width: 174px;
        height: 38px;
        margin-top: 16px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid #e5e6eb;
      }
    }

    .custom-input {
      font-weight: 400;
      input {
        border: 1px solid #e5e6eb !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
      }
    }

    .identify {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 16px;

      .identify-card-group {
        width: calc(50% - 12px);
        gap: 16px;
        display: block;

        @include mq('sm') {
          width: 100%;
        }

        .identify-card {
          margin-top: 0 !important;
          .card-img {
            position: relative !important;
          }
        }
      }
    }

    .notes {
      margin-top: 16px;
      p {
        font-size: 12px;
        line-height: 17px;
        color: #666565;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}

@include mq('sm') {
  .create-contracts {
    &.sm-w {
      position: static !important;

      .content-wrapper {
        max-height: 100%;
      }

      .contracts {
        border-radius: 0 !important;
        width: 100% !important;
        min-height: 100% !important;
        margin: 0 !important;

        .title {
          border-bottom: 0;
        }
        .content {
          width: 100%;
        }
        .form {
          padding: 0 16px;
          padding-bottom: 16px;
        }
      }
    }
  }
}
