@import 'src/style/variables';
@import 'src/style/mixin';

.popup-wrap {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .loading-timeline {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    z-index: 1000;
    height: 100%;
  }
}

.popup-view-full-post-timeline {
  width: 75%;
  background: rgba(26, 26, 26, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  cursor: pointer;
  padding: 50px;
  @media screen and (max-width: 640px) {
    padding: 40px 30px;
  }

  .lock-screen {
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    .text-follow {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    button {
      background-color: #dc143c;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 11px 24px;
      border-radius: 8px;
      font-size: 12px;
    }
  }

  .cls-title-2nd {
    color: #787880;
    margin-top: 0;
  }

  .close-icon {
    position: absolute;
    top: 40px;
    left: 20px;
    z-index: 18;
    background-color: #000;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    svg {
      display: flex;
      width: 25px;
      height: 25px;
      color: white;
    }
  }

  .pagination-slide {
    position: absolute;
    bottom: 10px;
    z-index: 10;
    width: 45px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, 0.3);
    border-radius: 2px;
    span {
      font-size: 12px;
      color: white;
    }
    &.hidden {
      display: none;
    }
  }

  .slide-popup-post-details {
    &.slide-image-full-view {
      width: 100%;
      height: 100%;

      .slick-arrow {
        background: $color-26;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        z-index: 1;

        &.slick-next {
          position: absolute;
          right: 3%;
          @include mq('md') {
            right: 0%;
          }
        }
        &.slick-prev {
          position: absolute;
          left: 3%;
          @include mq('md') {
            left: 0%;
          }
        }
        &:before {
          display: none;
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }

      .slick-list {
        width: 100%;
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide {
            > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              .popup-post-details {
                padding-bottom: 0;
                height: 100%;
                display: flex !important;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        .list-images-item-detail {
          // height: 360px;
          width: 100%;
          padding: 0;
          display: flex !important;
          justify-content: center;
          align-items: center;
          &.video {
            background-color: #1a1a1a;
            display: inline-block !important;

            .video-detail.trailer_video {
              width: 100%;
              height: 100%;
              z-index: 11;
              position: relative;
            }
          }
          .blue-img {
            // height: 360px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 640px;
            position: absolute;
            display: flex;
            justify-content: center;
            .bg-img {
              height: 100%;
              width: 640px;
              // @include mq('sm') {
              //   width: 304px;
              // }
              position: absolute;
              z-index: 9;
              background-size: cover;
              background: rgba(255, 255, 255, 0.2);
              backdrop-filter: blur(20px);
              min-height: unset;
              max-height: unset;
            }

            .image-item {
              position: absolute;
              z-index: 10;
              background: transparent;
              width: auto;
              // height: 360px;
              // @include mq('sm') {
              //   height: 193px;
              // }
            }
          }
          .change-bg {
            background-color: transparent;
          }
          .button-play-icon {
            display: flex;
            cursor: pointer;
            position: absolute;
            z-index: 3;
            width: 68px;
            height: 48px;
            padding: 2px;
            border: none;
            border-radius: 14px;
            background: transparent;
            inset: 0;
            margin: auto;
            color: white;
            .anticon {
              display: flex;
              margin: 0 auto;
              align-items: center;
            }
          }
        }
      }
      .slick-dots {
        li {
          width: 50px;
          height: 50px;
          button {
            width: 50px;
            height: 50px;
            &:before {
              font-size: 20px;
              top: 15px;
              left: 15px;
              color: white;
            }
          }
        }
      }
    }
  }

  .slide-image-full-view.slide-post-details {
    width: 100%;
    height: 100%;

    .slick-arrow {
      background: $color-26;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      z-index: 1;

      &.slick-next {
        position: absolute;
        right: 3%;
      }
      &.slick-prev {
        position: absolute;
        left: 3%;
      }
      &:before {
        display: none;
      }
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          > div {
            height: 100%;
          }
          @include mq('sm') {
            // > div {
            //   height: 193px;
            // }
          }
        }
      }
      .list-images-item-detail {
        //height: 360px;
        aspect-ratio: 16/9;
        @supports not (aspect-ratio: 16 / 9) {
          padding-top: 56.25%;
        }
        width: 100%;
        padding: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        position: relative;
        // @include mq('sm') {
        //   height: 193px;
        // }
        .post-details-video {
          background-color: #fafafa;
          video {
            background-color: #1a1a1a !important;
          }
        }
        .show-btn-play {
          *::-webkit-media-controls-panel {
            display: none !important;
            -webkit-appearance: none;
          }
        }
        &.video {
          display: inline-block !important;
          padding: 0px;
          .video-detail.trailer_video {
            width: 100%;
            height: 100%;
            z-index: 11;
            position: relative;
          }
        }
        .blue-img {
          aspect-ratio: 16/9;
          @supports not (aspect-ratio: 16 / 9) {
            padding-top: 56.25%;
          }
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 100%;
          // @include mq('sm') {
          //   width: 304px;
          // }
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          @include mq('sm') {
            height: 100%;
          }
          .bg-img {
            height: 100%;
            width: 100%;
            // @include mq('sm') {
            //   width: 304px;
            // }
            position: absolute;
            z-index: 9;
            background-size: cover;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(20px);
            min-height: unset;
            max-height: unset;
          }

          .image-item {
            // position: absolute;
            z-index: 10;
            background: transparent;
            width: auto;
            //height: 360px;
            width: 100%;
          }
        }
        .change-bg {
          background-color: transparent;
        }
        .button-play-icon {
          display: flex;
          cursor: pointer;
          position: absolute;
          z-index: 3;
          width: 68px;
          height: 48px;
          padding: 2px;
          border: none;
          border-radius: 14px;
          background: transparent;
          margin: auto;
          color: white;
          inset: 0;

          &.button-play-icon-post-details {
            inset: unset;
            top: 65%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          &.popup-play-icon-post-details {
            position: absolute;
            inset: unset;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .anticon {
            display: flex;
            margin: 0 auto;
            align-items: center;
          }
        }
      }
    }
    .slick-dots {
      li {
        width: 50px;
        height: 50px;
        button {
          width: 50px;
          height: 50px;
          &:before {
            font-size: 20px;
            top: 15px;
            left: 15px;
            color: white;
          }
        }
      }
    }
  }

  img,
  video {
    cursor: pointer;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    margin: auto;
    &:focus {
      outline: none;
    }
  }

  @include mq('md') {
    img,
    video {
      max-width: 100%;
    }
  }

  .list-images-item-detail.video {
    .video-no-watermark {
      @include mq('md') {
        position: relative;
        z-index: 11;
        background-color: #fff;
      }
      .video-no-watermark-img {
        margin-top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include mq('md') {
          object-fit: contain;
        }
      }
    }
  }
}
.h-w-100{
  height: 100%;
  width: 100%;
  .img-cover {
    object-fit: cover !important;
  }
}
.left-content-comment {
  width: 25%;
  height: auto;
  background-color: #fff;
  //padding: 16px;
  .comment-wrap-popup {
    .div-list-cmt {
      .comment.block {
        form {
          .form-item {
            textarea {
              background-color: #ffffff;
              &:disabled {
                background-color: #efefef;
              }
            }
          }
          .anticon {
            position: absolute;
            // bottom: 10.67px;
            right: 11px;
            z-index: 3;
            //transform: rotate(45deg) !important;
            cursor: pointer;
            color: $color-14;
            &.active {
              color: $color-06;
            }
            svg {
              width: 19px;
              height: 21px;
            }
          }
        }
      }
    }
  }

  .d-middle-popup-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .cls-title {
    width: 50%;
  }

  .line {
    width: 94% !important;
    border-bottom: 1px solid #e5e6eb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto !important;
  }

  .box-popup {
    position: relative;
    margin-left: auto;
    .three-point-icon {
      z-index: 4;
      cursor: pointer;
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 229px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      right: 0;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        background: $color-01 !important;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07 !important;
          border-radius: 0px;
        }
        &:first-child {
          margin-top: 6px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.comment-wrap-popup {
  position: relative;
  // margin-top: 25px;
  //height: calc(100% - 242px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .sroll-list {
    padding: 0 16px;
    max-height: calc(100% - 100px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    .list-comment .comment-item .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0;
      overflow: visible;
    }
  }
  .div-list-cmt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 16px;
    z-index: 2000;
  }
}

.popup-wrap.popup-post-detail {
  .left-content-comment {
    display: none;
  }

  .popup-view-full-post-timeline {
    width: 100%;

    &.my-page-item {
      padding: 0;
    }
  }
}

.popup-wrap.list-media-item {
  z-index: 0 !important;
  // width: calc(100% - 32px);
  // margin: 0 16px;
  width: 100%;
  margin: 0;
  padding: 0 16px;
  background: transparent;
  position: relative;
  // @include mq('sm') {
  //   margin: 0 !important;
  // }
  .popup-view-full-post-timeline {
    background: #ffffff;
    padding: 0 50px;

    .close-icon {
      display: none;
    }

    .pagination-slide {
      top: 10px;
      left: 10px;
    }
  }
}

.popup-wrap.post-parent {
  @include mq('md') {
    padding: 0;
    margin-bottom: 5px;
  }
}

.popup-wrap.popup-post-detail-show-media {
  z-index: 4000 !important;
  .left-content-comment {
    display: none;
  }

  .popup-view-full-post-timeline {
    width: 100%;
  }
}
