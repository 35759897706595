@import 'src/style/variables';
@import 'src/style/mixin';

@mixin TypeImgSend {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 4px;
  img {
    width: 240px;
    overflow: hidden;
    object-fit: contain;
    object-position: bottom;
  }
}

@mixin TextIconWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@mixin TextIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

@mixin TypeImgSendForUser {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  img {
    width: 240px;
    overflow: hidden;
    object-fit: contain;
    object-position: bottom;
  }
}

@mixin ImgOrVideoPreviewItem {
  max-width: 160px;
  max-height: 160px;
  min-width: 160px;
  min-height: 160px;
  // background-color: $color-02;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  video,
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @include mq('sm') {
    max-width: calc(100% - 5px);
    min-width: calc(100% - 5px);
    max-height: auto;
    min-height: auto;
  }
}

.list-image {
  max-height: 90vh;
  overflow-y: auto;
  min-height: 100%;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  &.no-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textOptionNull {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $color-14;
  }
}

.page-message-detail {
  .shimmer-loading {
    height: 100%;
    overflow: hidden;
    padding: 24px;
    @include mq('sm') {
      padding: 16px;
    }
  }
  .back-to-page {
    margin-top: 0px !important;
  }
  .content-container {
    height: calc(100vh - 200px) !important;
    display: flex;
    flex-direction: column;
    min-height: unset;
    padding-bottom: 0 !important;
    @include mq('md') {
      position: fixed;
      left: 0%;
      right: 0%;
      overflow: auto;
      .creator-message-detail {
        border-radius: 0px;
      }
    }
  }
}

.creator-block-message {
  padding: 12px 24px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  &-left {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    width: calc(100% - 70px);
    &.isAdmin {
      width: calc(100% - 70px);
    }
    img {
      border-radius: 45px;
      width: 56px;
      height: 56px;
      object-fit: contain;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      color: #282727 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-right: -4px;
    &__hyphen {
      display: inline-block;
      width: 1px;
      height: 65px;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  @include mq('sm') {
    padding-bottom: 20px !important;
    padding: 16px;
  }
  span {
    color: #000000;
    line-height: 25px;
  }
  &.admin-block {
    padding: 15px 30px;
    @include mq('sm') {
      padding-bottom: 15px !important;
    }
  }
}

.popup-survey {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #282727b3;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-survey-container {
    max-width: 375px;
    width: calc(100% - 32px);
    max-height: 50vh;
    overflow-y: auto;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    &_header {
      height: 48px;
      display: flex;
      font-weight: 700;
      font-size: 16px;
      align-items: start;
      border-bottom: 1px solid #eeeeee;
      position: relative;
      color: #282727;
      padding: 16px 24px;
      height: 56px;
      &_close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    &_list {
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: space-between;
      flex-wrap: wrap;
      .item-survey {
        width: 100%;
        .survey-name {
          white-space: pre-wrap;
          word-break: break-all;
        }
        .form-check-input {
          border-radius: 45px;
        }
      }
    }
    &_submit {
      border-top: 1px solid #eeeeee;
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      align-items: center;
      .btn {
        border-radius: 6px;
      }
      .btn-submit {
        background-color: $color-26;
        color: $color-01;
        border: none;
        &:disabled {
          background-color: $color-30;
        }
      }
      .btn-cancel {
        background: #ffffff;
        border: 1px solid #e5e6eb;
        &:hover {
          background: #f1f1f1;
        }
      }
    }
  }
}

.creator-message-detail {
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  min-height: 100px;
  padding-bottom: 0 !important;
  background-color: #f7f8fa;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 20px 20px;
  @include mq('md') {
    height: calc(100% - 110px - 100px);
  }
  @include mq('sm') {
    height: calc(100% - 110px - 38px);
  }
  &.admin-chat {
    height: calc(100% - 70px);
    @include mq('md') {
      height: calc(100% - 120px);
    }
  }
  .content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    @include mq('md') {
      padding-bottom: 0px;
      margin-bottom: 60px;
    }
    @include mq('sm') {
      margin-bottom: 120px;
    }
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .item {
      width: 100%;
      position: relative;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      // @include mq('md') {
      //   &:first-child {
      //     margin-bottom: 48px;
      //   }
      // }
      .time {
        color: $color-16;
      }
      .from-user {
        width: 70%;
        display: flex;
        align-items: flex-start;
        margin-left: 24px;
        @include mq('sm') {
          margin-left: 16px;
        }
        .avatar-user {
          border-radius: 50%;
          max-width: 34px;
          max-height: 34px;
          min-width: 34px;
          min-height: 34px;
          margin-right: 12px;
          cursor: pointer;
          background-color: #eeeeee;
        }
        .time {
          text-align: start;
        }
        .type-video {
          video {
            width: 100%;
          }
        }
        .type-text {
          width: fit-content;
          max-width: 100%;
          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            padding: 12px 20px;
            background: $color-32;
            border-radius: 16px 16px 16px 0px;
            white-space: pre-wrap;
            word-break: break-all;
            a {
              color: $color-03 !important;
            }
          }
        }
        .type-img {
          @include TypeImgSendForUser;
        }
        .type-coin {
          .coin {
            display: flex;
            align-items: center;
            background: #e5e6eb;
            padding: 11px 16px;
            color: #1a1a1a;
            font-weight: 400;
            font-size: 14px;
            border-radius: 16px 16px 16px 0px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            .number {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .type-file {
          .file {
            display: flex;
            align-content: center;
            padding: 11px 16px;
            background-color: $color-32;
            border-radius: 16px 16px 16px 0px;
            cursor: pointer;
            max-width: 600px;
            white-space: pre-wrap;
            word-break: break-all;
            & + .file {
              margin-top: 4px;
            }
            .arrow-icon {
              height: 10px;
              align-self: center;
            }
          }
          &.delete {
            .file {
              cursor: default;
              color: #bebebe;
            }
          }
          .name {
            margin: 0 15px 0 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
          .file-icon {
            width: 24px;
            height: 24px;
          }
          .arrow-icon {
            transform: rotate(-90deg);
            color: $color-08;
            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .from-me {
        width: 70%;
        margin-right: 16px;
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        .type-video {
          text-align: end;
          position: relative;
          video {
            width: 100%;
          }
        }
        .time {
          text-align: end;
        }
        .type-text {
          width: 100%;
          .text-icon {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .text {
              position: relative;
              max-width: 100%;
            }

            .box-icon-more {
              left: -20px;
            }
          }
          .text {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            padding: 12px;
            color: $color-01;
            background: $color-26;
            border-radius: 16px 16px 0px 16px;
            white-space: pre-wrap;
            word-break: break-all;
            a {
              color: #fff;
            }
          }
        }
        .type-img {
          @include TypeImgSend;
          .text-icon {
            @include TextIconWrap;
          }
          .text-icon-img {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            position: relative;
            .list-img {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
        .type-coin {
          .text-icon {
            @include TextIcon;
          }
          .coin {
            display: flex;
            align-items: center;
            background: $color-26;
            position: relative;
            padding: 11px 16px;
            border-radius: 16px 16px 0px 16px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            .number {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: $color-01;
            }
          }
        }
        .type-file {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .text-icon {
            @include TextIcon;
          }
          .file {
            color: white;
            background-color: $color-26;
            display: flex;
            align-content: center;
            padding: 11px 16px;
            border-radius: 16px 16px 0px 16px;
            cursor: pointer;
            white-space: pre-wrap;
            word-break: break-all;
            & + .file {
              margin-top: 4px;
            }
            .arrow-icon {
              height: 10px;
              align-self: center;
            }
          }
          &.delete {
            .file {
              cursor: default;
              background-color: $color-32;
              color: #bebebe;
            }
          }
          .name {
            margin: 0 15px 0 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
          .file-icon {
            width: 24px;
            height: 24px;
          }
          .arrow-icon {
            transform: rotate(-90deg);
            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  .post-content {
    padding: 20px 24px;
    z-index: 20;
    @include mq('md') {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #f7f8fa;
      width: 100%;
      padding: 16px;
    }

    .fansite-form {
      background-color: transparent;

      @include mq('sm') {
        padding: 0;
      }
      .img-video-wrapper {
        padding: 12px 0 12px 12px;
        background-color: white;
        overflow-y: auto;
        max-height: 250px;
        max-width: calc(100% - 60px);
        border: 1px solid #e5e6eb;
        border-radius: 8px 8px 0px 0px;
        @include mq('sm') {
          max-width: calc(100% - 34px);
        }
        &::-webkit-scrollbar {
          width: 3px !important;
        }
        &::-webkit-scrollbar-thumb {
          background: #e5e6eb;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        @include mq('sm') {
          padding: 0;
          max-height: 200px;
        }
        .list-img-preview {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          @include mq('l') {
            gap: 8px;
          }
          @include mq('mw768') {
            grid-template-columns: repeat(3, 1fr);
          }
          @include mq('sm') {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        .remove-icon {
          position: absolute;
          top: 12px;
          right: 12px;
          color: $color-01;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2;
          svg {
            width: 40px;
            height: 40px;
          }
        }
        .img-preview {
          @include ImgOrVideoPreviewItem;
        }
        .video-preview {
          @include ImgOrVideoPreviewItem;
        }
      }
      .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .meta-item {
          padding: 0px 12px 12px 12px;
        }
        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          max-width: calc(100% - 60px);
          &.full {
            max-width: 100%;
          }
          @include mq('sm') {
            padding-right: 0;
            margin-right: 12px;
            max-width: none;
          }

          .fansite-form-input__controls {
            margin-bottom: 0;
            background: #ffffff;
            border: 1px solid #e5e6eb;
            border-radius: 8px;
            position: relative;
            &.have-img {
              border-radius: 0px 0px 8px 8px;
            }
          }
          .icon-send-coin {
            position: absolute;
            top: 12px;
            right: 50px;
          }
          button {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: transparent;
            border: none;
            color: $color-26;
            &:disabled {
              color: $color-30;
            }
          }
          textarea {
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;
            border: none;
            box-sizing: border-box;
            border-radius: 6px;
            padding: 9px 12px;
            z-index: 2;
            min-height: 44px;
            height: 44px;
            width: calc(100% - 80px);
            @include mq('md') {
              max-height: 300px !important;
            }
            &::-webkit-scrollbar {
              display: none;
            }
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: $color-14;
              font-size: 14px;
            }
          }
        }
        .content-right {
          display: flex;
          @include mq('sm') {
            margin-right: 0;
          }
          &.has-video-img {
            display: flex;
            align-items: flex-end;
          }
          .box-icon-plus {
            position: relative;
            .pop-up {
              position: absolute;
              background: $color-01;
              box-shadow: 0px 40px 64px rgba(0, 0, 0, 0.05);
              border-radius: 6px;
              width: 189px;
              height: 105px;
              padding: 10px 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              bottom: calc(100% + 6px);
              right: 20px;
              border-radius: 6px;
              overflow: hidden;
              z-index: 10;
              &.isAdmin {
                height: 50px;
              }
              input {
                background-color: red;
                border: none;
                visibility: hidden;
                height: 0;
              }
              .item {
                display: flex;
                align-items: center;
                padding-left: 8px;
                cursor: pointer;
                height: 38px;
                &.disabled-survey {
                  cursor: default;
                  opacity: 0.5;
                }
                &:hover {
                  .icon-add {
                    color: $color-26;
                  }
                  background-color: #fdf3f5;
                }
                .anticon {
                  color: #282727;
                  width: 20px;
                  height: 20px;
                }
                .text {
                  font-size: 12px;
                  line-height: 22px;
                  margin-left: 8px;
                }
              }
              .item.item1.disabled {
                color: $color-08;
                .anticon {
                  color: $color-08;
                }
              }
            }
            .icon-plus {
              cursor: pointer;
              &.disable {
                cursor: default;
              }
              svg {
                height: 22px;
                width: 22px;
                margin-right: 15px;
                color: $color-17;
                @include mq('sm') {
                  margin-right: 0;
                }
              }
            }
          }
          .button-submit {
            &.disabled {
              background-color: transparent;
              button {
                color: $color-14;
                cursor: auto;
              }
            }
            button {
              font-weight: bold;
              color: $color-06;
              background: transparent;
              box-sizing: border-box;
              border: none;
              padding: 0;
              cursor: pointer;
              position: relative;
              .anticon {
                svg {
                  height: 20px;
                  width: 20px;
                }
              }
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
