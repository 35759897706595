@import 'src/style/variables';
@import 'src/style/mixin';

.view-affiliator--home-page {
  .content-box-affiliator {
    background: #fff;
    height: 100%;
    padding: 16px 18px;
    .affiliator-referen_url-title {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    .affiliator-referen_url {
      display: flex;
      align-items: center;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 10px;
      .icon_copy_affiliator {
        color: #ff0000;
        margin-left: 10px;
      }
    }
  }
}
