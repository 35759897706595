@import "variables";
//
// helper
//
.--max-width-468-pc{
  @include mq('m'){
    max-width: 468px!important;
  }
}

.--max-width-375-pc{
  @include mq('xs'){
    max-width: 375px!important;
  }
}

