@import 'src/style/variables';
@import 'src/style/mixin';

.list-idol-popular-content {
  background-color: $color-21;
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 60px auto;
    text-align: center;
  }
  .list-content {
    display: flex;
    justify-content: space-between;
    padding: 0 65px 65px 65px;
    .item {
      border-radius: 12px;
      overflow: hidden;
      width: 100%;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      max-width: 305px;
      img {
        width: 100%;
        height: 168px;
        object-fit: cover;
      }
      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        background-color: $color-01;
        padding: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        white-space: nowrap;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .slick-list {
    padding: 20px 10px 20px 10px;
    width: 100%;
  }

  .slick-slide {
    & > div {
      width: calc(100% - 25px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .slick-arrow {
    width: 32px;
    height: 32px;
    background-color: $color-01;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 10;
    transform: none;
    top: 40%;
    cursor: pointer;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    svg {
      color: $color-08;
    }
    &::before {
      display: none;
    }
  }
  .slick-prev {
    left: 16px;
  }
  .slick-next {
    right: 16px;
    transform: rotate(180deg);
  }
}

@include mq('l') {
  .list-idol-popular-content {
    .list-content {
      padding: 0 30px 30px 30px;
    }
  }
}

@include mq('md') {
  .list-idol-popular-content {
    background-size: 100% 100%;
    .title {
      margin: 20px auto 10px auto;
    }
    .list-content {
      padding: 0 0 30px 5px;
      .slick-list {
        padding: 20px 10px 20px 10px;
      }
      .slick-slide {
        display: block;
      }
    }
  }
}

@include mq('sm') {
  .list-idol-popular-content {
    .title {
      margin: 32px auto 10px auto;
    }
    .list-content {
      .item {
        img {
          height: 88px;
        }
        .name {
          padding: 3px 6px;
          font-size: 14px;
        }
      }
      .slick-arrow {
        top: 33% !important;
      }
    }
  }
}
