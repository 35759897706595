@import 'src/style/variables';
@import 'src/style/mixin';

.list_comment_detail {
  // display: flex;
  // flex-direction: column;
  // height: calc(100vh - 150px);
  // padding-top: 15px;
}
.search-mess-short{
  .div-list-cmt {
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    z-index: 20;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.mess-short{
  .div-list-cmt {
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    z-index: 20;
    background: #fff;
    border-top: 1px solid #ccc;
    padding: 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

//detail
.list-comment {
  .comment-item-wrapper .comment-item .reply-comment {
    .comment-item-reply {
      .comment-content.display-linebreak {
        max-width: 100%;
      }
    }
  }
  .infinite-scroll-component {
    height: initial !important;
    overflow: initial !important;
  }
  .comment-item-wrapper {
    position: relative;
    & + .comment-item-wrapper {
      border-top: 1px solid $color-07;
      margin-top: 6px;
      padding-top: 15px;
    }
  }
  .dp-flex {
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
    width: 100%;
  }
  .comment-item {
    display: flex;
    width: 100%;
    .avatar {
      cursor: pointer;
      img {
        min-width: 44px;
        min-height: 44px;
        max-width: 44px;
        max-height: 44px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      margin: 0;
      overflow: visible;
      .info-comment {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        .readmore-top-cmt {
          ::-webkit-scrollbar {
            display: none;
          }
        }
        .custom_name {
          display: block;
          margin-top: 0px;
          color: #1a1a1a;
          max-width: 380px;
          cursor: pointer;
          @include mq('sm') {
            max-width: 96px;
          }
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }

        .content-right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
        }
        .like-icon1 {
          margin-right: 5px;
          &.active {
            color: #dc143c;
          }
        }
        .cls-text {
          color: #666565;
          font-weight: 400;
          font-size: 14px;
          margin-right: 10px;
          line-height: 22px;
        }
        .left {
          width: 100%;
          cursor: pointer;
          .name {
            word-break: break-all;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            align-items: center;
            .account-name {
              max-width: 270px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
              @include mq('md') {
                max-width: 350px;
              }
              @include mq('sm') {
                max-width: 120px;
              }
            }
          }
          .comment-content {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            max-width: calc(100% - 60px);
            color: #1a1a1a;
          }
          .icon-edited {
            margin-left: 10px;
            svg {
              width: 14px;
              height: 14px;
              color: $color-08;
            }
          }
          .read-more {
            color: $color-28;
          }
        }
        .time {
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          //margin-top: 8.5px;
          white-space: nowrap;
          color: #666565;
        }
        .box-popup {
          position: relative;
          margin-left: auto;
          .three-point-icon {
            z-index: 4;
            cursor: pointer;
          }
          .popup-edit-comment {
            position: absolute;
            background: $color-01;
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            width: 189px;
            height: unset !important;
            display: flex;
            flex-direction: column;
            top: calc(100% + 8.5px);
            right: 0;
            border-radius: 6px;
            overflow: hidden;
            z-index: 15;
            .item {
              display: flex;
              align-items: center;
              padding-left: 18px;
              cursor: pointer;
              height: 38px;
              z-index: 10;
              color: $color-03;
              .anticon {
                margin-right: 8px;
              }
              &:hover {
                background-color: $color-07;
              }
              &:first-child {
                position: relative;
              }
              &:nth-child(2) {
                margin-bottom: 6px !important;
              }
              .text {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        .content-right {
          color: $color-08;
        }
      }
      .like-reply1 {
        margin-top: 8px;
        margin-bottom: 15px;
        color: $color-08;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        // span {
        //   cursor: pointer;
        // }
        .follow {
          margin-left: 16px;
        }
        .follow.active {
          color: $color-04;
          display: inline !important;
          width: max-content;
          border-bottom: none !important;
          font-size: 10px !important;
        }
        .reply-btn {
          margin-left: 16px;
        }
        .comment-icon {
          margin-left: 30px;
        }
        .like {
          font-weight: 400;
          font-size: 14px;
          color: #666565;
          line-height: 22px;
          display: flex;
          align-items: center;
        }
        .like.active {
          color: $color-06;
          display: inline !important;
          width: max-content;
          border-bottom: none !important;
          font-size: 10px !important;
        }
      }
    }
    .reply-comment {
      .comment-item-reply {
        gap: 10px;
        & + .comment-item-reply {
          border-top: none;
          padding: 0;
        }
      }
      .avatar {
        cursor: pointer;
        img {
          width: 44px !important;
          height: 44px !important;
          max-width: 44px !important;
          min-width: 44px !important;
          max-height: 44px !important;
          min-height: 44px !important;
        }
      }
      .like-reply {
        margin-bottom: 15px;
      }
      .content .info-comment {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: start;
      }
    }
    .form-reply-comment,
    .form-edit-comment {
      img {
        margin-top: 3px;
        width: 44px;
        height: 44px;
        max-width: 44px;
        min-width: 44px;
        max-height: 44px;
        min-height: 44px;
      }
      form {
        textarea {
          height: 44px !important;
          //height: fit-content;
          padding-right: 40px;
        }
      }
      .form-input-comment-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff !important;
        border: 1px solid #e5e6eb;
        .input-comment {
          border: 1px solid #e5e6eb;
        }
        &.disable {
          background-color: #efefef;
        }
        &.haveMeta {
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          padding: 6px 36px 12px 6px;
          textarea {
            border: none !important;
          }
          .input-comment {
            border: none !important;
          }
          .meta-tag-image {
            img {
              min-width: unset;
              min-height: unset;
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .form-reply-comment {
      margin-bottom: 16px;
    }
    .form-edit-comment {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .out-edit {
        font-size: 12px;
        line-height: 20px;
        color: $color-08;
        margin-top: 4px;
        margin-left: 10px;
        cursor: pointer;
        margin-bottom: 12px;
        span {
          color: #007be5;
        }
      }
    }
  }
  .view-more-comment,
  .collapse-comment {
    color: #787880;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }
  .view-more-comment {
    &:hover {
      color: $color-28;
    }
  }
  .textarea-post-rep-comment {
    &::placeholder {
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @include mq('sm') {
    .comment-content {
      max-width: calc(100% - 15px) !important;
    }
  }
}
