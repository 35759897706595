@import "src/style/variables";
@import "src/style/mixin";

.pick-range-input {

  .calendar-icon-from {
    padding-right: 8px;
  }

  padding: 4px 12px;
  position: relative;
  max-width: 300px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #b1b6bb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  .icon {
    color: #b1b6bb;
    position: absolute;
    right: 2px;
    cursor: pointer;
    margin-right: 12px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
  }
}
.pick-range-date {
  width: 230px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .label, .text-dark {
    color: #b1b6bb;
    font-size: 13px;
  }
}