@import 'src/style/variables';

.notification-layout {
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.creator-notification-page {
  background-color: $color-01;
  margin: 0 auto;
  border-top: 1px solid #eeeeee;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  .shimmer-loading {
    padding: 16px 24px;
  }

  .tab-container {
    display: flex;
    align-items: center;
    height: 48px;
    background: #f2f3f5;
    margin: 24px 24px 16px 24px;
    border-radius: 43px;
    @include mq('sm') {
      margin: 16px;
    }
    .item {
      display: flex;
      flex: 1;
      color: #666565;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      margin: 4px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
    }
    .active {
      // display: flex;
      font-weight: 500;
      color: $color-01;
      background-color: $color-26;
      font-size: 14px;
      // border-bottom: 2px solid $color-06;
    }
  }
  .body,
  .list {
    min-height: calc(100vh - 135px - 48px);
    @include mq('md') {
      min-height: calc(100vh - 130px - 48px);
    }
    .infinite-scroll-component,
    .infinite-scroll-component__outerdiv {
      min-height: calc(100vh - 135px - 48px - 32px);
      @include mq('md') {
        // max-height: calc(100vh - 24px - 48px - 72px - 64px - 12px - 60px);
        min-height: calc(100vh - 130px - 48px - 32px);
      }
    }

    .no-result {
      display: flex;
      flex-direction: column;
    }
    .tooltip.custom-text.creator {
      width: 100%;
      .text {
        max-width: 100%;
      }
      span.tooltiptext {
        min-width: 80px;
        max-width: 100%;
      }
    }

  }
  .body {
    background-color: $color-01;
    min-height: 100%;
    position: relative;
    .list {
      padding: 0 24px;
      @include mq('sm') {
        padding: 0 16px;
      }
      .infinite-scroll-component {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: unset !important;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        & > div {
          @include mq('sm') {
            width: 88%;
          }

          width: 100%;
          @media screen and (min-width: 1024px) and (max-width: 1440px) {
            width: 84%;
          }
        }
        div {
          cursor: pointer;
        }
        img {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
          object-fit: cover;
          border-radius: 50%;
          margin-right: 16px;
        }
        .logo-coco-fan {
          object-fit: contain;
          background-color: #eeeeee;
        }
        .logo-fan {
          cursor: pointer;
        }
        .content,
        .name {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
        }
        .content {
          min-width: 250px;
        }
        .name {
          color: $color-03;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          overflow: hidden;
          max-width: 500px;
          @include mq('md') {
            max-width: 500px;
          }
          @include mq('mw768') {
            width: 300px;
          }
          @include mq('sm') {
            width: 150px;
          }
        }
        .date {
          font-size: 14px;
          white-space: nowrap;
          // width: 25%;
        }
        .--isRead {
          color: $color-14;

          .date {
            color: $color-03;
          }
        }
        .--isUnRead {
          // color: $color-03;
          color: #666565;
        }
      }
    }
    .no-result {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100% - 70px);
      color: $color-14;
      font-size: 14px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .detail-noti {
    // padding: 16px;
    margin: 31px 24px 0 24px;
    background-color: $color-01;
    min-height: inherit;
    color: #1a1a1a;

    .detail-notification-title {
      font-weight: 700;
      font-size: 16px;
    }

    .date {
      font-weight: 400;
      font-size: 12px;
      color: #282727;
    }
    .title {
      font-weight: 700;
      font-size: 12px;
      margin: 10px 0;
      word-wrap: break-word;
    }
    .content {
      font-weight: 400;
      font-size: 14px;
      word-wrap: break-word;
    }
  }

  .setting-noti {
    background-color: $color-01;
    font-size: 14px;
    .title {
      font-weight: 700;
      padding: 16px;
    }
    .toggle {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
      label {
        font-weight: 500;
      }
    }
    .btn-setting {
      padding: 16px;
      button {
        height: 44px;
        width: 100%;
        margin-top: 10px;
        padding: 8px;
        border-radius: 6px;
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        border: 0;
        cursor: pointer;
        color: #fff;
        background-color: #ff0000;
      }
    }
  }
}
