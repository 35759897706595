@import 'src/style/variables';
@import 'src/style/mixin';

.time-line-home {
  .my-page {
    min-height: calc(100vh - 200px) !important;
  }
}
.button-next-api {
  background: #DC143C;
  border: none;
  color: #fff;
  border-radius: 8px;
  height: 44px;
  width: 300px;
}

.post-login {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  .content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 888px;
    @include mq('sm') {
      padding: 1px 16px;
    }
  }
  .my-page {
    @include mq('sm') {
      margin-top: 30px;
      min-height: revert !important;
    }
  }
  .title-timeline {
    margin: 20px 0px;
    @include mq('sm') {
      margin-top: 0px;
    }
  }
}
.title-timeline {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 700;
}

.time-line {
  background-color: $color-01;
  min-height: 100%;
}

.btn-follow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 80px;
  height: 36px;
  border: 1px solid #b1b6bb;
  border-radius: 6px;
  background: #ffffff;
}

.btn-followOrUnFollow {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 6px;
  background: #DC143C;
  color: #fff;
  height: max-content;
  white-space: nowrap;
  border-radius: 6px;
  border: 1px solid #000;
  cursor: pointer;
  
  &.following {
    color: #000;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #b1b6bb !important;
    font-weight: 700;
    border: none;
  }

  &.no-follow {
    color: #fff;
    border: 1px solid #e5e6eb;
    @media (min-width: 992px) {
      &:hover {
        background: rgba(255, 0, 0, 0.1) !important;
        border: 1px solid rgba(255, 0, 0, 0.1) !important;
        color: #DC143C;
      }
    }
  }

  &.hover {
    background: #f1f1f1;
    //background: rgba(255, 0, 0, 0.1);
    //border: 1px solid rgba(255, 0, 0, 0.1);
    //color: #DC143C;
    font-weight: 700;
  }
}

.lock-popup {
  .lock-content-common, .in_my_page {
    margin: 0 16px !important;
    width: calc(100% - 32px) !important;
  }
}

.my-page {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 180px) !important;
  textarea {
    &::placeholder {
      color: #bebebe;
    }
  }
  // video {
  //   background: #1A1A1A !important;
  // }
}

.div-ghim1 {
  position: relative;
  .popup-ghim1 {
    position: absolute;
    background: $color-01;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    width: 229px;
    height: unset !important;
    display: flex;
    flex-direction: column;
    left: 10px;
    top: 30px;
    border-radius: 6px;
    overflow: hidden;
    z-index: 15;
    .item1 {
      display: flex;
      align-items: center;
      padding-left: 18px;
      cursor: pointer;
      height: 38px;
      z-index: 10;
      background: $color-01 !important;
      color: $color-03;
      .anticon {
        margin-right: 8px;
      }
      &:hover {
        background-color: $color-07 !important;
        border-radius: 0px;
      }
      &:first-child {
        margin-top: 6px;
        position: relative;
      }
      &:nth-child(2) {
        margin-bottom: 6px;
      }
      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.block-quote {
  padding: 0;
  margin: 0 16px;
  border: 1px solid #e5e6eb;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  &:hover {
    background-color: #f2f3f5 !important;
  }
}

.style-img-box {
  img {
    object-fit: contain;
    width: 640px;
    height: 418px;
  }
  @include mq('sm') {
    img {
      width: 343px;
      height: 193px;
    }
  }
}

.block-quote-no-img {
  padding: 0 0px 16px 0px;
  margin: 0 16px;
  border: 1px solid #e5e6eb;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -5px;
  &:hover {
    background-color: #f2f3f5 !important;
  }
}

.popup-edit-comment {
  position: absolute;
  background: $color-01;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  width: 189px;
  height: unset !important;
  display: flex;
  flex-direction: column;
  top: 2;
  //top: calc(100% + 2px);
  right: 0;
  border-radius: 6px;
  overflow: hidden;
  z-index: 35;
  .item {
    display: flex;
    align-items: center;
    padding-left: 18px;
    cursor: pointer;
    height: 38px;
    z-index: 10;
    color: $color-03;
    .anticon {
      margin-right: 8px;
    }
    &:hover {
      background-color: $color-07;
    }
    &:first-child {
      margin-top: 6px;
      position: relative;
    }
    &:nth-child(2) {
      margin-bottom: 6px;
    }
    .text {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.empty-box {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
}

.disabled {
  color: rgb(177, 182, 187);
}

.hidden-block {
  display: none;
}

@include mq('mw1024') {
  .mr-top-mb {
    margin-top: 0px;
  }
}
.custom-content {
  left: 0px !important;
  width: calc(100% - 60px) !important;
}

.comment-wrap {
  margin-top: 10px;
  // height: calc(100% - 200px);
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  .form-input-comment-meta {
    border: 1px solid #e5e6eb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff !important;
    &.disable {
      background-color: #efefef;
    }
    &.haveMeta {
      border: 1px solid #e5e6eb;
      border-radius: 6px;
      padding: 6px 36px 12px 6px;
      .input-comment {
        border: none !important;
      }
    }
  }
  .btn-view-all {
    background-color: transparent;
    border: unset;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #666565;
    margin-bottom: 10px;
    &:hover {
      color: #1480ff;
    }
  }
}

.list-comment .comment-item .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  overflow: visible;
}

@include mq('sm') {
  .mobile-timeline {
    padding: 0px 16px;
  }
}

@include mq('md') {
  .mobile-timeline {
    padding: 0px 16px;
  }
}

@include mq('mw768') {
  .mobile-timeline {
    max-width: 100%;
  }
}
