@import 'src/style/variables';
@import 'src/style/mixin';
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  .table-affiliator.table-no-border-radius {
    margin: 0 auto;
    border: 0px solid #1c6ea4;
    background-color: #ffffff;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    &.table-sales-history {
      tr {
        & + tr {
          border-top: 1px solid #eee;
        }
        th {
          height: 55px;
        }
        td {
          height: 76px;
        }
      }
      .td-price,
      .td-name {
        min-width: 200px;
      }
      .td-date {
        width: 200px;
      }
    }

    .td-title {
      width: 150px;
      max-width: 150px;
    }
    .td-date,
    .td-status,
    .td-button {
      min-width: 100px;
    }
    .td-link {
      padding-right: 20px;
    }

    td,
    th {
      padding-left: 10px;
    }

    thead {
      display: table;
      width: 100%;
      height: 58px;
      background: #eeeeee;
    }
    thead th {
      font-size: 15px;
      font-weight: normal;
      color: #282727;
      text-align: left;
    }

    tbody {
      display: block;
      table-layout: fixed;
      overflow: auto;
    }
    tbody::-webkit-scrollbar {
      display: none;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tbody tr td {
      height: 54px;
      text-align: left;
      .btn__action-table {
        border: none;
        background: transparent;
        // style text btn-action
        color: #604de7;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.002em;
      }
    }
    .td-link:hover {
      overflow: visible;
    }
    .tooltip {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      cursor: auto;
      position: relative;
      .tooltiptext {
        top: 0;
        left: 0;
        right: unset;
        bottom: unset;
        transform: translate(-10%, 31%);
        max-width: max-content;
        @include mq('l') {
          transform: translate(-50%, 31%);
        }
        &::after {
          display: none;
        }
      }
    }
    tbody td,
    .text {
      font-size: 14px;
      color: #282727;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include mq('mw768') {
      min-width: 768px;
      width: 768px;
      overflow-x: auto;
    }
  }
}
.pagination-bar {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
