@import 'src/style/variables';
@import 'src/style/mixin';

.setting-noti-secur-opt {
  padding:  16px 24px;
  @include mq('sm') {
    padding:  16px;
  }
}
.text-des-2 {
  color: #1a1a1a;
  font-size: 14px;
  margin-bottom: 16px;
}
.block-code-opt-msm {
  max-width: 320px;
  @include mq('sm') {
    width: 100%;
    max-width: 100%;
  }
}