@import 'src/style/variables';
@import 'src/style/mixin';
.list-help {
  overflow-y: auto;
  height: auto;
  padding: 17px 16px 0 17px;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  .box-text {
    display: flex;
    align-items: center;
    gap: 8px;
    .anticon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .text1 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 9px;
    margin-bottom: 24px;
  }
  .btn-help {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background: #ff0000;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 24px;
    &:hover {
      background-color: $color-27;
    }
  }
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    margin-top: 21px;
    position: relative;
    margin-left: 10px;
    &::before {
      position: absolute;
      content: '';
      background-color: $color-03;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      bottom: 9px;
      left: -10px;
    }
    &.title2 {
      margin-top: 6px;
    }
  }
  .box-content {
    .item {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .sub-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
        }
        .icon {
          color: $color-08;
          font-weight: bold;
          font-size: 16px;
          .icon-subtraction {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              width: 9px;
              height: 2.29px;
              background-color: $color-08;
              top: 0;
              right: 0;
              border-radius: 1px;
            }
          }
        }
        &.no-active {
          padding-bottom: 20px;
          position: relative;
          margin-bottom: 18px;
          &::after {
            position: absolute;
            bottom: 0;
            width: calc(100% + 40px);
            height: 1px;
            background: $color-07;
            content: '';
            left: -16px;
          }
        }
      }
      &-content {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        margin-top: 10px;
        margin-bottom: 41px;
      }
    }
    &.box-content2 {
      .no-active {
        height: 60px;
        padding-bottom: 0;
        margin-bottom: 0;

        &::after {
          display: none;
        }
      }
      .sub-title {
        width: 280px;
      }
    }
  }
}

.post-help {
  .fansite-form {
    padding: 16px 16px 0 16px;
    background: $color-01;
    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.button-submit {
        margin-bottom: 16px;
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 4px;
        .fansite-form-input__required {
          display: none;
        }
      }
      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      select,
      textarea {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-14;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
        }
      }
      textarea {
        height: 166px;
        min-height: 166px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .category {
      position: relative;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    .category {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          height: 11px;
        }
      }
    }

    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }
      button {
        height: 100%;
        width: 100%;
        font-weight: bold;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
