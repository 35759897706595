@import 'src/style/variables';
@import 'src/style/mixin';

.custom-select-time-wrapper {
  .csp {
    cursor: pointer;
  }
}
.custom-time-post {
  position: absolute;
  left: 12px;
  width: 112px;
  z-index: 10 !important;
  top: 48px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  .hour,
  .minute {
    height: 224px;
    overflow-y: auto;
    width: 56px;
    text-align: center;
    background-color: white;
    box-shadow: inset 1px 0px 0px #f0f0f0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .item {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 3px;
    cursor: pointer;
  }
  .item.active {
    color: $color-01;
    background: $color-26;
    padding: 0 2px;
  }
  .box-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    border: 1px solid #f0f0f0;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    background-color: #fff;
    .btn {
      color: $color-01;
      margin-right: 12px;
      background-color: $color-26;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 2px;
      width: 35px;
      height: 24px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      justify-content: center;
    }
  }
}
