@import 'src/style/variables';

.policy-page {
  padding: 16px 24px;
  min-height: calc(100vh - 128px);
  overflow: auto;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;
  max-width: 1056px;
  color: #282727;

  .link_route {
    color: #282727;

    &:hover {
      color: #282727;
    }
  }
  &.creator {
    padding: 16px 50px;
    @include mq('sm') {
      padding: 16px 24px;
    }
  }
  @include mq('sm') {
    padding: 16px;
    margin-bottom: 0;
  }
  .title-page {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .title-chapter {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 700;
  }
  .title-article {
    margin-top: 8px;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .content {
    color: #282727;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    margin-left: 30px;
    text-indent: 30px;
    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      content: '';
      width: 3px;
      height: 3px;
      background-color: #282727;
      border-radius: 50%;
    }
    &.nodot {
      text-indent: 0px;
      &::before {
        display: none;
      }
    }
    .sub-content {
      margin-left: 15px;
      position: relative;
      text-indent: 15px;
      &::before {
        position: absolute;
        top: 10px;
        left: 0;
        content: '';
        width: 2px;
        height: 2px;
        background-color: #282727;
        border-radius: 50%;
      }
      &.nodot {
        text-indent: 0px;
        &::before {
          display: none;
        }
      }
    }
    &.no-texindent {
      text-indent: 0;
    }
  }
  .end-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
