@import 'src/style/variables';
@import 'src/style/mixin';

.calendar-layout {
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
}

.calendar-page.creator {
  background-color: #fff;
  padding-bottom: 12px;
  width: 100%;
  max-width: 100%;
  padding: 35px 24px 0 24px;

  .shimmer-loading {
    padding-top: 16px !important;
  }

  .event-container { 
    // border-top: 1px solid #eeeeee;
    padding-bottom: 0;
    .event-time {
      display: flex;
      align-items: flex-end;
      position: relative;
      .hour {
        display: flex;
        align-items: flex-end;
        color: #787880;
        font-size: 14px;
        margin-left: 15px;
        height: 46px;
      }

      .desc {
        border-bottom: 1px solid #eeeeee;
        width: 100%;
        min-height: 40px;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-wrap: wrap;
        padding: 16px 0 32px 0;
        .item {
          width: 100%;
          padding-left: 5px;
          display: flex;
          align-items: center;
          z-index: 10;
          cursor: pointer;
          @include mq('sm') {
            padding-left: 0;
          }
          img {
            height: 44px;
            width: 44px;
            min-width: 44px;
            min-height: 44px;
            object-fit: unset;
            border-radius: 50%;
            cursor: pointer;
            margin-right: 0;
          }
          .name {
            color: #282727;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            .text {
              max-width: 400px;
              margin-bottom: 4px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              font-weight: 700;
              font-size: 14px;
              color: #282727;
              @include mq('sm') {
                max-width: 200px;
              }
            }
          }
          .description {
            display: flex;
            align-items: baseline;
            gap: 2px;
            color: #666565;
          }
          .live {
            position: absolute;
            margin-left: 0px;
            top: -10px;
            padding: 2px 10px;
            color: #fff;
            background: linear-gradient(90deg, #dc143c 0%, #e9367c 100%);
            font-size: 9px;
            font-weight: bold;
            text-transform: uppercase;
            border-radius: 20px;
            cursor: pointer;
          }
          .content {
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-height: 20px;
            max-height: 20px;
            min-width: min-content;
            max-width: 60%;
            width: unset;
            -webkit-line-clamp: 1;
            cursor: pointer;
          }
          .content,
          .time {
            color: #787880;
            font-weight: 500;
            font-size: 12px;
          }
          .avatar {
            position: relative;
            margin-right: 8px;
            .is-live {
              position: absolute;
              bottom: 0;
              height: 44px;
              width: 44px;
              border-radius: 50%;
              border: 2px solid transparent;
              background: linear-gradient(90deg, #dc143c 0%, #e9367c 100%) border-box; /*3*/
              -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              mask-composite: exclude;
              transform: scale(1.2);
            }
            .is-live-text {
              position: absolute;
              bottom: 0;
              left: 3px;
            }
          }
        }
        .icon-show-more {
          position: absolute;
          top: 35px;
          right: 10px;
          color: $color-08;
          cursor: pointer;
          z-index: 20;
          svg {
            width: 15px;
            height: 15px;
            color: inherit;
          }
          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }
    .event-time:last-child {
      .hour {
        opacity: 0;
      }
      .desc {
        border-bottom: none;
      }
    }
  }

  .calendar-root {
    border: 1px solid $color-07;
  }

  .navigation-header {
    margin: 16px 0;
    & > * {
      margin: 0 24px;
    }
  }
  @include mq('mw768') {
    .content {
      max-width: 70% !important;
    }
  }
  @include mq('sm') {
    padding: 0 0;
    .content {
      max-width: 135px !important;
    }
    .content-full {
      max-width: 150px !important;
    }
  }
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
}
