@function convert_px_to_rem($rem1,$rem2,$rem3,$rem4,$fz){
    @if $rem4!='none'{
        @return (#{($rem1 / $fz)}rem) (#{($rem2 / $fz)}rem) (#{($rem3 / $fz)}rem) (#{($rem4 / $fz)}rem);
    }@else if $rem3!='none' {
        @return (#{($rem1 / $fz)}rem) (#{($rem2 / $fz)}rem) (#{($rem3 / $fz)}rem);
    }@else if $rem2!='none' {
        @return (#{($rem1 / $fz)}rem) (#{($rem2 / $fz)}rem);
    }@else{
        @return (#{($rem1 / $fz)}rem);
    }
}
@function s($rem1,$rem2:'none',$rem3:'none',$rem4:'none') {
    @return convert_px_to_rem($rem1,$rem2,$rem3,$rem4,16);
}
@function to-number($value) {
    @if type-of($value) == 'number' {
      @return $value;
    } @else if type-of($value) != 'string' {
      @error 'Value for `to-number` should be a number or a string.';
    }
  
    $result: 0;
    $digits: 0;
    $minus: str-slice($value, 1, 1) == '-';
    $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
  
    @for $i from if($minus, 2, 1) through str-length($value) {
      $character: str-slice($value, $i, $i);
  
      @if (index(map-keys($numbers), $character) or $character == '.') {
        @if $character == '.' {
          $digits: 1; 
        } @else if $digits == 0 {
          $result: $result * 10 + map-get($numbers, $character);  
        } @else {
          $digits: $digits * 10;
          $result: $result + map-get($numbers, $character) / $digits;
        }
      }
    }
  
    @return if($minus, -$result, $result);;
}
