.donut {
  width: 48px;
  height: 48px;
  margin: 4rem;
  border-radius: 50%;
  border: 0.5rem solid rgba(#FBA7A0, 0.4);
  border-top-color: #DC143C;
  animation: 1.2s spin infinite linear;

  &.multi {
    border-bottom-color: #DC143C;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}