//
// font-family
//
$font-family-base: 'Noto Sans JP';
$font-family-weight: 400;
$font-family-awesome: 'Font Awesome 5 Free';

//
// color
//
$color-black: #141b29;
$color-black-deep: #0b0b0b;
$color-black-soft: #302e2e;
$color-01: #fff;
$color-02: #000;
$color-03: #1a1a1a;
$color-04: #604de7;
$color-05: #f0f0f0;
$color-06: #ff0000;
$color-07: #eeeeee;
$color-08: #787880;
$color-09: #f2f2f2;
$color-10: #52C41A;
$color-11: #ff9f43;
$color-12: #f759ab;
$color-13: #ea5455;
$color-14: #b1b6bb;
$color-15: #f8f8f8;
$color-16: #a1a3a8;
$color-17: #1a1a1a;
$color-18: #302f2f;
$color-19: #FD378E;
$color-20: #ffdbdb;
$color-21: #fcf6f7;
$color-22: #faf5e9;
$color-23: #f2f9f9;
$color-24: #7b7b7b;
$color-25: #868686;
$color-26: #DC143C;
$color-27: #CE1338;
$color-28: #1480FF;
$color-29: #F1F1F1;
$color-30: #dc143c4d;
$color-31: #FCECEF;
$color-32: #E5E6EB;
$color-33: #F2F3F5;
//
// break points
//
$breakpoints: (
  'xs': 'screen and (max-width: 375px)',
  //  <  small phone
  'ms': 'screen and (min-width: 480px)',
  'sm': 'screen and (max-width: 575px)',
  'minHeight': 'screen and (max-height: 414px)',
  'xsHeight': 'screen and (max-height: 430px)',
  'smHeight': 'screen and (max-height: 500px)',
  //  <  normal phone
  'mw768': 'screen and (max-width: 767px)',
  'mw1024': 'screen and (max-width: 1023px)',
  's': 'screen and (min-width: 769px)',
  //  <  tablet small
  'm': 'screen and (min-width: 769px)',
  'md': 'screen and (max-width: 991px)',
  // <  tablet middle
  'ml': 'screen and (min-width: 1367px)',
  // <  tablet large
  'l': 'screen and (max-width: 1279px)',
  // 'l': 'screen and (min-width: 1440px)',
  'xl': 'screen and (max-width: 1439px)',
  // <  pc
  // 'xxl': 'screen and (min-width: 1980px)',
  // =< larger display,,,,,,,,,,,,,,,,,,,,,,,,
) !default;
@mixin mq($break: xs) {
  // 's' as default break point
  @media #{map-get($breakpoints, $break)} {
    @content;
  }
}
