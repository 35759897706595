@import 'src/style/variables';
@import 'src/style/mixin';

.message-item {
  padding: 0 16px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  img {
    border-radius: 50%;
    min-width: 56px;
    max-width: 56px;
    min-height: 56px;
    max-height: 56px;
    border: none;
    margin-right: 12px;
    background-color: transparent;
  }
  .content-left {
    display: flex;
    align-items: center;
    padding-right: 25px;
    flex: 1;
    height: 100%;
    cursor: pointer;
    width: calc(100% - 150px);
    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 0;
      .name {
        font-size: 14px;
        line-height: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .message {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
        font-size: 14px;
        line-height: 22px;
        color: $color-08;
      }
    }
    &.not-read {
      .name {
        font-weight: 700;
        color: #282727;
      }
      .message {
        font-weight: 500;
        color: #282727;
      }
    }
  }
  .content-right {
    .delete-btn {
      margin-top: 5px;
      margin-left: auto;
      font-weight: bold;
      font-size: 12px;
      padding: 8px 15px;
      background: $color-26;
      border-radius: 6px;
      color: $color-01;
      cursor: pointer;
      width: 70px;
      text-align: center;
      height: 32px;
      &.disable {
        background-color: $color-30;
      }
      &:hover {
        background-color: $color-27;
      }
    }
  }
}
