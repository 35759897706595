@import 'src/style/variables';
@import 'src/style/mixin';

.help-header {
  overflow: hidden;
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}
.help-post {
  .back-to-page {
    @media (max-width: 1023px) {
      display: flex;
    }
  }
}
.creator-help-page {
  padding: 16px 24px;
  color: $color-03;
  @include mq('sm') {
    padding: 16px 16px;
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
  }
  .box-text {
    display: flex;
    align-items: center;
    gap: 8px;
    .anticon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .text1 {
    font-size: 14px;
    line-height: 22px;
    margin-top: 9px;
    margin-bottom: 24px;
  }
  .btn-help {
    margin: 0 auto;
    width: 343px;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background: #dc143c;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 15px;
    &:hover {
      background-color: $color-27;
    }
  }

  .divider {
    border-bottom: 1px solid #e5e6eb;
    margin-bottom: 15px;
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    margin-top: 21px;
    position: relative;
    margin-left: 10px;
    &::before {
      position: absolute;
      content: '';
      background-color: $color-03;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      bottom: 9px;
      left: -10px;
    }
    &.title2 {
      margin-top: 24px;
    }
  }
  .box-content {
    border-bottom: 1px solid #eeeeee;
    padding: 0 16px;

    .item {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-top: 15px;
        .sub-title {
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          @include mq('sm') {
            width: 90%;
          }
        }
        .icon {
          color: $color-08;
          font-weight: bold;
          font-size: 16px;
          .icon-subtraction {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              width: 9px;
              height: 2.29px;
              background-color: $color-08;
              top: 0;
              right: 0;
              border-radius: 1px;
            }
          }
        }
        &.no-active {
          padding: 15px 0;
          position: relative;
        }
      }
      &-content {
        font-weight: normal;
        font-size: 14px;
        padding: 15px 0;
        text-align: justify;
        .custom-css-link-help {
          cursor: pointer;
          &:hover {
            color: #0d6efd;
          }
        }
      }
    }
  }
}

.creator-post-help {
  padding: 0 24px;
  @include mq('sm') {
    padding: 0 16px;
    margin-bottom: 0;
  }
  .fansite-form {
    background: $color-01;
    @include mq('sm') {
      padding: initial;
    }
    .title {
      margin-top: 20px;
      label {
        color: $color-03;
        font-size: 14px;
        margin-bottom: 4px;
      }
      input {
        width: 100%;
        height: 44px;
        padding: 0 12px;
      }
      textarea {
        width: 100%;
        // height: 166px;
        height: 103px;
        padding: 12px;
      }
      input,
      textarea {
        // border: 1px solid #b1b6bb;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        outline: none;
      }
    }

    .category {
      position: relative;
      border-radius: 6px;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    .category {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        pointer-events: none;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          // height: 11px;
        }
      }
    }

    .button-submit {
      display: flex;
      gap: 20px;
      @include mq('sm') {
        display: initial;
        button {
          width: 100% !important;
          margin-bottom: 16px;
        }
      }
      button {
        height: 100%;
        width: calc(100% / 2 - 10px);
        font-weight: bold;
        background-color: $color-26;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        border-radius: 8px;

        &.cancel {
          color: #1a1a1a;
          background: #ffffff;
          border: 1px solid #e5e6eb;
        }

        &:focus {
          outline: none;
        }

        &:disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
    }
  }
}

.question-container {
  flex-grow: 1;
}
