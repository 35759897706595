@import 'src/style/variables';
@import 'src/style/mixin';

.sub-purchase-list {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
    .layout-header {
      height: 50px;
    }
  }
}

.page-post-buyed {
  padding: 16px 24px 24px 24px;
  min-height: 310px;
  position: relative;
  .shimmer-loading {
    padding: 0;
  }

  .fansite-form {
    @include mq('sm') {
      padding: 0 !important;

      .react-select-container > div {
        width: 100%;
      }
    }
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #b1b6bb;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    gap: 12px;
    .icon-no-data {
      position: relative;
      transform: unset;
      right: unset;
    }
    img {
      max-width: 100px;
      max-height: 100px;
      background-color: transparent;
      position: relative;
    }
  }
  .list-post-buyed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 2px;
    //margin-top: calc(50px - 16px);
    .item {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      z-index: 1;
      color: $color-01;
      cursor: pointer;

      .background-img {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
    }
    .file-zip {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      z-index: 1;
      font-weight: 500;
      font-size: 8px;
      line-height: 12px;
      width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      svg {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 7px;
        width: 15px;
        height: 15px;
      }
    }
    img {
      z-index: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      // aspect-ratio: 4/3;
      // object-fit: contain;
    }

    .blue-img {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      position: absolute;
      .bg-img {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 9;
        background-size: cover;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(20px);
        min-height: unset;
        max-height: unset;
      }
    }

    .infor {
      position: absolute;
      z-index: 99;
      bottom: 0px;
      left: 0px;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      // margin: 8px;
      padding: 8px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background-blend-mode: multiply;

      .user-infor {
        display: flex;
        min-height: max-content;
        gap: 4px;
        align-items: center;
        position: relative;

        .name {
          display: block;
          margin-right: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span {
        width: 100%;
        line-height: 16px;
        margin-right: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content,
      .title {
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      .title {
        margin-top: 2px;
        margin-right: 10px;
        max-width: 206px;
        line-height: 20px;
      }

      .content {
        line-height: 17px;
        margin-right: 8px;
        font-size: 12px;
        color: #e5e6eb;
      }

      .avatar-mini {
        max-width: 28px;
        max-height: 28px;
        min-width: 28px;
        min-height: 28px;
        border-radius: 50%;
        position: relative;
      }
    }

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .no-data {
      position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #b1b6bb;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      width: 100%;

      img {
        max-width: 100px;
        max-height: 100px;
        background-color: transparent;
        position: relative;
        aspect-ratio: unset;
      }
    }
  }

  .select {
    position: relative;

    .select-label {
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
    }
    .anticon {
      position: absolute;
      right: 15.3px;
      top: 50%;
      transform: translateY(-50%);
      color: $color-14;
      z-index: 1;
      pointer-events: none;

      &.select-icon {
        top: 50%;
        transform: rotate(90deg) translateX(-50%);
        right: 12px;
      }
    }
  }
  //select
  .select {
    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
    .anticon-dropdown {
      right: 10px;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 11px;
      }
    }
  }

  .anticon {
    position: absolute;
    right: 15.3px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-14;
    z-index: 1;
  }
}

@include mq('sm') {
  .page-post-buyed {
    padding: 0 16px;

    .list-post-buyed {
      margin: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1;
      grid-auto-rows: auto;

      .item {
        width: 100%;
        padding-bottom: 100%;
      }
    }
  }
}
