@import 'src/style/variables';

.survey-detail-page {
  height: calc(100vh - 200px);
  padding: 18px 16px;
  position: relative;

  @include mq('sm') {
    height: calc(100vh - 120px);
  }

  form {
    height: calc(100% - 110px);
    overflow-y: auto;
  }
  .title,
  .expired-date {
    color: #282727;
    font-size: 14px;
    margin-bottom: 8px;
  }
  .list-questions {
    .question-text {
      color: #282727;
      font-weight: bold;
      font-size: 14px;
      margin: 16px 0;
    }
    .choice {
      position: relative;
      margin-bottom: 16px;
      display: flex;
      .form-input {
        width: 100%;
        margin-left: 8px;
        border: 0;
        border-bottom: 1px solid #eeeeee;
        outline: 0;
        &:disabled {
          background-color: transparent;
        }
      }
      .checkbox-input {
        width: 264px;
        margin-left: 8px;
        border: 0;
        border-bottom: 1px solid #eeeeee;
        outline: 0;
        height: 16px;
        &:disabled {
          background-color: transparent;
        }
      }
    }
  }
  .custom-checkbox-label {
    position: relative;
    display: flex;
    width: 100%;
    input[type='checkbox'] {
      visibility: hidden;
    }
    input[type='checkbox']:disabled ~ .checkmark {
      border: solid 1.5px #1a1a1a;
      background-color: #ffffff;
      cursor: default;
    }
    input[type='checkbox'] ~ .checkmark {
      margin-top: 0;
      position: absolute;
      top: 2px;
      left: 0;
      height: 17px;
      width: 17px;
      border-radius: 4px;
      border: solid 1.5px #b1b6bb;
      background-color: #ffffff;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        display: none;
      }
    }
    input[type='checkbox']:checked ~ .checkmark {
      background-color: $color-26;
      border-color: $color-26;
      &:after {
        display: block;
      }
      &.disabled-select {
        background-color: $color-30;
        border-color: #ff000000;
      }
    }
    label {
      margin-left: 12px;
      &.disabled-select {
        color: #1a1a1a;
      }
    }
    .color-disable {
      opacity: 0.4;
    }
  }
  .footer-btn {
    display: flex;
    gap: 32px;
    width: calc(100% - 40px);
    align-items: center;
    position: absolute;
    bottom: 20px;

    @include mq('sm') {
      display: block;
    }
  }
  .btn-answer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
    margin: 24px 0 6px;
    height: 44px;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Noto Sans JP';
    border-radius: 8px;
    outline: 0;
    cursor: pointer;

    @include mq('sm') {
      margin-top: 16px;
    }
    &.btn-cancel {
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      color: #1a1a1a;
      &:hover {
        background: #f1f1f1;
      }
    }
    &.btn-back {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      font-size: 16px;
      color: #1a1a1a;
    }
  }

  .active {
    color: #fff;
    background-color: $color-26;
    border: 1px solid $color-26;
  }

  .disabled {
    color: #fff;
    background-color: $color-30;
    border: 0;
  }
}

.input-other {
  border: none;
  border-bottom: 1px solid #858585;
  margin-left: 5px;
  color: #1a1a1a;
  flex: 1;
  min-width: 0;
  background-color: #f7f8fa;
  &:focus-visible {
    outline: none;
  }
  &.disabled-select {
    border-bottom: 1px solid #1a1a1a;
    color: #1a1a1a;
  }
}
