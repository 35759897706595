@import 'src/style/variables';
@import 'src/style/mixin';

.popup-card-management-layout {
  .back-to-page {
    @media (max-width: 1023px) {
      display: none;
    }
  }
  .popup-content {
    max-width: 379px !important;
  }
  .card-management-container {
    padding: 24px;

    .button-add-card {
      .btn {
        margin-right: 0;
        span {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .head-title-button-add {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .head-title-list {
        font-size: 14px;
        font-weight: 700;
        color: $color-03;
      }
    }
    .list-card {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      .card-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .text-card {
          font-size: 14px;
          font-weight: 400;
          color: #666565;
        }
        &-left {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 12px;
          width: calc(100% - 48px);
          &_checkbox {
            .label-checkbox {
              display: block;
            }
            .checkmark {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            input:checked + .checkmark:after {
              content: '';
              position: absolute;
              border-left: 2.5px solid $color-01;
              border-bottom: 2.5px solid $color-01;
              border-radius: 0.5px;
              height: 7px;
              width: 11px;
              top: 43%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
          &_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            .info-name {
              font-size: 14px;
              font-weight: 400;
              color: $color-03;
              text-transform: uppercase;
              display: flex;
              align-items: center;
              width: 100%;
              .card-name {
                width: 100%;
                &.is-have-default {
                  width: calc(100% - 80px);
                }
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .card-default {
                color: $color-26;
                border: 1px solid $color-26;
                border-radius: 2px;
                margin-right: 8px;
                padding: 2px 4px;
                text-transform: none;
                white-space: nowrap;
              }
            }
          }
        }
        &-right {
          display: flex;
          gap: 8px;
          align-items: center;
          .card-icon {
            cursor: pointer;
          }
        }
      }
    }

    .no-data {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #f1afbb;
      position: absolute;
      top: calc(50% - 24px);
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-align: center;
      .text {
        margin-right: -16px;
        color: #b1b6bb;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 12px;
      }
      @include mq('sm') {
        position: fixed;
      }
    }
  }
}
