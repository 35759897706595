@import 'src/style/variables';
@import 'src/style/mixin';

.affiliator-sales-management {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .content-box-affiliator {
    height: 100%;
    background: #fff;
  }
}
