@import 'src/style/variables';
@import 'src/style/mixin';

.popup-wrap-short-video1 {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.6);
  .comment.form-edit-comment {
    max-width: calc(100% - 44px);
  }
  form {
    width: 100%;
    &.form-reply-comment {
      max-width: 100%;
      .form-input-comment-meta {
        border: none !important;
      }
      &.haveMeta {
        max-width: calc(100% - 52px);
      }
    }
    &.form-comment-popup-short-search {
      .form-input-comment-meta {
        max-height: 80px;
        overflow-y: auto;
        textarea {
          min-height: 44px;
        }
      }
    }
  }
  @include mq('mw768') {
    z-index: 4001 !important;
  }
  .icon-send {
    position: absolute;
    right: 11px;
    bottom: 11px !important;
  }
  .close-icon {
    position: absolute;
    left: -44px;
    top: 0px;
    z-index: 120;
    @include mq('sm') {
      z-index: 9000 !important;
      right: 2px;
      top: -40px;
      left: unset;
    }
    background-color: #000;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    svg {
      display: flex;
      width: 32px;
      height: 32px;
      color: white;
    }
  }

  .comment-wrap-popup {
    position: relative;
    //margin-top: 30px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 90px);
    textarea#textarea-post-comment-full {
      background: white;
      &:disabled {
        background: #efefef;
      }
    }
    .sroll-list {
      padding: 0 16px;
      height: calc(100% - 74px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
      }
    }
    .div-list-cmt {
      position: absolute;
      bottom: -12px;
      left: 0;
      right: 0;
      z-index: 20;
      background: #fff;
      border-top: 1px solid #ccc;
      padding: 16px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .form-comment-popup {
        max-width: calc(100% - 56px);
      }
      .form-input-comment-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #fff !important;
        .input-comment {
          border: 1px solid #e5e6eb;
        }
        &.disable {
          background-color: #efefef;
        }
        &.haveMeta {
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          padding: 6px 36px 12px 6px;
          .input-comment {
            border: none !important;
          }
        }
      }
    }
  }
  .card-item--detail {
    &-line {
      width: 94%;
      border-bottom: 1px solid #e5e6eb;
      margin: auto;
    }
  }
  .card-item--detail--header {
    display: flex;
    padding: 24px;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    &.header-search {
      justify-content: flex-start;
    }
    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
    }
    &-right {
      .btn-list-hide-comment {
        cursor: pointer;
      }
    }
    &-title {
      font-weight: 700;
      font-size: 16px;
      color: #1a1a1a;
    }
    &-comment {
      font-weight: 700;
      font-size: 16px;
      color: rgba(102, 101, 101, 1);
    }
  }
}

.left-content-comment-mess {
  background-color: #fff;
  margin-top: 0px !important;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 720px;
  height: 900px;
  max-height: 90%;
  @include mq('sm') {
    width: 343px !important;
    height: 607px !important;
    max-height: 80%;
  }

  // background: #fff;
  // margin-top: 30px;
  // border-radius: 20px;
  // width: 720px;
  // height: 900px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 50px;

  .d-middle-popup-all {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .cls-title {
    width: 50%;
  }
  .line {
    width: 94%;
    border-bottom: 1px solid #e5e6eb;
    margin: auto;
  }

  .box-popup {
    position: relative;
    margin-left: auto;
    .three-point-icon {
      z-index: 4;
      cursor: pointer;
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 189px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      top: 2;
      //top: calc(100% + 2px);
      right: 0;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07;
        }
        &:first-child {
          margin-top: 13px;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
