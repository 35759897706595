.Toastify__toast {
	min-height: 55px;
	padding: 8px 10px;
	min-width: 320px;
}

.Toastify__toast-container {
	min-width: 320px;
	width: auto;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #F6FFED !important;
	border: 2px solid #B7EB8F !important;
	border-radius: 2px !important;
	color: #1A1A1A !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #FFFBE6 !important;
	border: 2px solid #FFE58F !important;
	border-radius: 2px !important;
	color: #1A1A1A !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #FFF1F0 !important;
	border: 2px solid #FFCCC7 !important;
	border-radius: 2px !important;
	color: #1A1A1A !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warn {
  background-color: #E6F7FF !important;
	border: 2px solid #91D5FF !important;
	border-radius: 2px !important;
	color: #1A1A1A !important;
}

.Toastify__close-button {
	color: rgba(0, 0, 0, 0.85) !important;
	align-self: center !important; 
}

.Toastify__toast--error {
	z-index: 1000000 !important;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: 99vw;
		padding: 0;
		right: 2px;
		margin: 0;
		left: unset;
	}
}