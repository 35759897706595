@import 'src/style/variables';
@import 'src/style/mixin';

.ranking-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  &.isIdol {
    cursor: pointer;
  }
  .ranking {
    font-size: 14px;
    // min-width: 44px;
    &.en {
      min-width: 60px;
    }
    .ranking-icon {
      width: 24px;
    }
  }
  .ranking-item-info {
    display: flex;
    align-items: center;
    width: calc(100% - 150px);
    .people-info {
      width: 100%;
      display: flex;
      align-items: center;
      @include mq('sm') {
        justify-content: space-between;
      }
      .name {
        font-size: 14px;
        font-weight: bold;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @include mq('sm') {
        display: block;
        .people-points {
          justify-content: start;
          margin-left: initial;
        }
      }
    }

    .people-points {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      color: #282727;
      font-size: 18px;
      margin-left: 10px;

      .points {
        font-weight: 500;
      }
      .text {
        width: 100px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .people-avatar {
    border-radius: 50%;
    min-height: 56px;
    max-height: 56px;
    min-width: 56px;
    max-width: 56px;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
    object-fit: cover;
  }
}
