@import "src/style/variables";
@import "src/style/mixin";

.popup-wrap-mess {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(26, 26, 26, 0.8);
  .close-icon {
    position: absolute;
    left: -44px;
    top: 0px;
    z-index: 120;
    @include mq('sm') {
      right: 2px;
      top: -40px;
      left: unset;
    }
    background-color: #000;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    svg {
      display: flex;
      width: 32px;
      height: 32px;
      color: white;
    }
  }
  
  .card-item--detail .comment form .form-item .anticon {
    //transform: rotate(45deg) !important;
  }
  
  .comment-wrap-popup {
    position: relative;
    margin-top: 25px;
    //height: calc(100% - 242px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sroll-list {
      padding: 0 16px;
      max-height: calc(100% - 78px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
      }
    }
    .div-list-cmt {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      border-top: 1px solid #ccc;
      padding: 16px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}

.list-comment .comment-item .content .like-reply {
  margin-top: 8px !important;
}

.left-content-comment-mess {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 20px;
  width: 720px;
  height: 900px;
  // background: #fff;
  // margin-top: 30px;
  // border-radius: 20px;
  // width: 720px;
  // height: 900px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // padding: 50px;
      
  .d-middle-popup-all{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px !important;
  }
  
  .line {
    width: 96% !important;
  }

  .cls-title {
    width: 50%;
  }
  .line {
    width: 100%;
    border-bottom: 1px solid #e5e6eb;
    margin: 15px 0;
  }

  .box-popup {
    position: relative;
    margin-left: auto;
    .three-point-icon {
      color: #666565;
      z-index: 4;
      cursor: pointer;
    }

    .popup-edit-comment {
      position: absolute;
      background: $color-01;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      width: 229px;
      height: unset !important;
      display: flex;
      flex-direction: column;
      right: 0;
      border-radius: 6px;
      overflow: hidden;
      z-index: 15;
      .item {
        display: flex;
        align-items: center;
        padding-left: 18px;
        cursor: pointer;
        height: 38px;
        z-index: 10;
        color: $color-03;
        .anticon {
          margin-right: 8px;
        }
        &:hover {
          background-color: $color-07;
          border-radius: 0px;
        }
        &:first-child {
          margin-top: 6px !important;
          position: relative;
        }
        &:nth-child(2) {
          margin-bottom: 6px;
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
