.container__error {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;

  // Position
  &__title {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 87px;
    color: #000000;
  }
  .error__container {
    position: relative;
    .person {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff0000;
    color: #ff0000;
    padding: 10px 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    border-radius: 6px;
    background: transparent;
    &:hover {
      transition: all 0.3s ease-in;
      background: #ff0000;
      color: #fff;
      path {
        transition: all 0.3s ease-in;
        stroke: #fff;
      }
    }
  }

  .error_content {
    text-align: center;
  }
}
