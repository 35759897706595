@import 'src/style/variables';
@import 'src/style/mixin';

.layout-with-menu-sidebar {
  margin: 0 auto;
  display: flex;
  gap: 32px;
  justify-content: space-between !important;

  &.creator {
    max-width: 1056px;
  }

  .layout-content {
    position: relative;
    // width: 100%;
    display: flex;
    background-color: transparent;
    .layout-left {
      position: relative;
      // max-width: 500px;
      // min-width: 500px;
    }
    .layout-right {
      position: relative;
      overflow: hidden;
      .calendar-wrapper {
        position: fixed;
        height: calc(100vh - 60px);
        overflow-y: auto;
        &::-webkit-scrollbar-track {
          display: none;
        }
      }
    }
  }

  @include mq('xl') {
    padding: 0;
    width: 100%;
    gap: 20px;
    margin: 0 auto;
    max-width: 100%;
    // .layoutSider-wrapper {
    //   width: 330px;
    //   min-width: 330px;
    // }
    .layout-content {
      // width: calc(100% - 350px);
      // min-width: calc(100% - 350px);
      gap: 20px;
      .layout-left {
        max-width: unset;
        min-width: 60%;
        width: 60%;
      }
    }
  }

  @include mq('l') {
    gap: 10px;
    // .layoutSider-wrapper,
    // .layoutSider {
    //   width: 270px;
    //   min-width: 270px;
    // }
    .layout-content {
      // width: calc(100% - 280px);
      // min-width: calc(100% - 280px);
      gap: 10px;
    }
  }

  @media screen and (max-width: 1023px) {
    .layout-content {
      width: 100%;
      min-width: 100%;
      @include mq('s') {
        min-width: unset;
      }
      .layout-left {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      .layout-right {
        display: none;
      }
    }
  }
}

.layout-center {
  display: flex;
  justify-content: center;
  .box-content {
    display: flex;
    flex-direction: column;
    max-width: 375px;
    overflow: hidden;
  }
  .layout-content {
    margin-top: 60px;
    width: 375px;
    min-width: 375px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    background: $color-01;
    min-height: 200px;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    svg {
      width: 80px;
      height: 22px;
    }
    margin-top: 8px;
    height: 60px;
  }
}

.layout-center .layout-content.language-setting {
  min-height: unset;
}

.layout-with-menu-sidebar.creator {
  gap: 0;
  max-width: 1440px;
  @media screen and (max-width: 1440px) and (min-width: 1440px) {
    padding-left: 20px;
  }
  @media screen and (max-width: 1023px) {
    top: 0;
  }

  .layoutSider-wrapper {
    width: 302px;
    min-width: 302px;
    height: calc(100vh - 60px);
    z-index: 2;
    background-color: #f2f2f2;
    position: fixed;

    &.is_minimal {
      margin-top: 24px;
      width: 100px;
      min-width: 100px;
      // background-color: #FFF;
      border-radius: 8px;
    }
    .layoutSider {
      width: 100%;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      height: calc(100vh - 180px);
      padding: 0;
      max-height: calc(100% - 100px);
      margin: 24px auto;
      margin-bottom: 5px;
      background-color: #fff;
      position: relative;
      border-radius: 20px;

      @media (orientation: landscape) {
        height: calc(100vh - 180px - 50px);
        min-height: 300px;
      }

      @include mq('xl') {
        @include mq('s') {
          width: 21vw;
          min-width: 21vw;
        }
      }

      &.is_minimal {
        margin-top: 0;
        //padding: 32px 12px;
        padding: 0;
      }
    }

    .layout-content {
      // width: 284px;
      // min-width: 284px;
    }
  }

  .btn-minimal {
    position: absolute;
    right: 0;
    top: 24px;
    background-color: #fff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    padding: 5px;
    border-radius: 50%;
    transform: translateX(13px);
    cursor: pointer;
  }

  .recommend-block {
    width: 350px;

    @include mq('xl') {
      @media only screen and (min-width: 991px) {
        margin-left: 10px;
      }
    }
  }

  .header {
    background: $color-01;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-07;
    @include mq('sm') {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    }
    .title {
      font-size: 20px;
      color: $color-03;
      padding-left: 19px;
    }
  }
  .layout-content-creator {
    width: 50%;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 1023px) {
      box-shadow: none;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    border-top: 0.5px solid $color-07;
    svg {
      width: 80px;
      height: 22px;
    }
    max-height: 60px !important;
    min-height: 60px !important;
    height: 60px;
    bottom: env(safe-area-inset-bottom);
    z-index: 0;
  }
  @include mq('xl') {
    gap: 0;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    .layoutSider-wrapper {
      width: 284px;
      min-width: 284px;

      @include mq('s') {
        width: 22vw;
        min-width: 22vw;
      }
    }
    .layout-content {
      width: calc(100% - 284px);
      min-width: calc(100% - 284px);
      gap: 0;

      @include mq('s') {
        width: 100%;
        max-width: calc(100% - 22vw - 27vw);
        min-width: unset;
      }
      .layout-left {
        max-width: unset;
        min-width: unset;
        width: 60%;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .layout-content {
      width: 100%;
      min-width: 100%;
      height: 100%;

      .layout-left {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      .layout-right {
        display: none;
      }
    }
  }
}

.body-survey {
  background: $color-01;
  gap: 0 !important;
  max-width: 772px;
}

.position-footer-creator {
  position: absolute;
  bottom: 0;
  @include mq('sm') {
    position: unset;
  }
}

.layout-right-creator {
  .btn-three-point {
    padding: 0px 4px;
    cursor: pointer;
  }
  background-color: transparent;
  background: $color-01;
  margin-top: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  margin-bottom: 50px;
  border-radius: 20px;
  @media screen and (max-width: 1023px) {
    border-radius: 0px;
    .layout-header {
      height: 40px;
    }
  }
  @media screen and (max-width: 1023px) {
    border-radius: 0px;
    .layout-header {
      height: 40px;
    }
  }
  @media screen and (max-width: 375px) and (max-height: 812px) {
    .layout-header {
      height: 40px;
      //min-height: 40px !important;
    }
  }

  &.sub-purchase-list {
    background-color: #ffffff;
  }

  @media screen and (max-width: 1023px) {
    margin-top: 0px;
  }

  @include mq('sm') {
    box-shadow: none;
    margin-bottom: 0;
  }

  .content-container {
    min-height: calc(100vh - 200px);
    background-color: $color-01;
    border-radius: 20px 20px 20px 20px;

    &.bg-grey {
      background-color: #f7f8fa;
      min-height: calc(100vh - 200px);
      border-radius: 0px 0px 20px 20px;
      @media screen and (max-width: 1023px) {
        min-height: 100%;
      }
    }

    @media screen and (max-width: 1023px) {
      min-height: calc(100vh - 135px);
    }

    @include mq('sm') {
      border: none;
      border-radius: 0;
      min-height: 100%;
      &.data-empty {
        height: calc(100vh - 120px);
      }
      padding: 0 0 67px 0;
    }
  }
}

.custom-layout-center {
  margin-top: 0px !important;
  display: flex;
  justify-content: center;
  background: transparent !important;
  box-shadow: 0 4px 8px rgba(0,0,0,.05);
  .content-container { 
    max-width: 1056px;
    .container-fluid {
      box-shadow: 0 4px 8px rgba(0,0,0,.05);
    }
  }
  .back-header {
    max-width: 1056px;
    border-radius: 0 !important;
    position: fixed;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    z-index: 100;
  }
  @media screen and (max-width: 575px) {
    .content-container {
      padding: 0 !important;
    }
  }
  .layout-header {
    display: none !important;
  }
  .hidden-policy {
    display: none !important;
  }
  .footer-mobile{
    display: none !important;
  }
  .back-to-page {
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
    position: fixed !important;
  }
  .term-page {
    padding-top: 76px !important;
  }
  .policy-page {
    padding-top: 76px !important;
  }
  .back-header p {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

// .layout-desktop-position {
//   @media screen and (max-width: 768px) {
//     justify-content: space-between !important;
//   }
//   justify-content: start !important;
// }

.layout-list-idol {
  max-width: 1440px;
  margin: 0 auto;
  overflow: hidden;
  .box-content {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 1023px) {
    padding-top: 110px;
  }
  @include mq('sm') {
    padding-top: 150px;
  }
}

// AF005 START
// HEADER BACK
.back-header {
  width: 100% !important;
}
.layout-with-menu-sidebar.affiliator {
  margin-top: 60px;
  width: 100%;
  .layout-content {
    max-width: 905px;
    @include mq('sm') {
      max-width: 100%;
    }
  }
}
.layoutSider.affiliator {
  top: 60px !important;
}

.layout-content-affiliator {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100vh - 68px);
  gap: 0 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  & > div {
    height: 100%;
  }
}

.footer.footer-affiliator {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 60px;
  border-top: 1px solid #eee;
}
.wrapper__header-affiliator {
  .header-affiliator {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background: #fff;
  }
  .fansite-header-affiliator {
    display: flex;
    align-items: center;
    margin: 0 auto;
    gap: 30px;
    height: 60px;
    overflow: hidden;
    @include mq('xl') {
      width: 1280px;
      gap: 20px;
    }
    @include mq('l') {
      width: 100%;
      gap: 10px;
    }
    .content-left {
      width: 335px;
      @include mq('l') {
        width: 270px;
      }
      .logo-bird {
        margin-right: 11px;
      }
    }

    .content-right {
      display: flex;
      align-items: center;
      .back-header {
        margin-left: 15px;
        .col-12 {
          padding: 0 !important;
        }
      }
    }
  }
}

// AF005 ENDs
#layout-centent-creator .layout-center .layout-content {
  width: 375px;
  max-width: 375px;
  margin-top: 60px;
}

.layout-with-menu-sidebar.livestream {
  gap: 30px;
  height: 100%;
  width: 100%;
  max-width: 1440px;

  @include mq('xl') {
    gap: 20px;
  }
  @include mq('l') {
    gap: 10px;
  }
  @media screen and (max-width: 1023px) {
    margin-top: 0;
    height: 100%;
    gap: 0;
  }
  &.view-mobile {
    .layout-content {
      height: 100vh;
    }
  }
  &.view-live {
    .layout-content {
      @media screen and (max-width: 1023px) {
        height: 100vh;
      }
    }
  }
  .layoutSider {
    height: calc(100vh - 60px);
  }
  .layout-content {
    gap: 0;
    display: block;
    flex-grow: 1;
    max-width: 907px;
  }
}

.layout-with-menu-sidebar.fan {
  background-color: #f2f2f2;
  .layout-content {
    background-color: #f2f2f2;
  }
  @media screen and (max-width: 1023px) {
    background-color: $color-01;
    .layout-content {
      background-color: $color-01;
    }
  }
}

.layout-right-creator.list-subscriber {
  .fansite-form {
    padding-top: 20px;
  }
}

.layout-right-creator.list-buyer {
  .fansite-form {
    padding: 16px 0 0 24px;
  }
}

.skeleton {
  background-color: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  min-height: 200px;
  margin-top: 20px;
  padding: 30px;
}

.skeleton__title {
  background-color: #e7edf1;
  border-radius: 12px;
  height: 24px;
  margin-bottom: 24px;
  width: 60%;
}

.skeleton__text {
  background-color: #e7edf1;
  border-radius: 8px;
  height: 16px;
  margin-top: 16px;
}

.skeleton__title,
.skeleton__text,
.user-skeleton {
  animation: shimmer 1.2s infinite linear;
  background-image: linear-gradient(90deg, #e7edf1 0px, #f1f6fa 200px, #e7edf1 400px);
  background-size: 1200px;
}

@keyframes shimmer {
  0% {
    background-position: -400px;
  }
  50% {
    background-position: 700px;
  }
  100% {
    background-position: 700px;
  }
}
