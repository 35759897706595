@import 'src/style/variables';
@import 'src/style/mixin';

.subcription-list-header {
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    margin-top: 0px !important;
  }
  .shimmer-loading {
    padding: 24px;
    @include mq("sm") {
      padding: 16px;
    }
  }
}

.list-package{
  @include mq("sm") {
    padding: 0 16px;
  }
}

.fansite-creator-subcription-post,
.fansite-creator-subcription-edit {
  background-color: $color-01;

  .note-coin {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $color-08;
    margin-bottom: 16px;
  }
  form {
    padding: 16px 24px;
    .tooltip {
      margin-left: 10px;
    }
    input:focus {
      outline: none;
      border: 1px solid $color-14;
      box-shadow: none;
    }
    input::placeholder {
      color: $color-14;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    input:disabled {
      background-color: $color-07;
    }

    // .fansite-form-input__required {
    //   font-weight: 700;
    //   font-size: 12px;
    //   line-height: 20px;
    //    padding: 2px 12px;
    //    border: 1px solid $color-13;
    //    box-sizing: border-box;
    //    border-radius: 6px;
    //   color: $color-13;
    // }

    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.fsite-price {
        margin-bottom: 8px;
      }
    }
    .form-label.fansite-form-input__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 8px;
      flex-wrap: wrap;
      .tooltip {
        color: $color-08;
      }
    }
    .form-item-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form-label {
        margin-bottom: 0 !important;
        margin-right: 8px;
      }
    }

    input,
    textarea {
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      width: 100%;
      border: 1px solid $color-14;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 9px 12px;
      z-index: 2;
      height: 44px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $color-14;
        font-size: 14px;
      }
    }
    .input-price {
      position: relative;
      overflow: hidden;
      &::after {
        content: 'Pt';
        position: absolute;
        top: 50%;
        right: 0;
        color: $color-03;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        transform: translateY(-50%);
        background-color: $color-14;
        width: 44px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 6px 6px 0px;
      }
    }

    textarea {
      min-height: 178px;
    }

    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }

      button {
        height: 100%;
        width: 100%;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
    .button-back {
      &:focus {
        outline: none;
      }
    }
  }
}

.fansite-creator-subcription-list {
  .subscription-btn-add {
    border: 1px solid $color-26 !important;
    border-radius: 6px;
    color: $color-26 !important;
    &:hover {
      background-color: $color-26 !important;
      color: $color-01 !important;
    }
  }
  .no-data {
    padding: 12px 12px 0 12px;
    padding-top: calc(50vh - 230px);
    .empty-text {
      margin-top: 16px;
      color: #b1b6bb;
      text-align: center;
    }
  }
  .btn-outline-red {
    border-color: $color-26;
    background-color: #ffffff;
    color: $color-26;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
}
