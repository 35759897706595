@import 'src/style/variables';
@import 'src/style/mixin';

.page-home {
  min-height: calc((100vh - 60px) - 70px);
}
.popup-creator-block-lg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(5px);
  z-index: 9990;
}
.popup-creator-block_content-lg {
  z-index: 9999;
  position: fixed;
  left: calc(50% + 142px);
  top: 50%;
  transform: translate(-50%, -50%);
  width: 343px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19px;
  background: #ffffff;
  opacity: 0.8;
  font-size: 28px;
  font-weight: bold;
  color: #282727;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  &.by-fan {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 1;
    .content {
      padding: 32px 16px 22px 34.5px;
      display: flex;
      column-gap: 19px;
      align-items: 'flex-start';
      text-align: left !important;
      white-space: pre-line;
      color: #282727;
    }
    .check-icon {
      margin-top: 5px;
      svg {
        min-height: 19px;
        min-width: 19px;
        max-height: 19px;
        max-width: 19px;
      }
    }
    .ok.btn {
      background: #ff0000;
      border-radius: 6px;
      color: #ffffff;
      margin-bottom: 22px;
      margin-right: 44px;
    }
  }
}

@include mq('sm') {
  .popup-creator-block_content-lg {
    left: 50%;
  }
}

.create-mess-creator-page {
  z-index: 13;
  position: fixed;
  bottom: 90px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(40, 199, 111);
  border-radius: 6px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.home-page-content.fan.more-padding-bottom {
  padding-bottom: 85px !important;
  .create-mess-creator-page {
    bottom: 95px !important;
    @include mq('md') {
      bottom: 95px !important;
    }
  }
}
