@import 'src/style/variables';
@import 'src/style/mixin';
.view-affiliator--banner-detail {
  background: #ffffff;
  min-height: calc(100vh - 128px);
  .banner-detail-wrapper {
    width: 476px;
    margin: 0 auto;
    padding-top: 32px;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
    @include mq('sm') {
      width: 100%;
    }
    @include mq('sm') {
      padding: 24px 12px;
    }
  }
  img {
    width: 476px;
    height: 278px;
    object-fit: cover;
    @include mq('sm') {
      width: 100%;
    }
  }
  .d-flex {
    gap: 32px;
    margin-top: 16px;
  }
  .name {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    width: 140px;
    min-width: 140px;
  }
  .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    @include mq('sm') {
      max-width: 160px;
    }
  }
  .btn-wrapper {
    gap: 32px;
    margin-top: 24px;
    .btn {
      width: 50%;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      border-radius: 6px;
      height: 44px;
      @include mq('sm') {
        width: 160px;
      }
    }
    .btn-share {
      background: #ff0000;
      color: #ffffff;
    }
    .btn-download {
      border: 1px solid #787880;
    }
  }
}
