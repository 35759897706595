@import 'src/style/variables';
@import 'src/style/mixin';

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-01;
  svg {
    width: 80px;
    height: 22px;
  }
  margin-top: 8px;
  min-height: 60px !important;
  max-height: 60px !important;
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  &.footer-pc {
    @media screen and (max-width: 991px) {
      display: none !important;
    }
  }
  &.footer-login {
    width: 375px;
  }
}

.footer-vender {
  margin-top: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

  .footer-terms {
    .block-div {
      padding: 20px;
      @include mq('sm') {
        padding: 0;
      }
      hr {
        display: none;
      }
      .contact-about {
        display: none;
      }
    }
    // .block-div .block-center .content-left {
    //   width: 50%;
    // }
    // .block-div .block-center .content-right {
    //   width: 50%;
    // }
  }
}
