@import 'src/style/variables';
@import 'src/style/mixin';
.navbar-type-post {
  &.is_fan_post{
    position: sticky;
    top: 59px;
    z-index: 13;
  }
  display: flex;
  justify-content: space-between;
  padding: 4px;
  height: 48px;
  background: #F2F3F5;
  width: auto;
  margin: 0 auto;
  border-radius: 43px;
  .tab-item {
    background: #F2F3F5;
    color: #666565;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    border-radius: 43px;
    //border-bottom: 1px solid $color-07;
    cursor: pointer;
    &.active {
      font-weight: 500;
      background: $color-26;
      color: $color-01;
      border-radius: 43px;
      //border-bottom: 2px solid $color-06;
    }
  }
}
