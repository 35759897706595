@import 'src/style/variables';
@import 'src/style/mixin';
.fansite-sign-up-creator {
  &__active:focus {
    border: 1px solid #1480ff !important;
  }
  .css-15obhdq-control {
    border-color: #2684ff !important;
  }
  background-color: transparent;
  background: url('../../../images/bg-singin.png');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .nation-zindex {
    z-index: 3;
  }
  // min-height: 100vh;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq('md') {
    padding: 0;
  }
  .box-sign-up {
    width: 772px;
    max-width: 772px;
    margin-top: 40px;
    @include mq('md') {
      width: 820px;
      max-width: 820px;
    }
    // box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 28px;
    }
    padding-top: 33px;
    padding-bottom: 15px;
    background: $color-01;
  }

  .fansite-form {
    padding: 0 48px 32px 48px;
    background: $color-01;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    @include mq('md') {
      border-radius: 0;
      padding: 16px;

    }
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
      padding-top: 48px;
      @include mq('mw768') {
        white-space: pre-line;
        padding-top: 0;
      }
    }

    .note-policy-init {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-26;
      padding-left: 23px;
      padding-right: 19px;
    }

    .link {
      color: $color-26;
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }

    .text {
      text-align: center;
      //color: $color-08;
      span {
        color: $color-26;
      }
    }
    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.button-submit {
        margin-bottom: 12px;
        @include mq("sm") {
          margin-bottom: 16px;
        }
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 4px;
      }
      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      input,
      select {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-14;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
        }
      }
    }
    .confirm-password,
    .password,
    .gender,
    .birthday {
      position: relative;
      .active-border {
        border: 1px solid #1480ff;
      }
      .anticon {
        top: 0;
        bottom: 0;
        position: absolute;
        right: 15px;
        transform: none;
        color: $color-14;
        z-index: 4;
        height: 44px;
        display: flex;
        align-items: center;
      }
      input {
        padding: 9px 30px 9px 12px;
      }
    }
    .birthday {
      .anticon {
        user-select: none;
        pointer-events: none;
        cursor: pointer;
      }
    }
    .password,
    .confirm-password {
      .anticon {
        z-index: 1;
        right: 10px;
        svg {
          height: 20px;
        }
      }
    }
    .gender {
      .css-6j8wv5-Input,
      .css-319lph-ValueContainer {
        margin: 0;
        padding: 0 0 0 9px;
      }
      .css-6j8wv5-Input {
        padding-left: 0;
      }

      .css-1okebmr-indicatorSeparator,
      .css-tlfecz-indicatorContainer,
      .css-tj5bde-Svg {
        display: none;
      }
      .css-1pahdxg-control {
        outline: none;
        border: 1px solid $color-14;
        box-shadow: none;
        &:focus,
        &:hover {
          border: 1px solid $color-14;
          outline: none;
          box-shadow: none;
        }
      }
      .css-4ljt47-MenuList {
        & > div {
          background-color: $color-01;
          color: $color-03;
          &:hover {
            background-color: $color-07;
            color: $color-03;
          }
        }
      }
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(180deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(0deg);
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .gender_input_radio-wrap {
        display: flex;
        align-items: center;

        .gender_input_radio {
          height: 24px;
          display: flex;
          align-items: center;

          & + .gender_input_radio {
            margin-left: 24px;
          }

          input {
            width: 14.67px;
            height: 14.67px;
            margin-right: 8px;
            padding-right: 0;
            accent-color: #dc143c;
          }
        }
      }
    }

    .birthday {
      .anticon {
        right: 10px;
      }
      svg {
        width: 20px;
      }
    }
    .button-submit {
      &.disabled {
        button {
          background-color: $color-26;
          opacity: 0.3;
          font-weight: 500;
          border-radius: 8px;
          cursor: auto;
        }
      }
      button {
        height: 100%;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        background-color: $color-26;
        box-sizing: border-box;
        border-radius: 8px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    svg {
      width: 80px;
      height: 22px;
    }
    margin-top: 8px;
    height: 60px;
  }
  .css-ackcql {
    // height: 44px;
    // margin: 0;
  }
  // .translate-fix {
  //   transform: translateY(18%)!important;
  // }
  .text-custom-error {
    color: #ea5455;
    font-size: 0.875rem;
    margin: 8px 0;
  }
}
.fansite-sign-up-success {
  height: calc(100vh - 60px);
}
