@import 'src/style/variables';
@import 'src/style/mixin';

.post-report-page {
  .fansite-form {
    padding: 16px 16px 0 16px;
    background: $color-01;
    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.button-submit {
        margin-bottom: 16px;
      }
      input:disabled {
        background-color: $color-07;
        color: $color-14;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 4px;
      }
      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      select,
      textarea,
      input {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-14;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
        }
      }
      textarea {
        height: 166px;
        min-height: 166px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .category {
      position: relative;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    .category {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          height: 11px;
        }
      }
    }

    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }
      button {
        height: 100%;
        width: 100%;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
