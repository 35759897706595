.popup-forgot-success {
  
  .popup-content {
    max-width: 416px;
    padding: 24px 32px;

    .btn {
      margin-top: 10px;
      max-width: 92px;
      margin-left: auto;
      height: 32px;
      background-color: #dc143c;
      font-weight: 400;
    }
  }
}
