.wrapper-bookmark {
  background-color: #787880;
  height: 41px;
  min-width: 26px;
  width: auto;
  display: inline-block;
  position: absolute;
  clip-path: polygon(100% 0, 100% 100%, 50% 90%, 0 100%, 0 0);
  top: 16px;
  right: 16px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.6); */
  &.active {
    background-color: red;
  }
  &.custom {
    top: 0;
  }
  &.top8Right8 {
    top: 8px;
    right: 8px;
  }
}

.button-bookmark {
  background-color: #fff;
  padding: 1px 5px;
  height: 39px;
  min-width: 24px;
  max-width: calc(100% - 2px);
  border-radius: 2px;
  clip-path: polygon(100% 0, 100% 100%, 50% 90%, 0 100%, 0 0);
  display: flex;
  align-items: center;
  flex-direction: column;
  &.active {
    background-color: red !important;
    color: #fff;
    svg {
      color: #fff;
    }
  }
  svg {
    width: 13px;
    height: 13px;
    margin-top: 5px;
    color: #787880;
  }
  .number-book-mark {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
