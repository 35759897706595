@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');

@import 'src/style/mixin';
@import 'src/style/utils';

@import 'src/style/header';
@import 'src/style/popup';

@import 'src/style/variables';

body,
html {
  font-family: $font-family-base !important;
  font-weight: $font-family-weight;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: $color-09;
  box-sizing: border-box;
  height: 100vh;
  height: -webkit-fill-available;
  // scroll-behavior: smooth;
  @media screen and (max-width: 991px) {
    padding: 0 !important;
  }

  .rotate-popup {
    display: none;
    .popup.popup-withdraw-success.no-button {
      .popup-content {
        .content {
          align-items: start;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  @media only screen and (max-height: 350px) and (orientation: landscape) {
    .rotate-popup {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// html {
//   height: -webkit-fill-available;
// }

// body {
//   height: 100vh;
// }

@mixin flex-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-less-than-1024 {
  @media screen and (max-width: 1023px) {
    display: none !important;
  }
}

.hide-greater-than-1024 {
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.h-100 {
  height: 100%;
}

.d-center {
  display: flex;
  justify-content: center;
}

.back-to-page {
  .anticon{
    outline: none !important;
    svg {
      outline: none !important;
    }
  }
}

.d-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: unset;
  text-decoration: none;
}

@for $i from 6 through 50 {
  .rounded-#{$i} {
    border-radius: #{$i}px !important;
  }
}

@for $i from 5 through 50 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 1 through 9 {
  .font-weight-#{$i}00 {
    font-weight: #{$i * 100} !important;
  }
}

@for $i from 1 through 100 {
  .line-height-#{$i} {
    line-height: $i !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.img-admin {
  position: absolute;
  max-width: 23px;
  max-height: 23px;
  min-width: 23px;
  min-height: 12px;
  top: 40%;
  left: 40%;
}

.img-common-blk {
  min-width: 44px;
  min-height: 44px;
  max-width: 44px;
  max-height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.form-item-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-07;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 5px;
    bottom: 5px;
    background-color: $color-01;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: $color-10;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 14px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.no-data {
  text-align: center;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #b1b6bb;
}
.display-linebreak {
  white-space: pre-line;
  word-break: break-word;
}
.dsl-t-space {
  white-space: pre-line;
}

button .spinner-border {
  width: 1.5em;
  height: 1.5rem;
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.loading-comment {
  margin-left: 16px;
  height: 45px !important;
}

.checkmark {
  display: inline-block;
  margin-top: 3px;
  width: 16.67px;
  height: 16.67px;
  background: $color-01;
  position: absolute;
  left: 0;
  top: 0;
  border: 1.5px solid $color-08;
  padding: 0;
  border-radius: 5px;
  box-sizing: border-box;
}
input:checked + .checkmark {
  background-color: $color-27;
  border: none;
}

input:checked + .checkmark:after {
  content: '';
  position: absolute;
  height: 5px;
  width: 8px;
  border-left: 2.5px solid $color-01;
  border-bottom: 2.5px solid $color-01;
  border-radius: 0.5px;
  top: 42%;
  left: 49.5%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
input:checked + .checkmark.radio:after,
input:checked + .checkmark.checkbox:after {
  height: 5px;
  width: 9px;
  top: 43%;
  left: 51.5%;
}

form {
  .box-checkbox,
  .box-radio-checkbox {
    width: 100%;
    overflow-x: hidden;
    .item {
      padding-left: 25px !important;
      display: inline-block;
      align-items: flex-start !important;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
    }
    .text {
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $color-17;
    }
    input {
      opacity: 0;
      position: absolute;
      width: 16.67px;
      height: 16.67px;
      left: 0;
      margin: 0;
      top: 3px;
    }
  }
}
.rounded-6px {
  border-radius: 6px;
}

.subscribers-area {
  background-color: #eeeeee;
  min-height: 90px;
  max-height: 150;
  overflow-y: auto;
}

@mixin btn {
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
}

.fsite-btn-submit {
  background: #ff0000;
  border: 1px solid #ff0000;
  color: #ffff;
  @include btn;
}

.fsite-btn-cancel {
  background: #ffffff;
  border: 1px solid #787880;
  color: #282727;
  @include btn;
}

.btn:disabled {
  //background-color: #b1b6bb;
  //border-color: #b1b6bb;
  background: #dc143c;
  opacity: 0.3;
  &:hover {
    background-color: #b1b6bb;
    border-color: #b1b6bb;
  }
}

.bg-while {
  &:hover {
    background-color: #f1f1f1 !important;
  }
}

.cancel {
  &:hover {
    background-color: #f1f1f1 !important;
  }
}

.button-red-new {
  &:hover {
    background-color: #CE1338 !important;
  }
}

.hover-blue {
  &:hover {
    background: #11B259 !important;
  }
}

.btn-forgot-pass:disabled {
  background-color: #dc143c;
  border-color: #dc143c;
  opacity: 0.3;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  user-select: none;
}
.btn-outline {
  background-color: #ffff;
}

.btn {
  border-radius: 6px;
  &:focus {
    box-shadow: none;
  }
}

.btn-lg {
  font-size: 14px;
  padding: 0.75rem 1rem;
}

.btn-danger {
  background-color: $color-26;
  border-color: $color-26;
  color: #ffffff;
  &:hover {
    background-color: $color-27;
    border-color: $color-27;
  }
}

.fw-500 {
  font-weight: 500;
}

.empty-box {
  color: #b1b6bb;
  text-align: center;
  padding: 50px 20px;
}

.currency-format-noinput {
  width: auto;
  outline: none;
  padding: 0;
  border: none;
  display: block;
  min-width: 0;
  user-select: none;
  pointer-events: none;
}

.text-red,
.text-danger {
  color: $color-06 !important;
}

.text-success,
.text-green {
  color: $color-10 !important;
}

.text-gray {
  color: #787880;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
  @include mq('md') {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.pt-24 {
  padding-top: 24px;
  @include mq('md') {
    padding-top: 20px;
  }
}

.font-24 {
  font-size: 24px;
}
.scroll-bar-custom {
  &::-webkit-scrollbar {
    background: transparent;
    width: 7px;
    height: 7px;
    scroll-margin-right: 7px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1.5px;
    background: $color-14;
    border-radius: 10px;
    border-right: 2px white solid;
    border-left: 2px white solid;
  }
}

.scroll-bar-custom-horizontal {
  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    height: 3px;
    background: $color-14;
    border-radius: 10px;
    border-top: 7px white solid;
    border-bottom: 7px white solid;
  }
}
.link {
  &:hover {
    color: inherit;
  }
}
textarea {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  resize: none;
  -webkit-appearance: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

input[name='password'],
input[name='password_confirmation'],
input[name='password_old'],
input[name='new_password'],
input[name='old_password'],
input[name='new_password_confirmation'] {
  padding-right: 40px !important;
  letter-spacing: 2px !important;
  &::placeholder {
    letter-spacing: 0;
  }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.video-react .video-react-button.video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-outline-danger {
  border-color: $color-26;
  color: $color-26;
  &:hover {
    background-color: $color-26;
    color: #ffffff;
  }
}

[placeholder] {
  text-overflow: ellipsis;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  text-overflow: ellipsis;
}
::-moz-placeholder {
  /* Firefox 19+ */
  text-overflow: ellipsis;
}
:-ms-input-placeholder {
  /* IE 10+ */
  text-overflow: ellipsis;
}
:-moz-placeholder {
  /* Firefox 18- */
  text-overflow: ellipsis;
}

textarea:placeholder-shown {
  text-overflow: ellipsis;
}

textarea::placeholder {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
}

span[type='button'] {
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.back-to-page {
  @media screen and (max-width: 375px) and (max-height: 812px) {
    margin-top: 0px !important;
  }
}

.video-react {
  background-color: $color-01;
}

.video-react-poster {
  background-size: contain !important;
  aspect-ratio: 4/3;
  margin: 0 auto;
  position: unset;
}

.item-list-sub {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 600px;
  @include mq('mw768') {
    max-width: 400px;
  }
  @include mq('sm') {
    max-width: 250px;
  }
  &.fan {
    max-width: 400px;
    @include mq('l') {
      max-width: 300px;
    }
    @include mq('mw768') {
      max-width: 400px;
    }
    @include mq('sm') {
      max-width: 250px;
    }
  }
}

.read-more {
  color: $color-28;
}

img.avatar_admin,
.avatar_admin img {
  object-fit: contain !important;
  background-color: #eee;
}

@include mq('sm') {
  .fansite-form {
    padding: 16px;
  }
}
body {
  @include mq('md') {
    background-color: #fff;
    overflow: auto !important;
  }
}

#root {

  @include mq('md') {
    height: 100% !important;
    min-height: 100vh;
    background-color: #fff;
  }
}
