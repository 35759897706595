@import 'src/style/variables';
@import 'src/style/mixin';
.package-item-container {
  max-width: 343px;
  border: 1px solid $color-06;
  border-radius: 6px;
  padding: 24px 12px;
  margin: 16px auto;
  &:first-child {
    margin-top: 0;
  }
  .text-center {
    text-align: center;
  }
  &__title {
  }
  &__price {
    font-size: 16px;
    color: $color-06;
    font-weight: 500;
    margin-bottom: 24px;
    .price-fee {
      font-weight: normal;
      font-size: 14px;
    }
  }
  &__description {
    white-space: pre-line;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 279px;
    height: 44px;
    color: white;
    max-width: 100%;
    & + .btn {
      margin-left: auto !important;
    }
  }
  .btn-danger {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  &__create-ticket {
    background-color: #ff9f43;
  }
  &__edit-ticket {
    background-color: #604de7;
  }
}
