
.video-thumbnail {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  z-index: 32;

  .blue-img {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    .bg-img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      min-height: unset;
      max-height: unset;
    }

    .image-item {
      position: absolute;
      z-index: 10;
      background: transparent;
      width: auto;
      height: 100%;
    }
  }

  .thumbnail-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
