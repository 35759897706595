@import 'src/style/variables';
@import 'src/style/mixin';

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  background: rgba(28, 27, 27, 0.2);
  position: fixed; // absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  .pp-disable-action {
    background: '#a9a9a9';
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    cursor: none;
  }

  .popup-content {
    margin: 16px;
    padding: 32px 32px 28px 34.75px;
    background: $color-01;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    min-width: 321px;
    min-height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    position: relative;
    @include mq('smHeight') {
      max-height: 80vh;
      //height: 80vh;
      overflow: hidden;
    }
  }
  .content {
    display: flex;
    // @include mq(sm){
    //   margin-top: 60px;
    // }
    @include mq('smHeight') {
      height: 100%;
      width: 100%;
      overflow: hidden;
      min-height: unset;
    }
    .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .check-icon {
    margin-top: 2px;
    min-width: 18.5px;
    max-width: 18.5px;
    min-height: 18.5px;
    max-height: 18.5px;
    svg {
      min-width: 18.5px;
      max-width: 18.5px;
      min-height: 18.5px;
      max-height: 18.5px;
    }
  }
  .box-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    border-radius: 6px;
    cursor: pointer;
    & + .btn {
      margin-left: 8px;
    }
  }
  .ok {
    background: $color-26;
    color: $color-01;
    font-weight: bold;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 36px;
    &:hover {
      background: $color-27;
    }
  }
  .cancel {
    border: 1px solid $color-32;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background-color: $color-01;
  }

  &.payment-success,
  &.register-success-fan {
    background: $color-15;
    position: relative;
    min-height: 100%;
    @include mq('sm') {
      z-index: 0;
    }
    .popup-content {
      padding: 0;
      background: unset;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      z-index: 10;
      // min-width: 375px;
      width: calc(100% - 32px);
      max-width: 375px;
      height: unset;
      border-radius: 12px;
      display: block;
      box-shadow: none;
      @include mq('sm') {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $color-01;
      padding: 32px 25px;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
      @include mq('sm') {
        padding: 50px 32px;
        box-shadow: none;
        border-radius: 0;
        margin-top: 40px;
      }
    }
    .confirm-twitter {
      @include mq('sm') {
        padding: 77px 16px;
      }
      .content-body {
        line-height: 20px;
        @include mq('sm') {
          padding: 0 16px;
          margin: 24px 0;
        }
      }
    }
    .point {
      color: #28c76f;
      font-weight: 700;
      font-size: 14px;
    }
    .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      color: $color-06;
    }
    .text {
      margin: 22px 0 44px 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      white-space: pre-line;
      @include mq('sm') {
        margin: 32px 0;
      }
    }
    .box-button {
      justify-content: center;
      width: 343px;
      @include mq('sm') {
        width: 100%;
      }
      .go-login {
        position: relative;
        bottom: unset;
        right: unset;
        background: $color-26;
        box-sizing: border-box;
        border-radius: 8px;
        color: $color-01;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        &:hover {
          background: $color-27;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-01;
      box-shadow: 0px 40px 64px 16px rgba(0, 0, 0, 0.05);
      svg {
        width: 80px;
        height: 22px;
      }
      margin-top: 8px;
      height: 60px;
    }
  }

  &.popup-copy-link {
    top: -60px;
    height: calc(100% + 60px);
    .popup-content {
      max-width: 343px;
      max-height: 117px;
      height: 117px;
      width: unset;
      height: unset;
      padding: 24px 16px;
      justify-content: unset;
    }
    .close-icon {
      position: absolute;
      top: 10.5px;
      right: 10.5px;
      svg {
        width: 20px;
        height: 20px;
        color: $color-08;
      }
    }
    .title {
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      margin: 0 0 20px 0;
    }
    .content {
      position: relative;
      justify-content: space-between;
      .text {
        font-size: 14px;
        line-height: 20px;
        margin-left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-16;
        max-width: 245px;
        max-width: 245px;
        margin-right: 38px;
        // margin-left: 24px;
      }
      svg {
        color: $color-06;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .copy-success {
        position: absolute;
        font-size: 14px;
        line-height: 20px;
        color: $color-01;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 6px 12px;
        background: $color-03;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 21;
      }
    }
  }
  &.popup-send-coin {
    top: -60px;
    height: calc(100% + 60px);

    .popup-content {
      max-width: 375px;
      // min-width: 375px;
      width: calc(100% - 32px);
      height: auto;
      padding: 0;
      justify-content: unset;
    }
    .close-icon {
      position: absolute;
      top: 10.5px;
      right: 10.5px;
      svg {
        width: 20px;
        height: 20px;
        color: $color-08;
      }
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-08;
      margin: 16px 0 2px 16px;
    }
    .value-choice {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-left: 16px;
      color: $color-03;
    }
    .box-coin {
      padding: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      max-height: 232px;
      overflow: auto;
      &.is-mobile {
        max-height: 155px;
      }
      .item {
        // width: 78px;
        width: calc(25% - 8px);
        height: 60px;
        background: $color-07;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        color: $color-17;
        &.active {
          background: $color-28;
          color: $color-01;
        }
        img {
          width: 24px;
          height: 24px;
        }
        .number {
          font-weight: bold;
          font-size: 14px;
          line-height: 22px;
          margin-top: 4px;
        }
      }
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5e6eb;
        border-radius: 10px;
      }
    }
    .box-btn {
      padding: 16px 0;
      margin: 0 16px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $color-07;
      .btn-submit {
        width: 72px;
        height: 32px;
        background: $color-30;
        border: none;
        box-sizing: border-box;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: auto;
        &.default-grey {
          background: #bebebe;
          border-radius: 8px;
        }

        &.active {
          cursor: pointer;
          color: $color-01;
          background: $color-26;
        }
        .anticon {
          color: $color-01;
          svg {
            width: 16.67px;
            height: 16.67px;
          }
        }
      }
    }
  }

  &.confirm-fan {
    z-index: 99999;
    .box-button {
      margin-top: 10px;
    }
    .popup-content {
      height: auto;
      min-height: 163px;
      max-width: 622px;

      .content-text {
        line-height: 24px;
        font-weight: 400;
        font-size: 16px;
        margin: 10px 0 23px auto;
        width: 100%;
        margin-left: 38px;
      }
    }
    .text {
      font-weight: 700 !important;
    }
    .text2 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $color-06;
      margin-top: 8px;
      margin-bottom: 17px;
      white-space: pre-line;
    }
    .text3 {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $color-03;
      margin: 8px 0;
    }
  }
  &.popup-delete {
    .popup-content {
      min-height: 163px;

      .box-button {
        .btn.cancel {
          padding: 6px 18px;
        }
      }
    }
  }
  &.popup-general {
    .popup-content {
      // min-width: 375px;
      width: calc(100% - 32px);
      max-width: 375px;
      padding: 0;
      border-radius: 12px;
      height: unset;
    }
    &.follow {
      .popup-content {
        // min-width: 375px;
        width: calc(100% - 32px);
        max-width: 497px;
        padding: 0;
        border-radius: 12px;
        height: unset;
      }
      &.is-mobile {
        .popup-content {
          height: 571px;
          width: 343px;
          .title {
            min-height: 56px;
          }
        }
      }
    }
    .popup-with-scroll {
      overflow-y: scroll;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      height: 56px;
      display: flex;
      justify-content: flex-start;
      padding-left: 24px;
      align-items: center;
      border-bottom: 1px solid $color-07;
    }
    .close-icon {
      position: absolute;
      top: 18px;
      right: 24px;
      svg {
        width: 15px;
        height: 15px;
        color: $color-08 !important;
      }
    }
    .box-general {
      height: 716px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        color: $color-03;
        .info-user {
          display: flex;
          align-items: center;
          gap: 12px;
          height: 60px;
          width: calc(100% - 110px);
          &.live {
            gap: 16px;
          }
          &.mobile {
            width: 100%;
            &.live {
              gap: 12px;
            }
          }
          .ranking {
            font-weight: bold;
            font-size: 12px;
            line-height: 17px;
            min-width: 22px;
            max-width: 22px;
            text-align: center;
            img {
              min-width: 22px;
              max-width: 22px;
              height: 23.5px;
            }
          }
          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
          }
          .avatar {
            min-height: 40px;
            min-width: 40px;
            max-height: 40px;
            max-width: 40px;
          }
        }
        .box-coin {
          display: flex;
          align-items: center;
          gap: 4px;
          img {
            height: 16px;
            width: 16px;
            border-radius: 50%;
          }
          .number-coin {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $color-08;
          }
        }
      }
    }

    .box-general .fansite-form {
      input[type='checkbox'] {
        width: 16.67px;
        height: 16.67px;
      }
    }
  }
  &.popup-general.follow {
    .box-follow-block {
      .btn {
        font-size: 12px;
        line-height: 20px;
        border-radius: 4px;
        padding: 3px 12px;
        border: 1px solid $color-14;
      }
      .btn.active {
        border: none;
        background-color: $color-06;
        color: white;
      }
    }
  }
  &.popup-general.delivery-setting,
  &.popup-general.delivery-change {
    .popup-content {
      max-width: 375px;
    }
    &.obs {
      .popup-content {
        max-width: 445px;
      }
    }
    .box-general {
      height: auto;
      overflow: unset;
    }
    .fansite-form {
      padding: 16px 16px 24px 16px;
      .fsite-id label {
        position: relative;
        &::after {
          content: '*';
          color: $color-06;
        }
      }

      textarea,
      input {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-14;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
        }
      }
      textarea {
        height: 100px;
        background-color: $color-07;
        min-height: 100px;
        border: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .input-time {
        position: relative;
        margin-bottom: 21px !important;
        .time {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: $color-14;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 6.5px;
          }
          &.time-custom {
            transform: translateY(10%);
          }
        }
      }

      .fansite-form-input__controls {
        margin-bottom: 16px;
        @include mq('sm') {
          margin-bottom: 12px;
        }
        &.button-submit {
          margin-bottom: 0;
        }
        &.any {
          margin-bottom: 12px;
        }
        .form-label.fansite-form-input__label {
          font-weight: normal;
          margin-bottom: 4px;
          .fansite-form-input__required {
            display: none;
          }
        }

        .fansite-form-input__error.text-danger {
          color: $color-13;
          line-height: 20px;
          margin-top: 4px;
          margin-bottom: 0;
        }

        // disabled
        input:disabled,
        .css-lr9fot-singleValue,
        .css-1insrsq-control {
          background: $color-07;
          cursor: auto;
          color: $color-14;
        }
      }

      .box-checkbox {
        display: flex;
        justify-content: space-between;
        padding: 0;
      }
      //select
      .select {
        .option {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .avatar {
          width: 26px;
          height: 26px;
        }
        svg {
          color: $color-14;
        }
        select {
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */
          appearance: none;
        }
        .anticon {
          right: 10px;
          &.focus {
            svg {
              transform: rotate(90deg);
            }
          }
          &.no-focus {
            svg {
              transform: rotate(-90deg);
            }
          }
          svg {
            width: 20px;
            height: 11px;
          }
        }
      }
    }
    .subscribers-area {
      .box-checkbox .item {
        width: 50%;
        .text {
          white-space: nowrap;
        }
      }
    }
    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }

      button {
        height: 100%;
        width: 100%;
        font-weight: bold;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        &:focus {
          outline: none;
        }
      }
    }
    .list-user-selected-wrapper {
      border: 1px solid #efefef;
      border-radius: 6px;
      width: 100%;
      position: relative;
      cursor: not-allowed;
      background-color: #eee;
      &.approve {
        cursor: unset;
        background-color: #fff;
      }
      margin-bottom: 12px;
      .list-user {
        padding: 8px 40px 8px 8px;
        display: flex;
        flex-wrap: wrap;
        min-height: 44px;
        gap: 8px;
        max-height: 134px;
        overflow-y: auto;
        font-size: 14px;
      }

      .item {
        padding: 4px 4px 4px 8px;
        background: #eeeeee;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        span {
          color: #b1b6bb;
          margin-left: 8px;
        }
      }
      .btn-popup-select-user {
        position: absolute;
        top: 13.5px;
        right: 13.5px;
        width: 16.5px;
        height: 16.5px;
        cursor: pointer;
        &.notAllow {
          cursor: not-allowed;
          pointer-events: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.5px solid #787880;
        border-radius: 6px;
        padding-bottom: 2px;
      }
    }
    .subscribers-area {
      max-height: 150px;
      @include mq('sm') {
        max-height: 100px;
      }
      overflow-y: auto;
      .item {
        overflow: hidden;
        text-overflow: ellipsis;
        .text {
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.popup.update-email {
  .popup-content {
    padding-right: 36px;
  }
}

.popup.popup-loading {
  z-index: 99999;
  .popup-content {
    display: flex;
    width: max-content;
    min-width: max-content;
    background: transparent;
    box-shadow: none;
  }
  &.loading-reload-page {
    background: $color-01;
    .loader {
      border: 5px dotted rgba(0, 0, 0, 0.2);
    }
  }
}

.popup.popup-ng-setting.popup-general {
  .box-general.pp-up-ng-setting-views {
    overflow-y: visible;
    padding: 16px 0 28px 0;
    position: relative;
    height: 170px;
  }
  .box-table {
    height: 155px;
    overflow: auto;
    position: absolute;
    width: 100%;
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      display: none;
    }
  }
  .table-setting {
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid $color-14;
    margin: 0 24px;
  }
  .table-head,
  .row-item {
    display: flex;
    background: $color-07;
    height: 29px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    position: relative;
    .icon-close {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
  .no {
    width: 32px;
    border-right: 1px solid $color-14;
  }
  .text-setting {
    width: calc(100% - 32px);
  }
  .table-body {
    .row-item {
      background: transparent;
      border-top: 1px solid $color-14;
    }
    .text-setting {
      justify-content: flex-start;
      margin-left: 8px;
    }
  }
  .row-item-add input,
  .row-item-add .input {
    border: none;
    outline: none;
    width: 100%;
    padding-right: 10px;
  }
  .btn-save-local {
    border: 1px solid $color-06;
    border-radius: 6px;
    color: $color-06;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin: 8px 24px 0 24px;
    width: calc(100% - 48px);
    position: absolute;
    bottom: calc(51px + 24px);
  }
  .box-btn-submit {
    position: absolute;
    bottom: 0;
    padding: 0 20px;
    box-shadow: inset 0px 1px 0px #f0f0f0;
    margin-top: 24px;
    .btn-submit.btn {
      font-weight: 700;
      font-size: 12px;
      line-height: 10px;
      margin: 10px 0;
    }
  }
  .inputWrapper-ngword {
    .tooltip {
      color: $color-14;
      .tooltiptext {
        top: 64%;
        left: 100%;
        transform: translate(10px, -50%);
        bottom: unset;
        right: unset;
        height: 81px;
        display: flex;
        align-items: center;
        &::after {
          top: 50%;
          left: -5px;
          transform: translate(0, -50%) rotate(270deg);
        }
      }
    }
  }
}

.popup.delivery-setting {
  @include mq('smHeight') {
    .box-general {
      height: 300px !important;
      overflow-y: auto !important;
    }
  }
}
.popup.popup-fan-ranking {
  .title {
    color: $color-03;
    border-bottom: 1px solid $color-07;
  }
  .text-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $color-14;
  }
  .box-general.pp-up-fan-views {
    height: 716px;
    max-height: 716px;
    @media screen and (max-width: 1023px) {
      height: 515px;
      max-height: 515px;
      overflow: hidden !important;
    }
  }
  @media screen and (max-width: 1023px) {
    .popup-content {
      height: 571px;
      width: 343px;
      .title {
        min-height: 56px;
      }
    }
  }
}
.pp-up-fan-views {
  overflow-y: auto !important;
}

.popup.popup-general.follow {
  .box-general.pp-up-fan-views {
    @media screen and (max-height: 450px) {
      max-height: calc(100vh - 120px);
      overflow: auto;
    }
  }
}
.popup-share-post {
  z-index: 2000 !important;
  @media screen and (max-height: 991px) {
    z-index: 4002 !important;
  }
  .popup-content {
    padding: 16px 0px 24px 0px;
    width: 100%;
    max-width: 445px;
    height: auto;
    font-weight: normal;
    .popup-header {
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 16px;
    }
    .sub-title {
      //border-top: 1px solid #a988882e;
      padding: 0 24px;
      margin-bottom: 13px;
      font-size: 14px;
      font-weight: 400;
    }
    .copy-link-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      .text {
        padding: 12px 0px 12px 12px;
        font-size: 14px;
        line-height: 20px;
        margin-left: 0;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #1a1a1a;
        max-width: 100%;
      }
      .anticon {
        color: $color-06;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .btn-copy-link {
        border: 1px solid #e5e6eb;
        margin-right: 6px;
        width: 74px;
        color: #fff;
        background-color: #dc143c;
        height: 32px;
        border-radius: 4px;
        font-weight: 400;
        font-size: 14px;
        &:hover {
          background-color: $color-27;
        }
        @include mq('sm') {
          width: 90px;
        }
      }
      .copy-success {
        position: absolute;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        color: $color-01;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 6px 12px;
        background: $color-03;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 21;
      }
    }
    .social-button {
      margin: 0px 0px 10px 10px;
      display: flex;
      align-items: center;
      .btn {
        outline: none !important;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-radius: 11px;
      }
      .btn-twitter {
        padding: 0px 0px 0px 20px;
        .anticon {
          color: $color-01;
        }
      }
      .btn-line {
        padding: 0px 0px 0px 20px;
        width: 70px;
        // background: #06c755;
      }
    }
  }
}

.popup-select-tag {
  .popup-content {
    width: calc(100% - 30px);
    max-width: 375px;
    height: auto;
    padding: 0;
  }
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
    .close-icon {
      position: absolute;
      right: 24px;
    }
  }
  .popup-body {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    max-height: 200px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background: #eeeeee;
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
  }
  .popup-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 16px;
  }
}

.popup-alert {
  .popup-content {
    width: calc(100% - 30px);
    max-width: 375px;
    height: auto;
    padding: 0;
  }
  .popup-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 16px 24px;
    .close-icon {
      position: absolute;
      right: 24px;
    }
  }
  .popup-body {
    padding: 24px 20px;
    display: flex;
    //align-items: center;
    &::-webkit-scrollbar-thumb {
      background: #eeeeee;
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
  }
  .popup-footer {
    display: flex;
    justify-content: flex-end;
    padding: 24px 32px;
  }
}

.popup {
  .ranking-name-tooltip-wrapper.tooltip {
    max-width: 136px;
  }
}

.popup.popup-success-subscribe {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    border-radius: 12px;

    .title {
      padding-left: 24px;
      justify-content: flex-start;
      display: none;
    }

    .close-icon {
      display: none;
    }
    .box-general {
      // margin: 24px 19px 22px 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: fit-content;

      svg {
        margin-top: 24px;
      }

      .success-message {
        margin: 0 19px 0 28px;
        color: #52c41a;
        font-size: 14px;
        font-weight: 600;
      }

      .success-description {
        font-size: 14px;
        font-weight: 500;
        margin: 0 19px 33px 28px;
      }

      .button-wrapper {
        // border-top: 1px solid #eeeeee;
        width: 100%;
        display: flex;
        justify-content: center;

        .ok.btn {
          margin: 16px;
          margin-top: unset;
          min-width: 124px;
        }
      }
    }
  }
}

.popup.unsub-modal {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    max-width: unset;
    width: 379px;
    height: 136px;
    padding: 32px 32px 22px 34.75px;

    .content {
      svg {
        min-width: 22px;
        max-width: 22px;
        min-height: 22px;
        max-height: 22px;
      }
    }

    .box-button {
      .ok.btn {
        width: 92px;
      }

      .cancel.btn {
        width: 119px;
      }
    }
  }
}

.popup.confirm-fan.popup-payment-tiger {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    max-width: 379px;
    padding: 32px 32px 22px 34.75px;

    .content {
      .anticon {
        svg {
          min-width: 22px;
          max-width: 22px;
          min-height: 22px;
          max-height: 22px;
        }
      }
    }
  }

  .box-button {
    .ok.btn {
      width: 92px;
      background-color: #dc143c;
      padding: 6px 8px;
    }

    .cancel.btn {
      width: 119px;
      padding: 6px 8px;
    }
  }
}

.popup.popup-success-payment-tiger {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    border-radius: 12px;

    .close-icon {
      display: none;
    }

    .title {
      padding-left: 24px;
      justify-content: flex-start;
      font-weight: 700;
      font-size: 16px;
      color: #1a1a1a;
      display: none;
    }

    .box-general {
      // margin: 24px 19px 22px 28px;
      display: flex;
      flex-direction: column;
      height: fit-content;
      font-weight: 400;
      font-size: 14px;

      .success-icon {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        margin-top: 24px;
      }
      .text-content {
        margin: 0 0 36px 28px;

        .div3 {
          display: flex;

          &.en {
            display: grid;
            grid-template-columns: 240px auto;
          }

          span {
            width: 240px;
          }
        }

        .en {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          .div2.en {
            display: contents;
          }
        }
      }

      .button-wrapper {
        // border-top: 1px solid #eeeeee;
        width: 100%;
        display: flex;
        justify-content: center;

        .ok.btn {
          margin: 16px;
          width: 124px;
          padding: 8px 10px;
        }
      }
    }
  }
}

.popup.confirm-fan.popup-setting-noti {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    padding: 34px 32px 24px 34px;
    .box-button {
      .ok.btn {
        min-width: 86px;
      }
    }
  }
}

.popup.confirm-fan.popup-success-post-help {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    padding: 34px 32px 24px 34px;
    .box-button {
      .ok.btn {
        min-width: 86px;
      }
    }
  }
}

.popup.confirm-fan.popup-confirm-delete-recording {
  .popup-content {
    padding: 34px 32px 24px 34px;
    width: 400px;

    .box-button {
      .ok.btn {
        min-width: 90px;
      }

      .btn.cancel {
        min-width: 90px;
      }
    }
  }
}

.popup.confirm-fan.popup-delete-post-details {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    padding: 34px 32px 24px 34px;
    .box-button {
      .ok.btn {
        min-width: 86px;
      }
      .btn.cancel {
        min-width: 90px;
      }
    }
  }
}

.popup.confirm-fan.popup-confirm-buy-post-premium-ticket {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    padding: 34px 32px 24px 34px;
    .box-button {
      .ok.btn {
        min-width: 86px;
      }
      .btn.cancel {
        padding: 6px 36px;
        min-width: 90px;
      }
    }
  }
}

.popup.popup-success-buy-post-sale-premium {
  background: rgba(40, 39, 39, 0.6);

  .popup-content {
    border-radius: 12px;
    width: 354px;
    height: 315px;

    .title {
      padding-left: 24px;
      justify-content: flex-start;
      display: none;
    }

    .close-icon {
      display: none;
    }
    .box-general {
      // margin: 24px 19px 22px 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;

      svg {
        margin-top: 24px;
      }

      .success-message {
        margin: 0 19px 0 28px;
        color: #52c41a;
        font-size: 14px;
        font-weight: 600;
      }

      .success-description {
        font-size: 14px;
        font-weight: 500;
        margin: 0 19px 16.5px 28px;
      }

      .button-wrapper {
        // border-top: 1px solid #eeeeee;
        width: 100%;
        display: flex;
        justify-content: center;

        .button-navigate {
          background: #ffffff;
          color: #1a1a1a;
          border: 1px solid #e5e6eb;
          border-radius: 8px;
          padding: 10px 40px;
          font-weight: 500;
          font-size: 16px;
        }

        .ok.btn {
          margin: 16px;
          margin-top: unset;
          min-width: 124px;
        }
      }
    }
  }
}

.thumbnail-bookmark-large {
  .popup.confirm-fan {
    .popup-content {
      padding: 4px;
    }
  }
}

.popup.confirm-fan.popup-confirm-delete-recording {
  z-index: 9999;
}
