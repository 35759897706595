@import 'src/style/variables';
@import 'src/style/mixin';

.ranking-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media screen and (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }
  .shimmer-loading {
    padding: 16px 0px;
  }
  .header-left-content {
    width: 100%;
  }
  .header-right-content {
    white-space: nowrap;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 400;
    width: calc(100% - 100px);
    text-align: end;
    @media screen and (max-width: 1280px) {
      white-space: pre-wrap;
      min-width: 320px;
    }
  }
  .ranking-container {
    padding: 24px;
    @include mq('md') {
      padding: 16px;
    }
    .list-ranking {
      margin-top: 24px;
      @include mq('md') {
        margin-top: 16px;
      }
    }
  }
  .no-data {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f1afbb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    text-align: center;
    .text {
      margin-right: -16px;
      color: #b1b6bb;
      font-weight: 400;
      font-size: 14px;
    }
    @include mq('sm') {
      position: fixed;
    }
  }
}
