.box-fansite-header {
  background-color: $color-01;
  position: fixed;
  z-index: 14;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}
.fansite-header {
  height: 60px;
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  position: relative;
  background-color: $color-01;
  @include mq('xl') {
    padding: 0;
    .content-right {
      color: $color-08;
      width: unset;
    }
  }
  @include mq('md') {
    height: 40px;
    min-height: 40px !important;
    padding: 0 5px;
  }
  @media screen and (max-width: 375px) and (max-height: 812px) {
    height: 40px;
  }
  // chua loggined
  .content-right {
    font-weight: 700;
    a {
      display: block;
    }
    .text1,
    .text2 {
      cursor: pointer;
      line-height: 22px;
    }
    .text1 {
      color: $color-03;
      margin-right: 32px;
    }
    .text2 {
      color: $color-04;
    }
  }

  &.loggined {
    .left {
      display: flex;
      align-items: center;
      svg {
        max-width: 90px;
      }
    }

    .form-search {
      margin-left: 23.71px;
      .form-item {
        background: $color-07;
        border: 1px solid $color-07;
        box-sizing: border-box;
        border-radius: 6px;
        height: 32px;
        width: 247px;
        display: flex;
        align-items: center;
        padding: 8.3px 0 8.7px 14px;
      }
      .anticon svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      input {
        border: none;
        background-color: transparent;
        margin-left: 6.7px;
        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }

    .content-right {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 355px);
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        width: 520px;
        text-align: center;
        color: $color-03;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq('md') {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
        .title-text {
          white-space: nowrap;
          overflow: hidden;
          width: 80%;
          text-overflow: ellipsis;
        }
      }
      //tooltip
      .title-header {
        text-align: center;
        .text {
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 90%;
          margin-left: 50px;
          @include mq('l') {
            margin-left: 30px;
          }
        }
        .tooltiptext {
          left: 50%;
          max-width: 500px;
          right: unset;
          transform: translate(-50%, 120%);
        }
      }
      .back-icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 15;
        @include mq('l') {
          left: 0px;
        }
      }

      .space {
        width: 375px;
        color: transparent;
      }
      &.has-avatar {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: calc(100% - 305px);
        @include mq('xl') {
          width: calc(100% - 380px);
        }
        @include mq('l') {
          width: calc(100% - 300px);
        }

        .infor-creator {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $color-03;
          margin: 0 auto;
          position: relative;
          padding: 0 20px;
          @include mq('md') {
            padding: 0;
          }
          .back-icon {
            left: 0px;
          }

          .name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 500;
            font-size: 20px;
            max-width: 80%;

            @include mq('md') {
              margin-right: 20px !important;
            }
          }
        }
        img {
          height: 44px;
          width: 44px;
          border-radius: 50%;
          border: 0;
        }

        .space {
          display: unset;
          @include mq('l') {
            width: 324px;
          }
          @include mq('md') {
            display: none;
          }
        }
      }
    }

    @include mq('xl') {
      gap: 20px;
      justify-content: flex-start;
      .left {
        max-width: 330px;
        width: 330px;
      }
      .content-right {
        width: calc(100% - 330px);
        display: flex;
        gap: 20px;
        .title {
          width: 60%;
        }
        .space {
          display: none;
        }
      }
      .form-search {
        margin-left: 15px;
        .form-item {
          width: 100%;
          max-width: 216px;
        }
      }
    }
    @include mq('l') {
      .content-right {
        width: calc(100% - 280px);
      }
      .left {
        max-width: 270px;
        width: 270px;
      }
    }

    &.mobileScreen {
      justify-content: space-between;
      position: relative;
      padding: 0 16px 0 24px;
      @include mq('sm') {
        padding: 0 16px;
      }
      .left {
        width: unset;
      }
      .center {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        font-size: 20px;
        min-width: 65%;
      }
      .content-right {
        width: unset;
        gap: unset;
        &.undefined {
          .title {
            width: 100%;
            @include mq('sm') {
            }
            &.page-F021,
            &.page-F015 {
              white-space: normal !important;
              word-break: break-all;
              font-size: 16px;
            }
          }
        }
        .anticon {
          & + .anticon {
            margin-left: 14px;
            svg {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
      .search-mobile {
        height: 64px;
        display: flex;
        align-items: center;
        .anticon {
          cursor: pointer;
          color: $color-03;
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        .form-search {
          margin-left: 20.5px;
          .form-item {
            width: 307px;
          }
        }
      }
    }
  }
}
