@import 'src/style/variables';
@import 'src/style/mixin';

.post-report-page {
  .fansite-form {
    padding: 20px;
    background: $color-01;
    @include mq('md') {
      padding: 16px;
      .report-category {
        .select-report > div {
          width: 100%;
        }
        .anticon {
          pointer-events: none;
        }
      }
    }
    .fansite-form-input__controls {
      margin-bottom: 20px;
      @include mq('md') {
        margin-bottom: 16px;
      }
      &.button-submit {
        margin-bottom: 16px;
      }
      input:disabled {
        background-color: $color-07;
        color: $color-14;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        border: 1px solid $color-32;
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 8px;
        @include mq('md') {
          margin-bottom: 4px;
        }
      }
      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      select,
      textarea,
      input {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid $color-32;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 6px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
        }
      }
      textarea {
        min-height: 103px;
        resize: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    .btn {
      width: 100%;
      &.disabled {
        button {
          background-color: $color-30;
          cursor: auto;
        }
      }
      button {
        height: 100%;
        width: 100%;
        background-color: $color-26;
        box-sizing: border-box;
        border-radius: 6px;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        &:focus {
          outline: none;
        }
      }
      &.button-cancel {
        button {
          cursor: pointer;
          color: $color-17;
          background-color: #fff;
          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
    }

    .footer {
      width: 100%;
      border: none !important;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      button {
        padding: 8px 24px;
      }
      @include mq('sm') {
        display: block;
      }
      .btn {
        padding: 0;
      }
    }
  }
  .wrapper-content-ogp {
    &.have-meta {
      border-radius: 6px;
      border: 1px solid #e5e6eb;
      padding: 16px;
      .content-ogp {
        border: none;
        border-radius: 0;
        padding: 0;
        min-height: 40px;
        height: 40px;
        max-height: 40px;
      }
    }
  }
}
