@import 'src/style/variables';
@import 'src/style/mixin';

.chart-report-layout {
  min-height: calc((100vh - 60px) - 70px);
  @media (max-width: 1023px) {
    .back-to-page {
      display: none;
    }
  }

  .pick-month {
    .pick-month-input {
      @media screen and (max-width: 575px) {
        max-width: 343px;
      }
    }
  }

  .table-container {
    overflow-x: scroll;
  }
}
.chart-report-page {
  padding-top: calc(20px - 1rem);
  padding-bottom: 24px;
  border-radius: 0px 0px 20px 20px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;

  @include mq('sm') {
    overflow-x: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
  .report-chart-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #7b7b7b;
  }
  .report-table-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .chart-circle {
    .recharts-legend-item {
      &.legend-item-0 {
        display: none !important;
      }
      margin-bottom: 20px;
      font-size: 12px;
      .recharts-surface {
        width: 10px;
      }
    }
  }
  .chart-subscription,
  .chart-content,
  .chart-livestream {
    .recharts-legend-wrapper {
      width: max-content !important;
    }
    .recharts-default-legend {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}
