@import 'src/style/variables';
@import 'src/style/mixin';

.shimmer-loading {
  padding: 24px;
}

.passwork-info {
  min-height: calc((100vh - 60px) - 70px);
  .back-to-page {
    display: flex;
    box-shadow: none;
    border-bottom: 1px solid #e5e6eb;
    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.user-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  .btn-update-email {
    background: $color-06;
    border-radius: 6px;
    padding: 6px 12px;
    color: $color-01;
    cursor: pointer;
  }
  .box-user-wrapper {
    background-color: $color-01;
    width: 100%;
    .header {
      li {
        white-space: pre-line;
      }
    }
  }
  .form-change-password {
    padding-top: 16px;
  }
  // form
  .fansite-form {
    padding: 16px 24px 32px 24px;
    @include mq('sm') {
      padding: 0 16px 16px 16px;
    }
    .avatar {
      display: flex;
      justify-content: center;
      .box-file {
        width: 100px;
        height: 100px;
        position: relative;
      }
      input[type='file'] {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        border: 1px solid $color-01;
        object-fit: cover;
      }
      .camera-icon {
        color: red;
        width: 26px;
        height: 26px;
        border: 1px solid $color-01;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-07;
        svg {
          width: 9.53px;
          height: 8.58px;
        }
      }
    }

    .fsite-id label {
      position: relative;
      &::after {
        content: '*';
        color: $color-06;
      }
    }

    .fansite-form-input__controls {
      margin-bottom: 16px;
      &.category {
        margin-bottom: 0;
      }
      &.button-submit {
        margin-bottom: 0;
      }
      .form-label.fansite-form-input__label {
        font-weight: normal;
        margin-bottom: 4px;
      }

      .fansite-form-input__error.text-danger {
        color: $color-13;
        line-height: 20px;
        margin-top: 4px;
        margin-bottom: 0;
      }
      input,
      textarea {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        width: 100%;
        border: 1px solid #e5e6eb;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 9px 12px;
        z-index: 2;
        height: 44px;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: $color-14;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
        }
      }
      textarea {
        height: 100px;
        min-height: 100px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      // disabled
      input:disabled {
        background: $color-07;
        cursor: auto;
        color: $color-14;
      }
    }

    .box-checkbox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;
      padding: 8px 0 4px 0;
      .label-checkbox.column {
        margin-top: 8px;
      }
    }

    .btn-show-more {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $color-04;
      margin-bottom: 16px;
    }

    .form-status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .label {
        font-size: 14px;
        line-height: 24px;
      }
      .status {
        padding: 6px 12px;
        border-radius: 4px;
        &.status6,
        &.status1 {
          background: rgba(40, 199, 111, 0.2);
          color: #28c76f;
        }
        &.status2 {
          background: rgba(234, 84, 85, 0.1);
          color: #ea5455;
        }
        &.status5 {
          background: rgba(255, 143, 80, 0.2);
          color: #f6851f;
        }
      }
    }

    .withdraw-money-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      .btn-withdraw-money {
        background: $color-06;
        border: 1px solid $color-06;
        border-radius: 6px;
        font-weight: 700;
        font-size: 12px;
        color: $color-01;
        line-height: 20px;
        padding: 6px 12px;
      }
    }

    .password,
    .old-password,
    .confirm-password,
    .gender,
    .birthday {
      position: relative;
      .anticon {
        position: absolute;
        right: 15.3px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-14;
        z-index: 1;
      }
    }
    //gender
    .gender {
      select {
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
      }
      .anticon {
        right: 10px;
        &.focus {
          svg {
            transform: rotate(90deg);
          }
        }
        &.no-focus {
          svg {
            transform: rotate(-90deg);
          }
        }
        svg {
          width: 20px;
          height: 11px;
        }
      }
    }
    //birthday
    .birthday {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    .btn-link-or-unlink-twitter {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      background-color: $color-06;
      color: $color-01;
    }

    .button-submit {
      &.disabled {
        button {
          background-color: $color-14;
          cursor: auto;
        }
      }

      button {
        height: 100%;
        width: 100%;
        font-weight: bold;
        background-color: $color-06;
        box-sizing: border-box;
        border-radius: 6px;
        color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        height: 44px;
        position: relative;
        color: $color-01;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .form-profile,
  .form-tranfer-account {
    .form-label::after {
      display: none;
    }
  }
  .form-tranfer-account {
    .fansite-form-input__required {
      display: none;
    }
    .anticon {
      right: 10px;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 11px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-01;
    svg {
      width: 80px;
      height: 22px;
    }
    margin-top: 8px;
    height: 60px;
  }
}

.user-wrapper.change-email,
.user-wrapper.without-money {
  .fansite-form-input__required {
    display: none;
  }
  .button-cancel button {
    margin-top: 16px;
    height: 44px;
    background: $color-01;
    border: 1px solid $color-08;
    border-radius: 6px;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
}

.user-wrapper {
  .flat-btn {
    text-align: right;
    margin-top: 12px;
    font-weight: 400;
    .reset-password {
      color: #1a1a1a;
    }
    .here {
      color: $color-26;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.user-wrapper.without-money {
  .list-content {
    padding: 16px;
  }
  form {
    padding: 0 16px;
  }
  .confirm {
    text-align: center;
  }
  ul {
    padding-left: 10px;
    li {
      list-style: none;
    }
  }
}

.confirm-change-email-step2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  .confirm-change-email-step2-wrapper {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-14;
    height: 300px;
    border-radius: 10px;
    padding: 0 30px;
  }
  .title {
    color: $color-06;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  .content {
    font-size: 16px;
    margin: 32px 0;
    text-align: center;
  }
  .btn {
    background: $color-06;
    color: $color-01;
    font-size: 16px;
  }
}
