@import 'src/style/variables';
@import 'src/style/mixin';

.create-and-update-card-layout {
  .shimmer-loading {
    padding: 16px 24px;
  }
  .mt-24 {
    margin-top: 24px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mb-14 {
    margin-bottom: 14px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .mb-4 {
    margin-bottom: 4px !important;
  }
  .psr {
    position: relative;
  }
  .back-to-page {
    margin-top: 0px !important;
  }
  .form-card {
    padding: 24px;
  }
  .block-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    &.block-item_50 {
      width: 50%;
    }
    @media (max-width: 1367px) {
      .holder {
        display: none;
      }
      &.block-item_50 {
        width: 100%;
      }
      flex-direction: column;
      gap: 0px;
      &.sub-block {
        flex-direction: row;
        gap: 17px;
      }
      &.sub-col {
        flex-direction: column;
        gap: 12px;
      }
    }
    .item {
      width: 100%;
      label {
        font-weight: 400;
        font-size: 14px;
        color: #1a1a1a;
      }
      .tooltiptext {
        text-align: start;
      }
      .item-input {
        height: 44px;
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 12px;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #1a1a1a;
        &::placeholder {
          color: #bebebe;
        }
        &:active,
        &:focus {
          box-shadow: none;
          border: 1px solid #e5e6eb;
          outline: none;
        }
      }
      .icon-card {
        position: absolute;
        right: 14px;
        top: 55%;
        transform: translateY(-50%);
        &.jcbCard {
          span {
            transform: scale(1.5);
          }
        }
      }
    }
    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      border-radius: 8px;
      height: 44px;
      &.btn-submit {
        background: #dc143c;
        color: #ffffff;
      }
      &.btn-cancel {
        background: #ffffff;
        color: #1a1a1a;
        border: 1px solid #e5e6eb;
      }
    }
  }
}
