@import 'src/style/variables';
@import 'src/style/mixin';
.popup.popup-general.popup-list-package {
  .popup-content {
    max-width: 375px;
    .box-general.pp-up-list-package-views {
      overflow-y: auto;
      position: relative;
      height: 250px;
    }
    .list-package-wrapper {
      max-height: 189px;
      overflow-y: auto;
      position: absolute;
      width: calc(100% - 20px);
      margin: 0 8px 0px 8px;
      column-gap: 5px;
      .label-checkbox.column {
        margin-top: 8px;
      }
    }

    .box-btn-submit {
      position: absolute;
      bottom: 0;
      padding: 10px 0;
      box-shadow: inset 0px 1px 0px #f0f0f0;
      .btn-cancel {
        border: 1px solid $color-08;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        height: 32px;
        width: 84px;
        padding: 0;
      }
      .btn-submit {
        background: $color-06;
        border: 1px solid $color-06;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        margin-right: 16px;
      }
    }
  }
}
