@import 'src/style/variables';
@import 'src/style/mixin';
@mixin translate-50 {
  transform: translateY(-50%);
}

@mixin avatar {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  background-color: #eeeeee;
  z-index: 1;
  object-fit: cover;
  @include mq('sm') {
    width: 82px;
    height: 82px;
  }
}

.avatar-container {
  margin-top: -60px;
  margin-bottom: 40px;
  @include mq('sm') {
    margin-top: -44px;
    margin-bottom: 16px;
  }
  .camera-bg {
    left: 65px;
    bottom: -5px;
    cursor: pointer;
    @include mq('sm') {
      left: 50px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
  .navigate-fan {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    padding: 6px 13px;
    background: #ff0000;
    border-radius: 6px;
    height: max-content;
    margin-top: 115px;
    @include mq('sm') {
      margin-top: 50px;
    }
  }
}

.profile .avatar {
  @include avatar;
  bottom: -50%;
  z-index: 0;
}

.creator-profile-info .avatar {
  @include avatar;
  bottom: -50%;
  z-index: 0;
}

.box-user-wrapper .avatar {
  @include avatar;
  bottom: -50%;
  z-index: 0;
}

.z-index-20 {
  z-index: 20;
}

.avatar-error {
  margin-top: -20px;
  margin-bottom: 20px;
}
