@import 'src/style/variables';

.create-survey-layout {
  @media screen and (max-width: 1023px) {
    .back-to-page {
      margin-top: 0px !important;
    }
  }
}
.post-survey-page {
  padding: 20px;
  margin-bottom: 16px;
  min-height: 576px;
  .crp {
    cursor: pointer;
  }
  input,
  textarea {
    &::placeholder {
      color: #b1b6bb;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }
  @include mq('sm') {
    padding: 16px;
    margin-bottom: 0;
    height: 100%;
  }
  .title {
    label {
      color: #282727;
      font-size: 14px;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
      height: 44px;
      padding: 0 12px;
    }
    textarea {
      width: 100%;
      height: 106px;
      padding: 12px;
    }
    input,
    textarea {
      border: 1px solid #e5e6eb;
      border-radius: 6px;
    }
  }

  .survey-box,
  .question-box {
    width: 100%;
    border-radius: 6px;
    border: none;
    padding: 0;
  }

  .survey-box {
    @include mq('sm') {
      border: 0;
      border-radius: 0;
      padding: 0;
    }
  }
  .date {
    position: relative;
    width: 50%;
    margin-right: 12px;
    @include mq('sm') {
      margin-right: 4px;
      width: 60%;
    }
    label {
      color: #282727;
      font-size: 14px;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
      height: 44px;
      padding: 0 12px;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
    }
    .anticon {
      position: absolute;
      right: 15px;
      bottom: 0;
      transform: translateY(-50%);
      color: $color-14;
      z-index: 1;
    }
  }
  .time {
    position: relative;
    width: 50%;
    margin-left: 12px;
    @include mq('sm') {
      margin-left: 4px;
      width: 40%;
    }
    label {
      color: #282727;
      font-size: 14px;
      margin-bottom: 4px;
      white-space: nowrap;
    }
    input {
      width: 100%;
      height: 44px;
      padding: 0 12px;
      border: 1px solid #e5e6eb;
      border-radius: 6px;
    }
    .time-icon {
      position: absolute;
      right: 13px;
      bottom: 0;
      transform: translateY(42%);
      color: $color-14;
      z-index: 1;
    }
  }
  .custom-time-post {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 112px;
    z-index: 3;
    .d-flex {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      border-bottom: 1px solid #f0f0f0;
    }
    border-radius: 6px;
    .hour,
    .minute {
      height: 224px;
      overflow-y: auto;
      width: 56px;
      text-align: center;
      background-color: white;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .hour {
      border-right: 1px solid #f0f0f0;
    }
    .item {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 3px;
      cursor: pointer;
    }
    .item.active {
      color: $color-01;
      background: $color-26;
      padding: 0 2px;
    }
    .box-btn {
      width: 100%;
      display: flex;
      align-items: end;
      height: 38px;
      padding: 6px;
      background-color: $color-01;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 2px 2px;
      .btn {
        color: $color-01;
        background-color: $color-26;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        height: 24px;
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
  }
  .question-add {
    margin-top: 20px;

    @include mq('sm') {
      margin-top: 16px;
    }

    button {
      width: 100%;
      border: 1px dashed #bebebe;
      border-radius: 6px;
      height: 44px;
      padding: 0 12px;
      color: #1480ff;
      text-align: start;
      background-color: #fff;
    }
    &-icon_plus {
      position: relative;
      top: -4px;
      margin-right: 14px;
    }
    &-text {
      color: #1480ff !important;
    }
  }
  .question-box {
    margin-top: 14px;
    border: 1px solid #e5e6eb;
    border-radius: 6px;
    padding: 12px 0px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);

    @include mq('sm') {
      padding: 12px 0px 16px 0;
    }

    .question-title {
      text-align: center;
      color: #282727;
      font-size: 14px;
      font-weight: 500;
    }
    .hyphen {
      background: #e5e6eb;
      margin: 0;
    }
    .question-header {
      .input-title {
        margin: 12px 20px;
        width: calc(100% - 40px);
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        height: 44px;
        padding: 0 12px;

        @include mq('sm') {
          margin: 12px 16px;
          width: calc(100% - 32px);
        }
      }
    }
    .answer {
      padding: 16px 20px;

      @include mq('sm') {
        padding: 16px;
      }

      .answer-title {
        color: #282727;
        font-weight: 500;
        font-size: 14px;
      }
      .list-answer {
        margin-top: 16px;
        input {
          width: 100%;
          border: 0;
          outline: 0;
          font-size: 14px;
          color: #212529;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          height: 44px;
          padding: 0 12px;
          &:focus {
            border-bottom: 1px solid #eeeeee;
          }
          &:disabled {
            background-color: #fff;
            color: #1a1a1a;
          }
        }
        &-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
        }
        &-drag {
          background-color: transparent;
        }
        .other-title {
          width: 100%;
          margin-left: 2px;
        }
        .other-text {
          color: #6f66d4;
          cursor: pointer;
        }
        .option-text {
          color: #787880;
          cursor: pointer;
        }
      }
      .add-answer {
        margin-left: 20px;
        button {
          width: 100%;
          border: 1px dashed #bebebe;
          border-radius: 6px;
          height: 44px;
          padding: 0 12px;
          color: #1480ff;
          text-align: start;
          background-color: #fff;
        }
        &-icon_plus {
          position: relative;
          top: -4px;
          margin-right: 14px;
        }
        &_other {
          margin-bottom: 10px;
        }
      }
    }
    .icon {
      display: flex;
      justify-content: end;
      padding-top: 12px;
      margin: 0 20px;
      border-top: 1px solid #eeeeee;
    }
    img {
      cursor: pointer;
    }
    .error-message {
      color: #ff0000;
      font-size: 12px;
    }
  }

  //button
  .container-btn {
    display: flex;
    @include mq('sm') {
      display: unset;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      font-family: 'Noto Sans JP';
      border-radius: 6px;
      outline: 0;
      cursor: pointer;
      margin-top: 24px;
      border-radius: 8px;
    }
    .btn-back {
      background: #fff;
      border: 1px solid #d9d9d9;
      color: #1a1a1a;
      @include mq('sm') {
        margin-top: 12px;
      }
    }

    .add {
      border: 1px solid #787880;
      color: #282727;
      margin-left: 12px;
      @include mq('sm') {
        margin-left: 0;
      }
    }

    .active {
      color: #fff;
      background-color: $color-26;
      border: 1px solid $color-26;
      margin-right: 12px;
    }

    .disabled {
      color: #fff;
      background-color: $color-30;
      border: 0;
      margin-right: 12px;
    }
  }
}
