@import 'src/style/variables';
@import 'src/style/mixin';
@mixin img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.loadding-upload {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  color: red;
  z-index: 15;
  @include mq('sm') {
    font-size: 12px;
  }
}

.loadding-upload-avatar {
  position: absolute;
  color: red;
  top: 50%;
  left: 50px;
  transform: translate(-50%, -50%);
  @include mq('sm') {
    font-size: 12px;
    left: 41px;
  }
}

.image-container {
  aspect-ratio: 2/1;
  @include img;
  background-color: #f4f4f4;
}

.bg-img {
  @include img;
  aspect-ratio: 2/1;
}

.w-h-bg {
  position: absolute;
  bottom: 16%;
  color: #bebebe;
  font-weight: 700;
  font-size: 14px;
  z-index: 1;
  @include mq('sm') {
    bottom: 10px;
  }
}

.edit-cover {
  z-index: 50;
  cursor: pointer;
  bottom: 5px;
  right: 5px;
  @include mq('sm') {
    bottom: 16px;
    right: 16px;
    svg {
      width: 36px;
      height: 36px;
    }
  }
}
